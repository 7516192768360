import styled from "styled-components"

export const MeWrap = styled.div`
  padding: 2% 3%;
  overflow-x: hidden;

  section {
    min-height: calc(100vh - 90px);
  }

  @media screen and (max-width: 600px) {
    padding: 3% 2%;

    section {
      padding: 5px 0;
    }
  }
`

import { NotificationItemWrap } from "./notification-widget.styles"
import { Button, FlexibleDiv } from "components/lib"
import { Avatar } from "antd"
import React from "react"

export type NotificationItemProps = {
  text: React.ReactElement
  timestamp: string
  avatar?: string
  hasAction?: boolean
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  text,
  timestamp,
  avatar,
  hasAction,
}) => {
  return (
    <NotificationItemWrap>
      <div className="avatar__icon">
        <Avatar src={avatar} />
      </div>

      <FlexibleDiv className="info" flexDir="column">
        <p className="notification__text">{text}</p>
        <p className="timestamp">{timestamp}</p>

        {hasAction && (
          <FlexibleDiv className="action__wrap">
            <Button contained color="var(--primaryBlack)">
              <span>Accept</span>
            </Button>
            <Button>
              <span>Decline</span>
            </Button>
          </FlexibleDiv>
        )}
      </FlexibleDiv>
    </NotificationItemWrap>
  )
}

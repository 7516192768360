import {
  Footer,
  Header,
  PaymentHero,
  PaymentInfo,
  Subscription,
} from "./Components"
import { PaymentHomeWrapper } from "./payment-home.styles"

export const PaymentHome = () => {
  return (
    <PaymentHomeWrapper>
      <Header />

      <PaymentHero />
      <Subscription />
      <PaymentInfo />

      <Footer />
    </PaymentHomeWrapper>
  )
}

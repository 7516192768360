import styled from "styled-components";
import { FlexibleDiv } from "../../Box/styles";

export interface MediaCardWrapProp {
  dominantColors?: Array<string>;
}

export const MediaCardWrap = styled(FlexibleDiv)<MediaCardWrapProp>`
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 15px;
  position: relative;

  .rank__badge {
    position: absolute;
    top: 0;
    left: 3px;
    z-index: 40;
    background-color: var(--primaryYellow);
    width: 30px;
    display: flex;
    align-items: center;
    font-weight: 900;
    line-height: 29px;
    font-size: 1.3rem;
    border-radius: 4px;
    justify-content: center;
    height: 30px;
    color: var(--primaryBlack);

    &.song__rank__badge{
      left: 0px;
    }
  }

  .media__image__wrapper {
    width: 190px;
    min-width: 190px;
    height: 190px;
    max-width: max-content;
    cursor: pointer;
    position: relative;
    z-index: 30;
    border-radius: 4px;

    .media__image {
      width: 100%;
      height: 100%;
      object-position: 100%;
      object-fit: fill;
    }

    &.album {
      .media__image1 {
        width: 50%;
        position: absolute;
        height: 80%;
        top: 5px;
        border-radius: 0px 0px 0px 4px;
        z-index: 1;
        left: 0;
        position: absolute;
        object-fit: cover;
      }
      .media__image2 {
        width: 95%;
        height: 95%;
        top: 0;
        left: 50%;
        border-radius: 4px;
        z-index: 3;
        transform: translateX(-50%);
        position: absolute;
        object-fit: cover;
      }

      .media__image3 {
        width: 85%;
        position: absolute;
        bottom: 0;
        z-index: 2;
        right: 0;
        height: 90%;
        object-fit: cover;
        object-position: 100%;
        border-radius: 0px 0px 4px 4px;
      }
    }
  }

  .information__and__actions {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 20px;
    padding: 5px 0;

    .media__info {
      flex-direction: column;
      row-gap: 5px;
      align-items: flex-start;
      justify-content: flex-start;
      .media__title {
        white-space: nowrap;
        font-weight: 700;
        font-size: 0.9rem;
        font-family: var(--mMedium);
        color: var(--primaryWhite);
      }
      .media__creator {
        white-space: nowrap;
        font-weight: 400;
        font-size: 0.7rem;
        font-family: var(--mLight);
        color: #717171;
      }

      .media__release__date__and__creator {
        white-space: nowrap;
        white-space: nowrap;
        font-weight: 400;
        font-size: 0.7rem;
        font-family: var(--mLight);
        color: #f5e8e8;
        line-height: 20px;
      }
    }

    .media__actions {
      column-gap: 10px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: nowrap;

      .icon__wrap {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          path {
            transition: all 0.5s ease;
          }
        }
      }
    }

    .play__btn {
      width: 35px;
      height: 35px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
      background: var(--primaryYellow);
    }
  }

  @media screen and (max-width: 1024px) {
    .media__image__wrapper {
      width: 88px;
      min-width: 88px;
      height: 88px;
    }

    .information__and__actions {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: 20px;
      justify-content: space-between;

      .media__info {
        .media__release__date__and__creator {
          display: none;
        }
      }

      .media__actions {
        justify-content: flex-end;
      }

      .play__btn {
        display: none;
      }
    }
  }

  @media screen and (max-width: 765px) {

    .rank__badge {
    width: 20px;
    height: 20px;
    font-size: 0.8rem;
  }
    .media__image__wrapper {
      width: 55px;
      min-width: 55px;
      height: 55px;
    }
  }
`;

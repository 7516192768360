export type IconProps = {
  fill?: string
  color?: string
  stroke?: string
  width?: string | number
  height?: string | number
  bgColor?: string
  className?: string
  style?: React.CSSProperties
  transform?: string
  size?: string | number
  onClick?: () => void
}

export interface StatusState {
  err?: string | boolean
  success?: string | boolean
}

export type DateType = "day" | "month" | "year"

export type Picture = {
  url: string
  id?: string
  filename?: string
}

export type CoverArt = {
  deleted: boolean
  category: string
  key: string
} & Picture

export enum ApiResponseBannerType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  IDLE = "IDLE",
}

export type EnrichedData = {
  isEnriched: boolean
  msisdn?: string
  isMtn?: boolean
}

export type StringNumberUnion = string | number

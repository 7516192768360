const env = process.env

export type ConfigProps = typeof Config

export const Config = {
  port: env.PORT || 3000,
  baseUrl: env.REACT_APP_BASE_URL,
  prod: env.NODE_ENV === "production",

  sourceService: env.REACT_APP_SOURCE_SERVICE || "udux",
  headerEnrichmentUrl:
    env.REACT_APP_HEADER_ENRICHMENT_URL || "http://mtn-service.udux.com/he",
  loggerUrl:
    env.REACT_APP_LOGGER_URL ||
    "https://4cca-105-112-112-96.ngrok-free.app/users",
}

import { FlexibleDiv } from "components"
import styled from "styled-components"

export const SubscriptionWrapper = styled(FlexibleDiv)`
  /* height: 100vh; */ // TODO: return to this...
  width: 100%;
  background: var(--primaryBlack);
  padding: 3rem 3.5%;

  .sub__lead__info {
    h2 {
      font-size: 2rem;
      color: var(--primaryWhite);
      font-weight: 600;
    }

    p {
      padding-top: 0.5rem;
      color: var(--primaryWhite);
      opacity: 0.6;
    }
  }

  //ipad screens fix
  @media (min-width: 601px) and (max-width: 1024px) {
    margin-top: -80px;
  }

  @media (max-width: 600px) {
    padding: 1rem 3.5%;

    .sub__lead__info {
      h2 {
        font-size: 1.75rem;
      }
    }
  }
`

import { CallIcon,  MtnIcon, UduXIcon } from "assets/images/Auth";
import ContinueWithPhoneGI from "assets/images/Auth/continue_with_phone.png"
// import SignUpGI from "assets/images/Auth/create_account.png";
import ExistingUserGI from "assets/images/Auth/existing_user.png";
import defaultGalleryImage from "assets/images/Auth/default.png";
import { Button, FlexibleDiv } from "components";
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { ActionBox } from "components/lib/ActionBox/ActionBox";
import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./loginType.styles";
import { checkAvailablePhone, loginWithPhone } from "network";
import { useMutation } from "react-query";
import { Types, useMainContext } from "context";
import { Config } from "config";
import { useEffect } from "react";
import { GoogleIcon } from "assets/images/Auth/Icons/GoogleIcon";
// import { FacebookIcon } from "assets/images/Auth/Icons/FacebookIcon";
// import { AppleIcon } from "assets/images/Auth/Icons/AppleIcon";

const checkHeight = window.innerHeight > 800 ? true : false;
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false;

const LoginType = () => {
  const [loadingWithMTN, setLoadingWithMTN] = React.useState(false);
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const { mutate, isLoading } = useMutation(loginWithPhone);
  const [activeSlide, setActiveSlide] = React.useState(3);
  const handleActionTileHover = (slideIndex: number) => {
    setActiveSlide(slideIndex);
  };

  const {
    dispatch,
    state: { app },
  } = useMainContext();

  const cardsObjects = [
    {
      redirectURL: () => navigate("/signup"),
      textURL: "/signup",
      pageHoverImage: defaultGalleryImage,
      boxIcon: <UduXIcon />,
      text: "Sign Up for <span>Free!</span>",
    },
    {
      redirectURL: () => navigate("/login/phone", { state }),
      textURL: "/login/phone",
      pageHoverImage: ContinueWithPhoneGI,
      boxIcon: <CallIcon />,
      text: 'Continue with <span>Phone No.</span>',
    },
    {
      redirectURL: () => navigate("/login/basic", { state }),
      textURL: "/login/basic",
      pageHoverImage: ExistingUserGI,
      boxIcon: <GoogleIcon />,
      text: 'Continue with <span>Google</span>',
    },
    // {
    //   redirectURL: () => navigate("/login/basic", { state }),
    //   textURL: "/login/basic",
    //   pageHoverImage: ExistingUserGI,
    //   boxIcon: <AppleIcon />,
    //   text: 'Continue with <span>Apple ID</span>',
    // },
    // {
    //   redirectURL: () => navigate("/login/basic", { state }),
    //   textURL: "/login/basic",
    //   pageHoverImage: ExistingUserGI,
    //   boxIcon: <FacebookIcon />,
    //   text: 'Continue with <span>Facebook</span>',
    // },
    {
      redirectURL: () => handleContinueWithMTN(),
      pageHoverImage: defaultGalleryImage,
      boxIcon: <MtnIcon />,
      text: 'Continue with <span>MTN</span>',
      loading: loadingWithMTN || isLoading,
    },
  ];
  

  const requestPhoneLogin = useCallback(
    async function (msisdn: string) {
      const phoneExists = await checkAvailablePhone(`+${msisdn}`);

      if (phoneExists?.exists) {
        // request for OTP and send user to opt input page.
        const payload = {
          phoneNumber: `+${msisdn}`,
        };

        mutate(payload, {
          onSuccess: () => {
            navigate("/login/verify-otp", { state: payload });
          },
          onError: (error) => {
            setLoadingWithMTN(false);
            dispatch({
              type: Types.API_RESPONSE_ERROR,
              payload: error,
            });
          },
        });
      } else {
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload:
            "This user is not on record, please create an account to continue!",
        });
      }
    },
    [dispatch, mutate, navigate]
  );

  useEffect(() => {
    const isEnriched = search.includes("isMtn") || search.includes("msisdn");

    if (app.isEnriched) {
      return;
    }

    if (isEnriched) {
      // update context
      const qp = new URLSearchParams(search);
      const msisdn = qp.get("msisdn");
      const isMtn = qp.get("isMtn") === "true";

      dispatch({
        type: Types.HE_SERVICE,
        payload: {
          msisdn,
          isEnriched: true,
          isMtn,
        },
      });

      if (isMtn && msisdn?.startsWith("234")) {
        requestPhoneLogin(msisdn);
      } else {
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload:
            "Sorry, It seems you aren't on MTN network, please choose a different option",
        });
      }
    }
  }, [search, dispatch, app, mutate, navigate, requestPhoneLogin]);

  return (
    <AuthLayout
      title="Follow the Rhythm."
      galleryImages={cardsObjects}
      activeSlide={activeSlide}
    >
      <Container checkHeight={checkHeight} smallHeight={checkSmallScreenHeight}>
        <FlexibleDiv className="cards_container">
          {cardsObjects.map((card, idx) => (
            <ActionBox
              key={idx}
              boxIcon={card.boxIcon}
              loading={card.loading}
              text={card.text}
              redirectURL={card.redirectURL}
              setHoverImage={() => handleActionTileHover(idx)}
            />
          ))}
          <FlexibleDiv justifyContent="center" alignItems="center" className="btn_box">
            <Button
               width="180px"
               height="40px"
               font_size="16px"
               type="submit"
               contained
               radius="10px"
               color="var(--black)"
               bold
               ltr
              onClick={() => navigate("/login/basic")}
            >
              <span>Existing User</span>
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
      </Container>
    </AuthLayout>
  );

  async function handleContinueWithMTN() {
    setLoadingWithMTN(true);

    const protocol = Config.prod
      ? "udux.com/login"
      : "http://localhost:3000/login";

    const url = `${Config.headerEnrichmentUrl}?redirectUrl=${protocol}`;
    console.log({ url });
    window.location.href = url;
  }
};

export default LoginType;

import React from "react";
import { IconProps } from "types";

export const RecentlyPlayedIcon = ({
  color = "#D9D9D9",
  width = "22.338",
  height = "22.198",
}: IconProps) => (
  <svg
    width={width || "16"}
    height={height || "20"}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.316406V19.6829H16V0.316406H0ZM8 2.73722C8.70724 2.73722 9.38552 2.99227 9.88562 3.44626C10.3857 3.90026 10.6667 4.516 10.6667 5.15804C10.6667 5.80008 10.3857 6.41583 9.88562 6.86982C9.38552 7.32381 8.70724 7.57886 8 7.57886C7.29276 7.57886 6.61448 7.32381 6.11438 6.86982C5.61428 6.41583 5.33333 5.80008 5.33333 5.15804C5.33333 4.516 5.61428 3.90026 6.11438 3.44626C6.61448 2.99227 7.29276 2.73722 8 2.73722ZM8 17.2621C7.47471 17.2621 6.95457 17.1682 6.46927 16.9857C5.98396 16.8032 5.54301 16.5358 5.17157 16.1986C4.80014 15.8614 4.5055 15.4611 4.30448 15.0205C4.10346 14.5799 4 14.1078 4 13.6309C4 13.154 4.10346 12.6819 4.30448 12.2413C4.5055 11.8007 4.80014 11.4004 5.17157 11.0632C5.54301 10.726 5.98396 10.4586 6.46927 10.2761C6.95457 10.0936 7.47471 9.99967 8 9.99967C9.06087 9.99967 10.0783 10.3822 10.8284 11.0632C11.5786 11.7442 12 12.6678 12 13.6309C12 14.594 11.5786 15.5176 10.8284 16.1986C10.0783 16.8796 9.06087 17.2621 8 17.2621Z"
      fill={color}
    />
    <path
      d="M8 15.4476C9.10457 15.4476 10 14.6348 10 13.632C10 12.6293 9.10457 11.8164 8 11.8164C6.89543 11.8164 6 12.6293 6 13.632C6 14.6348 6.89543 15.4476 8 15.4476Z"
      fill={color}
    />
  </svg>
);

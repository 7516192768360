import { LoadingOutlined } from "@ant-design/icons"
import { Form } from "antd"
import galleryImage from "assets/images/Auth/starting_page_img.png"
import {
  BorderedTextfield,
  Button,
  FlexibleDiv,
  PhoneInput,
  TabContent,
} from "components"
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout"
import { SourceService, Types, useMainContext } from "context"
import { login, loginWithPhone } from "network"
import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { validateBasicField, validatePassword } from "utils"
import { Container } from "./login.styles"
import { Config } from "config"
import { LoginPayload } from "types"
import { LoginTabHeader } from "components/lib/Tab/LoginTabHeader"

const checkHeight = window.innerHeight > 800 ? true : false
export type LoginTabRoutes = typeof loginTabRoutes
const loginTabRoutes = [
  { title: "Email", hash: "email" },
  { title: "Phone", hash: "phone" },
]
const Login = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [, setCountryCode] = useState("")
  const state = useLocation().state as unknown as Record<string, any>
  const { dispatch } = useMainContext()
  const [activeTab, setActiveTab] = useState<string>("email") // Default active tab is "Phone"
  const { mutate, isLoading } = useMutation(login)
  const { mutate: phoneMutate, isLoading: phoneLoading } =
    useMutation(loginWithPhone)

  async function handlePhoneFormSubmit() {
    const payload = {
      phoneNumber: `+${phoneNumber}`,
    }

    phoneMutate(payload, {
      onSuccess: () => {
        navigate("/login/verify-otp", { state: payload })
      },
      onError: error => {
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload: error,
        })
      },
    })
  }

  const handleFormSubmit = async (values: LoginPayload) => {
    mutate(
      {
        ...values,
        sourceService: Config.sourceService as SourceService,
      },
      {
        onSuccess: res => {
          if (res) {
            const { user, tokens } = res

            const pathToReturn = state?.from
              ? `${state.from.pathname}${state.from.hash}${state.from.search}`
              : "/"

            localStorage.setItem("udx__user", JSON.stringify(user))
            localStorage.setItem("udx__tokens", JSON.stringify(tokens))

            dispatch({ type: Types.CURRENT_USER, payload: user })
            dispatch({ type: Types.IS_LOGGED_IN, payload: true })
            navigate(pathToReturn)
          }
        },
        onError: error => {
          dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
        },
      }
    )
  }

  // Function to handle tab changes
  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab)
  }

  

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined })
  }, [dispatch])

  const renderTabContent = () => {
    switch (activeTab) {
      case "email":
        return (
          <Form form={form} onFinish={handleFormSubmit} className="form__box">
            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                type="text"
                paddingLeft="0px"
                borderBottom="1px solid #707070"
                name="emailAddress"
                placeholder="Email/Username "
                validator={validateBasicField}
                small
              />
            </FlexibleDiv>

            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                type="password"
                name="password"
                borderBottom="1px solid #707070"
                placeholder="Password"
                paddingLeft="0px"
                validator={validatePassword}
                small
              />
            </FlexibleDiv>

            <FlexibleDiv
              flexDir="column"
              className="btn__box"
              justifyContent="center"
            >
              <Button
                width="180px"
                height="40px"
                font_size="16px"
                type="submit"
                contained
                radius="10px"
                color="var(--uduxBlackPrimary)"
                bold
                ltr
              >
                <span>{isLoading ? <LoadingOutlined /> : "Login"}</span>
              </Button>
            </FlexibleDiv>

            <FlexibleDiv
              flexDir="column"
              className="btn__box"
              justifyContent="center"
            >
              <Link to="/reset-password" className="existing__user">
                Forgot your Password
              </Link>

              <Link to="/signup" className="existing__user">
                Create an Account
              </Link>
            </FlexibleDiv>
          </Form>
        )
      case "phone":
        return (
          <Form
            onFinish={handlePhoneFormSubmit}
            form={form}
            className="phone_form_box"
          >
            <FlexibleDiv>
              <PhoneInput
                value={phoneNumber}
                borderBottom="1px solid #707070"
                onChange={e => {
                  setPhoneNumber(e)
                }}
                label=""
                onSetCountryCode={code => setCountryCode(code)}
                small
              />
              <p className="caution__text">
                You must have created an account already to use this service
              </p>
            </FlexibleDiv>

            <FlexibleDiv
              flexDir="column"
              className="btn__box"
              justifyContent="center"
            >
              <Button
                width="180px"
                height="40px"
                font_size="16px"
                type="submit"
                contained
                radius="10px"
                color="var(--uduxBlackPrimary)"
                bold
                ltr
              >
                <span>
                  {phoneLoading ? (
                    <LoadingOutlined color="var(--uduxYellowPrimary)" />
                  ) : (
                    "Continue"
                  )}
                </span>
              </Button>
            </FlexibleDiv>

            <FlexibleDiv
              flexDir="column"
              className="btn__box"
              justifyContent="center"
            >
              <Link to="/signup" className="existing__user">
                New user? Create an Account
              </Link>
            </FlexibleDiv>
          </Form>
        )
      default:
        return null
    }
  }

  return (
    <AuthLayout
      title="African’s Music Hub"
      subText="For all the ways music moves you."
      galleryImage={galleryImage}
    >
      <Container checkHeight={checkHeight}>
        <LoginTabHeader
          tabs={loginTabRoutes}
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          showCloser={false} // Adjust based on your requirements
        />

        {/* Render the TabContent based on the activeTab */}
        <TabContent>{renderTabContent()}</TabContent>
      </Container>
    </AuthLayout>
  )
}

export default Login

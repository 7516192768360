import React from "react"
import { IconProps } from "types"

export const MyDetailsIcon = ({
  color = "#eaeaea",
  width,
  height,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 20 20"
  >
    <g
      id="Group_3757"
      data-name="Group 3757"
      transform="translate(-45.475 -136)"
    >
      <g id="headset-" transform="translate(45.475 136)">
        <g id="Group_3756" data-name="Group 3756" transform="translate(0 0)">
          <g id="Group_3755" data-name="Group 3755" transform="translate(0 0)">
            <g id="XMLID_1_">
              <path
                id="Path_1203"
                data-name="Path 1203"
                d="M396.885,133.8a10.377,10.377,0,0,1-.126,1.6,2.531,2.531,0,0,0-.627-.578,10.032,10.032,0,0,0,.053-1.02,9.163,9.163,0,0,0-2.585-6.414,1.086,1.086,0,0,0,.253-.2,1.191,1.191,0,0,0,.214-.287A9.881,9.881,0,0,1,396.885,133.8Z"
                transform="translate(-376.938 -123.382)"
                fill={color}
              />
              <path
                id="Path_1204"
                data-name="Path 1204"
                d="M424.538,293.283a1.965,1.965,0,0,1,.126.7,2,2,0,0,1-1.764,1.983V292a2.03,2.03,0,0,1,.7.219.361.361,0,0,0,.058.034,2,2,0,0,1,.85.962C424.518,293.234,424.528,293.259,424.538,293.283Z"
                transform="translate(-404.814 -280.459)"
                fill={color}
              />
              <path
                id="Path_1205"
                data-name="Path 1205"
                d="M376.856,276.238v.039a.738.738,0,0,1-.739.739h-.141a.2.2,0,0,0-.087,0h-.68a.211.211,0,0,1-.209-.209v-5.6a.211.211,0,0,1,.209-.209v0h.909a.738.738,0,0,1,.739.739v4.495Z"
                transform="translate(-359.242 -260.48)"
                fill={color}
              />
              <path
                id="Path_1206"
                data-name="Path 1206"
                d="M128.7,57.974a.733.733,0,0,1-.384.209.789.789,0,0,1-.656-.155,8.849,8.849,0,0,0-11.128,0,.8.8,0,0,1-.651.155.769.769,0,0,1-.394-.214.747.747,0,0,1-.214-.4.174.174,0,0,0-.01-.053.593.593,0,0,1,0-.131.785.785,0,0,1,.282-.564,10.389,10.389,0,0,1,13.1,0,.758.758,0,0,1,.282.559v.034l-.01.126A.841.841,0,0,1,128.7,57.974Z"
                transform="translate(-112.122 -54.5)"
                fill={color}
              />
              <path
                id="Path_1207"
                data-name="Path 1207"
                d="M251.014,436.33a.531.531,0,0,1-.53.53H248.93a.53.53,0,0,1,0-1.059h1.555A.531.531,0,0,1,251.014,436.33Z"
                transform="translate(-238.793 -417.272)"
                fill={color}
              />
              <path
                id="Path_1208"
                data-name="Path 1208"
                d="M100.661,271.209V276.8a.211.211,0,0,1-.209.209h-.914a.738.738,0,0,1-.739-.739v-4.529a.738.738,0,0,1,.739-.739h.914A.214.214,0,0,1,100.661,271.209Z"
                transform="translate(-96.463 -260.48)"
                fill={color}
              />
              <path
                id="Path_1209"
                data-name="Path 1209"
                d="M53.99,127.491l-.01.01a9.218,9.218,0,0,0-2.566,6.395,9.932,9.932,0,0,0,.053,1.016,2.511,2.511,0,0,0-.637.583,10.383,10.383,0,0,1-.131-1.6,9.924,9.924,0,0,1,2.813-6.9,1.15,1.15,0,0,0,.219.287A1.286,1.286,0,0,0,53.99,127.491Z"
                transform="translate(-50.7 -123.477)"
                fill={color}
              />
              <path
                id="Path_1210"
                data-name="Path 1210"
                d="M54.274,291.9v3.975a2,2,0,0,1-1.774-1.983,1.865,1.865,0,0,1,.121-.685.249.249,0,0,0,.049-.117,2.042,2.042,0,0,1,.811-.918.216.216,0,0,0,.121-.068A2.026,2.026,0,0,1,54.274,291.9Z"
                transform="translate(-52.413 -280.364)"
                fill={color}
              />
              <path
                id="Path_1211"
                data-name="Path 1211"
                d="M241.9,228.3h1.37v1.37H241.9Z"
                transform="translate(-232.609 -219.855)"
                fill={color}
              />
              <path
                id="Path_1212"
                data-name="Path 1212"
                d="M212.77,198.4v.471a.193.193,0,0,1-.194.2h-.481a.2.2,0,0,0-.087,0h-1.531a.2.2,0,0,0-.087,0h-1.531a.2.2,0,0,0-.087,0h-.476a.192.192,0,0,1-.194-.194v-4.281a.192.192,0,0,1,.194-.194h4.276a.192.192,0,0,1,.194.194v.471a.247.247,0,0,0,0,.1v1.516a.167.167,0,0,0,0,.049.151.151,0,0,0,0,.049v1.531a.166.166,0,0,0,0,.049C212.765,198.37,212.765,198.385,212.77,198.4Zm-1.254-.617a.3.3,0,0,0,.078-.219v-1.676a.32.32,0,0,0-.321-.321h-1.686a.32.32,0,0,0-.321.321v1.686a.32.32,0,0,0,.321.321h1.686A.326.326,0,0,0,211.516,197.782Z"
                transform="translate(-200.452 -187.602)"
                fill={color}
              />
              <path
                id="Path_1213"
                data-name="Path 1213"
                d="M315.757,404.5v.515a1.778,1.778,0,0,1-1.778,1.774H311.5a.986.986,0,0,0,0-.476h2.483a1.292,1.292,0,0,0,1.293-1.293V404.5h.428A.2.2,0,0,1,315.757,404.5Z"
                transform="translate(-298.827 -387.493)"
                fill={color}
              />
              <path
                id="Path_1214"
                data-name="Path 1214"
                d="M315.034,204.1a.242.242,0,0,1,.238.238.238.238,0,0,1-.238.238H313.8V204.1Z"
                transform="translate(-301.016 -196.831)"
                fill={color}
              />
              <path
                id="Path_1215"
                data-name="Path 1215"
                d="M315.034,237.4a.242.242,0,0,1,.238.238.238.238,0,0,1-.238.238H313.8V237.4Z"
                transform="translate(-301.016 -228.513)"
                fill={color}
              />
              <path
                id="Path_1216"
                data-name="Path 1216"
                d="M315.034,270.8a.242.242,0,0,1,.238.238.238.238,0,0,1-.238.238H313.8V270.8Z"
                transform="translate(-301.016 -260.29)"
                fill={color}
              />
              <path
                id="Path_1217"
                data-name="Path 1217"
                d="M284.876,300.2v1.229a.237.237,0,0,1-.238.233.242.242,0,0,1-.238-.238V300.2Z"
                transform="translate(-273.044 -288.261)"
                fill={color}
              />
              <path
                id="Path_1218"
                data-name="Path 1218"
                d="M284.876,154.538v1.229H284.4v-1.229a.238.238,0,0,1,.238-.238A.235.235,0,0,1,284.876,154.538Z"
                transform="translate(-273.044 -149.451)"
                fill={color}
              />
              <path
                id="Path_1219"
                data-name="Path 1219"
                d="M251.576,300.2v1.229a.237.237,0,0,1-.238.233.242.242,0,0,1-.238-.238V300.2Z"
                transform="translate(-241.362 -288.261)"
                fill={color}
              />
              <path
                id="Path_1220"
                data-name="Path 1220"
                d="M251.576,154.538v1.229H251.1v-1.229a.238.238,0,0,1,.238-.238A.235.235,0,0,1,251.576,154.538Z"
                transform="translate(-241.362 -149.451)"
                fill={color}
              />
              <path
                id="Path_1221"
                data-name="Path 1221"
                d="M218.276,300.2v1.229a.238.238,0,0,1-.476,0V300.2Z"
                transform="translate(-209.68 -288.261)"
                fill={color}
              />
              <path
                id="Path_1222"
                data-name="Path 1222"
                d="M218.276,154.538v1.229H217.8v-1.229a.238.238,0,0,1,.476,0Z"
                transform="translate(-209.68 -149.451)"
                fill={color}
              />
              <path
                id="Path_1223"
                data-name="Path 1223"
                d="M169.367,270.8v.476h-1.229a.242.242,0,0,1-.238-.238.238.238,0,0,1,.238-.238Z"
                transform="translate(-162.205 -260.29)"
                fill={color}
              />
              <path
                id="Path_1224"
                data-name="Path 1224"
                d="M169.367,237.4v.476h-1.229a.242.242,0,0,1-.238-.238.235.235,0,0,1,.238-.238Z"
                transform="translate(-162.205 -228.513)"
                fill={color}
              />
              <path
                id="Path_1225"
                data-name="Path 1225"
                d="M169.367,204.1v.476h-1.229a.242.242,0,0,1-.238-.238.235.235,0,0,1,.238-.238Z"
                transform="translate(-162.205 -196.831)"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

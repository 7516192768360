import { IconProps } from "types"

export const UduXGreenLogo = ({
  color = "#00565b",
  width = "99.672",
  height = "32.66",
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g
        id="Group_3366"
        data-name="Group 3366"
        transform="translate(-1077.752 -657.664)"
      >
        <g
          id="Group_3407"
          data-name="Group 3407"
          transform="translate(1518.542 118.369)"
        >
          <g id="Group_3405" data-name="Group 3405" transform="translate(0 0)">
            <g id="Text" transform="translate(-440.79 539.296)">
              <path
                id="XMLID_146_"
                d="M196.753,267.15l10.189-16.31h-6.765c-.484.805-1.047,1.691-1.651,2.617-.6.968-1.168,1.893-1.772,2.859s-1.168,1.893-1.732,2.819-1.128,1.772-1.611,2.537L186.686,250.8H179.84c1.651,2.658,3.342,5.356,5.115,8.136s3.463,5.477,5.074,8.095L179.8,283.46h6.725l6.926-10.954,6.927,10.954h6.685Z"
                transform="translate(-107.391 -250.8)"
                fill={color}
              />
              <path
                id="XMLID_143_"
                d="M79.522,271.254a10.712,10.712,0,1,0,0,17.72V290.3h4.712V263.2H79.522Zm-6.041,14.337a5.477,5.477,0,1,1,5.477-5.477A5.477,5.477,0,0,1,73.481,285.591Z"
                transform="translate(-37.518 -258.206)"
                fill={color}
              />
              <path
                id="XMLID_142_"
                d="M18.082,281h0v8.3h0a6.423,6.423,0,0,1-12.806.282h-.04V281H0v8.578H0a11.654,11.654,0,0,0,23.277,0h0V281Z"
                transform="translate(0 -268.838)"
                fill={color}
              />
              <path
                id="XMLID_141_"
                d="M139.582,282h0v8.3h0a6.423,6.423,0,0,1-12.806.282h-.04V282H121.5v8.578h0a11.654,11.654,0,0,0,23.277,0h0V282Z"
                transform="translate(-72.57 -269.435)"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

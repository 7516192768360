import { FlexibleDiv } from "components"
import { SubscriptionPlans } from "./SubscriptionPlans"
import { SubscriptionWrapper } from "./subscription.styles"

export const Subscription = () => {
  return (
    <SubscriptionWrapper id="subscribe__section">
      <FlexibleDiv flexDir="column" className="sub__lead__info">
        <h2>It's Easy to Get Started</h2>
        <p>Choose a plan tailored to your needs</p>
      </FlexibleDiv>

      <SubscriptionPlans />
    </SubscriptionWrapper>
  )
}

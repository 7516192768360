import { Menu, Skeleton } from "antd";
import { QueueIcon } from "assets";
import OrganizeSvg from "assets/images/general/organize.svg";
import {
  AddPlaylistCard,
  Button,
  Dropdown,
  FlexibleDiv,
  NoDataDisplay,
  OverflowScroll,
} from "components";
import { Types, useMainContext } from "context";
import { pull, uniq } from "lodash";
import {
  addToMultiplePlaylists,
  errorResponseHandler,
  getPlaylistsByQuery,
  successResponseHandler,
} from "network";
import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { ATPWrap } from "./add-to-playlist.styles";
import { ChoosePlaylistType } from "./components";
import { StringNumberUnion } from "types";

export type AddToPlaylistProps = {
  isMobile?: boolean;
};
export const AddToPlaylist = ({ isMobile = false }: AddToPlaylistProps) => {
  const {
    state: { users, playlist, player, app },
    dispatch,
  } = useMainContext();
  const me = users.me;
  const { creatingPlaylist } = playlist;
  const { isLoggedIn } = app;

  const [searchValue, setSearchValue] = React.useState("");
  const [checkedPlaylists, setCheckedPlaylists] = React.useState<
    StringNumberUnion[]
  >([]);
  const [, setChoosePlaylistType] = React.useState(false);
  const [, setOrganizePlaylist] = React.useState("custom");
  const { data, isLoading, refetch } = useQuery(
    ["fetch-user-playlist", creatingPlaylist],
    () => getPlaylistsByQuery(`user=${me?.id}`),
    {
      enabled: !!me?.id,
    }
  );
  const { mutate, isLoading: atmpLoading } = useMutation(
    addToMultiplePlaylists
  );

  const { currentTrack } = player;

  const filteredList = searchValue
    ? data?.filter((fl) =>
        fl.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    : data;

  const handleOrganizePlaylist = (type: string) => {
    setOrganizePlaylist(type);
  };

  const organizeDetailsMenu = (
    <Menu
      style={{ backgroundColor: "#000000", border: "1px solid #0F0F0F" }}
      items={[
        {
          key: "1",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("custom")}
            >
              <QueueIcon width={18} />
              <p>Custom Order</p>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("title")}
            >
              <QueueIcon width={18} />
              <p>Title</p>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("creator")}
            >
              <QueueIcon width={18} />
              <p>Creator</p>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("album")}
            >
              <QueueIcon width={18} />
              <p>Album</p>
            </div>
          ),
        },
        {
          key: "5",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("duration")}
            >
              <QueueIcon width={18} />
              <p>Duration</p>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <OverflowScroll>
      <ATPWrap isMobile={isMobile}>
        {creatingPlaylist ? (
          <ChoosePlaylistType />
        ) : (
          <React.Fragment>
            <FlexibleDiv className="section__display__controls">
              <p className="title">Add to existing playlist</p>
              {checkedPlaylists.length ? (
                <Button
                  onClick={handleAddToMultiplePlaylists}
                  loading={atmpLoading}
                  width="180px"
                  color="var(--black)"
                  className="action__btn"
                  background="var(--uduxYellowPrimary)"
                  contained
                  bold
                >
                  <span>
                    {checkedPlaylists.length === 1
                      ? "Add To Playlist"
                      : "Add To Playlists"}
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (!isLoggedIn) {
                      return dispatch({
                        type: Types.SET_PRIVATE_ACTION,
                        payload: true,
                      });
                    }

                    dispatch({ type: Types.CREATING_PLAYLIST, payload: true });
                    setChoosePlaylistType(true);
                  }}
                  width="180px"
                  color="var(--black)"
                  className="action__btn"
                  background="var(--uduxYellowPrimary)"
                  contained
                  bold
                >
                  <span>Create New Playlist</span>
                </Button>
              )}
            </FlexibleDiv>

            <FlexibleDiv
              className="filter__playlist__box"
              justifyContent="space-between"
            >
              <Dropdown
                trigger={["click"]}
                overlay={organizeDetailsMenu}
                placement="bottomLeft"
              >
                <FlexibleDiv
                  width="unset"
                  className="organize__by"
                  alignItems="center"
                >
                  <img src={OrganizeSvg} alt="filter icon" />
                  <p>Organize by</p>
                </FlexibleDiv>
              </Dropdown>
              <FlexibleDiv className="search__input">
                <BiSearchAlt2 size={20} color="rgba(217, 217, 217, 0.5)" />
                <input
                  onChange={({ target }) => setSearchValue(target.value)}
                  placeholder="Search this playlist"
                />
              </FlexibleDiv>
            </FlexibleDiv>

            {isLoading ? (
              <Skeleton active={isLoading} style={{ paddingTop: "1rem" }} />
            ) : (
              <FlexibleDiv
                flexWrap="nowrap"
                alignItems="center"
                justifyContent="flex-start"
                className="playlist__content"
              >
                {data?.length ? (
                  filteredList?.length ? (
                    filteredList.map((pt) => (
                      <AddPlaylistCard
                        key={pt.id}
                        {...pt}
                        onCheck={({ checked }, pl) => {
                          if (checked) {
                            setCheckedPlaylists((prevPl) =>
                              uniq([...prevPl, pl])
                            );
                          } else {
                            setCheckedPlaylists((prevPl) =>
                              pull([...prevPl], pl)
                            );
                          }
                        }}
                      />
                    ))
                  ) : (
                    <NoDataDisplay
                      text="No playlist matched your search."
                      height="260px"
                    />
                  )
                ) : (
                  <NoDataDisplay
                    text="You have no playlists yet. Try creating one for yourself."
                    height="260px"
                  />
                )}
              </FlexibleDiv>
            )}
          </React.Fragment>
        )}
      </ATPWrap>
    </OverflowScroll>
  );

  function handleAddToMultiplePlaylists() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    const payload = {
      playlists: checkedPlaylists,
      tracks: [
        {
          sequence: 0,
          track: currentTrack?.track?.id,
        },
      ],
    };

    mutate(payload, {
      onSuccess: (res) => {
        refetch();
        successResponseHandler(res);
        setCheckedPlaylists([]);
      },
      onError: (err) => errorResponseHandler(err),
    });
  }
};

import { IconProps } from "types";
export const TrackDurationIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "14"}
    height={height || "20"}
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00537 11.9531H7.34002V13.2554H6.00537V11.9531Z"
      fill={color}
    />
    <path
      d="M6.00537 14.5547H7.34002V15.8569H6.00537V14.5547Z"
      fill={color}
    />
    <path
      d="M11.3445 7.17913V2.18505H13.3465V0.882812H0V2.18505H2.00197V7.17913C2.00197 7.4609 2.09563 7.73506 2.2689 7.96047L3.83711 9.99847L2.2689 12.0365C2.09563 12.2619 2.00197 12.5361 2.00197 12.8178V17.8119H0V19.1141H13.3465V17.8119H11.3445V12.8178C11.3445 12.5361 11.2508 12.2619 11.0776 12.0365L9.50935 9.99847L11.0776 7.96047C11.2508 7.73506 11.3445 7.4609 11.3445 7.17913ZM10.0098 2.18505V6.74288H3.33661V2.18505H10.0098ZM10.0098 12.8178V17.8119H3.33661V12.8178L5.50541 9.99847L4.00394 8.04512H9.34252L7.84104 9.99847L10.0098 12.8178Z"
      fill={color}
    />
  </svg>
);

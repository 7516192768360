import { HTMLAttributes } from "react"
import styled from "styled-components"

export interface FlexibleDivProps {
  justifyContent?: string
  alignItems?: string
  flexWrap?: string
  flexDir?: string
  width?: string
  maxWidth?: string
  minWidth?: string
  height?: string
  minHeight?: string
  margin?: string
  padding?: string
  bgColor?: string
}

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  justifyContent?: string
  alignItems?: string
  flexWrap?: string
  flexDir?: string
  width?: string
  height?: string
  bgColor?: string
}

export interface GridableDivProps {
  gridCol?: string
  gridRow?: string
  gap?: string
  width?:string;
}

export const FlexibleDiv = styled.div<FlexibleDivProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  flex-direction: ${({ flexDir }) => flexDir || "row"};
  width: ${({ width }) => width || "100%"};
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height || "max-content"};
  min-height: ${({ minHeight }) => minHeight || "max-content"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  background: ${({ bgColor }) => bgColor || ""};

  .udux__logo__wrap > img {
    max-width: 100%;
    width: 80px;
  }
`

// Flexible box section element
export const Section = styled.section<SectionProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  flex-direction: ${({ flexDir }) => flexDir || "row"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "max-content"};
  background: ${({ bgColor }) => bgColor || ""};
`

// Gridable box
export const GridableDiv = styled.div<GridableDivProps>`
  display: grid;
  grid-template-columns: ${({ gridCol }) => gridCol || "1fr"};
  grid-template-rows: ${({ gridRow }) => gridRow || "auto"};
  grid-gap: ${({ gap }) => gap || "10px"};
`

export const GridSection = styled.section<GridableDivProps>`
  padding: 50px 0;
  display: grid;
  grid-template-columns: ${({ gridCol }) => gridCol || "1fr  1fr 1fr 1fr"};
  grid-template-rows: ${({ gridRow }) => gridRow || "auto"};
  grid-gap: ${({ gap }) => gap || "10px"};
`

export const ContainerBox = styled.div`
  padding: 0 3% 3% 3%;
  /* overflow-x: hidden; */

  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
`

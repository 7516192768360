import UduXGrayLogo from "assets/images/Auth/udux_logo_gray.svg"
import PaymentLoginBng from "assets/images/payment/payment_login.png"
import { FlexibleDiv } from "components/lib"
import { useMainContext } from "context"
import { AuthResponseError } from "pages/Auth/AuthResponseError"
import React from "react"
import { IoIosArrowRoundBack } from "react-icons/io"
import { useMediaQuery } from "react-responsive"
import { useLocation, useNavigate } from "react-router-dom"
import { PaymentRegisterLayoutWrapper } from "./paymentRegisterLayout.styles"
import { slideTo, topSlideInFrom } from "utils"
import BackgroundImage from "assets/images/general/udux_bng.png"

const $checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

export type PaymentRegisterLayoutProps = {
  children?: React.ReactNode
  title?: string
  subText?: string
  galleryImage?: string
  customGoBack?: () => void
  backURL?: string
  checkHeight?: boolean
  smallHeight?: boolean
  accentColor?: string
}

export const PaymentRegisterLayout = ({
  children,
  title,
  subText,
  galleryImage = PaymentLoginBng,
  backURL,
  checkHeight = $checkHeight,
  smallHeight = checkSmallScreenHeight,
  accentColor,
}: PaymentRegisterLayoutProps) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const formWrapRef = React.useRef(null)
  const apiResponseErrorRef = React.useRef(null)
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const {
    state: { app },
  } = useMainContext()
  const { apiResponseError } = app
  const showBackButton = pathname !== "/login" && pathname !== "/oauth"

  React.useLayoutEffect(() => {
    if (apiResponseError) {
      slideTo(formWrapRef, { duration: 0.3, y: 5 })
    }
  }, [apiResponseError])

  React.useLayoutEffect(() => {
    topSlideInFrom(apiResponseErrorRef, { duration: 0.8, y: 30 })
  }, [apiResponseError])

  const handleGoBack = () => {
    if (backURL) {
      return navigate(backURL)
    }

    return navigate(-1)
  }

  return (
    <PaymentRegisterLayoutWrapper
      checkHeight={checkHeight}
      bng={BackgroundImage}
      smallHeight={smallHeight}
      galleryImage={galleryImage}
      accentColor={accentColor}
    >
      <FlexibleDiv className="content__box">
        {!isMobile && (
          <div className="gallery__pane">
            <div className="image__holder"></div>
          </div>
        )}

        <div className="activity__pane">
          {showBackButton && (
            <button className="go__back__box" onClick={handleGoBack}>
              <IoIosArrowRoundBack color="var(--primaryWhite)" size={30} />{" "}
              <span>Back</span>
            </button>
          )}
          <div className="logo__wrap">
            <img src={UduXGrayLogo} alt="uduX" />
          </div>

          <div className="content__box__layout">
            <div className="content__layout">
              {title && <h2 className="title">{title}</h2>}
              {subText && <p className="sub__text">{subText} </p>}

              <div className="auth__response__error__main">
                <AuthResponseError
                  ref={apiResponseErrorRef}
                  error={apiResponseError}
                />
              </div>

              <div className="form__wrap">{children}</div>
            </div>
          </div>
        </div>
      </FlexibleDiv>
    </PaymentRegisterLayoutWrapper>
  )
}

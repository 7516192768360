import { IconProps } from "types";

export const FsePausePlayIcon = ({ color="#D9D9D9", width, height, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "48"}
    height={height || "48"}
    viewBox="0 0 48 48"
    fill="none"
    {...props}
  >
    <path
      d="M10.5 41.9982C10.1022 41.9982 9.72064 41.8401 9.43934 41.5588C9.15804 41.2775 9 40.896 9 40.4982V7.49816C8.99998 7.23749 9.06789 6.98131 9.19704 6.75488C9.32619 6.52845 9.51212 6.33958 9.7365 6.2069C9.96088 6.07422 10.216 6.0023 10.4766 5.99823C10.7372 5.99416 10.9944 6.05809 11.2229 6.18371L41.2228 22.6837C41.4582 22.8131 41.6545 23.0034 41.7912 23.2346C41.9279 23.4658 42.0001 23.7295 42.0001 23.9981C42.0001 24.2667 41.9279 24.5304 41.7912 24.7616C41.6545 24.9928 41.4582 25.183 41.2228 25.3125L11.2229 41.8125C11.0014 41.9342 10.7527 41.9981 10.5 41.9982Z"
      fill={color}
    />
  </svg>
);

import gsap, { Power3, Power2, Power4 } from "gsap"
import React from "react"

//Animate text
export const textIntro = (elem: React.RefObject<HTMLElement | null>) => {
  gsap.from(elem, {
    xPercent: -20,
    opacity: 0,
    stagger: 0.2,
    duration: 1,
    scale: 2,
    ease: "back",
  })
}

export const subTextIntro = (
  elem: React.RefObject<HTMLElement | null>,
  delay: number = 0.1
) => {
  gsap.from(elem, {
    // xPercent: -20,
    x: -100,
    opacity: 0,
    stagger: 0.2,
    duration: 1,
    delay: delay,
    ease: "back",
  })
}

export const topSlideIn = (
  elem: React.RefObject<HTMLElement | null>,
  delay: number
) => {
  const tl = gsap.timeline()

  tl.from(elem, {
    duration: 1,
    y: 150,
    opacity: 0,
    autoAlpha: 0,
    delay: 0 || delay,
    ease: Power3.easeIn,
    stagger: 1.5,
  })
}

export const topSlideInFrom = (
  elem: React.RefObject<HTMLElement | null>,
  { duration = 0.5, y = 0, x = 0, opacity = 0 }
) => {
  return gsap.fromTo(
    elem.current,
    {
      y,
      x,
      opacity,
    },
    {
      y: 0,
      x: 0,
      opacity: 1,
      duration: duration,
      ease: Power4.easeIn,
      stagger: 1.5,
    }
  )
}

export const slideTo = (
  elem: React.RefObject<HTMLElement | null>,
  { duration = 0.5, y = 0, x = 0 }
) => {
  return gsap.fromTo(
    elem.current,
    {
      y: 0,
      x: 0,
    },
    {
      y,
      x,
      duration: duration,
      ease: Power4.easeIn,
      stagger: 1.5,
    }
  )
}

export const leftSlideIn = (elem: React.RefObject<HTMLElement | null>) => {
  gsap.from(elem, {
    // xPercent: -20,
    x: -200,
    opacity: 0,
    stagger: 0.2,
    duration: 1.5,
    ease: "back",
  })
}

export const rightSlideIn = (elem: React.RefObject<HTMLElement | null>) => {
  gsap.from(elem, {
    // xPercent: -20,
    x: 500,
    opacity: 0,
    stagger: 0.2,
    duration: 1,
    delay: 2,
    ease: "back",
  })
}

export const bottomSlideIn = (
  elem: React.RefObject<HTMLElement | null>
  // { duration = 1, delay = 0.2, stagger = 0, opacity = 0, y = 500 }
) => {
  gsap.from(elem.current, {
    y: 0,
    opacity: 1,
    // stagger: 0.1,
    delay: 0.2,
    duration: 1,
    ease: "back",
  })
  // gsap.from(elem, {
  //   y,
  //   opacity,
  //   stagger,
  //   delay,
  //   duration,
  //   ease: "back",
  // })
}

export const scaleIn = (
  elem: React.RefObject<HTMLElement | null>,
  duration: number,
  delay: number
) => {
  gsap.from(elem, {
    // xPercent: -20,
    scale: 0,
    opacity: 0,
    stagger: 0.2,
    duration: duration,
    delay: delay,
    ease: "back",
  })
}

export const heroLogoAnimation = (
  elem: React.RefObject<HTMLElement | null>,
  duration: number,
  delay: number
) => {
  gsap.from(elem, {
    x: 200,
    duration: duration,
    delay: delay,
    scrollTrigger: {
      trigger: ".long__arrow__wrap",
      start: "top 40%",
      toggleActions: "play none none none",
    },
  })
}

export const ImageSlideIn = (elem: React.RefObject<HTMLElement | null>) => {
  gsap.from(elem, {
    // xPercent: -20,
    x: 200,
    y: 400,
    opacity: 0,
    stagger: 0.2,
    duration: 1.5,
    ease: "back",
  })
}

export const ImageReveal = (
  container: gsap.DOMTarget,
  img: React.RefObject<HTMLElement | null>
) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      toggleActions: "restart none none reset",
    },
  })

  tl.set(container, { autoAlpha: 1 })
  tl.from(container, 1.5, {
    xPercent: 0,
    ease: Power2.easeIn,
  })
  tl.from(img, 1.5, {
    // xPercent: 300,
    width: "0%",
    left: 0,
    // opacity: 0,
    scale: 1.3,
    delay: -1.5,
    ease: Power2.easeOut,
  })
}

export const fadeIn = (
  elem: React.RefObject<HTMLElement | null>,
  { duration = 0.5 }
) => {
  return gsap.set(elem, {
    opacity: 1,
    duration,
  })
}
export const fadeOut = (
  elem: React.RefObject<HTMLElement | null>,
  { duration = 0.5 }
) => {
  return gsap.set(elem, {
    opacity: 0,
    duration,
  })
}

import { memo, useEffect, useState } from "react"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { MdCancel as Cancel } from "react-icons/md"
import { StatusState } from "types"
import { BorderedTextfieldProps } from "./BorderedTextField"
import {
  BorderedFormItem,
  BorderedFormItemWrap,
  BorderedIconWrap,
} from "./bordered-textfield.styles"

export type BorderedTextareaProps = {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
} & Omit<BorderedTextfieldProps, "onChange">

export const BorderedTextarea = memo(
  ({
    name,
    rows,
    cols,
    onChange,
    validator,
    placeholder,
    hasError,
    hasSuccess,
    hasErrorMessage,
    checkAvailability,
    ...props
  }: BorderedTextareaProps) => {
    const [status, setStatus] = useState<StatusState>()
    const [, setValue] = useState("")

    const styleHasError = checkAvailability ? hasError : Boolean(status?.err)
    const styleHasSuccess = checkAvailability
      ? hasSuccess
      : Boolean(status?.success)

    useEffect(() => {
      if (hasError) {
        setStatus({ err: hasErrorMessage })
      }
    }, [hasError, hasErrorMessage])

    const handleBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (hasError && hasErrorMessage) {
        return setStatus({ err: hasErrorMessage })
      } else {
        validator && validator({ value: e?.target?.value, setStatus })
      }
    }

    return (
      <BorderedFormItemWrap
        className="form-item-container"
        height={props.height}
        width={props.width}
        hasError={styleHasError}
        hasSuccess={styleHasSuccess}
        {...props}
      >
        <div className="input__wrapper">
          <BorderedFormItem name={name} lightMask={props.lightMask} {...props}>
            <textarea
              rows={rows}
              cols={cols}
              placeholder={placeholder}
              disabled={props.editLocked}
              onBlur={handleBlur}
              onChange={e => {
                if (onChange) {
                  return onChange(e)
                }

                setValue(e.target.value)

                if (checkAvailability) {
                  return checkAvailability(e?.target?.value)
                }

                validator &&
                  validator({ rules: e, value: e?.target?.value, setStatus })
              }}
            />
          </BorderedFormItem>

          <BorderedIconWrap height={props.height}>
            {status?.err && <Cancel className="cancel" />}
            {status?.success && (
              <BsFillCheckCircleFill
                className="check"
                color="var(--successPrimary)"
              />
            )}
          </BorderedIconWrap>
        </div>

        <p className="input__error__text">{status?.err}</p>
      </BorderedFormItemWrap>
    )
  }
)

BorderedTextarea.displayName = "BorderedTextarea"

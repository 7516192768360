import { CloseOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { FlexibleDiv } from "components/lib/Box"
import { Button } from "components/lib/Button"
import { BorderedTextfield } from "components/lib/TextField"
import { useState } from "react"
import { CPMWrap } from "./invite-collab-user.styles"
import { useInviteCollabUserService } from "network/hooks"

export type InviteCollabUserModalProps = {
  playlistId: string
  open?: boolean
  title?: string
  content?: string
  btnContent?: string
  skinBanner?: string
  handleClose?: () => void
}

export const InviteCollabUserModal = ({
  handleClose,
  playlistId,
  title = "Invite a collaborator",
  open,
}: InviteCollabUserModalProps) => {
  const [email, setEmail] = useState("")
  const { mutate, isLoading } = useInviteCollabUserService()

  return (
    <CPMWrap
      open={open}
      centered
      className="modal__wrap"
      footer={null}
      closable={false}
      onCancel={handleClose}
    >
      <FlexibleDiv
        className="modal__header"
        alignItems="center"
        justifyContent="space-between"
      >
        <h2>{title}</h2>
        <CloseOutlined onClick={handleClose} className="close__icon" />
      </FlexibleDiv>

      <FlexibleDiv alignItems="center" className="content">
        <Form className="form_content">
          <FlexibleDiv className="signup__form__box">
            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                small
                type="email"
                name="email"
                placeholder="Enter email address"
                paddingLeft="0.5rem"
                hasErrorMessage={"Email title is required"}
                onChange={({ target }) => setEmail(target.value)}
              />
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv className="btn__box" justifyContent="flex-end">
            <Button
              width="115px"
              className="create__btn"
              height="40px"
              font_size="15px"
              type="submit"
              color="var(--black)"
              contained
              radius="0px"
              bold
              onClick={() => {
                mutate(
                  {
                    emailAddress: email,
                    message: "Join my playlist, let's make memories together",
                    playlistId,
                  },
                  {
                    onSuccess: () => {
                      setEmail("")

                      if (handleClose) {
                        handleClose()
                      }
                    },
                  }
                )
              }}
              loading={isLoading}
              ltr
            >
              Send Invite
            </Button>
          </FlexibleDiv>
        </Form>
      </FlexibleDiv>
    </CPMWrap>
  )
}

import { Button, FlexibleDiv, Section } from "components/lib"
import { useMainContext } from "context"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { getPlayerSidebarNavItems } from "utils"
import { SidebarWrap } from "./sidebar.styles"
import { useCallback } from "react"
import listen from "assets/images/general/listen.png"
import { CreatePlaylistModal } from "components/lib/Modals/CreatePlaylistModal"
import {
  useCreateCollabPlaylistService,
  useCreateStandardPlaylistService,
  useFetchPlaylists,
} from "network/hooks"
import { PlusIcon } from "assets"

interface PlaylistDisplay {
  id: string
  img: string
  text: string
}

export type PlayerSidebarProps = {
  isLoggedIn: boolean
  isSidebarOpen?: boolean
}

export const PlayerSidebar = ({
  isLoggedIn,
  isSidebarOpen,
}: PlayerSidebarProps) => {
  const [openItems, setOpenItems] = useState<Record<number, boolean>>({})
  const { mutate: mutateCollab, isLoading: collabLoading } =
    useCreateCollabPlaylistService()
    const { mutate: mutateStandardPlaylist } =
    useCreateStandardPlaylistService()
  const [playlists, setPlaylists] = useState<PlaylistDisplay[]>([])
  const [isPlaylistModal, setIsPlaylistModal] = useState<
    "standard" | "collab"
  >()
  // const [, setIsScrolled] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {
    state: { users, player },
  } = useMainContext()
  const { me } = users
  const { isPlayerActive } = player
  const { data: allPlaylists } = useFetchPlaylists(me, isLoggedIn)

  const toggleItem = (itemIdx: number) => {
    setOpenItems(prev => ({
      ...prev,
      [itemIdx]: !prev[itemIdx],
    }))
  }

  const handlePlaylists = useCallback(() => {
    if (allPlaylists?.length) {
      const playlistObjects = allPlaylists.map(data => ({
        id: data.id,
        img: data.coverArt?.url || "", // Fallback to empty string if img is unavailable
        text: data.title,
      }))
      setPlaylists(playlistObjects)
    } else {
      setPlaylists([])
    }
  }, [allPlaylists])

  useEffect(() => {
    handlePlaylists()
  }, [handlePlaylists])

  // const handleScroll = (e: any) => {
  //   const contentContainer = e.target
  //   setIsScrolled(contentContainer.scrollTop > 0)
  // }

  return (
    <SidebarWrap isPlayerActive={isPlayerActive} isMenuActive={isSidebarOpen}>
      <Section flexDir="column" className="sidebar__subwrap">
        <FlexibleDiv
          className="sidebar__bodywrap"
          flexDir="column"
          flexWrap="nowrap"
        >
          <FlexibleDiv className="sidebar__navwrap">
            {getPlayerSidebarNavItems(isLoggedIn).map((section, sectionIdx) => (
              <FlexibleDiv
                className="sidebar__nav__sections"
                key={sectionIdx}
                flexDir="column"
                alignItems="flex-start"
              >
                <h3 className="sidebar__section__title">{section.title}</h3>
                {section.sectionItems.map((item, itemIdx) => (
                  <React.Fragment key={itemIdx}>
                    <FlexibleDiv
                      flexWrap="no-wrap"
                      justifyContent="flex-start"
                      className={
                        "sidebar__nav__sectionitems " +
                        (location.pathname === item.path &&
                          "sidebar__activenav__sectionitems")
                      }
                      onClick={() => {
                        if (item.children) {
                          toggleItem(itemIdx)
                        } else {
                          navigate(item.path)
                        }
                      }}
                    >
                      {item.icon}
                      <p className="nav__section__content">{item.title}</p>

                      {/* {location.pathname === item.path && !!isPlayerActive && (
                        <FlexibleDiv width="max-content">
                          <SoundIcon width={18} />
                        </FlexibleDiv>
                      )} */}
                    </FlexibleDiv>

                    {/* Render child items if available */}
                    {item.children && openItems[itemIdx] && (
                      <div className="sidebar__nav__subsection__children">
                        {item.children.map((childItem, childIdx) => (
                          <FlexibleDiv
                            key={childIdx}
                            flexWrap="no-wrap"
                            justifyContent="flex-start"
                            className={
                              "sidebar__nav__sectionitems " +
                              (location.pathname === childItem.path &&
                                " sidebar__activenav__sectionitems")
                            }
                            onClick={() => {
                              if (childItem.title === "Create New Playlist") {
                                return setIsPlaylistModal("standard")
                              } else if (
                                childItem.title === "Create Collab Playlist"
                              ) {
                                return setIsPlaylistModal("collab")
                              } else {
                                navigate(childItem.path)
                              }
                            }}
                          >
                            {childItem.icon}
                            <p className="nav__section__content">
                              {childItem.title}
                            </p>
                          </FlexibleDiv>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </FlexibleDiv>
            ))}

            <FlexibleDiv
              className="sidebar__nav__sections"
              flexDir="column"
              alignItems="flex-start"
            >
              <h3 className="sidebar__section__title">Your Playlist</h3>
             {isLoggedIn ? 
              playlists?.map(pl => (
                <FlexibleDiv
                  key={pl.id}
                  flexWrap="no-wrap"
                  style={{ marginBottom: "10px" }}
                  onClick={() =>
                    navigate(`/playlist/${pl.id}?t=pl`, {
                      state: { module: "playlist" },
                    })
                  }
                  justifyContent="flex-start"
                  className="sidebar__nav__sectionitems"
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      transition: "all 0.3s ease",
                    }}
                    src={pl.img}
                    alt=""
                  />
                  <p
                    style={{ marginLeft: "10px" }}
                    className="nav__section__content"
                  >
                    {pl.text}
                  </p>
                </FlexibleDiv>
              ))
              : 
              <Button className="playlist__btn">Create Playlist <PlusIcon width="10px" height="10px" color="#fff" /></Button>
            }
            </FlexibleDiv>

            <FlexibleDiv
              className="sidebar__nav__sections"
              flexDir="column"
              alignItems="flex-start"
              margin="40px 0 0 0"
            >
              <div className="list__image__wrap">
                <img className="listen" src={listen} alt="icon" />
              </div>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </Section>

      {isPlaylistModal === "standard" && (
        <CreatePlaylistModal
          handleClose={() => setIsPlaylistModal(undefined)}
          open={isPlaylistModal === "standard"}
          title="Create New Playlist"
          handleBtnClick={payload => {
            console.log({payload})
            mutateStandardPlaylist(
              { ...payload },
              {
                onSuccess: ({ data }) => {
                  setIsPlaylistModal(undefined)
                  navigate(`/playlist/${data.id}`)
                },
              }
            )
          }}
        />
      )}
      {isPlaylistModal === "collab" && (
        <CreatePlaylistModal
          handleBtnClick={payload => {
            mutateCollab(
              { ...payload, private: payload.type === "private" },
              {
                onSuccess: ({ data }) => {
                  setIsPlaylistModal(undefined)
                  navigate(`/playlist/${data.id}`)
                },
              }
            )
          }}
          handleClose={() => setIsPlaylistModal(undefined)}
          open={isPlaylistModal === "collab"}
          title="Create Collab Playlist"
          isLoading={collabLoading}
        />
      )}
    </SidebarWrap>
  )
}

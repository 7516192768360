import { AREModalWrap } from "./auth-response-error.styles"

export type ReadMoreModalProps = {
  open: boolean
  content: string[]
  handleOk?: () => void
  handleCancel?: () => void
}
export const ReadMoreModal = ({
  open,
  content,
  handleCancel,
  handleOk,
}: ReadMoreModalProps) => {
  return (
    <AREModalWrap
      visible={open}
      onOk={handleOk}
      onCancel={handleCancel}
      title="We encountered some errors"
    >
      <ul className="list__errors">
        {content.map((e, idx) => (
          <li key={idx}>{e}</li>
        ))}
      </ul>
    </AREModalWrap>
  )
}

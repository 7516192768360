import styled from "styled-components"

export const OverflowScroll = styled.div`
  width: 100%;
  height: 100%;
  /* pointer-events: all; */

  div {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      background: var(--primaryYellow);
      max-height: 100px !important;
    }
    &::-webkit-scrollbar-thumb:active {
      background: var(--primaryYellow);
      max-height: 100px !important;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 0.3px solid #282828;
      border-width: 0 0.3px 0 0.3px;
    }
    &::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    &::-webkit-scrollbar-track:active {
      background: var(--primaryYellow);
      height: 100px;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`

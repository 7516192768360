import { IconProps } from "types"

export const CallIcon = ({
  color = "#fff",
  width = "33.343",
  height = "33.343",
}: IconProps) => (
  <svg
    id="call-2"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <g id="Group_3612" data-name="Group 3612">
      <path
        id="Path_1160"
        data-name="Path 1160"
        d="M30.667,21.886a18.931,18.931,0,0,1-5.945-.947,2.717,2.717,0,0,0-2.643.557l-3.749,2.83a20.719,20.719,0,0,1-9.315-9.313l2.747-3.651a2.7,2.7,0,0,0,.663-2.731,18.96,18.96,0,0,1-.951-5.955A2.678,2.678,0,0,0,8.8,0H2.675A2.679,2.679,0,0,0,0,2.675,30.7,30.7,0,0,0,30.667,33.343a2.679,2.679,0,0,0,2.675-2.675V24.561A2.679,2.679,0,0,0,30.667,21.886Z"
        fill={color}
      />
    </g>
  </svg>
)

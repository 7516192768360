import { GotoAlbumIcon, GotoArtistIcon, SimilarVibesIcon, GotoCreditsIcon, ShareIcon2 } from "assets"
import { StyledMenu } from "components"

export type TrackItemMoreMenuProps = {
  handleShare?: () => void
  handleGotoAlbum?: () => void
  handleGotoArtistPage?: () => void
  handleAddToQueue?: () => void; 
  handleShowCredits?: ()=> void;
  top: string
  handleSimilarVibes?: ()=> void;
}

export const TrackItemMoreMenu = ({
  handleShare,
  handleGotoAlbum,
  handleGotoArtistPage,
  handleSimilarVibes,
  handleShowCredits,
  top,
  ...props
}: TrackItemMoreMenuProps) => {
  const items = [
    {
      label: (
        <div className="menu_box" onClick={handleSimilarVibes}>
          <SimilarVibesIcon width={18} />
          <p>Similar Vibes</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleGotoArtistPage}>
          <GotoArtistIcon width={18} />
          <p>Go to Artist</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleGotoAlbum}>
          <GotoAlbumIcon width={18} />
          <p>Go to Album</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleShowCredits}>
          <GotoCreditsIcon width={18} />
          <p>Show Credits</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleShare}>
          <ShareIcon2 width={18} />
          <p>Share</p>
        </div>
      ),
    },
  ]

  return (
    <StyledMenu
      top={top || "1rem"}
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
      {...props}
    />
  )
}

import { IconProps } from "types";

export const PlusIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "23"}
    height={height || "23"}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.502 1V22"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.002 11.5H1.00195"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { useEffect, useRef, useState } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { ArrowCarouselWrap } from "./arrrow-carousel.styles"
import { FlexibleDiv } from "../Box"

export type ArrowCarouselProps = {
  list?: Array<any>
  slot: React.ReactElement
}
export const ArrowCarousel = ({ list, slot }: ArrowCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  // Function to update the state of the arrows visibility
  const updateScrollButtons = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
    }
  }

  // Scroll left or right by 300px when arrow buttons are clicked
  const scroll = (direction: "left" | "right") => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: direction === "left" ? -300 : 300,
        behavior: "smooth",
      })
    }
  }

  // Update arrow visibility on mount and whenever tracks are loaded or scrolled
  useEffect(() => {
    const carouselElement = carouselRef.current // Copy the current ref value to a local variable

    updateScrollButtons()

    if (carouselElement) {
      carouselElement.addEventListener("scroll", updateScrollButtons)
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener("scroll", updateScrollButtons)
      }
    }
  }, [list])

  return (
    <ArrowCarouselWrap>
      {/* Left Arrow */}
      {canScrollLeft && (
        <div className="left-arrow" onClick={() => scroll("left")}>
          <IoIosArrowBack />
        </div>
      )}

      <FlexibleDiv ref={carouselRef} className="cards__carousel">
        {slot}
      </FlexibleDiv>

      {/* Right Arrow */}
      {canScrollRight && (
        <div className="right-arrow" onClick={() => scroll("right")}>
          <IoIosArrowForward />
        </div>
      )}
    </ArrowCarouselWrap>
  )
}

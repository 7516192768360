import { CollabTabDisplayWrap } from "../styles"
import { InActivePlaylist } from "../InActivePlaylist"
import { SearchCollabUser } from "./SearchCollabUser"
import { Fragment, useState } from "react"
import { useQuery } from "react-query"
import { getCollabRequestSent } from "network"
import { TheCollabLineup } from "./TheCollabLineup"
import { Loader } from "components"

export const CollabTabDisplay = () => {
  const [isSearchUser, setIsSearchUser] = useState(false)
  const { data, isLoading } = useQuery(["sent-collab-requests"], () =>
    getCollabRequestSent()
  )
  const hasRequests = (data && data.length > 0) || false

  return (
    <CollabTabDisplayWrap>
      <Fragment>
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            {hasRequests ? (
              <TheCollabLineup invites={data} />
            ) : (
              <Fragment>
                {isSearchUser ? (
                  <SearchCollabUser />
                ) : (
                  <InActivePlaylist
                    title="Manage Collab List"
                    description="Add, remove or edit collaborators on this playlist"
                    isCollabTab
                    handleClick={() => setIsSearchUser(true)}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    </CollabTabDisplayWrap>
  )
}

import { DownloadIcon, LikeIcon, MoreIcon, PlayIcon, ShuffleIcon } from "assets"
import { PlaylistMoreMenu } from "./PlaylistMoreMenu"
import { Dropdown } from "antd"
import { HiOutlineUserAdd } from "react-icons/hi"
import { CgMoreVertical } from "react-icons/cg"
import { BsDownload } from "react-icons/bs"
import { CollabControlsWrap, StandardControlsWrap } from "./jumbotron-controls.styles"
import { useState } from "react"
import { InviteCollabUserModal } from "components/lib/Modals/InviteCollabUserModal"
import { useParams } from "react-router-dom"

export const CollabControls = () => {
  const [isSendInvite, setIsSendInvite] = useState(false)
  const { playlistId } = useParams()
  const handleDelete = () => {}

  return (
    <CollabControlsWrap>
      <div className="icon__wrap hover__fill">
        <BsDownload color="#fff" />
      </div>
      <div
        className="icon__wrap hover__fill"
        onClick={() => setIsSendInvite(true)}
      >
        <HiOutlineUserAdd color="#fff" />
      </div>
      <div className="icon__wrap hover__fill">
        <Dropdown
          trigger={["click"]}
          overlay={<PlaylistMoreMenu handleDelete={handleDelete} />}
          placement="bottomRight"
        >
          <CgMoreVertical color="#fff" height="19" />
        </Dropdown>
      </div>

      {isSendInvite && (
        <InviteCollabUserModal
          playlistId={playlistId || ""}
          open={isSendInvite}
          handleClose={() => setIsSendInvite(false)}
        />
      )}
    </CollabControlsWrap>
  )
}

export const StandardControls = () => {
  const handleDelete = () => {}

  return (
    <StandardControlsWrap>
      <div className="icon__wrap hover__fill">
        <PlayIcon color="#fff" height={"25"} />
      </div>
      <div className="icon__wrap hover__fill">
        <ShuffleIcon color="#fff" height={"25"} />
      </div>
      <div className="icon__wrap hover__fill">
        <LikeIcon color="#fff" height={"25"} />
      </div>
      <div className="icon__wrap hover__fill">
        <DownloadIcon color="#fff" height={"25"} />
      </div>
      <div className="icon__wrap hover__fill">
        <Dropdown
          trigger={["click"]}
          overlay={<PlaylistMoreMenu handleDelete={handleDelete} />}
          placement="bottomRight"
        >
          <MoreIcon color="#fff" height="19" />
        </Dropdown>
      </div>
    </StandardControlsWrap>
  )
}

import {
  DominantColors,
  Profile,
  SourceService,
  Statistics,
  User,
  UserRole,
} from "context"
import { CoverArt } from "./misc"
import { Track } from "./artist"

export type Playlist = {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  status: string
  creationStatus: string
  type: PlaylistType
  title: string
  description: string
  portedFrom: string
  featuredArtists: Array<string>
  tracks: Array<Track>
  private: boolean
  editors: Editor[]
  descriptionAsSummary: boolean
  user: User
  artist: string
  coverArt: CoverArt
  track?: Track
  coverArtDominantColors: DominantColors
  category: string
  statistics: Statistics
  userLiked: boolean
}

export enum PlaylistStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}

export enum PlaylistType {
  SOLO = "SOLO",
  MANAGED = "MANAGED",
  COLLABORATIVE = "COLLABORATIVE",
  SINGLE = "SINGLE",
  ALBUM = "ALBUM",
}

export type Editor = {
  deleted: boolean
  username: string
  lowerCaseUsername: string
  emailConfirmed: boolean
  linkedPhoneNumbers: string[]
  isPremium: boolean
  role: UserRole
  profile: Profile
  registeredFrom: SourceService
  isMTNRegistration: boolean
  isTempAccount: boolean
  statistics: Statistics
  accounts: Array<any>
  acceptedTermsAndConditions: boolean
  createdAt: Date
  updatedAt: Date
  accountVerificationDate: Date
  id: string
}

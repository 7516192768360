import { BellIcon } from "assets/icons/BellIcon"
import { useState } from "react"
import { useMediaQuery } from "react-responsive"
import {
  NotificationDrawer,
  NotificationWidgetWrap,
} from "./notification-widget.styles"
import { NotificationItem } from "./NotificationItem"

export const NotificationWidget = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <NotificationWidgetWrap>
      <button onClick={showDrawer}>
        <BellIcon width={"24px"} height={"24px"} />
      </button>

      <NotificationDrawer
        className="notification__drawer"
        width={isMobile ? "100%" : "460px"}
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"placement"}
      >
        <NotificationItem
          text={
            <>
              <b>Tanya</b> invited you to <b>Konvicts Music</b> playlist
            </>
          }
          timestamp="5 mins ago"
          hasAction
        />
        <NotificationItem
          text={
            <>
              <b>Cz</b> invited you to <b>Intimate Sounds</b> playlist
            </>
          }
          timestamp="8 mins ago"
          hasAction
        />
        <NotificationItem
          text={
            <>
              <b>Paul Smith</b> followed you.
            </>
          }
          timestamp="15 mins ago"
        />
        <NotificationItem
          text={
            <>
              <b>Tanzo1</b> invited you to <b>Yellow Page</b> playlist
            </>
          }
          timestamp="18 mins ago"
          hasAction
        />
        <NotificationItem
          text={
            <>
              <b>Sandra Li</b> followed you.
            </>
          }
          timestamp="25 mins ago"
        />
      </NotificationDrawer>
    </NotificationWidgetWrap>
  )
}

import {
  AddToMultiplePlaylistsPayload,
  BaseNetworkResponse,
  CollabPlaylistPayload,
  ResponseCollabPlaylistEngagements,
  ResponsePlaylist,
  ResponsePlaylists,
  ResponseWithAlbum,
  ResponseWithAlbumTracks,
  ResponseWithTrack,
  SoloPlaylistPayload,
  StandardPlaylistPayload,
  UpdateSoloPlaylistPayload,
} from "types"
import { instance } from "./axios"

export const getPlaylistById = async (id: string) => {
  const { data } = await instance.get<ResponsePlaylist>(
    `/streaming/playlists/${id}`
  )

  return data.data
}

export const publicGetPlaylistById = async (id: string) => {
  const { data } = await instance.get<ResponsePlaylist>(
    `/streaming/playlists/${id}/public`
  )

  return data.data
}

export const getAlbumById = async (id?: string) => {
  if (id) {
    const { data } = await instance.get<ResponseWithAlbum>(
      `/streaming/albums/${id}`
    )

    return data.data
  }
}

export const getTrackById = async (id?: string) => {
  if (id) {
    const { data } = await instance.get<ResponseWithTrack>(
      `/streaming/tracks/${id}`
    )

    return data.data
  }
}

export const getAlbumTracksById = async (id?: string) => {
  if (id) {
    const { data } = await instance.get<ResponseWithAlbumTracks>(
      `/streaming/albums/${id}/tracks`
    )

    return data.data
  }
}

export const getAlbumAndTracks = async (id: string) => {
  const [album, tracks] = await Promise.all([
    instance.get<ResponseWithAlbum>(`/streaming/albums/${id}`),
    instance.get<ResponseWithAlbum>(`/streaming/albums/${id}/tracks`),
  ])

  return {
    album: album.data,
    tracks: tracks.data,
  }
}

export const getPlaylistsByQuery = async (q: string) => {
  const { data } = await instance.get<ResponsePlaylists>(
    `/streaming/playlists?${q}`
  )

  return data.data
}

export const createSoloPlaylist = async (data: SoloPlaylistPayload) => {
  const r = await instance.post<ResponsePlaylist>(
    "/streaming/playlists/solo",
    data
  )

  return r.data
}

export const createCollabPlaylist = async (data: CollabPlaylistPayload) => {
  const r = await instance.post<ResponsePlaylist>(
    "/streaming/playlists/collab",
    data
  )

  return r.data
}

export const createStandardPlaylist = async (data: StandardPlaylistPayload) => {
  const r = await instance.post<ResponsePlaylist>(
    "/streaming/playlists/managed",
    data
  )

  return r.data
}

export const addToMultiplePlaylists = async (
  data: AddToMultiplePlaylistsPayload
) => {
  const r = await instance.patch<BaseNetworkResponse>(
    "/streaming/playlists/add-tracks-to-multiple-playlists",
    data
  )

  return r.data
}

export const updatePlaylistInfo = async ({
  id,
  data,
}: {
  id: string
  data: UpdateSoloPlaylistPayload
}) => {
  const r = await instance.patch<ResponsePlaylist>(
    `/streaming/playlists/${id}/solo`,
    data
  )

  return r.data
}

export const deletePlaylist = async (id?: string) => {
  if (id) {
    const r = await instance.delete<ResponsePlaylist>(
      `/streaming/playlists/${id}`
    )

    return r.data
  }
}

export const getAllStreamingPlaylistsForUser = async (userId: string) => {
  const r = await instance.get<ResponsePlaylists>(
    `/streaming/playlists?${userId}`
  )

  return r.data
}

export const getTopCollabPlaylists = async () => {
  const r = await instance.get<ResponsePlaylists>(
    `/streaming/playlists/collab/owned`
  )

  return r.data
}


export const getWidgetPlaylist = async (payload: string) => {
  const { data } = await instance.get<ResponsePlaylist>(
    `/streaming/playlists/poprev/artist/${payload}`
  )
  return data
}

export const getCollabPlaylistEngagements = async (
  collabPlaylistId?: string
) => {
  const r = await instance.get<ResponseCollabPlaylistEngagements>(
    `/streaming/playlists/collab/engagements/all/${collabPlaylistId}`
  )
  return r.data
}

export const getCollabPlaylistStats = async (collabPlaylistId?: string) => {
  const r = await instance.get<ResponseCollabPlaylistEngagements>(
    `/streaming/playlists/collab/stats/all/${collabPlaylistId}`
  )
  return r.data
}

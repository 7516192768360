import styled from "styled-components"

export interface StyledButtonProps {
  width?: string
  color?: string
  border?: string
  height?: string
  margin?: string
  radius?: string
  opacity?: string
  flexDir?: string
  padding?: string
  bgColor?: string
  boxShadow?: string
  font_size?: string
  background?: string
  font_family?: string
  transform_text?: string
  type?: "button" | "submit" | "reset"

  ltr?: boolean
  bold?: boolean
  contained?: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
  /*Reset*/
  outline: none;
  display: inline-block;

  display: flex;
  cursor: pointer;
  /* line-height: 200%; */
  position: relative;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => margin || "0"};
  width: ${({ width }) => width || "inherit"};
  height: ${({ height }) => height || "auto"};
  opacity: ${({ opacity }) => opacity || "1"};
  border-radius: ${({ radius }) => radius || "2px"};
  padding: ${({ padding }) => padding || "0.5rem 1rem"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  color: ${({ color }) => color || "var(--textPrimary)"};
  border: ${({ border, background }) =>
    background ? "transparent" : border || "1px solid var(--buttonPrimary)"};
  text-transform: ${({ transform_text }) => transform_text || "capitalize"};
  font-family: ${({ font_family }) =>
    font_family || "var(--mRegular), sans-serif"};

  background-color: ${({ contained, background }) =>
    contained
      ? "var(--buttonPrimary)"
      : background
      ? background
      : "transparent"};

  ::before {
    left: 0;
    bottom: 0;
    content: "";
    transition: 0.4s;
    position: absolute;
    height: ${({ ltr }) => (ltr ? "100%" : "0")};
    width: ${({ ltr }) => (ltr ? "0" : "100%")};
    border-radius: ${({ radius }) => (radius ? radius : "0")};
    background-color: ${({ contained }) =>
      contained ? "var(--black)" : "var(--buttonPrimary)"};
  }

  :hover {
    ::before {
      height: 100%;
      width: 100%;
    }

    span,
    svg {
      color: ${({ contained }) => (contained ? "var(--textPrimary)" : "")};
    }
  }

  svg {
    color: inherit;
    font-size: 11px;
    margin-right: 5px;
    transition: all 0.85s ease;
    color: ${({ color }) => color || "var(--textPrimary)"};
  }

  span {
    position: relative;
    transition: all 0.3s ease;
    font-size: ${({ font_size }) => font_size || "1rem"};
    color: ${({ color }) => color || "var(--textPrimary)"};
    -webkit-text-stroke-width: ${({ bold }) => (bold ? `1px` : "none")};
    font-family: ${({ font_family }) =>
      font_family || "var(--mRegular), sans-serif"};
  }

  :disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;

    &::before,
    &::after {
      content: "";
      width: 0%;
      height: 100%;
      background: ${({ contained }) =>
        contained ? "var(--overlayLighter)" : "var(--buttonPrimaryOverlay)"};
      z-index: 10;
      animation: loadSignal 3s linear infinite;
    }

    :hover {
      &::before,
      &::after {
        background: ${({ contained }) =>
          contained ? "var(--buttonPrimaryOverlay)" : "var(--overlayLighter)"};
      }
    }
  }

  @keyframes loadSignal {
    0% {
      width: 0%;
    }
    25% {
      width: 25%;
    }
    50% {
      width: 50%;
    }
    75% {
      width: 75%;
    }
    100% {
      width: 0%;
    }
  }

  @media screen and (max-width: 600px) {
    svg {
      font-size: 0.75rem;
    }

    span {
      font-size: 0.85rem;
    }
  }
`

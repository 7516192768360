import { CollabPlaylistTabHeader, TabContent } from "components"
import { TheCollabLineupWrap } from "../styles"
import { PiMusicNotesPlusBold } from "react-icons/pi"
import { IoMusicalNotes } from "react-icons/io5"
import { useState } from "react"
import { CollaboratorsListTabDisplay } from "./CollaboratorsListTabDisplay"
import { InvitesTabDisplay } from "./InvitesTabDisplay"
import { Collaboration } from "types"

export type CollabLineupTabRoutes = typeof collabLineupTabRoutes
const collabLineupTabRoutes = [
  {
    icon: <PiMusicNotesPlusBold />,
    title: "Collaborators",
    hash: "#collaborators",
  },
  {
    icon: <IoMusicalNotes />,
    title: "Invites",
    hash: "#invites",
  },
]
export type TheCollabLineupProps = {
  invites?: Collaboration[]
}

export const TheCollabLineup = ({ invites }: TheCollabLineupProps) => {
  const [activeTab, setActiveTab] = useState("#collaborators")

  return (
    <TheCollabLineupWrap>
      <header>
        <h2 className="title">The Collab Lineup</h2>
        <p className="description">All X'ers contributing to this playlist</p>
      </header>

      <CollabPlaylistTabHeader
        width="100%"
        activeTab={activeTab}
        tabs={collabLineupTabRoutes}
        padding="0.2rem"
        setActiveTab={tab => setActiveTab(tab)}
      />

      <TabContent>
        {activeTab === "#collaborators" && <CollaboratorsListTabDisplay />}
        {activeTab === "#invites" && <InvitesTabDisplay invites={invites} />}
      </TabContent>
    </TheCollabLineupWrap>
  )
}

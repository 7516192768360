import React from "react";
import { IconProps } from "types";

export const AddToPlaylistIcon = ({
  color = "#D9D9D9",
  width = "18",
  height = "15",
}: IconProps) => (
  <svg
    width={width || "16"}
    height={height || "13"}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.57895H7.61905V10.9474H0V9.57895ZM0 4.78947H15.2381V6.1579H0V4.78947ZM0 0H15.2381V1.36842H0V0ZM12.1905 9.57895V7.52632H13.7143V9.57895H16V10.9474H13.7143V13H12.1905V10.9474H9.90476V9.57895H12.1905Z"
      fill={color}
      fillOpacity="0.9"
    />
  </svg>
);

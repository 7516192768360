import React, { useState } from "react";
import {
  BorderedSelectFormItem,
  BorderedSelectFormItemProps,
  BorderedSelectItemWrap,
  BorderedUduxSelect,
  BorderedUduxSelectProps,
} from "./bordered-select.styles";
import { IoChevronDownCircleSharp } from "react-icons/io5";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdCancel as Cancel } from "react-icons/md";
import { StatusState } from "types";
import { ValidateInputProps } from "utils";
import { RuleObject } from "antd/lib/form";

// Correct the prop types for event handlers and ensure they match Ant Design expectations
export type BorderedSelectFieldProps = {
  name?: string;
  label?: string;
  onBlur?: React.FocusEventHandler<HTMLElement>; // Updated to FocusEventHandler
  margin?: string;
  showSearch?: boolean; // Changed to boolean to match Ant Design Select
  placeholder?: string;
  children: React.ReactNode;
  nullable?: boolean;
  onchange?: (value: any, option: any) => void; // Update for Ant Design onChange handler
  onClick?: React.MouseEventHandler<HTMLDivElement>; // Updated to MouseEventHandler
  validator?: (value: ValidateInputProps) => Promise<void>;
} & BorderedUduxSelectProps &
  BorderedSelectFormItemProps;

export const BorderedSelectField = ({
  name,
  width,
  color,
  label,
  border,
  radius,
  height,
  onBlur,
  margin,
  padding,
  onClick,
  onchange,
  children,
  validator,
  showSearch,
  background,
  text_align,
  placeholder,
  borderColor,
  font_family,
  placeholder_color,
  ...props
}: BorderedSelectFieldProps) => {
  const [status, setStatus] = useState<StatusState>();

  return (
    <BorderedSelectItemWrap className="select__item__container" {...props}>
      {label && <label htmlFor={name}>{label}</label>}

      <BorderedSelectFormItem
        name={name}
        width={width}
        color={color}
        height={height}
        radius={radius}
        border={border}
        margin={margin}
        text_align={text_align}
        background={background}
        borderColor={borderColor}
        hasError={Boolean(status?.err)}
        hasSuccess={Boolean(status?.success)}
        rules={[
          {
            validator: (rules: RuleObject, value: string) =>
              validator ? validator({ rules, value, setStatus }) : undefined,
          },
        ]}
      >
        <BorderedUduxSelect
          small={props.small}
          onBlur={onBlur} // Correct type for onBlur
          padding={padding}
          onClick={onClick} // Correct type for onClick
          popupMatchSelectWidth
          onChange={onchange} // Correct type for onChange
          showSearch={showSearch} // Boolean value, as expected by Ant Design Select
          placeholder={placeholder}
          font_family={font_family}
          suffixIcon={
            status?.success ? (
              <div className="check">
                <BsFillCheckCircleFill
                  className="check"
                  color="var(--successPrimary)"
                />
              </div>
            ) : status?.err ? (
              <div className="cancel">
                <Cancel />
              </div>
            ) : (
              <IoChevronDownCircleSharp color="var(--pinkPrimary)" />
            )
          }
          onFocus={() => setStatus(status)}
          placeholder_color={placeholder_color}
        >
          {children}
        </BorderedUduxSelect>
      </BorderedSelectFormItem>

      <p className="input__error__text">{status?.err}</p>
    </BorderedSelectItemWrap>
  );
};

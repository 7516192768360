import { Album, Track } from "./artist"
import { Playlist } from "./playlist"

export type StreamLog = {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  playlist: string
  track: string
  playlistTrack: string
  source: StreamLogSource | string
  userAgent: string
  user: string
  session: string
  streamTime: number
}

export enum StreamLogSource {
  MOBILE = "MOBILE",
  WEB = "WEB",
  UNKNOWN = "UNKNOWN",
}

export type UserLibrary = {
  tracks: Track[]
  playlists: Playlist[]
  album: Album[]
}

import { IconProps } from "types";

export const BellIcon = ({ color = "#848484", width, height }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
    className="bell__svg"
    fill="none"
  >
    <path
      d="M9.625 20H13.375C13.375 20.5304 13.1775 21.0391 12.8258 21.4142C12.4742 21.7893 11.9973 22 11.5 22C11.0027 22 10.5258 21.7893 10.1742 21.4142C9.82254 21.0391 9.625 20.5304 9.625 20ZM17.125 16V10C17.1237 8.58312 16.6523 7.21247 15.7943 6.13077C14.9364 5.04908 13.7472 4.32615 12.4375 4.09V3C12.4375 2.73478 12.3387 2.48043 12.1629 2.29289C11.9871 2.10536 11.7486 2 11.5 2C11.2514 2 11.0129 2.10536 10.8371 2.29289C10.6613 2.48043 10.5625 2.73478 10.5625 3V4.09C9.25276 4.32615 8.06362 5.04908 7.20567 6.13077C6.34773 7.21247 5.87635 8.58312 5.875 10V16L4 18H19L17.125 16Z"
      fill={color}
    />
  </svg>
);

import { FlexibleDiv } from "components"
import styled from "styled-components"

export interface MSPSWrapperProps {
  height?: string
  width?: string
}

export const MSPSWrapper = styled(FlexibleDiv)<MSPSWrapperProps>`
  max-height: 300px;

  .mtn__header {
    font-family: "Bw ModelicaMedium";
    color: #ff9d00;
    font-size: 19px;
  }

  .mtn__subtext {
    color: #fdfeff;
    opacity: 0.6;
    font-family: "Bw ModelicaMedium";
  }
`

import { useNavigate, useSearchParams } from "react-router-dom"
import { useMainContext, Types, SourceService } from "context"
import { LoginPayload } from "types"
import { Config } from "config"
import { useMutation } from "react-query"
import { login } from "network/authenticationServices"

// Custom hook for login service
export const useLoginService = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { dispatch } = useMainContext()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"

  return useMutation(
    async (payload: LoginPayload) => {
      return login({
        ...payload,
        sourceService: Config.sourceService as SourceService,
      })
    },
    {
      onSuccess: res => {
        if (res) {
          const { user, tokens } = res
          const pathToReturn = `/payment/confirm?type=${plan}&mode=${mode}`

          // Store user and tokens
          localStorage.setItem("udx__user", JSON.stringify(user))
          localStorage.setItem("udx__tokens", JSON.stringify(tokens))

          // Update the global state
          dispatch({ type: Types.CURRENT_USER, payload: user })

          // Navigate to the confirmation page
          navigate(pathToReturn, {
            state: { prevRoute: `/payment/plan?type=${plan}&mode=${mode}` },
          })
        }
      },
      onError: error => {
        // Dispatch the error to the global state
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    }
  )
}

import { FlexibleDiv, FlexibleDivProps } from "components";
import styled from "styled-components";

export const QTIWrap = styled(FlexibleDiv)<FlexibleDivProps>`
  width: 100%;
  padding: 1.2rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-bottom: 0.2px solid var(--borderLighter);
  flex-wrap: nowrap;
  justify-content: space-between;

  &:hover {
    background: var(--backgroundDarkLight);

    .show__on__hover {
      opacity: 1;
    }
  }

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .selectable--wrap {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex: 0;
    flex-wrap: nowrap;
    column-gap: 10px;
    width: 200px;
    min-width: 200px;

    .closeicon__wrap {
      width: fit-content;
      min-width: fit-content;
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;
    }

    label {
      margin: 0;
    }

    .image--wrap {
      width: 45px;
      min-width: 45px;
      min-height: 45px;
      height: 45px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info--wrap {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 5px;

      p {
        margin-bottom: 0;
      }

      .title,
      .author {
        white-space: nowrap;
        text-transform: capitalize;
        font-family: var(--mLight);

        .artist {
          white-space: nowrap;
          opacity: 0.6;
          font-family: var(--mLight);
        }
      }
    }
  }

  .type--wrap {
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 150px;
    min-width: 150px;
  }

  .action__icons__wrap {
    flex: 1;
    justify-content: flex-end;
    flex-wrap: nowrap;
    column-gap: 20px;
    width: 150px;
    min-width: 150px;

    p {
      font-family: var(--mLight);
      opacity: 0.8;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 600px) {
    .duration--wrap {
      display: none;
    }
    .action__icons__wrap {
      column-gap: 10px;
    }

    .type--wrap {
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

import { Button, FlexibleDiv } from "components"

export type SendInviteProps = {
  title?: string
  handleClick: (payload: boolean) => void
}
export const SendInvite = ({
  title = "Your collaborator isn't on uduX yet!",
  handleClick,
}: SendInviteProps) => {
  return (
    <FlexibleDiv flexDir="column" className="not__on__udux">
      <h3 className="title">{title}</h3>

      <Button
        onClick={() => handleClick(true)}
        color="var(--primaryBlack)"
        width="30%"
        contained
      >
        Send Invite
      </Button>
    </FlexibleDiv>
  )
}

import { FlexibleDiv, FlexibleDivProps } from "components";
import styled from "styled-components";

interface FSEMPlayerWrapProps extends FlexibleDivProps {
  isMobile?: boolean;
}
export const FSEMPlayerWrap = styled(FlexibleDiv)<FSEMPlayerWrapProps>`
  width: 100%;
  justify-content: center;
  padding-top: ${({ isMobile }) => (isMobile ? "0" : "2rem")};

  .fsem__wrap {
    row-gap: 10px;
    @media screen and (max-width: 994px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .track__image__wrap {
      height: 400px;
      max-height: 400px;
      border: 0.5px solid var(--uduxGrayLightAlpha);
      padding: 5px;
      @media screen and (max-width: 765px) {
        height: 290px;
        max-height: 290px;
      }

      @media screen and (min-width: 375px) and (max-width: 375px) and (min-height: 667px) and (max-height: 667px) {
        height: 250px;
        min-height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .track__title__control {
      @media screen and (max-width: 994px) {
        padding: 0 20px;
      }

      .icon__wrap {
        width: 30px;
        height: 40px;
        cursor: pointer;
        align-items: center;
      }
      .icon__wrap.left {
        justify-content: flex-start;
      }
      .icon__wrap.right {
        justify-content: flex-end;
      }

      .track-title {
        flex: 3;

        p {
          margin-bottom: 0.5rem;
          font-family: "Baguede", sans-serif;
          color: var(--textPrimary);
        }

        .artist__wrap {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .artist {
          cursor: pointer;
          transition: all 10s ease-in;

          :hover {
            text-decoration: underline;
          }
        }
      }
    }

    .overal__controls {
      row-gap: 20px;
      @media screen and (max-width: 994px) {
        padding: 0px 20px;
      }
      .track__controls__wrap {
        flex: 1;
        align-items: center;
        row-gap: 2px;

        .controls__main {
          column-gap: 20px;
          @media screen and (max-width: 994px) {
            column-gap: 10px;
          }
          .control__icons {
            cursor: pointer;
          }
        }

        .song__progress__bar {
          position: relative;
          height: 6px;

          .track__inner {
            height: 100%;
            position: absolute;
            background-color: var(--yellowPrimary);
          }
        }
      }


      .control{
        color: red;
        cursor: pointer;
        &:hover {
          color: var(--yellowPrimary);
        }
      }
    }
  }
`;

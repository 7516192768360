import { Skeleton } from "antd"
import {
  FlexibleDiv,
  InvitationCard,
  NoDataDisplay,
  PlaylistCard,
} from "components"
import { useMainContext } from "context"
import {
  getCollabRequestReceived,
  getLikedPlaylists,
  getPlaylistsByQuery,
} from "network"
import { SearchCategoryFilter } from "pages/Search"
import { feedCategoryFilter } from "pages/Search/components/SearchFilter/data-helper"
import { useState } from "react"
import { useQueries } from "react-query"
import { useNavigate } from "react-router-dom"
import { FDWrap } from "./styles"

export const FeedData = () => {
  const [feedFilter, setFeedFilter] = useState("solo")
  const navigate = useNavigate()
  const {
    state: { users, playlist },
  } = useMainContext()
  const { me } = users
  const { creatingPlaylist } = playlist

  const feedDataInfo = useQueries([
    {
      enabled: !!me?.id,
      queryKey: ["artist-popular-tracks", creatingPlaylist],
      queryFn: () => getPlaylistsByQuery(`user=${me?.id}`),
    },
    {
      queryKey: ["liked-playlist"],
      queryFn: () => getLikedPlaylists(),
    },
    {
      queryKey: ["collab-invites-received"],
      queryFn: () => getCollabRequestReceived(),
    },
  ])

  const isFetching = feedDataInfo.some(o => o.isLoading)
  const playlists = feedDataInfo[0].data
  const following = feedDataInfo[1].data
  // const invitations = feedDataInfo[2].data
  const bg = me?.profile?.profilePicture?.url

  const data = [
    {
      key: "1",
      photo: "photo1.jpg",
      playlistName: "Playlist Name",
      invitedByName: "Invited By Name",
      dateOfInvite: "Date of Invite",
    },
    {
      key: "2",
      photo: "photo1.jpg",
      playlistName: "Playlist Name",
      invitedByName: "Invited By Name",
      dateOfInvite: "Date of Invite",
    },
    {
      key: "3",
      photo: "photo1.jpg",
      playlistName: "Playlist Name",
      invitedByName: "Invited By Name",
      dateOfInvite: "Date of Invite",
    },
    {
      key: "4",
      photo: "photo1.jpg",
      playlistName: "Playlist Name",
      invitedByName: "Invited By Name",
      dateOfInvite: "Date of Invite",
    },
  ]

  return isFetching ? (
    <FlexibleDiv minHeight="300px">
      <Skeleton active={isFetching} />
    </FlexibleDiv>
  ) : (
    <FDWrap bg={bg}>
      <FlexibleDiv className="feed-data-display-top">
        <FlexibleDiv className="header-info">
          <h2 className="your-x">Your X</h2>
        </FlexibleDiv>

        <FlexibleDiv className="category__filter">
          <FlexibleDiv className="category__filter__content">
            <SearchCategoryFilter
              activeLabel={feedFilter}
              filterBy={feedCategoryFilter}
              setActiveLabel={(ff: string) => setFeedFilter(ff)}
            />
          </FlexibleDiv>
          <span className="see__all" onClick={() => navigate(`/feed/see-all`)}>
            see all
          </span>
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="feed-data-display">
        {feedDataInfo?.length ? (
          getFeedData()
        ) : (
          <NoDataDisplay text="You have no feed at this time." />
        )}
      </FlexibleDiv>
    </FDWrap>
  )

  function getFeedData() {
    if (feedFilter === "solo" || feedFilter === "collab") {
      const filteredPl = playlists?.filter(pl =>
        pl.type.toLowerCase()?.includes(feedFilter)
      )

      return filteredPl?.length ? (
        filteredPl?.map(d => <PlaylistCard {...d} key={d.id} />)
      ) : (
        <NoDataDisplay
          height="350px"
          text={`No result found for '${feedFilter}'.`}
          transparentBng
        />
      )
    } else if (feedFilter === "following") {
      return following?.length ? (
        following.map(d => <PlaylistCard {...d} key={d.id} />)
      ) : (
        <NoDataDisplay
          height="350px"
          text={`No result found for '${feedFilter}'.`}
          transparentBng
        />
      )
    } else if (feedFilter === "invitations") {
      return data.length ? (
        <FlexibleDiv className="tracks__list">
          {data?.map((item, i) => {
            // TODO: Create a new component for this, as the types are completely different
            return <InvitationCard {...item} idx={i} key={i} />
          })}
        </FlexibleDiv>
      ) : (
        <NoDataDisplay
          height="280px"
          width="fit-content"
          inviteImg={true}
          text={`No result found for '${feedFilter}'.`}
        />
      )
    }
  }
}

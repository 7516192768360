import { IconProps } from "types";

export const RepeatOutlineIcon = ({
  color="#D9D9D9",
  width,
  height,
}: IconProps & { fullScreen?: boolean }) => (
  <svg
    width={width || "35"}
    height={height || "25"}
    viewBox="0 0 35 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8652 1.67578L26.9515 5.4961L22.8652 9.31642"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.59 5.49609H7.88274C6.07815 5.50113 4.34901 6.17358 3.07296 7.36657C1.79692 8.55956 1.07766 10.1762 1.07227 11.8633V13.1367"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9691 23.3242L7.88281 19.5039L11.9691 15.6836"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.24414 19.5078H26.9514C28.756 19.5028 30.4851 18.8303 31.7611 17.6373C33.0372 16.4444 33.7565 14.8278 33.7618 13.1406V11.8672"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";
import { IconProps } from "types";

export const HistoryIcon = ({
  color = "white",
  width = "22.338",
  height = "23.004",
}: IconProps) => (
  <svg
    width={width || "15"}
    height={height || "14"}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28586 10.7699C3.16088 10.8949 3.09067 11.0644 3.09067 11.2412C3.09067 11.418 3.16088 11.5875 3.28586 11.7125C5.88919 14.3159 10.1105 14.3159 12.7139 11.7125C15.3172 9.1092 15.3172 4.88786 12.7139 2.28453C10.1105 -0.318802 5.88919 -0.318802 3.28586 2.28453C2.19635 3.37051 1.51771 4.80108 1.36586 6.33186H0.666525C0.534691 6.33189 0.405826 6.37101 0.296219 6.44426C0.186612 6.51752 0.101186 6.62163 0.0507393 6.74343C0.000292604 6.86523 -0.0129094 6.99925 0.0128023 7.12855C0.038514 7.25785 0.101985 7.37663 0.195191 7.46986L1.52852 8.8032L1.52986 8.80453C1.59175 8.86642 1.66525 8.91548 1.74615 8.94889C1.82705 8.9823 1.91375 8.99941 2.00127 8.99922C2.0888 8.99903 2.17543 8.98156 2.25618 8.94781C2.33694 8.91406 2.41023 8.86469 2.47186 8.80253L3.80519 7.46986C3.89844 7.37659 3.96192 7.25775 3.98761 7.12839C4.0133 6.99902 4.00005 6.86495 3.94952 6.74312C3.89899 6.62129 3.81346 6.51719 3.70375 6.44399C3.59404 6.37078 3.46508 6.33176 3.33319 6.33186H2.70786C2.85858 5.1361 3.40988 4.02665 4.27188 3.18432C5.13389 2.342 6.25578 1.81647 7.45471 1.6934C8.65363 1.57033 9.8589 1.85697 10.8741 2.5066C11.8892 3.15623 12.6544 4.13055 13.0449 5.27075C13.4354 6.41096 13.4282 7.64981 13.0245 8.78541C12.6208 9.921 11.8443 10.8864 10.8217 11.5242C9.79908 12.162 8.59058 12.4346 7.39316 12.2976C6.19575 12.1607 5.08003 11.6221 4.22786 10.7699C4.10287 10.6451 3.93347 10.575 3.75686 10.575C3.58025 10.575 3.41085 10.6451 3.28586 10.7699ZM6.66652 4.33186C6.66652 4.15505 6.73676 3.98548 6.86179 3.86046C6.98681 3.73544 7.15638 3.6652 7.33319 3.6652C7.51 3.6652 7.67957 3.73544 7.8046 3.86046C7.92962 3.98548 7.99986 4.15505 7.99986 4.33186V6.99853H10.6665C10.8433 6.99853 11.0129 7.06877 11.1379 7.19379C11.263 7.31882 11.3332 7.48839 11.3332 7.6652C11.3332 7.84201 11.263 8.01158 11.1379 8.1366C11.0129 8.26163 10.8433 8.33186 10.6665 8.33186H7.33319C7.15638 8.33186 6.98681 8.26163 6.86179 8.1366C6.73676 8.01158 6.66652 7.84201 6.66652 7.6652V4.33186Z"
      fill={color}
    />
  </svg>
);

import styled from "styled-components"
import { FlexibleDiv } from "components"

export const PaymentInfoWrapper = styled(FlexibleDiv)`
  position: relative;
  height: 70vh;
  width: 100%;
  padding: 0 3.5%;
  padding-top: 100px;
  justify-content: space-between;

  .image__wrapper {
    width: auto;

    .background__overlay {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .info__section__wrapper {
    .top__container {
      padding-bottom: 8%;

      .small__udux__text {
        font-size: 0.7rem;
        font-family: var(-mRegular);
        color: var(--primaryCyan);
        width: 100%;
        padding-bottom: 2%;
      }

      .big__subtitle__text,
      .big__subtitle__text span {
        color: var(--primaryWhite);
        font-family: var(--mLight);
        font-size: 2.7rem;
      }

      .big__subtitle__text span {
        color: var(--primaryYellow);
        font-size: 1em;
      }
    }

    .bottom__container {
      .box__wrapper {
        .container__title {
          color: var(--primaryCyan);
          font-family: var(--mBold);
          text-align: left;
          margin: 0;
          width: 100%;
          font-size: 1.3rem;
          padding-bottom: 5%;
        }

        .container__subtitle {
          text-align: left;
          margin: 0;
          width: 100%;
          font-family: var(--mRegular);
          font-size: 0.9rem;
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    height: 50vh;
    width: 100%;
    padding: 0 3.5%;
    padding-top: 50px;

    .image__wrapper {
      display: none;
    }

    .info__section__wrapper {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 30px;

    .image__wrapper {
      display: none;
    }

    .info__section__wrapper {
      width: 100%;

      .top__container {
        padding-bottom: 15%;

        .small__udux__text {
          padding-bottom: 5%;
        }

        .big__subtitle__text,
        .big__subtitle__text span {
          font-size: 1.65rem;
        }
      }

      .bottom__container {
        width: 100%;
        gap: 2.5rem;

        .box__wrapper {
          width: 100%;

          .container__title {
            font-size: 1.1rem;
            padding-bottom: 3%;
          }

          .container__subtitle {
            text-align: left;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`

import { Ref, forwardRef } from "react";
import { IconProps } from "types";

export const VolumeControlIcon = forwardRef(
  ({ color="#D9D9D9", width, height }: IconProps, ref: Ref<SVGSVGElement>) => (
    <svg
      width={width || "30"}
      height={height || "24"}
      viewBox="0 0 30 24"
      fill="none"
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.75 7.1875C22.5833 9.63197 22.5833 14.368 20.75 16.8125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.875 2.375C30.3584 7.61148 30.3916 16.4235 24.875 21.625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 16.0683V7.93168C1.5 7.14098 2.11561 6.49999 2.875 6.49999H7.80546C8.17012 6.49999 8.51986 6.34916 8.77772 6.08066L12.9027 1.4222C13.769 0.520288 15.25 1.15906 15.25 2.43456V21.5654C15.25 22.8498 13.7512 23.4845 12.8904 22.5647L8.77975 17.9324C8.52095 17.6559 8.16587 17.5 7.79507 17.5H2.875C2.11561 17.5 1.5 16.859 1.5 16.0683V16.0683Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.75 7.1875C22.5833 9.63197 22.5833 14.368 20.75 16.8125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.875 2.375C30.3584 7.61148 30.3916 16.4235 24.875 21.625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 16.0683V7.93168C1.5 7.14098 2.11561 6.49999 2.875 6.49999H7.80546C8.17012 6.49999 8.51986 6.34916 8.77772 6.08066L12.9027 1.4222C13.769 0.520288 15.25 1.15906 15.25 2.43456V21.5654C15.25 22.8498 13.7512 23.4845 12.8904 22.5647L8.77975 17.9324C8.52095 17.6559 8.16587 17.5 7.79507 17.5H2.875C2.11561 17.5 1.5 16.859 1.5 16.0683V16.0683Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
);

VolumeControlIcon.displayName = "VolumeControlIcon";

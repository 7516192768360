import { FlexibleDiv } from "components/lib/Box"
import { Loader } from "components/lib/Loader"
import { Types, useMainContext } from "context"
import { instance } from "network"
import React, { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { BillingPlan } from "types"
import { SPBWrapper } from "./subscription-plan-banner.styles"

export type SubscriptionPlanBannerProps = {
  mt?: string
}

export const SubscriptionPlanBanner = ({ mt }: SubscriptionPlanBannerProps) => {
  const [subscriptionObj, setSubscriptionObj] = useState<BillingPlan>()
  const [nextBillingDate, setNextBillingDate] = useState("")
  const [billingPrice, setBillingPrice] = useState(0)
  const [searchParams] = useSearchParams()
  const [fetchBilling, setFetchBilling] = useState(false)

  const mode = searchParams.get("mode")
  const type = searchParams.get("type")

  const {
    state: { app },
    dispatch,
  } = useMainContext()
  const { billingPlans } = app

  const subInterval = subscriptionObj?.interval?.toLowerCase()
  const subAmount = subscriptionObj?.amount ? subscriptionObj.amount : 0

  const setBillingInfo = useCallback(
    (plans: BillingPlan[]) => {
      const activePlan = plans?.find(
        singlePlan =>
          singlePlan?.interval?.toLowerCase() === type?.toLowerCase() &&
          singlePlan?.provider?.toLowerCase() === mode?.toLowerCase()
      )

      setSubscriptionObj(activePlan)

      //update the billing price
      setBillingPrice(subAmount / 100)

      //update content
      const currentDate = new Date()

      if (subInterval === "daily") {
        calculateNextBillingDate(currentDate, 1, billingPrice)
      } else if (subInterval === "weekly") {
        calculateNextBillingDate(currentDate, 7, billingPrice)
      } else {
        calculateNextBillingDate(currentDate, 30, billingPrice)
      }
    },
    [billingPrice, mode, type, subInterval, subAmount]
  )

  const calculateNextBillingDate = (
    date: Date,
    days: number,
    price: number
  ) => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    const formattedDate = result.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })

    //update the next billing date
    setNextBillingDate(
      `Subscription will automatically renew at ₦${
        price ? price : "0"
      } daily, starting from ${formattedDate}, unless you cancel before the day of your next renewal.`
    )
  }

  React.useEffect(() => {
    const store = JSON.parse(localStorage.getItem("udx__billing__plans") ?? "")
    const shouldFetchBilling =
      billingPlans?.length < 1 && !Array.isArray(store) && !!store?.length

    async function fetchBilling() {
      try {
        const { data } = await instance.get("/core/billing-plan")

        dispatch({ type: Types.SET_BILLING_PLANS, payload: data?.data })
        localStorage.setItem("udx__billing__plans", JSON.stringify(data?.data))

        setFetchBilling(false)
      } catch (error) {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
        setFetchBilling(false)
      }
    }

    if (shouldFetchBilling) {
      setFetchBilling(true)

      fetchBilling()
    } else {
      setBillingInfo(store)

      if (billingPlans.length === 0) {
        dispatch({ type: Types.SET_BILLING_PLANS, payload: store })
      }
    }
  }, [billingPlans, dispatch, setBillingInfo])

  return fetchBilling ? (
    <Loader variant="green" height="100vh" />
  ) : (
    <SPBWrapper mt={mt}>
      <FlexibleDiv
        className="left__wrapper"
        flexDir="column"
        width="30%"
        height="100%"
        justifyContent="center"
      >
        <p className="type__text">
          {`${subscriptionObj?.interval?.[0].toUpperCase()}${subscriptionObj?.interval?.slice(
            1
          )}`}
        </p>
      </FlexibleDiv>

      <FlexibleDiv className="right__wrapper" width="70%" height="100%">
        <p className="content__text">{nextBillingDate}</p>
      </FlexibleDiv>
    </SPBWrapper>
  )
}

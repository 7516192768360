import { Skeleton } from "antd"
import {
  ArtisteCard,
  FlexibleDiv,
  NoDataDisplay,
  PlaylistCard,
} from "components"
import { take } from "lodash"
import { getPlaylistsByQuery, getSimilarArtists } from "network"
import { memo } from "react"
import { useQueries } from "react-query"
import { ExplorerBox, FSEMWrap } from "../shared/styles"
import { DisplayArtist } from "types/artist"
import { getArtistId, getArtistStageName } from "utils"

export type RecommendedProps = {
  artist: DisplayArtist
  isMobile?: boolean
}

export const Recommended = memo(({ artist, isMobile }: RecommendedProps) => {
  const artistId = getArtistId(artist)
  const artistName = getArtistStageName(artist)

  const recommendedSongsInfo = useQueries([
    {
      queryKey: ["similar-artists"],
      enabled: !!artistId,
      queryFn: () => getSimilarArtists(artistId),
    },
    {
      enabled: !!artistId,
      queryKey: ["artist-featured", artistId],
      queryFn: () => getPlaylistsByQuery(`track_by=${artistId}`),
    },
  ])

  const isFetching = recommendedSongsInfo.some(o => o.isLoading)
  const similarArtists = recommendedSongsInfo[0].data
  const featuredIn = recommendedSongsInfo[1].data

  return (
    <FSEMWrap isMobile={isMobile}>
      {isFetching ? (
        <Skeleton active={isFetching} />
      ) : (
        <FlexibleDiv
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="display__row"
        >
          <FlexibleDiv className="section__display__controls">
            <h1 className="title">
              People who listen to {artistName} also love...
            </h1>
          </FlexibleDiv>

          <ExplorerBox
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
            isMobile={isMobile}
          >
            {similarArtists?.length ? (
              take(similarArtists, 10).map(sa => (
                <ArtisteCard key={sa.id} {...sa} />
              ))
            ) : (
              <NoDataDisplay
                text="Please wait while we curate this content for you."
                height="280px"
              />
            )}
          </ExplorerBox>
        </FlexibleDiv>
      )}

      {isFetching ? (
        <Skeleton active={isFetching} />
      ) : (
        <FlexibleDiv
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="display__row"
        >
          <FlexibleDiv className="section__display__controls">
            <h1 className="title">We think this suits your current vibe</h1>
          </FlexibleDiv>

          <ExplorerBox
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
            isMobile={isMobile}
          >
            {featuredIn?.length ? (
              take(featuredIn, 10).map(fi => (
                <PlaylistCard key={fi.id} {...fi} />
              ))
            ) : (
              <NoDataDisplay
                text="Please wait while we curate this content for you."
                height="280px"
              />
            )}
          </ExplorerBox>
        </FlexibleDiv>
      )}
    </FSEMWrap>
  )
})

Recommended.displayName = "Recommended"

import { FlexibleDiv } from "components"
import styled from "styled-components"

export interface PLAWrapperProps {
  checkHeight?: boolean
  smallHeight?: boolean
}

export const PLAWrapper = styled(FlexibleDiv)<PLAWrapperProps>`
  width: 100%;
  padding-bottom: 5%;

  .info__description {
    padding-bottom: 3rem;

    h2 {
      font-size: 1.1rem;
      text-align: center;
      color: var(--primaryYellow);
      padding-bottom: 1rem;
    }

    p {
      font-size: 0.65rem;
      text-align: center;
    }
  }

  .form__box {
    width: 100%;
  }

  .action_text_box {
    margin-top: 2.5%;
  }

  .action_text,
  .action_text_one {
    color: var(--uduWhiteSecondary);
    font-size: ${({ checkHeight }) => {
      return checkHeight ? "20px" : "15px"
    }};
    font-weight: 600;
    margin-top: 6%;

    :hover {
      color: var(--uduxYellowPrimary);
      transition: all 0.4s;
    }
  }

  .textfield__wrap {
    padding-bottom: 2rem;
  }

  .btn__box {
    margin-top: 8%;

    span {
      font-size: 0.8rem;
    }
  }

  .existing__user {
    margin-top: 1.5rem;
    color: var(--white);
    opacity: 0.7;
    text-decoration: none;
    font-size: 12px;
  }

  @media screen and (max-width: 600px) {
    .action_text_box {
      margin-top: 2%;
    }

    .action_text,
    .action_text_one {
      margin-top: 12%;
      font-size: ${({ checkHeight }) => {
        return checkHeight ? "15px" : "15px"
      }};
    }
  }
`

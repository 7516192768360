import React from "react";
import { IconProps } from "types";

export const HomeIcon = ({
  color = "#929292",
  width = "22.338",
  height = "23.004",
}: IconProps) => (
  <svg
    width={width || "12"}
    height={height || "14"}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.7619 2.24065C6.00997 1.9901 6.4148 1.9901 6.66283 2.24065L11.6483 7.27646C11.8054 7.43515 11.7991 7.65771 11.5923 7.74198C11.0583 7.95965 9.68115 8.2829 6.21237 8.2829C2.74358 8.2829 1.3665 7.95965 0.832427 7.74198C0.625661 7.65771 0.619364 7.43515 0.776452 7.27646L5.7619 2.24065Z"
      fill={color}
    />
    <path
      d="M5.92193 0.833425C5.90776 0.663399 6.04192 0.517578 6.21253 0.517578C6.38314 0.517578 6.51733 0.663399 6.50316 0.833425L6.38418 2.2613C6.37674 2.35056 6.3021 2.41922 6.21253 2.41922C6.12296 2.41922 6.04835 2.35056 6.04091 2.2613L5.92193 0.833425Z"
      fill={color}
    />
    <path
      d="M6.83245 0.923225C6.90518 0.768897 7.09427 0.709699 7.24203 0.795007C7.38979 0.880311 7.43308 1.07369 7.33581 1.21385L6.5188 2.39093C6.46774 2.46451 6.36879 2.48666 6.29121 2.44188C6.21365 2.39709 6.18335 2.30032 6.22154 2.2193L6.83245 0.923225Z"
      fill={color}
    />
    <path
      d="M5.59158 0.923225C5.51885 0.768897 5.32976 0.709699 5.182 0.795007C5.03425 0.880311 4.99095 1.07369 5.08822 1.21385L5.90523 2.39093C5.95629 2.46451 6.05524 2.48666 6.13282 2.44188C6.21038 2.39709 6.24068 2.30032 6.20249 2.2193L5.59158 0.923225Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0918 8.71026C2.0918 8.61353 2.17759 8.53937 2.27347 8.55224C3.15516 8.67042 4.41304 8.75641 6.21202 8.75641C8.01101 8.75641 9.26888 8.67042 10.1506 8.55224C10.2465 8.53937 10.3322 8.61353 10.3322 8.71026V12.8769C10.3322 13.1583 9.14055 13.3968 7.49126 13.4796C7.31193 13.4886 7.16284 13.3447 7.16284 13.1651V11.6095C7.16284 11.0844 6.73716 10.6587 6.21202 10.6587C5.68688 10.6587 5.2612 11.0844 5.2612 11.6095V13.1651C5.2612 13.3447 5.11211 13.4886 4.93279 13.4796C3.28349 13.3968 2.0918 13.1583 2.0918 12.8769V8.71026Z"
      fill={color}
    />
  </svg>
);

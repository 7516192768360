import { FlexibleDiv, FlexibleDivProps } from "components"
import { DominantColors } from "context"
import styled from "styled-components"

interface FsemFooterWrapProps extends FlexibleDivProps {
  dominantColors?: DominantColors
}
export const FsemFooterWrap = styled(FlexibleDiv)<FsemFooterWrapProps>`
  position: fixed;
  width: 100%;
  height: 100px;
  bottom: 0;
  z-index: 999;
  padding: 0.5rem 1rem;
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 2.59%, ${dominantColors[1]} 99.97%, ${dominantColors[2]} 99.98%)`
      : `linear-gradient(
      180deg,
      #39452d 2.59%,
      #232b1a 99.97%,
      #9f6f2d 99.98%,
      #bb8233 99.99%
    )`};
  transition: background 0.3s ease-in;

  .info__box {
    width: 250px;
    min-width: calc(240px - 3.5%);
  }

  .track__status,
  .artist {
    font-family: "Bw Modelica", sans-serif;
  }

  .track__status {
    margin-bottom: -0.25rem;
  }

  .title__track {
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
    font-family: "Baguede", sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 230px;
  }

  .prev__track__info .title__track {
  }

  .next__track__info .title__track {
    text-align: right;
  }

  .artist {
    cursor: pointer;
    font-size: 13px;

    :hover {
      text-decoration: underline;
    }
  }

  .prev__track__info {
    align-items: flex-start;
  }

  .next__track__info {
    align-items: flex-end;
  }

  @media screen and (max-width: 600px) {
    padding: 0.85rem 0.5rem;
    height: auto;

    .info__box {
      width: 45%;
      min-width: 45%;
      align-items: flex-start;
    }

    .track__status,
    .artist {
      font-size: 0.65rem;
    }

    .next__track__info {
      align-items: flex-end;
    }

    .title__track {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      width: 150px;
    }
  }
`

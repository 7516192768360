import { FlexibleDiv } from "components"
import uduXBox from "assets/images/general/udux-drum.png"
import { InActivePlaylistWrap } from "./styles"
import { FaPlus } from "react-icons/fa"

export type InActivePlaylistProps = {
  title: string
  description: string
  isCollabTab?: boolean
  handleClick?: () => void
}
export const InActivePlaylist = ({
  title,
  description,
  isCollabTab = false,
  handleClick,
}: InActivePlaylistProps) => {
  return (
    <InActivePlaylistWrap flexDir="column">
      <h2>{title}</h2>

      <p className="text">{description}</p>

      {isCollabTab ? (
        <FlexibleDiv
          className="add__main"
          justifyContent="space-between"
          alignItems="center"
          flexDir="column"
          onClick={() => {
            if (handleClick) {
              handleClick()
            }
          }}
        >
          <div className="add__icon">
            <FaPlus />
          </div>

          <p className="add__text">Add User</p>
        </FlexibleDiv>
      ) : (
        <FlexibleDiv>
          <img src={uduXBox} />
        </FlexibleDiv>
      )}
    </InActivePlaylistWrap>
  )
}

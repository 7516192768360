import { IconProps } from "types";

export const SoloIcon = ({ color = "#FBBA12", width, height }: IconProps) => (
  <svg
    width={width || "25"}
    height={height || "26"}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.524 23.5419H16.4561V25.5H17.524C19.5067 25.5 21.4082 24.778 22.8101 23.4927C24.2121 22.2075 24.9997 20.4643 24.9997 18.6467V15.7096H22.8638V18.6467C22.8638 19.945 22.3012 21.1901 21.2998 22.1082C20.2984 23.0262 18.9402 23.5419 17.524 23.5419Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M22.8641 7.35299V8.33203H25V7.35299C25 5.53539 24.2124 3.79224 22.8104 2.507C21.4085 1.22176 19.507 0.499723 17.5243 0.499723H14.3204L14.3204 2.4578H17.5243C18.9405 2.4578 20.2987 2.97354 21.3001 3.89157C22.3015 4.8096 22.8641 6.05471 22.8641 7.35299Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M8.54435 2.48933H9.6123V0.53125L8.54435 0.53125C6.56167 0.53125 4.6602 1.25329 3.25824 2.53853C1.85628 3.82376 1.06866 5.56692 1.06866 7.38452V10.3216H3.20457V7.38452C3.20457 6.08623 3.76715 4.84112 4.76856 3.9231C5.76996 3.00507 7.12815 2.48933 8.54435 2.48933Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M2.13591 18.3111V17.332H0L0 18.3111C0 20.1287 0.787615 21.8718 2.18958 23.1571C3.59154 24.4423 5.49301 25.1643 7.47569 25.1643H10.6796V23.2063H7.47569C6.05949 23.2063 4.7013 22.6905 3.6999 21.7725C2.69849 20.8545 2.13591 19.6094 2.13591 18.3111Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M16.1868 14.3906H9.77906C8.92934 14.3906 8.11443 14.7001 7.51359 15.2509C6.91274 15.8017 6.5752 16.5488 6.5752 17.3277V19.2858H8.71111V17.3277C8.71111 17.0681 8.82362 16.8191 9.0239 16.6355C9.22418 16.4518 9.49582 16.3487 9.77906 16.3487H16.1868C16.47 16.3487 16.7417 16.4518 16.942 16.6355C17.1422 16.8191 17.2547 17.0681 17.2547 17.3277V19.2858H19.3907V17.3277C19.3907 16.5488 19.0531 15.8017 18.4523 15.2509C17.8514 14.7001 17.0365 14.3906 16.1868 14.3906Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M8.71191 9.49428C8.71191 10.2688 8.96245 11.026 9.43185 11.67C9.90124 12.314 10.5684 12.8159 11.349 13.1123C12.1296 13.4087 12.9885 13.4863 13.8171 13.3352C14.6458 13.1841 15.4069 12.8111 16.0044 12.2634C16.6018 11.7157 17.0086 11.0179 17.1735 10.2583C17.3383 9.49862 17.2537 8.71122 16.9304 7.99563C16.6071 7.28005 16.0595 6.66843 15.357 6.23812C14.6545 5.8078 13.8286 5.57812 12.9837 5.57812C11.8508 5.57812 10.7642 5.99072 9.9631 6.72514C9.16198 7.45956 8.71191 8.45565 8.71191 9.49428ZM15.1196 9.49428C15.1196 9.88155 14.9944 10.2601 14.7597 10.5821C14.525 10.9041 14.1914 11.1551 13.8011 11.3033C13.4108 11.4515 12.9814 11.4903 12.567 11.4147C12.1527 11.3392 11.7721 11.1527 11.4734 10.8788C11.1747 10.605 10.9713 10.2561 10.8889 9.87628C10.8065 9.49645 10.8487 9.10275 11.0104 8.74495C11.1721 8.38716 11.4458 8.08135 11.7971 7.8662C12.1483 7.65104 12.5613 7.5362 12.9837 7.5362C13.5502 7.5362 14.0935 7.7425 14.4941 8.10971C14.8946 8.47692 15.1196 8.97496 15.1196 9.49428Z"
      fill={color}
      fillOpacity="0.7"
    />
  </svg>
);

import { FlexibleDiv } from "components";
import styled from "styled-components";

export const DiscoverWrap = styled(FlexibleDiv)`
  .discover__header {
    row-gap: 30px;

    .user__mini__subheader {
      flex-basis: 50%;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 29px;
      color: var(--primaryWhite);
      font-family: "Inter", sans-serif;
      @media (max-width: 500px) {
        font-size: 1em;
      }
    }

    .discover__description {
      font-family: "Inter", sans-serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: 19.36px;
      color: #7c7c7c;
    }
  }

  .main__campaigns {
    width: 100%;
    height: fit-content;
    margin-top: 30px;
  }

  .campaigns__body__section {
    max-width: 100%;
    .mini__subheader {
      flex-basis: 50%;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 29px;
      color: var(--primaryWhite);
      font-family: "Inter", sans-serif;
    }

    .header__withlink {
      margin: 30px 0 20px 0;
      .see__more {
        color: var(--uduxYellowPrimary);
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }
`;

import { IconProps } from "types";

export const SearchIcon2 = ({ color = "#848484", width, height }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${width} ${height}`}
    className="searchIcon2__svg"
  >
    <path
      d="M9.13247 2C5.19405 2 2 5.19405 2 9.13247C2 13.0709 5.19405 16.2649 9.13247 16.2649C13.0709 16.2649 16.2649 13.0709 16.2649 9.13247C16.2649 5.19405 13.0709 2 9.13247 2ZM9.13247 14.9681C5.91508 14.9681 3.29681 12.3499 3.29681 9.13247C3.29681 5.91508 5.91508 3.29681 9.13247 3.29681C12.3499 3.29681 14.9681 5.91508 14.9681 9.13247C14.9681 12.3499 12.3499 14.9681 9.13247 14.9681ZM14.9681 16.9133L16.9133 14.9681L22 18.9697L18.9697 22L14.9681 16.9133Z"
      fill={color}
    />
    <path
      d="M14.9682 15.6166C15.3263 15.6166 15.6166 15.3263 15.6166 14.9682C15.6166 14.6101 15.3263 14.3198 14.9682 14.3198C14.6101 14.3198 14.3198 14.6101 14.3198 14.9682C14.3198 15.3263 14.6101 15.6166 14.9682 15.6166Z"
      fill={color}
    />
  </svg>
);

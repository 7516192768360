import {
  AuthResponse,
  BaseNetworkResponse,
  LoginPayload,
  ResetPasswordRequestPayload,
  SignUpPayload,
  VerifyOTPPayload,
} from "types"
import { instance } from "./axios"

export const signUp = async (payload: SignUpPayload) => {
  const { data } = await instance.post<AuthResponse>(
    `/auth/auth/register`,
    payload
  )

  return data
}

export const login = async (payload: LoginPayload) => {
  const emailRegex =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // const usernameRegex = /^[a-zA-Z0-9_\.]+$/

  let loginData = {}

  if (emailRegex.test(payload.emailAddress)) {
    loginData = {
      ...payload,
      emailAddress: payload.emailAddress,
      password: payload.password,
    }

    const { data } = await instance.post<AuthResponse>(
      `/auth/auth/login`,
      loginData
    )
    return data.data
  } else {
    loginData = {
      ...payload,
      username: payload.emailAddress,
      password: payload.password,
    }

    const { data } = await instance.post<AuthResponse>(
      "/auth/auth/login-with-username",
      loginData
    )

    return data.data
  }
}

export const resetPassword = async (payload: ResetPasswordRequestPayload) => {
  const { data } = await instance.post<BaseNetworkResponse>(
    `/auth/auth/reset-password/request`,
    payload
  )

  return data
}

export const updatePasswordWithToken = async (payload: {
  emailAddress: string
  password: string
}) => {
  const { data } = await instance.post<BaseNetworkResponse>(
    `/auth/auth/reset-password/update-password`,
    payload
  )

  return data
}

export const loginWithPhone = async (payload: { phoneNumber: string }) => {
  const { data } = await instance.post<BaseNetworkResponse>(
    `/auth/auth/phone/request`,
    payload
  )

  return data
}

export const verifyOtp = async (payload: VerifyOTPPayload) => {
  const { data } = await instance.post<AuthResponse>(
    `/auth/auth/phone/login`,
    payload
  )

  return data.data
}

export const OAuth = async (url: string, payload: any) => {
  const { data } = await instance.post(url, payload)

  return data
}

// TODO: review the url param
export const verifyEmail = async (url: string, payload: any) => {
  const { data } = await instance.post(url, payload)

  return data
}

import { FlexibleDiv } from "components"
import React, { useEffect, useState } from "react"
import { DoughnutChart } from "../charts/doughnut-chart"

export const GenderBreakdownChart: React.FC = () => {
  const [chartData, setChartData] = useState({
    labels: ["Female", "Male", "Others"],
    datasets: [
      {
        data: [120, 81, 56],
        backgroundColor: ["#fff", "#5F2A29", "#FBBA12"],
        borderWidth: 0,
      },
    ],
  })

  const options = {
    responsive: true,
    maintainAspectRation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "60%",
  }

  useEffect(() => {
    const handleResize = () => {
      // Force a re-render on resize
      setChartData({ ...chartData })
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [chartData])

  return (
    <FlexibleDiv height="200px" width="60%">
      <DoughnutChart
        data={chartData}
        options={options}
        labelText="60%"
        labelDescription="FEMALE"
      />
    </FlexibleDiv>
  )
}

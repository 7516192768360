import { LoadingOutlined } from "@ant-design/icons"
import { Form } from "antd"
import MTNPagePicture from "assets/images/payment/mtnPaymentPicture.png"
import { Button, CheckBox, FlexibleDiv, PhoneInput } from "components"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import { Types, useMainContext } from "context"
import { checkUserPhoneNumber } from "network"
import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { PaymentLoginWrapper } from "./paymentMTNPage.styles"
import { EnrichedData } from "types"
import { CheckboxProps } from "antd/lib"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

const PaymentLogin = () => {
  const [params] = useSearchParams()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [, setCountryCode] = useState("")
  const {
    dispatch,
    state: { app },
  } = useMainContext()
  const navigate = useNavigate()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"
  const [form] = Form.useForm()
  const { mutate, isLoading } = useMutation(checkUserPhoneNumber)
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false)

  const isMtnUser = Boolean(app?.isMtn)
  const udx__pay__esn = JSON.parse(
    localStorage.getItem("udx__pay__esn") || "{}"
  ) as EnrichedData

  const handleFormSubmit = async (values: string) => {
    const fullPhoneNumber = `+${values}`

    mutate(fullPhoneNumber, {
      onSuccess: () => {
        localStorage.setItem("phoneNumber", fullPhoneNumber)
        navigate(
          `/payment/mtn/confirm?type=${plan}&mode=${mode}&msisdn=${values}`
        )
      },
      onError: error => {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    })
  }

  useEffect(() => {
    setPhoneNumber(app?.msisdn)
  }, [isMtnUser, app?.msisdn])

  const checkboxChange: CheckboxProps["onChange"] = ({ target }) =>
    setAcceptedTermsAndConditions(target.checked)

  return (
    <PaymentRegisterLayout
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      galleryImage={MTNPagePicture}
      title="Y’ello"
      subText="Please Confirm your MTN Number"
    >
      <PaymentLoginWrapper checkHeight={checkHeight}>
        <Form form={form} className="form__box">
          <FlexibleDiv className="input">
            <PhoneInput
              onChange={e => {
                setPhoneNumber(e)
              }}
              onSetCountryCode={code => setCountryCode(code)}
              value={isMtnUser ? app?.msisdn : udx__pay__esn?.msisdn || ""}
              bordered
              small
            />
          </FlexibleDiv>

          <CheckBox
            color="var(--dampWhite)"
            name="acceptedTermsAndConditions"
            checked={acceptedTermsAndConditions}
            onChange={checkboxChange}
          >
            <span className="tac__text">
              I accept that on occasion I can be contacted by uduX
            </span>
          </CheckBox>

          <p className="tac">
            uduX is a personalised music device. By clicking on the sign up
            button, you agree to our{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions of Use
            </a>
            .
          </p>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              onClick={() => handleFormSubmit(phoneNumber)}
              disabled={!acceptedTermsAndConditions}
              color="var(--black)"
              width="50%"
              type="submit"
              contained
              bold
              ltr
            >
              <span>
                {isLoading ? (
                  <LoadingOutlined color="var(--uduxYellowPrimary)" />
                ) : (
                  "Continue"
                )}
              </span>
            </Button>
          </FlexibleDiv>
        </Form>
      </PaymentLoginWrapper>
    </PaymentRegisterLayout>
  )
}

export default PaymentLogin

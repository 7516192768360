import { FlexibleDiv, Loader } from "components"
import { InviteCollabUserModal } from "components/lib/Modals/InviteCollabUserModal"
import { useSearchUser } from "network/hooks"
import { Fragment, useEffect, useState } from "react"
import { IoMdSearch } from "react-icons/io"
import { IoClose } from "react-icons/io5"
import { useParams } from "react-router-dom"
import { SendInvite } from "./SendInvite"
import { SearchCollabUserWrap } from "../styles"
import { UserListItem } from "./UserListItem"

export const SearchCollabUser = () => {
  const { playlistId } = useParams()
  const [isSendInvite, setIsSendInvite] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [debouncedQuery, setDebouncedQuery] = useState<string>("")

  // Debounce the search query to prevent excessive API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchQuery.trim() !== "") {
        setDebouncedQuery(searchQuery)
      } else {
        setDebouncedQuery("")
      }
    }, 500) // 500ms debounce time

    return () => {
      clearTimeout(handler)
    }
  }, [searchQuery])

  // Run the search using the debounced query
  const { data, isLoading } = useSearchUser(debouncedQuery, {
    enabled: !!debouncedQuery,
  })

  return (
    <SearchCollabUserWrap>
      <header>
        <h2 className="title">Unlock Collab Features</h2>
        <p className="description">
          By inviting new or existing users to collab
        </p>
      </header>

      <FlexibleDiv className="search__wrap">
        <IoMdSearch />
        <input
          className="search__field"
          placeholder="Find by username or email"
          value={searchQuery} // Controlled input
          onChange={e => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <IoClose
            className="clear__search"
            onClick={() => {
              setSearchQuery("")
            }}
          />
        )}
      </FlexibleDiv>

      {isLoading ? (
        <FlexibleDiv className="loader">
          <Loader />
        </FlexibleDiv>
      ) : (
        <FlexibleDiv className="data__center">
          {data?.data.length ? (
            <Fragment>
              {data?.data.map(user => (
                <UserListItem
                  key={user.id}
                  user={user}
                  playlistId={playlistId || ""}
                />
              ))}

              <SendInvite handleClick={value => setIsSendInvite(value)} />
            </Fragment>
          ) : (
            <SendInvite handleClick={value => setIsSendInvite(value)} />
          )}
        </FlexibleDiv>
      )}

      {isSendInvite && (
        <InviteCollabUserModal
          playlistId={playlistId || ""}
          title="Can't find who you are looking for?"
          open={isSendInvite}
          handleClose={() => setIsSendInvite(false)}
        />
      )}
    </SearchCollabUserWrap>
  )
}

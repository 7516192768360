import {
  ResponseAlbumStreaming,
  ResponseFollowArtist,
  ResponseTrackStreaming,
  ResponseWithAlbums,
  ResponseWithArtist,
  ResponseWithArtistTracks,
  ResponseWithArtists,
  ResponseWithTracks,
} from "types"
import { instance } from "./axios"

export const getArtistProfile = async (artistId: string) => {
  const { data } = await instance.get<ResponseWithArtist>(
    `/streaming/artists/${artistId}`
  )

  return data
}

export const getAllArtist = async () => {
  const { data } = await instance.get<ResponseWithArtists>(`/streaming/artists`)

  return data
}

export const getSimilarArtists = async (artistId: string) => {
  const { data } = await instance.get<ResponseWithArtists>(
    `/streaming/artists/${artistId}/similar`
  )

  return data.data
}

export const getArtistPopularTracks = async (
  artistId: string,
  mode: "asc" | "desc" = "asc"
) => {
  const { data } = await instance.get<ResponseWithTracks>(
    `/streaming/artists/${artistId}/tracks?sortByPopularity=${mode}`
  )

  return data.data
}

export const getArtistRecentTracks = async (
  artistId: string,
  mode: "asc" | "desc" = "asc"
) => {
  const { data } = await instance.get<ResponseWithTracks>(
    `/streaming/artists/${artistId}/tracks?sortByTimeOfUpload=${mode}`
  )

  return data.data
}

export const followArtist = async (artistId: string) => {
  const response = await instance.post<ResponseFollowArtist>(
    `/social/follow/artist/${artistId}`
  )

  return response.data
}

export const unFollowArtist = async (artistId: string) => {
  const response = await instance.delete<ResponseFollowArtist>(
    `/social/unfollow/artist/${artistId}`
  )

  return response.data
}

export const getArtistTopTracks = async (artistId: string, q: string) => {
  const { data } = await instance.get<ResponseTrackStreaming>(
    `/streaming/artists/${artistId}/tracks?${q}`
  )

  return data.data
}

export const getArtistAlbums = async (artistId?: string, q?: string) => {
  if (artistId) {
    const { data } = await instance.get<ResponseAlbumStreaming>(
      `/streaming/artists/${artistId}/albums?${q}`
    )

    return data?.data
  }
}

export const getAllAlbums = async () => {
  const { data } = await instance.get<ResponseWithAlbums>(`/streaming/albums`)

  return data?.data
}

export const getSimilarArtistTracks = async (artistId: string, q: string) => {
  const { data } = await instance.get<ResponseWithArtistTracks>(
    `/streaming/artists/${artistId}/similar-artist-tracks?${q}`
  )

  return data?.data
}

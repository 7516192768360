import { StyledButton, StyledButtonProps } from "./button.styles"
import React from "react"

export type ButtonProps = {
  className?: string
  loading?: boolean
  disabled?: boolean
  children: React.ReactNode
  onClick?: () => void
} & StyledButtonProps
export const Button = ({
  className,
  loading,
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton className={className} {...props}>
      {loading && <div className="loading"></div>}
      {children}
    </StyledButton>
  )
}
import { FlexibleDiv } from "components"
import { InActivePlaylist } from "../InActivePlaylist"
import { StatsCard } from "./StatsCard"
import { CollabStatsTabDisplayWrap } from "./collab-stats-tab-display.styles"
import { TotalPlayChart } from "./components/TotalPlay.chart"
import { PlaylistLikeChart } from "./components/PlaylistLikes.chart"
import { TotalFollowersChart } from "./components/TotalFollowers.chart"
import { GenderBreakdownChart } from "./components/GenderBreakdown.chart"

export const CollabStatsTabDisplay = () => {
  return (
    <CollabStatsTabDisplayWrap>
      {/* <InActivePlaylist
        title="There are no stats to show"
        description="To see stats you need people"
      /> */}

      <FlexibleDiv className="central__data__display">
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Total Play</h3>
          <StatsCard
            bg="#5f2a29"
            hoverColor="#542424"
            chart={<TotalPlayChart />}
          />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Playlist Likes</h3>
          <StatsCard
            bg="#B77F3F"
            hoverColor="#a67339"
            chart={<PlaylistLikeChart />}
          />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Gender Breakdown</h3>
          <StatsCard
            bg="#B77F3F"
            hoverColor="#a67339"
            chart={<GenderBreakdownChart />}
            hideDescription
          />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Total Followers</h3>
          <StatsCard
            bg="#5f2a29"
            hoverColor="#542424"
            chart={<TotalFollowersChart />}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </CollabStatsTabDisplayWrap>
  )
}

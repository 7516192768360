import styled from "styled-components";
import { FlexibleDiv, FlexibleDivProps } from "components";

interface MobileMusicPlayerWrapProps extends FlexibleDivProps {
  playerActive?: boolean;
}
export const MobileMusicPlayerWrap = styled(FlexibleDiv)<MobileMusicPlayerWrapProps>`
  width: 100dvw;
  height: 100dvh;
  min-height: 100dvh;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--primaryBlack);
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: hidden;
  padding: 5px 0;

  .top__wrap {
    padding: 0px 20px;
    flex: 1;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: var(--primaryBlack);
    left: 0;
    height: 100%;
    min-width: 100%;
    z-index: 10;

    .top__info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 10px;
      /* @media screen and (max-width: 765px) {
        margin-bottom: 30px;
      } */

      .toggle__now__playing__wrap {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        padding: 20px 0;
        cursor: pointer;
        background-color: var(--primaryBlack);
        .toggle__icon {
          font-size: 15px;
          cursor: pointer;
          color: var(--primaryWhite);
        }
      }

      h3 {
        font-size: 1.4rem;
        font-family: "Bw Modelica", sans-serif;
        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
        }
      }

      p {
        font-size: 0.9rem;
        opacity: 0.9;
        font-family: "Bw Modelica", sans-serif;
      }
    }
  }

  .bottom__wrap {
    width: 100%;
    padding: 40px 20px 0px 20px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 100%;
    z-index: 20;
    background-color: #0f0f0f;
    justify-content: flex-start;

    .bottom__content__wrap{
      width: 100%;
      height: 100%;
      padding-top: 60px;
    }
  }
`;

interface SpacerProps extends FlexibleDivProps {
  pb?: string;
}
export const Spacer = styled(FlexibleDiv)<SpacerProps>`
  padding-bottom: ${({ pb }) => pb || "2rem"};

  @media screen and (max-width: 600px) and (max-height: 830px) {
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 375px) and (max-height: 667px) {
    padding-bottom: 1rem;
  }
`;

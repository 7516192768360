// import {
//   AddSongIcon,
//   ExpandOutlineIcon,
//   FavoriteFilledIcon,
//   FavoriteIcon,
//   MoreIcon,
//   NextOutlineIcon,
//   PauseOutlineIcon,
//   PlayOutlineIcon,
//   PrevOutlineIcon,
//   RepeatOutlineIcon,
//   ShuffleOutlineIcon,
// } from "assets";
// import { ExpandIcon } from "assets/images/player/SVGExports";
// import {
//   AddToPlaylistModal,
//   Dropdown,
//   FlexibleDiv,
//   ShareModal,
//   TrackItemMoreMenu,
//   TrackProgressControl,
//   VolumeControl,
// } from "components";
// import { Config } from "config";
// import { Types, useMainContext } from "context";
// import { addReaction, removeReaction } from "network";
// import React, { useLayoutEffect, useRef, useState } from "react";
// import { useMutation } from "react-query";
// import { useMediaQuery } from "react-responsive";
// import { useNavigate } from "react-router-dom";
// import {
//   fadeOut,
//   getArtist,
//   // addToQueue,
//   getDisplayArtist,
//   getTrackID,
//   getTrackTitle,
//   isEmpty,
//   pauseAudio,
//   playAudio,
//   playNextTrack,
//   repeatTrack,
//   topSlideInFrom,
//   // shuffleTrack,
//   // unShuffleTrack,
//   // playPreviousTrack,
// } from "utils";
// import { getDominantColors } from "utils/function-helpers/getDominantColors";
// import { MiniPlayerWrap } from "./mini-player.styles";
// import { ReactionType } from "types";

// export const MiniPlayer = React.memo(
//   ({ footerOnly }: { footerOnly?: boolean }) => {
//     const navigate = useNavigate();
//     const {
//       state: { player, app },
//       playerRef,
//       dispatch,
//     } = useMainContext();
//     const muteRef = useRef<HTMLElement>(null);
//     const playerMainRef = useRef<HTMLDivElement>(null);
//     const [, setLiked] = useState(false);
//     const [openAddToPlaylistModal, setOpenAddToPlaylistModal] = useState(false);
//     const [isSharing, setIsSharing] = useState({
//       open: false,
//       href: "",
//     });
//     const { mutate: mutateLike } = useMutation(addReaction);
//     const { mutate: mutateUnlike } = useMutation(removeReaction);
//     const isMobile = useMediaQuery({ maxWidth: 600 });

//     const { isLoggedIn } = app;
//     const {
//       isPlayerActive,
//       // tracks,
//       isPlaying,
//       repeat,
//       shuffle,
//       isPlayerMuted,
//       playingList,
//       currentTrack,
//       queuedTracks,
//       prevTracks,
//     } = player;

//     const currentTrackTitle = getTrackTitle(currentTrack, true);
//     const trackId = getTrackID(currentTrack);

//     // useEffect(() => {
//     //   const seconds = Math.floor(playerRef?.current?.duration)
//     //   setDuration(seconds)
//     // }, [
//     //   playerRef?.current?.loadedmetadata,
//     //   playerRef?.current?.readyState,
//     //   playerRef,
//     // ])

//     useLayoutEffect(() => {
//       topSlideInFrom(playerMainRef, { duration: 0.3, y: 20 });
//     }, [isPlayerActive]);

//     useLayoutEffect(() => {
//       fadeOut(muteRef, { duration: 3 });
//     }, [isPlayerMuted]);

//     return (
//       <MiniPlayerWrap
//         ref={playerMainRef}
//         footerOnly={footerOnly}
//         isActive={isPlayerActive}
//         style={{ bottom: isMobile ? "8%" : 0 }}
//         dominantColors={getDominantColors(currentTrack)}
//       >
//         {isPlayerActive && (
//           <FlexibleDiv
//             flexWrap="nowrap"
//             justifyContent="space-between"
//             className="player__home__footer"
//           >
//             <FlexibleDiv className="left__controls__wrap" flexWrap="nowrap">
//               <div className="player__image__wrap">
//                 <div
//                   className="expand__wrap"
//                   onClick={() => {
//                     const statePayload = {
//                       prevTracks: prevTracks,
//                       playingList: playingList,
//                       queuedTracks: queuedTracks,
//                     };
//                     dispatch({ type: Types.SET_FSEM, payload: true });
//                     navigate(`/player/${trackId}`, {
//                       state: statePayload,
//                     });
//                   }}
//                 >
//                   <ExpandOutlineIcon />
//                 </div>
//                 <img src={getImageDisplay()} alt={currentTrackTitle} />
//               </div>

//               <div className="song__title__wrap">
//                 <small>{getDisplayArtist(currentTrack, navigate)}</small>
//                 <h4
//                   className="track__title"
//                   onClick={() =>
//                     navigate(`/playlist/${playingList.id}?t=pl`, {
//                       state: { module: "playlist" },
//                     })
//                   }
//                 >
//                   {currentTrackTitle}
//                 </h4>
//                 <small>{getDisplayArtist(currentTrack, navigate)}</small>
//               </div>
//             </FlexibleDiv>

//             <FlexibleDiv className="center__controls__wrap">
//               <FlexibleDiv className="control__main__wrap" flexDir="column">
//                 <FlexibleDiv
//                   flexWrap="nowrap"
//                   justifyContent="center"
//                   className="controls__main"
//                 >
//                   <div
//                     className="control__icons repeat__icon"
//                     onClick={() => repeatTrack(Types.REPEAT, dispatch, repeat)}
//                   >
//                     <RepeatOutlineIcon
//                       color={repeat ? "var(--yellowPrimary)" : "#fff"}
//                       height="100%"
//                     />
//                   </div>
//                   <div
//                     className="control__icons"
//                     // onClick={() =>
//                     //   playPreviousTrack(dispatch, {
//                     //     track: currentTrack,
//                     //   })
//                     // }
//                   >
//                     <PrevOutlineIcon width={28} height="100%" />
//                   </div>

//                   {isPlaying ? (
//                     <div
//                       className="control__icons pause__icon"
//                       onClick={() => pauseAudio(playerRef, dispatch)}
//                     >
//                       <PauseOutlineIcon width={40} height="100%" />
//                     </div>
//                   ) : (
//                     <div
//                       className="control__icons play__icon"
//                       onClick={() => playAudio(playerRef, dispatch)}
//                     >
//                       <PlayOutlineIcon width={40} height={40} />
//                     </div>
//                   )}
//                   <div
//                     className="control__icons"
//                     onClick={() => {
//                       playNextTrack(dispatch, {
//                         track: currentTrack,
//                       });
//                     }}
//                   >
//                     <NextOutlineIcon width={28} height="100%" />
//                   </div>

//                   {isMobile ? (
//                     <div
//                       className="control__icons expand__icon"
//                       onClick={() => {
//                         const statePayload = {
//                           prevTracks: prevTracks,
//                           playingList: playingList,
//                           queuedTracks: queuedTracks,
//                         };
//                         dispatch({ type: Types.SET_FSEM, payload: true });
//                         navigate(`/player/${trackId}`, {
//                           state: statePayload,
//                         });
//                       }}
//                     >
//                       <ExpandIcon width="18" height="20" />
//                     </div>
//                   ) : shuffle ? (
//                     <div
//                       // onClick={() =>
//                       //   unShuffleTrack(
//                       //     tracks,
//                       //     dispatch,
//                       //   )
//                       // }
//                       className="control__icons shuffle__icon"
//                     >
//                       <ShuffleOutlineIcon
//                         height="100%"
//                         color="var(--yellowPrimary)"
//                       />
//                     </div>
//                   ) : (
//                     <div
//                       // onClick={() =>
//                       //   shuffleTrack(
//                       //     // tracks,
//                       //     dispatch,
//                       //   )
//                       // }
//                       className="control__icons shuffle__icon"
//                     >
//                       <ShuffleOutlineIcon height="100%" />
//                     </div>
//                   )}
//                 </FlexibleDiv>

//                 <FlexibleDiv className="song__progress__wrap" flexDir="column">
//                   <TrackProgressControl />
//                 </FlexibleDiv>
//               </FlexibleDiv>
//             </FlexibleDiv>

//             <FlexibleDiv className="right__controls__wrap" flexWrap="nowrap">
//               {currentTrack?.userLiked ? (
//                 <div className="icons__wrap" onClick={handleUnlikeTrack}>
//                   <FavoriteFilledIcon width={30} height={30} />
//                 </div>
//               ) : (
//                 <div className="icons__wrap" onClick={handleLikeTrack}>
//                   <FavoriteIcon width={30} height={28} />
//                 </div>
//               )}

//               <div
//                 className="icons__wrap"
//                 onClick={() => {
//                   if (isLoggedIn) {
//                     setOpenAddToPlaylistModal(true);
//                   } else {
//                     dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
//                   }
//                 }}
//               >
//                 <AddSongIcon width={30} height={30} />
//               </div>

//               <Dropdown
//                 trigger={["click"]}
//                 placement="topLeft"
//                 overlay={
//                   <TrackItemMoreMenu
//                     // handleAddToQueue={() => addToQueue(dispatch, currentTrack)}
//                     handleAddToQueue={() => {}}
//                     handleGotoAlbum={handleGoToAlbum}
//                     handleGotoArtistPage={handleGoToArtistPage}
//                     handleShare={handleShare}
//                     top="0rem"
//                   />
//                 }
//               >
//                 <div className="icons__wrap more__icon">
//                   <MoreIcon height={30} />
//                 </div>
//               </Dropdown>

//               <div className="icons__wrap">
//                 <VolumeControl width={"30"} height={"28"} />
//               </div>
//             </FlexibleDiv>
//           </FlexibleDiv>
//         )}

//         {isSharing.open && (
//           <ShareModal
//             open={isSharing.open}
//             href={isSharing.href}
//             handleClose={() =>
//               setIsSharing({
//                 open: false,
//                 href: "",
//               })
//             }
//           />
//         )}

//         {openAddToPlaylistModal && (
//           <AddToPlaylistModal
//             open={openAddToPlaylistModal}
//             handleClose={() => setOpenAddToPlaylistModal(false)}
//           />
//         )}
//       </MiniPlayerWrap>
//     );

//     function getImageDisplay() {
//       if (!isEmpty(currentTrack)) {
//         if (currentTrack?.track?.metadata) {
//           return currentTrack.track.metadata.coverArt.url;
//         }

//         if (currentTrack?.metadata) {
//           return currentTrack.metadata.coverArt.url;
//         }
//       }
//     }

//     function handleUnlikeTrack() {
//       if (!isLoggedIn) {
//         return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
//       }

//       setLiked(false);
//       const payload = {
//         entity: getTrackID(currentTrack),
//         entityType: "Track",
//       };

//       mutateUnlike(payload, {
//         onSuccess: (res) => {
//           console.log(res, "un-liked from mini player");
//         },
//         onError: (err) => {
//           setLiked(false);
//           console.error(err, "error un-liking from mini player");
//         },
//       });
//     }

//     function handleLikeTrack() {
//       if (!isLoggedIn) {
//         return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
//       }

//       setLiked(true);
//       const payload = {
//         type: ReactionType.LIKE,
//         entity: getTrackID(currentTrack),
//         entityType: "Track",
//       };

//       mutateLike(payload, {
//         onSuccess: (res) => {
//           console.log(res, "liked from mini player");
//         },
//         onError: (err) => {
//           setLiked(false);
//           console.error(err, "error liking from mini player");
//         },
//       });
//     }

//     function handleGoToAlbum() {
//       const albumId =
//         currentTrack?.track?.album || currentTrack?.metadata?.album;

//       if (albumId) {
//         navigate(`/playlist/${albumId}?t=alb`, {
//           state: {
//             module: "album",
//           },
//         });
//       } else {
//         // show notification for no album exists.
//         console.log(currentTrack, "ct ");
//       }
//     }

//     function handleGoToArtistPage() {
//       const artist = getArtist(currentTrack);

//       if (artist?.artist?.id) {
//         navigate(`/artist/${artist?.artist?.id}`);
//       } else {
//         // show notification for no album exists.
//         console.log(currentTrack, "ct ");
//       }
//     }

//     function handleShare() {
//       const protocol = Config.prod
//         ? "https://stage.udux.com/playlist"
//         : "http://localhost:3000/playlist";

//       const type = playingList.type === "playlist" ? "pl" : "alb";

//       const href = `${protocol}/${playingList?.id}?t=${type}&sid=`;

//       setIsSharing({
//         open: true,
//         href,
//       });
//     }
//   }
// );

// MiniPlayer.displayName = "MiniPlayer";

import {
  AddSongIcon,
  AddToQueueIcon,
  ExpandOutlineIcon,
  FavoriteFilledIcon,
  FsePausePlayIcon,
  FsePlayingIcon,
  LikeIcon,
  NextOutlineIcon,
  PrevOutlineIcon,
  RepeatOutlineIcon,
  ShuffleOutlineIcon,
} from "assets";
import {
  AddToPlaylistModal,
  FlexibleDiv,
  TrackProgressControl,
  VolumeControl,
} from "components";
import { Types, useMainContext } from "context";
import { isEmpty } from "lodash";
import { addReaction, removeReaction } from "network";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDisplayArtist,
  getTrackID,
  getTrackTitle,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
  repeatTrack,
} from "utils";
import { useMutation } from "react-query";
import { ReactionType } from "types";
import { MiniPlayerWrap } from "./mini-player.styles";
import { useMediaQuery } from "react-responsive";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { MobileMiniPlayer } from "./MobileMiniPlayer";

export const MiniPlayer = React.memo(() => {
  const { playlistId } = useParams() as { playlistId: string };
  const navigate = useNavigate();
  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext();
  const [, setLiked] = useState(false);
  const [playerMode] = useState(false);
  const [openAddToPlaylistModal, setOpenAddToPlaylistModal] = useState(false);
  const { mutate: mutateLike } = useMutation(addReaction);
  const { mutate: mutateUnlike } = useMutation(removeReaction);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const { isLoggedIn } = app;
  const {
    isPlaying,
    repeat,
    shuffle,
    playingList,
    currentTrack,
    queuedTracks,
    prevTracks,
    fsem,
  } = player;
  const [playerModeActivated] = useState(fsem);
  const trackId = getTrackID(currentTrack);
  const domColors = currentTrack?.metadata?.coverArtDominantColors
  const handlePlayerMode = () => {
    dispatch({ type: Types.SET_FSEM, payload: true });
    navigate(`/player/${trackId}/playlist/${playlistId}`);
  };

  const exitPlayerMode = () => {
    dispatch({ type: Types.SET_FSEM, payload: false });
    navigate(`/`);
  };

  const handleFullScreenMode = () => {
    const statePayload = {
      prevTracks: prevTracks,
      playingList: playingList,
      queuedTracks: queuedTracks,
    };
    dispatch({ type: Types.SET_FSEM, payload: true });
    navigate(`/full-screen-player/${trackId}`, {
      state: statePayload,
    });
    // Request fullscreen
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileMiniPlayer />
      ) : (
        <MiniPlayerWrap playerMode={playerMode} dominantColors={domColors}>
          <FlexibleDiv alignItems="center" className="fsem__wrap">
            {currentTrack && (
              <FlexibleDiv className="track__image__wrap">
                <div className="name__and__cover">
                  <div className="cover__art">
                    <img
                      src={getImageDisplay()}
                      alt={`${getTrackTitle(currentTrack, true)} cover`}
                    />
                  </div>
                  <div className="title__and__album__artist">
                    {currentTrack && (
                      <p className="title">
                        {getTrackTitle(currentTrack, true)}
                      </p>
                    )}

                    <p className="album">Album</p>
                    <p className="artist">{getDisplayArtist(currentTrack)}</p>
                  </div>
                  {playerModeActivated ? (
                    <div className="caret" onClick={exitPlayerMode}>
                      <CaretUpOutlined
                        className="activePlayerMode"
                        color="#fff"
                        width={30}
                        height={30}
                      />
                    </div>
                  ) : (
                    <div className="caret" onClick={handlePlayerMode}>
                      <CaretDownOutlined width={30} height={30} />
                    </div>
                  )}
                </div>
              </FlexibleDiv>
            )}

            <FlexibleDiv
              alignItems="center"
              className="track__controls__wrap"
              flexDir="column"
            >
              {!playerModeActivated && (
                <FlexibleDiv justifyContent="center" className="controls__main">
                  <div
                    className="control__icons repeat__icon"
                    onClick={() => repeatTrack(Types.REPEAT, dispatch, repeat)}
                  >
                    <RepeatOutlineIcon
                      width={25}
                      height={25}
                      color={repeat ? "var(--uduxYellowPrimary)" : "#D9D9D9"}
                    />
                  </div>

                  <div
                    className="control__icons"
                    onClick={() => {
                      if (currentTrack) {
                        playPreviousTrack(dispatch, {
                          track: currentTrack,
                        });
                      }
                    }}
                  >
                    <PrevOutlineIcon width={25} height={25} />
                  </div>

                  {isPlaying ? (
                    <div
                      className="control__icons pause__icon"
                      onClick={() => pauseAudio(playerRef, dispatch)}
                    >
                      <FsePlayingIcon width={25} height={25} />
                    </div>
                  ) : (
                    <div
                      className="control__icons play__icon"
                      onClick={() => playAudio(playerRef, dispatch)}
                    >
                      <FsePausePlayIcon width={25} color="#fff" height={25} />
                    </div>
                  )}

                  <div
                    className="control__icons"
                    onClick={() => {
                      playNextTrack(dispatch, {
                        track: currentTrack,
                      });
                    }}
                  >
                    <NextOutlineIcon width={25} height={25} />
                  </div>
                  {shuffle ? (
                    <div className="control__icons shuffle__icon">
                      <ShuffleOutlineIcon
                        width={25}
                        height={25}
                        color="var(--yellowPrimary)"
                      />
                    </div>
                  ) : (
                    <div className="control__icons shuffle__icon">
                      <ShuffleOutlineIcon width={25} height={25} />
                    </div>
                  )}
                </FlexibleDiv>
              )}

              <FlexibleDiv className="song__progress__wrap" flexDir="column">
                {!isMobile && <TrackProgressControl displayProgressTime />}
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv className="visual__controls">
              <div className="v__icon__wrap">
                {currentTrack?.userLiked ? (
                  <div onClick={handleUnlikeTrack}>
                    <FavoriteFilledIcon width={25} height={25} />
                  </div>
                ) : (
                  <div onClick={handleLikeTrack}>
                    <LikeIcon width={25} height={25} />
                  </div>
                )}
              </div>
              <div className="v__icon__wrap" onClick={handleFullScreenMode}>
                <ExpandOutlineIcon width={25} height={25} />
              </div>
              <div
                className="v__icon__wrap"
                onClick={() => {
                  if (isLoggedIn) {
                    setOpenAddToPlaylistModal(true);
                  } else {
                    dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
                  }
                }}
              >
                <AddSongIcon width={25} height={25} />
              </div>

              <div className="v__icon__wrap">
                <AddToQueueIcon width={25} height={25} />
              </div>
              <div>
                <VolumeControl />
              </div>
            </FlexibleDiv>
          </FlexibleDiv>
          {openAddToPlaylistModal && (
            <AddToPlaylistModal
              open={openAddToPlaylistModal}
              handleClose={() => setOpenAddToPlaylistModal(false)}
            />
          )}
        </MiniPlayerWrap>
      )}
    </>
  );

  function getImageDisplay() {
    if (!isEmpty(currentTrack)) {
      if (currentTrack?.track?.metadata) {
        return currentTrack.track.metadata.coverArt.url;
      }

      if (currentTrack?.metadata) {
        return currentTrack.metadata.coverArt.url;
      }
    }
  }

  function handleUnlikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(false);
    const payload = {
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateUnlike(payload, {
      onSuccess: () => {},
      onError: (err) => {
        setLiked(false);
        console.error(err, "error un-liking from mini player");
      },
    });
  }

  function handleLikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(true);
    const payload = {
      type: ReactionType.LIKE,
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateLike(payload, {
      onSuccess: (res) => {
        console.log(res, "liked from mini player");
      },
      onError: (err) => {
        setLiked(false);
        console.error(err, "error liking from mini player");
      },
    });
  }
});

MiniPlayer.displayName = "MiniPlayer";

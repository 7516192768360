import { IconProps } from "types";

export const FavoriteFilledIcon = ({
  color="#FBBA12",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "35"}
    height={height || "29"}
    viewBox="0 0 35 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7248 4.89925L17.1631 5.69766L17.6014 4.89925C19.0365 2.28506 21.8663 0.5 25.1318 0.5C29.8552 0.5 33.6631 4.23003 33.6631 8.80357C33.6631 14.5239 29.5689 19.4441 25.342 22.985C23.242 24.7442 21.1395 26.1375 19.5608 27.0913C18.772 27.5679 18.1153 27.9339 17.6569 28.1803C17.4496 28.2917 17.283 28.3785 17.1631 28.4401C17.0432 28.3785 16.8765 28.2917 16.6693 28.1803C16.2109 27.9339 15.5542 27.5679 14.7654 27.0913C13.1867 26.1375 11.0841 24.7442 8.98417 22.985C4.75729 19.4441 0.663086 14.5239 0.663086 8.80357C0.663086 4.23003 4.47099 0.5 9.19434 0.5C12.4599 0.5 15.2897 2.28506 16.7248 4.89925Z"
      fill={color}
      stroke={color}
    />
  </svg>
);

import UduxYellowLogo from "assets/images/Auth/udux_logo_yellow.svg";
import { FlexibleDiv } from "components/lib";
import { Types, useMainContext } from "context";
import { AuthResponseError } from "pages/Auth/AuthResponseError";
import React from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { slideTo, topSlideInFrom } from "utils";
import {
  AuthLayoutWrapper,
  AuthLayoutWrapperProps,
} from "./auth-layout.styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import SwiperCore from "swiper";
import { EffectFade } from "swiper/modules";

const checkHeight = window.innerHeight > 800 ? true : false;
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false;

export type GalleryImagesProps = {
  redirectURL?: () => void;
  boxIcon?: React.ReactNode;
  heroText?: string;
  spanText?: string;
  pageHoverImage: string;
  textURL?: string;
};

export type AuthLayoutProps = {
  children?: React.ReactNode;
  title?: string;
  subText?: string;
  galleryImages?: GalleryImagesProps[];
  activeSlide?: number;
  galleryImage?: string;
} & AuthLayoutWrapperProps;

export const AuthLayout = ({
  children,
  title = "Follow the Rhythm.",
  subText = "Build Your Tribe. Find Your X",
  galleryImages,
  activeSlide = 0,
  galleryImage,
  ...props
}: AuthLayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const apiResponseErrorRef = React.useRef(null);
  const formWrapRef = React.useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 994 });
  const [swiper, setSwiper] = React.useState<SwiperCore | null>(null);
  SwiperCore.use([EffectFade]);

  const {
    state: { app },
    dispatch,
  } = useMainContext();
  const { apiResponseError, signUpFormStep } = app;
  const showBackButton = pathname !== "/login" && pathname !== "/oauth";

  React.useLayoutEffect(() => {
    topSlideInFrom(apiResponseErrorRef, { duration: 0.8, y: 30 });
  }, [apiResponseError]);

  React.useLayoutEffect(() => {
    if (apiResponseError) {
      slideTo(formWrapRef, { duration: 0.3, y: 5 });
    }
  }, [apiResponseError]);

  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500);
    }
  }, [swiper, activeSlide]);

  React.useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined });
  }, [dispatch]);

  const handleGoBack = () => {
    if (signUpFormStep > 0 && pathname === "/signup") {
      return dispatch({ type: Types.SIGN_UP_FORM_STEP, payload: "SUB" });
    }

    return navigate(-1);
  };

  return (
    <AuthLayoutWrapper
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      hasError={Boolean(apiResponseError)}
      {...props}
    >
      <FlexibleDiv className="content__box">
        {!isMobile && (
          <div className="gallery__pane" id="gallery__pane">
            <Swiper
              className="swiper"
              effect="fade"
              slidesPerView={1}
              initialSlide={activeSlide}
              onSwiper={setSwiper}
              allowTouchMove={false}
            >
              {galleryImages ? (
                galleryImages?.map((image, index) => (
                  <SwiperSlide className="slider__slide " key={index}>
                    <div
                      className="gallery_pane_img"
                      style={{
                        backgroundImage: `url(${image?.pageHoverImage})`,
                      }}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide className="slider__slide ">
                  <div
                    className="gallery_pane_img"
                    style={{
                      backgroundImage: `url(${galleryImage})`,
                    }}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        )}

        <div className="activity__pane">
          {showBackButton && (
            <button className="go__back__box" onClick={handleGoBack}>
              <IoArrowBackCircleOutline color="var(--uduxYellowPrimary)" size={30} />{" "}
              <span>Back</span>
            </button>
          )}
          <div className="logo__wrap">
            <img src={UduxYellowLogo} alt="uduX" />
          </div>

          <div className="content__box__layout">
            <div className="content__layout">
              {title && <h2 className="title">{title}</h2>}
              {subText && <p className="sub__text">{subText} </p>}

              <div className="auth__response__error__main">
                <AuthResponseError
                  ref={apiResponseErrorRef}
                  error={apiResponseError}
                />
              </div>

              <div className="form__wrap">{children}</div>
            </div>
          </div>
        </div>
      </FlexibleDiv>
    </AuthLayoutWrapper>
  );
};

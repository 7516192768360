import React from "react"
import { IconProps } from "types"

export const DiscoverIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "18"}
      height={height || "14"}
      viewBox="0 0 18 14"
      fill="none"
      className="fill__player__svg"
    >
      <path
        d="M11.6889 13.2772C14.4139 13.2772 16.6229 11.0682 16.6229 8.34319C16.6229 5.61821 14.4139 3.40918 11.6889 3.40918C8.96392 3.40918 6.75488 5.61821 6.75488 8.34319C6.75488 11.0682 8.96392 13.2772 11.6889 13.2772Z"
        fill="black"
      />
      <path
        d="M4.31056 0.551758C3.31341 0.55176 2.35709 0.947879 1.652 1.65297C0.946903 2.35807 0.550783 3.31438 0.550781 4.31154C0.550783 5.30869 0.946903 6.26501 1.652 6.9701C2.35709 7.6752 3.31341 8.07132 4.31056 8.07132C4.78316 8.07093 5.25145 7.98145 5.6909 7.80756C5.76477 6.98345 6.00759 6.18339 6.40424 5.45725C6.80088 4.73111 7.34284 4.09445 7.99634 3.58695C7.82851 2.73247 7.36929 1.96275 6.69709 1.40919C6.02488 0.855636 5.18136 0.552576 4.31056 0.551758Z"
        fill="black"
      />
      <path
        d="M4.31054 0C1.93641 0 0 1.93641 0 4.31054C0 6.68466 1.93641 8.62108 4.31054 8.62108C4.78439 8.62108 5.23929 8.54098 5.66635 8.39862C5.66554 8.38013 5.66483 8.36162 5.66419 8.34312C5.66443 7.94679 5.70378 7.55146 5.78166 7.16286C5.34121 7.38944 4.8421 7.51956 4.31053 7.51956C2.53171 7.51956 1.10151 6.08936 1.10151 4.31054C1.10151 2.53172 2.53171 1.10152 4.31053 1.10152C5.98777 1.10152 7.3538 2.37341 7.5045 4.00977C7.80321 3.72223 8.13081 3.46629 8.48209 3.24603C8.00539 1.3848 6.31679 0 4.31053 0H4.31054Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M4.31052 2.31543C3.78113 2.31543 3.27342 2.52573 2.89909 2.90006C2.52475 3.2744 2.31445 3.78211 2.31445 4.3115C2.31445 4.84089 2.52475 5.34859 2.89909 5.72293C3.27342 6.09726 3.78113 6.30756 4.31052 6.30756C4.83991 6.30756 5.34762 6.09726 5.72195 5.72293C6.09629 5.34859 6.30658 4.84089 6.30659 4.3115C6.30582 4.15607 6.2869 4.00127 6.25022 3.85024C6.05541 4.0377 5.79555 4.14241 5.5252 4.1424C5.24797 4.1424 4.98208 4.0323 4.78601 3.83631C4.58994 3.64032 4.47973 3.37448 4.47962 3.09725C4.47995 2.82671 4.58514 2.56683 4.77307 2.37223C4.62163 2.3353 4.46639 2.31624 4.31052 2.31543Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M11.6889 5.72363C10.9943 5.72373 10.3281 5.99976 9.83689 6.49101C9.34572 6.98226 9.0698 7.6485 9.06982 8.34317C9.0698 9.03785 9.34572 9.70409 9.83689 10.1953C10.3281 10.6866 10.9943 10.9626 11.6889 10.9627C12.0329 10.9627 12.3736 10.895 12.6914 10.7633C13.0092 10.6317 13.298 10.4387 13.5413 10.1955C13.7845 9.95224 13.9775 9.66346 14.1091 9.34564C14.2407 9.02782 14.3085 8.68718 14.3085 8.34317C14.3075 8.13904 14.2827 7.93571 14.2345 7.73734C13.9789 7.98347 13.6379 8.12103 13.2831 8.12115C12.9193 8.12117 12.5703 7.97666 12.313 7.71942C12.0557 7.46218 11.9111 7.11327 11.911 6.74942C11.9115 6.39437 12.0497 6.05336 12.2965 5.79807C12.0976 5.7496 11.8937 5.72462 11.6889 5.72363Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M11.6891 2.68652C8.57352 2.68652 6.03223 5.22739 6.03223 8.34298C6.03223 11.4586 8.57352 13.9999 11.6891 13.9999C14.8047 13.9999 17.346 11.4586 17.346 8.34298C17.346 5.22739 14.8047 2.68652 11.6891 2.68652ZM11.6891 4.13183C14.0235 4.13183 15.9003 6.00861 15.9003 8.34298C15.9003 10.6773 14.0235 12.5546 11.6891 12.5546C9.35474 12.5546 7.47797 10.6773 7.47797 8.34298C7.47797 6.00861 9.35474 4.13183 11.6891 4.13183Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const OrganizePlaylistIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "17"}
      height="15"
      viewBox="0 0 17 15"
      fill="none"
    >
      <path
        d="M7.75879 0.0493164V14.3169H8.94776V0.0493164H7.75879ZM0.625 1.23828V2.42725H5.38086V1.23828H0.625ZM11.9202 1.23828C11.9202 1.23828 11.3257 1.23828 11.3257 1.83276V5.39966C11.3257 5.39966 11.3257 5.99414 11.9202 5.99414H15.4871C15.4871 5.99414 16.0816 5.99414 16.0816 5.39966V1.83276C16.0816 1.83276 16.0816 1.23828 15.4871 1.23828H11.9202ZM12.5147 2.42725H14.8926V4.80518H12.5147V2.42725ZM0.625 4.80518V5.99414H5.38086V4.80518H0.625ZM0.625 8.37207V9.56104H5.38086V8.37207H0.625ZM11.9202 8.37207C11.9202 8.37207 11.3257 8.37207 11.3257 8.96656V12.5335C11.3257 12.5335 11.3257 13.1279 11.9202 13.1279H15.4871C15.4871 13.1279 16.0816 13.1279 16.0816 12.5335V8.96656C16.0816 8.96656 16.0816 8.37207 15.4871 8.37207H11.9202ZM12.5147 9.56104H14.8926V11.939H12.5147V9.56104ZM0.625 11.939V13.1279H5.38086V11.939H0.625Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const DragIcon = ({ width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20"}
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <g clipPath="url(#clip0_618_3821)">
        <path d="M20 0H0V1.74194H20V0Z" fill="#D9D9D9" />
        <path d="M20 16.2578H0V17.9997H20V16.2578Z" fill="#D9D9D9" />
        <path d="M20 8.12891H0V9.87084H20V8.12891Z" fill="#D9D9D9" />
      </g>
      <defs>
        <clipPath id="clip0_618_3821">
          <rect width="20" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PlayNextIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "27"}
      height="23"
      viewBox="0 0 27 23"
      fill="none"
    >
      <path
        d="M19.1808 8.65723C22.9945 8.65723 26.0861 11.7488 26.0861 15.5626C26.0861 19.3764 22.9945 22.468 19.1808 22.468C15.367 22.468 12.2754 19.3764 12.2754 15.5626C12.2754 11.7488 15.367 8.65723 19.1808 8.65723ZM22.7918 16.1058L22.7956 16.1018C22.8676 16.0291 22.9219 15.9455 22.9589 15.8563C22.9957 15.7671 23.0165 15.6696 23.0171 15.5672V15.5626V15.558C23.0165 15.4556 22.9957 15.358 22.9589 15.2689C22.9215 15.1784 22.8659 15.0936 22.7924 15.02L19.7234 11.9509C19.4237 11.6514 18.9378 11.6514 18.6382 11.9509C18.3386 12.2506 18.3386 12.7365 18.6382 13.0362L20.3975 14.7953H16.1117C15.688 14.7953 15.3444 15.1389 15.3444 15.5626C15.3444 15.9863 15.688 16.3299 16.1117 16.3299H20.3975L18.6382 18.089C18.3386 18.3887 18.3386 18.8746 18.6382 19.1743C18.9378 19.4738 19.4237 19.4738 19.7234 19.1743L22.7918 16.1058Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M24.5524 2.13722C24.5524 1.50161 24.0371 0.986328 23.4015 0.986328H1.1509C0.515263 0.986328 0 1.50161 0 2.13722C0 2.77284 0.515279 3.28812 1.1509 3.28812H23.4015C24.0371 3.28812 24.5524 2.77284 24.5524 2.13722Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M24.5524 6.73781C24.5524 6.10219 24.0371 5.58691 23.4015 5.58691H1.1509C0.515263 5.58691 0 6.10219 0 6.73781C0 7.37343 0.515279 7.8887 1.1509 7.8887H15.6606C16.7323 7.39612 17.9248 7.12144 19.1816 7.12144C20.4384 7.12144 21.6308 7.39612 22.7026 7.8887H23.4015C24.0371 7.8887 24.5524 7.37343 24.5524 6.73781Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M10.8809 17.0987H1.1509C0.515279 17.0987 0 16.5834 0 15.9478C0 15.3122 0.515263 14.7969 1.1509 14.7969H10.7761C10.7533 15.0496 10.7417 15.3056 10.7417 15.5641C10.7417 16.0882 10.7894 16.601 10.8809 17.0987Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M11.317 12.4951H1.15089C0.515279 12.4951 0 11.9799 0 11.3443C0 10.7087 0.515263 10.1934 1.15089 10.1934H12.6709C12.1052 10.8782 11.6456 11.6539 11.317 12.4951Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}
export const HomeOutlinedIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "18"}
      height={height || "21"}
      viewBox="0 0 18 21"
      fill="none"
      className="fill__player__svg"
    >
      <path
        d="M8.25346 2.79075C8.65532 2.38486 9.31112 2.38486 9.71293 2.79074L17.7891 10.9485C18.0436 11.2055 18.0333 11.5661 17.6984 11.7026C16.8333 12.0552 14.6024 12.5789 8.98319 12.5789C3.36396 12.5789 1.13316 12.0552 0.267995 11.7026C-0.0669536 11.5661 -0.0771554 11.2055 0.177319 10.9485L8.25346 2.79075Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M8.51241 0.511654C8.48946 0.236222 8.70679 0 8.98317 0C9.25954 0 9.47693 0.236222 9.45398 0.511654L9.26124 2.82473C9.24917 2.96933 9.12826 3.08055 8.98317 3.08055C8.83807 3.08055 8.71721 2.96933 8.70515 2.82473L8.51241 0.511654Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M9.98847 0.655888C10.1063 0.405886 10.4126 0.309988 10.652 0.448182C10.8913 0.586371 10.9615 0.899627 10.8039 1.12668L9.48038 3.03348C9.39767 3.15269 9.23738 3.18857 9.11169 3.11602C8.98606 3.04347 8.93697 2.8867 8.99884 2.75545L9.98847 0.655888Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M7.97793 0.655888C7.86009 0.405886 7.55378 0.309988 7.31443 0.448182C7.07507 0.586371 7.00493 0.899627 7.1625 1.12668L8.48601 3.03348C8.56872 3.15269 8.72902 3.18857 8.8547 3.11602C8.98034 3.04347 9.02942 2.8867 8.96755 2.75545L7.97793 0.655888Z"
        fill={color || "#D9D9D9"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30859 13.272C2.30859 13.1153 2.44758 12.9952 2.60289 13.016C4.03119 13.2075 6.06887 13.3468 8.98313 13.3468C11.8974 13.3468 13.9351 13.2075 15.3634 13.016C15.5187 12.9952 15.6577 13.1153 15.6577 13.272V20.0217C15.6577 20.4775 13.7272 20.864 11.0554 20.9981C10.7649 21.0127 10.5234 20.7795 10.5234 20.4887V17.9686C10.5234 17.118 9.83382 16.4284 8.98313 16.4284C8.13243 16.4284 7.44285 17.118 7.44285 17.9686V20.4887C7.44285 20.7795 7.20133 21.0127 6.91084 20.9981C4.23907 20.864 2.30859 20.4775 2.30859 20.0217V13.272Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const CloseCircleIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      width={width || "28"}
      height={height || "28"}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke__player__svg"
    >
      <path
        d="M14 26.5C20.9036 26.5 26.5 20.9036 26.5 14C26.5 7.09644 20.9036 1.5 14 1.5C7.09644 1.5 1.5 7.09644 1.5 14C1.5 20.9036 7.09644 26.5 14 26.5Z"
        stroke={color || "white"}
        strokeWidth="2.5"
      />
    </svg>
  )
}

export const FavouritesMultipleHeartsIcon = ({
  color,
  width,
  height,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20"}
      height={height || "19"}
      viewBox="0 0 20 19"
      fill="none"
      className="stroke__player__svg"
    >
      <path
        d="M7.40146 1.72168C6.85493 1.7255 6.33215 1.94552 5.94737 2.33366C5.56247 1.94551 5.03958 1.72549 4.49297 1.72168C3.28824 1.72168 2.31152 2.78997 2.31152 4.11037C2.31152 5.43078 4.85649 7.69311 5.94737 8.48944C7.03794 7.69311 9.5829 5.42956 9.5829 4.11037C9.5829 2.79119 8.60618 1.72168 7.40146 1.72168Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6425 9.96094C15.096 9.96476 14.5732 10.1848 14.1885 10.5729C13.8036 10.1848 13.2807 9.96475 12.7341 9.96094C11.5293 9.96094 10.5526 11.0292 10.5526 12.3496C10.5526 13.67 13.0976 15.9324 14.1885 16.7287C15.279 15.9324 17.824 13.6688 17.824 12.3496C17.824 11.0304 16.8473 9.96094 15.6425 9.96094Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.32544 9.75977V10.3702"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.32544 11.5908V12.2013"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.10449 10.9805H7.71494"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.93579 10.9805H9.54624"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8355 2.12867C12.2569 2.12867 12.5985 1.78703 12.5985 1.3656C12.5985 0.944174 12.2569 0.602539 11.8355 0.602539C11.414 0.602539 11.0724 0.944174 11.0724 1.3656C11.0724 1.78703 11.414 2.12867 11.8355 2.12867Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.76306 18.0007C2.18449 18.0007 2.52613 17.6591 2.52613 17.2377C2.52613 16.8162 2.18449 16.4746 1.76306 16.4746C1.34163 16.4746 1 16.8162 1 17.2377C1 17.6591 1.34163 18.0007 1.76306 18.0007Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3555 7.95715C10.3555 8.02168 10.3363 8.08476 10.3005 8.13842C10.2646 8.19208 10.2137 8.2339 10.154 8.25859C10.0944 8.28329 10.0288 8.28975 9.96551 8.27716C9.90222 8.26457 9.84408 8.2335 9.79845 8.18786C9.75282 8.14223 9.72174 8.08409 9.70915 8.0208C9.69656 7.95751 9.70302 7.8919 9.72772 7.83228C9.75241 7.77266 9.79423 7.7217 9.84789 7.68585C9.90155 7.65 9.96463 7.63086 10.0292 7.63086C10.1157 7.63086 10.1987 7.66524 10.2599 7.72643C10.3211 7.78762 10.3555 7.87061 10.3555 7.95715Z"
        fill={color || "#fff"}
      />
      <path
        d="M18.5965 7.34679C18.5965 7.41133 18.5774 7.47441 18.5416 7.52807C18.5057 7.58173 18.4547 7.62355 18.3951 7.64824C18.3355 7.67294 18.2699 7.6794 18.2066 7.66681C18.1433 7.65422 18.0852 7.62314 18.0395 7.57751C17.9939 7.53188 17.9628 7.47374 17.9502 7.41045C17.9376 7.34716 17.9441 7.28155 17.9688 7.22193C17.9935 7.16231 18.0353 7.11135 18.089 7.0755C18.1426 7.03964 18.2057 7.02051 18.2703 7.02051C18.3568 7.02051 18.4398 7.05488 18.501 7.11607C18.5622 7.17727 18.5965 7.26026 18.5965 7.34679Z"
        fill={color || "#fff"}
      />
      <path
        d="M2.41954 0.326896C2.41942 0.391403 2.40018 0.454427 2.36425 0.508004C2.32833 0.561581 2.27733 0.603307 2.21769 0.627909C2.15806 0.652512 2.09248 0.658886 2.02922 0.646227C1.96597 0.633569 1.90789 0.602445 1.86232 0.556789C1.81675 0.511133 1.78573 0.452993 1.77319 0.389717C1.76065 0.32644 1.76715 0.260866 1.79186 0.201281C1.81658 0.141696 1.8584 0.0907728 1.91204 0.054947C1.96569 0.0191211 2.02875 1.12898e-07 2.09325 0C2.13615 -7.50807e-08 2.17863 0.00845975 2.21826 0.0248953C2.25789 0.0413309 2.29388 0.0654195 2.32419 0.0957826C2.35449 0.126146 2.37852 0.162187 2.39488 0.201845C2.41124 0.241502 2.41962 0.283996 2.41954 0.326896Z"
        fill={color || "#fff"}
      />
      <path
        d="M18.2914 16.8087C18.2914 16.8732 18.2722 16.9363 18.2364 16.99C18.2005 17.0436 18.1496 17.0855 18.0899 17.1102C18.0303 17.1349 17.9647 17.1413 17.9014 17.1287C17.8381 17.1161 17.78 17.0851 17.7344 17.0394C17.6887 16.9938 17.6577 16.9357 17.6451 16.8724C17.6325 16.8091 17.6389 16.7435 17.6636 16.6838C17.6883 16.6242 17.7301 16.5733 17.7838 16.5374C17.8375 16.5016 17.9005 16.4824 17.9651 16.4824C18.0079 16.4824 18.0504 16.4909 18.0899 16.5073C18.1295 16.5237 18.1655 16.5477 18.1958 16.578C18.2261 16.6083 18.2501 16.6443 18.2665 16.6838C18.2829 16.7234 18.2914 16.7659 18.2914 16.8087Z"
        fill={color || "#fff"}
      />
      <path
        d="M2.11436 8.87316C2.11436 8.93769 2.09523 9.00078 2.05937 9.05444C2.02352 9.10809 1.97256 9.14991 1.91294 9.17461C1.85332 9.19931 1.78772 9.20577 1.72442 9.19318C1.66113 9.18059 1.60299 9.14951 1.55736 9.10388C1.51173 9.05825 1.48065 9.00011 1.46806 8.93682C1.45547 8.87352 1.46193 8.80792 1.48663 8.7483C1.51132 8.68868 1.55315 8.63772 1.6068 8.60186C1.66046 8.56601 1.72354 8.54688 1.78808 8.54688C1.87461 8.54688 1.95761 8.58125 2.0188 8.64244C2.07999 8.70363 2.11436 8.78662 2.11436 8.87316Z"
        fill={color || "#fff"}
      />
      <path
        d="M14.4421 4.71777C14.2321 4.71923 14.0311 4.80372 13.8832 4.9528C13.7351 4.80376 13.5341 4.71928 13.324 4.71777C13.0912 4.72886 12.8722 4.83176 12.715 5.00396C12.5579 5.17615 12.4754 5.4036 12.4856 5.6365C12.4856 6.14348 13.4623 7.01368 13.8832 7.31982C14.3023 7.01459 15.2805 6.14348 15.2805 5.6365C15.2907 5.4036 15.2082 5.17615 15.0511 5.00396C14.8939 4.83176 14.6749 4.72886 14.4421 4.71777V4.71777Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7996 0.445312C17.5896 0.446769 17.3887 0.531259 17.2408 0.680336C17.0927 0.531297 16.8917 0.446821 16.6816 0.445312C16.4487 0.456394 16.2297 0.5593 16.0726 0.731494C15.9154 0.903689 15.8329 1.13114 15.8431 1.36404C15.8431 1.87102 16.8198 2.74122 17.2408 3.04736C17.6598 2.74213 18.6381 1.87102 18.6381 1.36404C18.6483 1.13114 18.5658 0.903689 18.4086 0.731494C18.2515 0.5593 18.0325 0.456394 17.7996 0.445312V0.445312Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67487 11.1289C4.46485 11.1304 4.26394 11.2149 4.116 11.3639C3.96791 11.2149 3.76692 11.1304 3.55683 11.1289C3.32397 11.14 3.10499 11.2429 2.94784 11.4151C2.79069 11.5873 2.70818 11.8147 2.71837 12.0476C2.71837 12.5546 3.69662 13.4248 4.116 13.731C4.53507 13.4257 5.51332 12.5546 5.51332 12.0476C5.52352 11.8147 5.44101 11.5873 5.28386 11.4151C5.12671 11.2429 4.90773 11.14 4.67487 11.1289V11.1289Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.64289 14.791C8.43287 14.7925 8.23196 14.877 8.08402 15.026C7.93593 14.877 7.73494 14.7925 7.52485 14.791C7.29198 14.8021 7.073 14.905 6.91585 15.0772C6.75871 15.2494 6.6762 15.4768 6.68639 15.7097C6.68639 16.2167 7.66464 17.0869 8.08402 17.3931C8.50309 17.0878 9.48134 16.2167 9.48134 15.7097C9.49153 15.4768 9.40902 15.2494 9.25188 15.0772C9.09473 14.905 8.87575 14.8021 8.64289 14.791V14.791Z"
        stroke={color || "#fff"}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LibraryIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      className="fill__player__svg"
    >
      <path
        d="M0 0H17V17H0V0ZM8.5 15.3C10.3035 15.3 12.0331 14.5836 13.3083 13.3083C14.5836 12.0331 15.3 10.3035 15.3 8.5C15.3 6.69653 14.5836 4.96692 13.3083 3.69167C12.0331 2.41643 10.3035 1.7 8.5 1.7C6.69653 1.7 4.96692 2.41643 3.69167 3.69167C2.41643 4.96692 1.7 6.69653 1.7 8.5C1.7 10.3035 2.41643 12.0331 3.69167 13.3083C4.96692 14.5836 6.69653 15.3 8.5 15.3ZM8.5 11.05C7.8237 11.05 7.1751 10.7813 6.69688 10.3031C6.21866 9.82491 5.95 9.1763 5.95 8.5C5.95 7.8237 6.21866 7.1751 6.69688 6.69688C7.1751 6.21866 7.8237 5.95 8.5 5.95C9.1763 5.95 9.82491 6.21866 10.3031 6.69688C10.7813 7.1751 11.05 7.8237 11.05 8.5C11.05 9.1763 10.7813 9.82491 10.3031 10.3031C9.82491 10.7813 9.1763 11.05 8.5 11.05Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const ExpandIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22.295 21.3676V17.7048C22.295 17.3427 22.5886 17.0491 22.9507 17.0491C23.3129 17.0491 23.6065 17.3427 23.6065 17.7048V22.9507C23.6065 23.3129 23.3129 23.6065 22.9507 23.6065H17.7048C17.3427 23.6065 17.0491 23.3129 17.0491 22.9507C17.0491 22.5886 17.3427 22.295 17.7048 22.295H21.3676L14.6182 15.5456C14.3621 15.2895 14.3621 14.8743 14.6182 14.6182C14.8743 14.3621 15.2895 14.3621 15.5456 14.6182L22.295 21.3676ZM1.31147 2.23882V5.90161C1.31147 6.26377 1.01789 6.55735 0.655735 6.55735C0.293582 6.55735 0 6.26377 0 5.90161V0.655735C0 0.293582 0.293582 0 0.655735 0H5.90161C6.26377 0 6.55735 0.293582 6.55735 0.655735C6.55735 1.01789 6.26377 1.31147 5.90161 1.31147H2.23882L8.98823 8.06088C9.24431 8.31696 9.24431 8.73215 8.98823 8.98823C8.73215 9.24431 8.31696 9.24431 8.06088 8.98823L1.31147 2.23882ZM2.23882 22.295H5.90161C6.26377 22.295 6.55735 22.5886 6.55735 22.9507C6.55735 23.3129 6.26377 23.6065 5.90161 23.6065H0.655735C0.293582 23.6065 0 23.3129 0 22.9507V17.7048C0 17.3427 0.293582 17.0491 0.655735 17.0491C1.01789 17.0491 1.31147 17.3427 1.31147 17.7048V21.3676L8.06088 14.6182C8.31696 14.3621 8.73215 14.3621 8.98823 14.6182C9.24431 14.8743 9.24431 15.2895 8.98823 15.5456L2.23882 22.295ZM21.3676 1.31147H17.7048C17.3427 1.31147 17.0491 1.01789 17.0491 0.655735C17.0491 0.293582 17.3427 0 17.7048 0H22.9507C23.3129 0 23.6065 0.293582 23.6065 0.655735V5.94244C23.6065 6.30459 23.3129 6.59817 22.9507 6.59817C22.5886 6.59817 22.295 6.30459 22.295 5.94244V2.23882L15.5456 8.98823C15.2895 9.24431 14.8743 9.24431 14.6182 8.98823C14.3621 8.73215 14.3621 8.31696 14.6182 8.06088L21.3676 1.31147Z"
        fill={color || "white"}
      />
    </svg>
  )
}

export const FavoritesSingleHeartIconOutlined = ({
  color,
  width,
  height,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "28"}
      height={height || "25"}
      viewBox="0 0 28 25"
      fill={color || "none"}
    >
      <path
        d="M13.5737 24.0741C13.4423 24.0741 13.313 24.0404 13.1983 23.9762C10.8633 22.6085 8.67187 21.0094 6.65705 19.2029C2.2397 15.2274 0 11.2654 0 7.42712C0.000454924 5.83848 0.510143 4.29181 1.45426 3.01414C2.39837 1.73647 3.72718 0.79508 5.24566 0.328147C6.76413 -0.138786 8.3923 -0.10667 9.89118 0.41978C11.3901 0.94623 12.6807 1.93929 13.5737 3.2532C14.4667 1.93929 15.7574 0.94623 17.2562 0.41978C18.7551 -0.10667 20.3833 -0.138786 21.9018 0.328147C23.4202 0.79508 24.7491 1.73647 25.6932 3.01414C26.6373 4.29181 27.147 5.83848 27.1474 7.42712C27.1474 11.2654 24.9077 15.2274 20.4904 19.2029C18.4756 21.0094 16.2841 22.6085 13.9491 23.9762C13.8344 24.0404 13.7052 24.0741 13.5737 24.0741ZM7.42712 1.53665C5.86542 1.53842 4.36818 2.15959 3.26388 3.26388C2.15959 4.36818 1.53842 5.86542 1.53665 7.42712C1.53665 14.7679 11.4975 21.1618 13.5737 22.4142C15.65 21.1618 25.6108 14.7679 25.6108 7.42712C25.6105 6.06564 25.1387 4.74626 24.2756 3.69332C23.4125 2.64037 22.2114 1.91885 20.8764 1.65142C19.5415 1.38399 18.1551 1.58717 16.953 2.22641C15.7509 2.86564 14.8073 3.90148 14.2826 5.15781C14.2242 5.29765 14.1257 5.41708 13.9996 5.50108C13.8734 5.58509 13.7253 5.62991 13.5737 5.62991C13.4222 5.62991 13.274 5.58509 13.1478 5.50108C13.0217 5.41708 12.9232 5.29765 12.8648 5.15781C12.4182 4.08408 11.6632 3.16692 10.6953 2.52235C9.72736 1.87777 8.59002 1.53474 7.42712 1.53665Z"
        fill={color || "white"}
      />
    </svg>
  )
}

export const FavoritesSingleHeartIconFilled = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "26"}
      height="22"
      viewBox="0 0 26 22"
      fill="none"
    >
      <path
        d="M18.4605 0.988281C15.945 0.988281 13.7611 2.33413 12.652 4.31081C11.543 2.33413 9.35906 0.988281 6.84362 0.988281C3.20832 0.988281 0.260742 3.79965 0.260742 7.26695C0.260742 15.7616 12.652 21.671 12.652 21.671C12.652 21.671 25.0434 15.7616 25.0434 7.26695C25.0434 3.79965 22.0958 0.988281 18.4605 0.988281Z"
        fill={color || "#FBBA12"}
      />
    </svg>
  )
}

export const CheckedIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "12"}
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M11.3572 0H0.597746C0.239098 0 0 0.239098 0 0.597746V11.3572C0 11.7158 0.239098 11.9549 0.597746 11.9549H11.3572C11.7158 11.9549 11.9549 11.7158 11.9549 11.3572V0.597746C11.9549 0.239098 11.7158 0 11.3572 0ZM9.38461 4.36354L5.31993 8.42821C5.08084 8.66731 4.72219 8.66731 4.48309 8.42821L2.57031 6.51543C2.33121 6.27633 2.33121 5.91768 2.57031 5.67858C2.8094 5.43948 3.16805 5.43948 3.40715 5.67858L4.90151 7.17295L8.54776 3.5267C8.78686 3.2876 9.14551 3.2876 9.38461 3.5267C9.6237 3.7658 9.6237 4.12444 9.38461 4.36354Z"
        fill={color || "#FBBA12"}
      />
    </svg>
  )
}

export const SoundIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32"}
      height={width || "19"}
      viewBox="0 0 30 24"
      fill="none"
    >
      <path
        d="M20.25 7.1875C22.0833 9.63197 22.0833 14.368 20.25 16.8125"
        stroke={color || "#FBBA12"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.375 2.375C29.8584 7.61148 29.8916 16.4235 24.375 21.625"
        stroke={color || "#FBBA12"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16.0683V7.93168C1 7.14098 1.61561 6.49999 2.375 6.49999H7.30546C7.67012 6.49999 8.01986 6.34916 8.27772 6.08066L12.4027 1.4222C13.269 0.520288 14.75 1.15906 14.75 2.43456V21.5654C14.75 22.8498 13.2512 23.4845 12.3904 22.5647L8.27975 17.9324C8.02095 17.6559 7.66587 17.5 7.29507 17.5H2.375C1.61561 17.5 1 16.859 1 16.0683V16.0683Z"
        stroke={color || "#FBBA12"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const RecentlyPlayedIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
      fill="none"
      className="fill__player__svg"
    >
      <path
        d="M0 0V19.3665H16V0H0ZM8 2.42082C8.70724 2.42082 9.38552 2.67587 9.88562 3.12986C10.3857 3.58385 10.6667 4.19959 10.6667 4.84163C10.6667 5.48368 10.3857 6.09942 9.88562 6.55341C9.38552 7.0074 8.70724 7.26245 8 7.26245C7.29276 7.26245 6.61448 7.0074 6.11438 6.55341C5.61428 6.09942 5.33333 5.48368 5.33333 4.84163C5.33333 4.19959 5.61428 3.58385 6.11438 3.12986C6.61448 2.67587 7.29276 2.42082 8 2.42082ZM8 16.9457C7.47471 16.9457 6.95457 16.8518 6.46927 16.6693C5.98396 16.4868 5.54301 16.2193 5.17157 15.8822C4.80014 15.545 4.5055 15.1447 4.30448 14.7041C4.10346 14.2635 4 13.7914 4 13.3145C4 12.8376 4.10346 12.3654 4.30448 11.9249C4.5055 11.4843 4.80014 11.084 5.17157 10.7468C5.54301 10.4096 5.98396 10.1422 6.46927 9.95968C6.95457 9.77719 7.47471 9.68327 8 9.68327C9.06087 9.68327 10.0783 10.0658 10.8284 10.7468C11.5786 11.4278 12 12.3514 12 13.3145C12 14.2776 11.5786 15.2012 10.8284 15.8822C10.0783 16.5631 9.06087 16.9457 8 16.9457Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const CreatePlaylist = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      className="fill__player__svg"
    >
      <path
        d="M1.5 0C0.671579 0 8.3e-06 0.67157 0 1.5C8.3e-06 2.32843 0.671579 3 1.5 3C2.32842 3 2.99999 2.32843 3 1.5C2.99999 0.67157 2.32842 0 1.5 0ZM4 1V2H5V1H4ZM6 1V2H16V1H6ZM6 3V4H9V3H6ZM1.5 6C0.671573 6 0 6.67157 0 7.5C0 8.32843 0.671573 9 1.5 9C2.32843 9 3 8.32843 3 7.5C3 6.67157 2.32843 6 1.5 6ZM4 7V8H5V7H4ZM6 7V8H16V7H6ZM12 9V12H9V13H12V16H13V13H16V12H13V9H12ZM1.5 12C0.671573 12 0 12.6716 0 13.5C0 14.3284 0.671573 15 1.5 15C2.32843 15 3 14.3284 3 13.5C3 12.6716 2.32843 12 1.5 12ZM4 13V14H5V13H4Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const RecordsIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M5 11.25H10V13.75H5V11.25Z" fill={color || "#D9D9D9"} />
      <path
        d="M20 2.5H18.75V0H6.25V2.5H3.75V4.0625L3 5H1.25V7.1875L0 8.75V20H15L20 13.75V2.5ZM2.5 6.25H12.5V8.75H2.5V6.25ZM13.75 18.75H1.25V10H13.75V18.75ZM15 8.75H13.75V5H5V3.75H15V8.75ZM17.5 5.625L16.25 7.1875V2.5H7.5V1.25H17.5V5.625Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const SearchIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      className="fill__player__svg"
    >
      <path
        d="M5.88429 0C2.63509 0 0 2.63509 0 5.88429C0 9.13349 2.63509 11.7686 5.88429 11.7686C9.13348 11.7686 11.7686 9.13349 11.7686 5.88429C11.7686 2.63509 9.13348 0 5.88429 0ZM5.88429 10.6987C3.22994 10.6987 1.06987 8.53864 1.06987 5.88429C1.06987 3.22994 3.22994 1.06987 5.88429 1.06987C8.53864 1.06987 10.6987 3.22994 10.6987 5.88429C10.6987 8.53864 8.53864 10.6987 5.88429 10.6987ZM10.6987 12.3035L12.3035 10.6987L16.5 14L14 16.5L10.6987 12.3035Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const ArrowRightSquare = ({
  color,
  width,
  height,
  bgColor,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "48"}
      height={height || "41"}
      viewBox="0 0 48 41"
      fill="none"
    >
      <rect
        x="48"
        y="41"
        width={width || "48"}
        height={height || "41"}
        transform="rotate(-180 48 41)"
        fill={bgColor || "#0F0F0F"}
      />
      <path
        d="M16.8107 32.3295L19.2272 34.7324L32.6537 21.2923L19.2136 7.85219L16.8107 10.2551L27.8479 21.2923L16.8107 32.3295Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const ArrowLeftSquare = ({
  color,
  width,
  height,
  bgColor,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "48"}
      height={height || "41"}
      viewBox="0 0 48 41"
      fill="none"
    >
      <rect
        width={width || "48"}
        height={height || "41"}
        fill={bgColor || "#0F0F0F"}
      />
      <path
        d="M31.7714 10.2525L29.3549 7.84961L15.9283 21.2897L29.3685 34.7298L31.7714 32.3269L20.7342 21.2897L31.7714 10.2525Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const ShareIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      width={width || "31"}
      height={height || "30"}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6323 21.25C25.0139 21.2476 24.4025 21.3806 23.8411 21.6398C23.2796 21.8989 22.7817 22.2779 22.3823 22.75L8.63232 16C8.74323 15.679 8.78581 15.3384 8.75732 15C8.73453 14.5735 8.65033 14.1525 8.50732 13.75L22.0073 6.875C22.4242 7.44777 22.969 7.91547 23.5982 8.24094C24.2275 8.56642 24.9239 8.74072 25.6323 8.75C26.7883 8.73605 27.893 8.27064 28.7105 7.45317C29.528 6.6357 29.9934 5.53099 30.0073 4.375C30.0073 3.21468 29.5464 2.10188 28.7259 1.28141C27.9054 0.460936 26.7926 0 25.6323 0C24.472 0 23.3592 0.460936 22.5387 1.28141C21.7183 2.10188 21.2573 3.21468 21.2573 4.375C21.2375 4.63144 21.2804 4.88884 21.3823 5.125L7.63232 12.125C7.22431 11.6632 6.72465 11.2913 6.16513 11.0331C5.60561 10.7748 4.99847 10.6359 4.38232 10.625C3.222 10.625 2.1092 11.0859 1.28873 11.9064C0.46826 12.7269 0.00732422 13.8397 0.00732422 15C0.00732422 16.1603 0.46826 17.2731 1.28873 18.0936C2.1092 18.9141 3.222 19.375 4.38232 19.375C5.03217 19.3832 5.67508 19.2407 6.26063 18.9588C6.84619 18.6769 7.35848 18.2631 7.75732 17.75L21.3823 24.5C21.2792 24.8656 21.237 25.2457 21.2573 25.625C21.2573 26.4903 21.5139 27.3362 21.9946 28.0556C22.4754 28.7751 23.1587 29.3358 23.9581 29.667C24.7575 29.9981 25.6372 30.0847 26.4858 29.9159C27.3345 29.7471 28.1141 29.3304 28.7259 28.7186C29.3378 28.1067 29.7544 27.3272 29.9233 26.4785C30.0921 25.6299 30.0054 24.7502 29.6743 23.9508C29.3432 23.1513 28.7824 22.4681 28.0629 21.9873C27.3435 21.5066 26.4976 21.25 25.6323 21.25Z"
        fill={color || "white"}
      />
    </svg>
  )
}

export const CollabPlaylistIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "27"}
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M5.0625 17.7188V16.875H3.375V17.7188C3.375 19.2852 3.99726 20.7875 5.1049 21.8951C6.21254 23.0027 7.71482 23.625 9.28125 23.625H11.8125V21.9375H9.28125C8.16237 21.9375 7.08931 21.493 6.29814 20.7019C5.50697 19.9107 5.0625 18.8376 5.0625 17.7188Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M20.25 9.28125V10.125H21.9375V9.28125C21.9375 7.71482 21.3152 6.21254 20.2076 5.1049C19.1 3.99726 17.5977 3.375 16.0313 3.375H13.5V5.0625H16.0313C16.5853 5.0625 17.1339 5.17162 17.6457 5.38363C18.1575 5.59565 18.6226 5.9064 19.0144 6.29814C19.4061 6.68989 19.7169 7.15496 19.9289 7.6668C20.1409 8.17865 20.25 8.72724 20.25 9.28125Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M9.28125 9.28125H4.21875C3.54742 9.28125 2.90359 9.54793 2.42889 10.0226C1.95418 10.4973 1.6875 11.1412 1.6875 11.8125V13.5H3.375V11.8125C3.375 11.5887 3.46389 11.3741 3.62213 11.2159C3.78036 11.0576 3.99497 10.9688 4.21875 10.9688H9.28125C9.50503 10.9688 9.71964 11.0576 9.87787 11.2159C10.0361 11.3741 10.125 11.5887 10.125 11.8125V13.5H11.8125V11.8125C11.8125 11.1412 11.5458 10.4973 11.0711 10.0226C10.5964 9.54793 9.95258 9.28125 9.28125 9.28125Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M6.75 8.4375C7.41751 8.4375 8.07003 8.23956 8.62505 7.86871C9.18007 7.49786 9.61265 6.97076 9.86809 6.35406C10.1235 5.73736 10.1904 5.05876 10.0602 4.40407C9.92993 3.74939 9.60849 3.14802 9.13649 2.67602C8.66448 2.20401 8.06312 1.88258 7.40843 1.75235C6.75374 1.62213 6.07514 1.68896 5.45844 1.94441C4.84174 2.19985 4.31464 2.63244 3.94379 3.18745C3.57294 3.74247 3.375 4.39499 3.375 5.0625C3.375 5.95761 3.73058 6.81605 4.36351 7.44899C4.99645 8.08192 5.85489 8.4375 6.75 8.4375ZM6.75 3.375C7.08376 3.375 7.41002 3.47397 7.68752 3.6594C7.96503 3.84482 8.18132 4.10837 8.30905 4.41672C8.43677 4.72507 8.47019 5.06437 8.40508 5.39172C8.33996 5.71906 8.17924 6.01974 7.94324 6.25574C7.70724 6.49175 7.40656 6.65246 7.07921 6.71758C6.75187 6.78269 6.41257 6.74927 6.10422 6.62155C5.79587 6.49383 5.53232 6.27753 5.3469 6.00003C5.16147 5.72252 5.0625 5.39626 5.0625 5.0625C5.0625 4.61495 5.24029 4.18573 5.55676 3.86926C5.87322 3.55279 6.30245 3.375 6.75 3.375Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M22.7812 21.0938H17.7188C17.0474 21.0938 16.4036 21.3604 15.9289 21.8351C15.4542 22.3098 15.1875 22.9537 15.1875 23.625V25.3125H16.875V23.625C16.875 23.4012 16.9639 23.1866 17.1221 23.0284C17.2804 22.8701 17.495 22.7813 17.7188 22.7813H22.7812C23.005 22.7813 23.2196 22.8701 23.3779 23.0284C23.5361 23.1866 23.625 23.4012 23.625 23.625V25.3125H25.3125V23.625C25.3125 22.9537 25.0458 22.3098 24.5711 21.8351C24.0964 21.3604 23.4526 21.0938 22.7812 21.0938Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M16.876 16.875C16.876 17.5425 17.0739 18.195 17.4448 18.75C17.8156 19.3051 18.3427 19.7376 18.9594 19.9931C19.5761 20.2485 20.2547 20.3154 20.9094 20.1852C21.5641 20.0549 22.1655 19.7335 22.6375 19.2615C23.1095 18.7895 23.4309 18.1881 23.5611 17.5334C23.6914 16.8787 23.6245 16.2001 23.3691 15.5834C23.1136 14.9667 22.681 14.4396 22.126 14.0688C21.571 13.6979 20.9185 13.5 20.251 13.5C19.3559 13.5 18.4974 13.8556 17.8645 14.4885C17.2316 15.1215 16.876 15.9799 16.876 16.875ZM21.9385 16.875C21.9385 17.2088 21.8395 17.535 21.6541 17.8125C21.4687 18.09 21.2051 18.3063 20.8968 18.434C20.5884 18.5618 20.2491 18.5952 19.9218 18.5301C19.5944 18.465 19.2937 18.3042 19.0577 18.0682C18.8217 17.8322 18.661 17.5316 18.5959 17.2042C18.5308 16.8769 18.5642 16.5376 18.6919 16.2292C18.8197 15.9209 19.0359 15.6573 19.3135 15.4719C19.591 15.2865 19.9172 15.1875 20.251 15.1875C20.6985 15.1875 21.1278 15.3653 21.4442 15.6818C21.7607 15.9982 21.9385 16.4274 21.9385 16.875Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
    </svg>
  )
}

export const SinglePlaylistIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "25"}
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M17.524 23.0419H16.4561V25H17.524C19.5067 25 21.4082 24.278 22.8101 22.9927C24.2121 21.7075 24.9997 19.9643 24.9997 18.1467V15.2096H22.8638V18.1467C22.8638 19.445 22.3012 20.6901 21.2998 21.6082C20.2984 22.5262 18.9402 23.0419 17.524 23.0419Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M22.8641 6.85299V7.83203H25V6.85299C25 5.03539 24.2124 3.29224 22.8104 2.007C21.4085 0.721762 19.507 -0.000277042 17.5243 -0.000277042H14.3204L14.3204 1.9578H17.5243C18.9405 1.9578 20.2987 2.47354 21.3001 3.39157C22.3015 4.3096 22.8641 5.55471 22.8641 6.85299Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M8.54435 1.98835L9.6123 1.98835V0.0302734L8.54435 0.0302734C6.56167 0.0302734 4.6602 0.752312 3.25824 2.03755C1.85628 3.32279 1.06866 5.06594 1.06866 6.88354V9.82066H3.20457V6.88354C3.20457 5.58526 3.76715 4.34015 4.76856 3.42212C5.76996 2.50409 7.12815 1.98835 8.54435 1.98835Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M2.13591 17.8101V16.8311H0L0 17.8101C0 19.6277 0.787615 21.3708 2.18958 22.6561C3.59154 23.9413 5.49301 24.6634 7.47569 24.6634H10.6796V22.7053H7.47569C6.05949 22.7053 4.7013 22.1895 3.6999 21.2715C2.69849 20.3535 2.13591 19.1084 2.13591 17.8101Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M16.1868 13.8906H9.77906C8.92934 13.8906 8.11443 14.2001 7.51359 14.7509C6.91274 15.3017 6.5752 16.0488 6.5752 16.8277V18.7858H8.71111V16.8277C8.71111 16.5681 8.82362 16.3191 9.0239 16.1355C9.22418 15.9518 9.49582 15.8487 9.77906 15.8487H16.1868C16.47 15.8487 16.7417 15.9518 16.942 16.1355C17.1422 16.3191 17.2547 16.5681 17.2547 16.8277V18.7858H19.3907V16.8277C19.3907 16.0488 19.0531 15.3017 18.4523 14.7509C17.8514 14.2001 17.0365 13.8906 16.1868 13.8906Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
      <path
        d="M8.71191 8.99623C8.71191 9.77077 8.96245 10.5279 9.43185 11.1719C9.90124 11.8159 10.5684 12.3179 11.349 12.6143C12.1296 12.9107 12.9885 12.9882 13.8171 12.8371C14.6458 12.686 15.4069 12.3131 16.0044 11.7654C16.6018 11.2177 17.0086 10.5199 17.1735 9.76024C17.3383 9.00058 17.2537 8.21317 16.9304 7.49759C16.6071 6.782 16.0595 6.17038 15.357 5.74007C14.6545 5.30976 13.8286 5.08008 12.9837 5.08008C11.8508 5.08008 10.7642 5.49267 9.9631 6.22709C9.16198 6.96152 8.71191 7.9576 8.71191 8.99623ZM15.1196 8.99623C15.1196 9.3835 14.9944 9.76208 14.7597 10.0841C14.525 10.4061 14.1914 10.6571 13.8011 10.8053C13.4108 10.9535 12.9814 10.9922 12.567 10.9167C12.1527 10.8411 11.7721 10.6546 11.4734 10.3808C11.1747 10.107 10.9713 9.75806 10.8889 9.37823C10.8065 8.9984 10.8487 8.6047 11.0104 8.24691C11.1721 7.88912 11.4458 7.58331 11.7971 7.36815C12.1483 7.15299 12.5613 7.03816 12.9837 7.03816C13.5502 7.03816 14.0935 7.24445 14.4941 7.61166C14.8946 7.97887 15.1196 8.47692 15.1196 8.99623Z"
        fill={color || "#FBBA12"}
        fillOpacity="0.7"
      />
    </svg>
  )
}

export const RepeatIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "35"}
      height={height || "24"}
      viewBox="0 0 35 24"
      fill="none"
    >
      <path
        d="M22.7931 1L26.8794 4.82032L22.7931 8.64064"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5177 4.82031H7.81047C6.00588 4.82535 4.27674 5.4978 3.0007 6.69079C1.72465 7.88378 1.00539 9.50037 1 11.1875V12.461"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8967 22.6475L7.81046 18.8272L11.8967 15.0068"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.17264 18.8311H26.8799C28.6845 18.826 30.4136 18.1536 31.6896 16.9606C32.9657 15.7676 33.6849 14.151 33.6903 12.4639V11.1904"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export const ShuffleIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "33"}
      height={height || "24"}
      viewBox="0 0 33 24"
      fill="none"
    >
      <path
        d="M27.9896 15.4189L31.8453 19.0237L27.9896 22.6284"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9896 1L31.8453 4.60471L27.9896 8.20943"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00003 19.0249H7.84304C8.90087 19.0248 9.94234 18.7807 10.875 18.314C11.8077 17.8474 12.6027 17.1727 13.1896 16.3499L16.4227 11.8154"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00003 4.60547H7.84304C8.90087 4.60549 9.94234 4.84965 10.875 5.31629C11.8077 5.78293 12.6027 6.45762 13.1896 7.28047L19.6558 16.3493C20.2427 17.1722 21.0377 17.8469 21.9704 18.3135C22.9031 18.7801 23.9445 19.0243 25.0024 19.0243H29.2749"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2746 4.60547H25.002C23.9442 4.60549 22.9027 4.84965 21.97 5.31629C21.0373 5.78293 20.2423 6.45762 19.6555 7.28047L18.9928 8.21018"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PrevoiusIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "30"}
      height={height || "30"}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M24.7509 25.6675C24.5903 25.6686 24.4322 25.6275 24.2925 25.5483L6.87524 15.4647C6.73416 15.3847 6.61682 15.2688 6.53518 15.1286C6.45354 14.9885 6.41052 14.8293 6.41052 14.6671C6.41052 14.505 6.45354 14.3457 6.53518 14.2056C6.61682 14.0655 6.73416 13.9495 6.87524 13.8696L24.2925 3.7859C24.4319 3.70544 24.59 3.66309 24.7509 3.66309C24.9118 3.66309 25.0699 3.70544 25.2092 3.7859C25.3491 3.86667 25.4652 3.98296 25.5457 4.12301C25.6262 4.26306 25.6682 4.4219 25.6676 4.58343V24.7508C25.6676 24.9939 25.571 25.2271 25.3991 25.399C25.2272 25.5709 24.994 25.6675 24.7509 25.6675ZM9.16699 14.6671L23.8342 23.1649V6.16932L9.16699 14.6671Z"
        fill={color || "#D9D9D9"}
        fillOpacity="0.9"
      />
      <path
        d="M1.8334 3.66699H3.6668V25.6678H1.8334V3.66699Z"
        fill={color || "#D9D9D9"}
        fillOpacity="0.9"
      />
    </svg>
  )
}

export const PauseIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "13"}
      height={height || "30"}
      viewBox="0 0 13 30"
      fill="none"
    >
      <path
        d="M0 0H2.78571V30H0V0ZM13 0V30H10.2143V0H13Z"
        fill={color || "#F2F2F2"}
      />
    </svg>
  )
}

export const PlayIcon = ({ color, width, height, ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "48"}
      height={height || "48"}
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path
        d="M10.5 41.9982C10.1022 41.9982 9.72064 41.8401 9.43934 41.5588C9.15804 41.2775 9 40.896 9 40.4982V7.49816C8.99998 7.23749 9.06789 6.98131 9.19704 6.75488C9.32619 6.52845 9.51212 6.33958 9.7365 6.2069C9.96088 6.07422 10.216 6.0023 10.4766 5.99823C10.7372 5.99416 10.9944 6.05809 11.2229 6.18371L41.2228 22.6837C41.4582 22.8131 41.6545 23.0034 41.7912 23.2346C41.9279 23.4658 42.0001 23.7295 42.0001 23.9981C42.0001 24.2667 41.9279 24.5304 41.7912 24.7616C41.6545 24.9928 41.4582 25.183 41.2228 25.3125L11.2229 41.8125C11.0014 41.9342 10.7527 41.9981 10.5 41.9982Z"
        fill={color || "white"}
      />
    </svg>
  )
}

export const NextIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "30"}
      height={height || "30"}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M4.58349 25.6675C4.34036 25.6675 4.1072 25.5709 3.93528 25.399C3.76337 25.2271 3.66679 24.9939 3.66679 24.7508V4.58343C3.66614 4.4219 3.7082 4.26306 3.78869 4.12301C3.86918 3.98296 3.98524 3.86667 4.12514 3.7859C4.26449 3.70544 4.42257 3.66309 4.58349 3.66309C4.7444 3.66309 4.90248 3.70544 5.04183 3.7859L22.4591 13.8696C22.6002 13.9495 22.7175 14.0655 22.7992 14.2056C22.8808 14.3457 22.9238 14.505 22.9238 14.6671C22.9238 14.8293 22.8808 14.9885 22.7992 15.1286C22.7175 15.2688 22.6002 15.3847 22.4591 15.4647L5.04183 25.5483C4.90213 25.6275 4.74407 25.6686 4.58349 25.6675ZM5.50018 6.16932V23.1649L20.1674 14.6671L5.50018 6.16932Z"
        fill={color || "#D9D9D9"}
        fillOpacity="0.9"
      />
      <path
        d="M25.6676 3.66699H27.501V25.6678H25.6676V3.66699Z"
        fill={color || "#D9D9D9"}
        fillOpacity="0.9"
      />
    </svg>
  )
}

export const QueueIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M0 7.84766V9.80958V23.5431V25.505H15.6954H17.6574V23.5431V9.80958V7.84766H15.6954H1.96193H0ZM1.96193 9.80958H15.6954V23.5431H1.96193V9.80958Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M3.92371 3.92383V5.88576H19.6191V21.5812H21.5811V5.88576V3.92383H19.6191H3.92371Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M7.84784 0V1.96193H23.5433V17.6574H25.5052V1.96193V0H23.5433H7.84784Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const MoreIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "6"}
      height={height || "23"}
      viewBox="0 0 6 23"
      fill="none"
    >
      <path
        d="M2.15625 1.4375C1.7593 1.4375 1.4375 1.7593 1.4375 2.15625V3.59375C1.4375 3.9907 1.7593 4.3125 2.15625 4.3125H3.59375C3.9907 4.3125 4.3125 3.9907 4.3125 3.59375V2.15625C4.3125 1.7593 3.9907 1.4375 3.59375 1.4375H2.15625ZM2.15625 0H3.59375C4.78461 0 5.75 0.965386 5.75 2.15625V3.59375C5.75 4.78461 4.78461 5.75 3.59375 5.75H2.15625C0.965386 5.75 0 4.78461 0 3.59375V2.15625C0 0.965386 0.965386 0 2.15625 0ZM2.15625 10.0625C1.7593 10.0625 1.4375 10.3843 1.4375 10.7812V12.2188C1.4375 12.6157 1.7593 12.9375 2.15625 12.9375H3.59375C3.9907 12.9375 4.3125 12.6157 4.3125 12.2188V10.7812C4.3125 10.3843 3.9907 10.0625 3.59375 10.0625H2.15625ZM2.15625 8.625H3.59375C4.78461 8.625 5.75 9.59039 5.75 10.7812V12.2188C5.75 13.4096 4.78461 14.375 3.59375 14.375H2.15625C0.965386 14.375 0 13.4096 0 12.2188V10.7812C0 9.59039 0.965386 8.625 2.15625 8.625ZM2.15625 18.6875C1.7593 18.6875 1.4375 19.0093 1.4375 19.4062V20.8438C1.4375 21.2407 1.7593 21.5625 2.15625 21.5625H3.59375C3.9907 21.5625 4.3125 21.2407 4.3125 20.8438V19.4062C4.3125 19.0093 3.9907 18.6875 3.59375 18.6875H2.15625ZM2.15625 17.25H3.59375C4.78461 17.25 5.75 18.2154 5.75 19.4062V20.8438C5.75 22.0346 4.78461 23 3.59375 23H2.15625C0.965386 23 0 22.0346 0 20.8438V19.4062C0 18.2154 0.965386 17.25 2.15625 17.25Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

export const PlusIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11.0479 2V20.0959"
        stroke={color || "white"}
        strokeWidth="2.12177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0959 11.0488H2"
        stroke={color || "white"}
        strokeWidth="2.12177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const RecommendedIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "20"}
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <path
        d="M7.91626 5.14648V7.52148H5.41626V5.14648H7.91626ZM9.16626 3.95898H4.16626V8.70898H9.16626V3.95898ZM7.91626 11.4798V13.8548H5.41626V11.4798H7.91626ZM9.16626 10.2923H4.16626V15.0423H9.16626V10.2923ZM14.5829 5.14648V7.52148H12.0829V5.14648H14.5829ZM15.8329 3.95898H10.8329V8.70898H15.8329V3.95898ZM10.8329 10.2923H12.0829V11.4798H10.8329V10.2923ZM12.0829 11.4798H13.3329V12.6673H12.0829V11.4798ZM13.3329 10.2923H14.5829V11.4798H13.3329V10.2923ZM10.8329 12.6673H12.0829V13.8548H10.8329V12.6673ZM12.0829 13.8548H13.3329V15.0423H12.0829V13.8548ZM13.3329 12.6673H14.5829V13.8548H13.3329V12.6673ZM14.5829 11.4798H15.8329V12.6673H14.5829V11.4798ZM14.5829 13.8548H15.8329V15.0423H14.5829V13.8548ZM18.3329 5.54232H16.6663V3.16732H14.1663V1.58398H18.3329V5.54232ZM18.3329 17.4173V13.459H16.6663V15.834H14.1663V17.4173H18.3329ZM1.66626 17.4173H5.83293V15.834H3.33293V13.459H1.66626V17.4173ZM1.66626 1.58398V5.54232H3.33293V3.16732H5.83293V1.58398H1.66626Z"
        fill={color || "#D9D9D9"}
        fillOpacity="0.9"
      />
    </svg>
  )
}

export const AddToPlaylistIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "18"}
      height="15"
      viewBox="0 0 18 15"
      fill="none"
    >
      <path
        d="M0 11.0526H8.57143V12.6316H0V11.0526ZM0 5.52632H17.1429V7.10526H0V5.52632ZM0 0H17.1429V1.57895H0V0ZM13.7143 11.0526V8.68421H15.4286V11.0526H18V12.6316H15.4286V15H13.7143V12.6316H11.1429V11.0526H13.7143Z"
        fill={color || "#D9D9D9"}
        fillOpacity="0.9"
      />
    </svg>
  )
}

export const ArtistCatalogueIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "14"}
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.97873 0C3.12355 0 0 3.12355 0 6.97873C0 10.8339 3.12355 13.9575 6.97873 13.9575C10.8339 13.9575 13.9575 10.8339 13.9575 6.97873C13.9575 3.12355 10.8339 0 6.97873 0ZM2.47632 6.97873H1.57584C1.57584 3.9987 3.9987 1.57584 6.97873 1.57584V2.47632C4.49678 2.47632 2.47632 4.49678 2.47632 6.97873ZM6.97873 9.68018C5.48731 9.68018 4.27729 8.47016 4.27729 6.97873C4.27729 5.48731 5.48731 4.27729 6.97873 4.27729C8.47016 4.27729 9.68018 5.48731 9.68018 6.97873C9.68018 8.47016 8.47016 9.68018 6.97873 9.68018ZM6.97873 6.07825C6.48065 6.07825 6.07825 6.48065 6.07825 6.97873C6.07825 7.47681 6.48065 7.87921 6.97873 7.87921C7.47681 7.87921 7.87921 7.47681 7.87921 6.97873C7.87921 6.48065 7.47681 6.07825 6.97873 6.07825Z"
        fill={color || "#D9D9D9"}
      />
    </svg>
  )
}

// export const SortIcon = ({ color, width, height }: IconProps) => {
//   return (
//     <svg
//       width={width || "30"}
//       height={height || "30"}
//       viewBox="0 0 30 30"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M8.70625 23.2263V0H4.83125V23.2263H0L6.77375 30L13.5475 23.2263H8.70625ZM12.5813 17.42H30V21.295H12.5813V17.42ZM12.5813 11.6138H26.1287V15.4888H12.5813V11.6138ZM12.5813 5.8075H22.2587V9.6825H12.5813V5.8075ZM12.5813 0.00125006H18.3875V3.87625H12.5813V0.00125006Z"
//         fill={color || "white"}
//       />
//     </svg>
//   );
// };

export const CloseOutlineIcon = ({ color, width, height }: IconProps) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 1.38375L18.6162 0L10.0625 8.67875L1.38375 0L0 1.38375L8.67875 10.0625L0 18.7425L1.38375 20L10.0625 11.3212L18.6162 20L20 18.6162L11.3212 10.0625L20 1.38375Z"
        fill={color || "white"}
      />
    </svg>
  )
}

export const SwipeUpIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "17"}
      height="19"
      viewBox="0 0 17 19"
      fill="none"
    >
      <path
        d="M2.70255 9.48664C3.33622 9.48664 3.84992 8.97294 3.84992 8.33927C3.84992 7.70559 3.33622 7.19189 2.70255 7.19189C2.06887 7.19189 1.55518 7.70559 1.55518 8.33927C1.55518 8.97294 2.06887 9.48664 2.70255 9.48664Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M16.2539 11.8642L16.0434 11.5968L16.1915 11.4204L12.1693 8.04541L8.84992 12.0013L5.18137 8.9228L5.14746 8.96319C5.09901 8.88066 5.03903 8.80335 4.96155 8.73832C4.59751 8.43283 4.05473 8.4803 3.74921 8.84435C3.44371 9.20842 3.49119 9.75119 3.85523 10.0567C3.93271 10.1217 4.01925 10.1674 4.10892 10.2008L4.07501 10.2412L8.5448 13.9921L8.28847 13.858L7.25653 13.9099L6.12419 14.9216L6.18678 15.7571L7.27841 15.9065L9.36639 16.1784L10.4505 17.088L10.4179 17.1299L10.6795 17.2802L11.0286 17.5731L11.0809 17.5108L11.1723 17.5633L11.4327 17.5378L12.7047 17.8309L14.5768 17.2654L15.9361 16.05L16.6789 14.1178L16.2539 11.8642Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M15.217 10.5942C16.9324 12.0337 17.1562 14.5912 15.7168 16.3066C14.2773 18.022 11.7198 18.2458 10.0044 16.8064"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0521 10.4558L12.7871 8.55518"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6241 9.82931C11.2724 9.50812 11.2477 8.96265 11.5689 8.61094C11.89 8.25924 12.4355 8.23448 12.7872 8.55567"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4718 11.114C10.1201 10.7928 10.0953 10.2473 10.4165 9.89561C10.7377 9.5439 11.2832 9.51915 11.6349 9.84033"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4473 11.0912C10.1082 10.7568 10.1043 10.2107 10.4388 9.87162C10.7732 9.53248 11.3192 9.52866 11.6584 9.8631"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.26691 12.3666C8.9224 12.0377 8.90972 11.4918 9.2386 11.1473C9.56747 10.8028 10.1134 10.7901 10.4579 11.119"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00129 15.9179C6.52615 15.9511 6.11403 15.5929 6.08076 15.1178C6.04748 14.6427 6.4057 14.2305 6.88081 14.1973"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.93552 10.1447C3.57068 9.8385 3.52306 9.29455 3.82922 8.92967C4.13537 8.5648 4.67933 8.51721 5.0442 8.82337"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25978 12.3598L5.04395 8.82227"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.93555 10.144L9.18109 14.5456"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.60883 14.1782L6.88086 14.1972"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71924 15.9528C8.43315 15.9238 9.22893 16.1345 10.0394 16.8377"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00146 15.9185L7.73921 15.9516"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.52332 8.08034C3.47599 7.92839 3.38598 7.78781 3.25503 7.67792C2.89096 7.37242 2.34816 7.41989 2.04266 7.78399C1.73717 8.14806 1.78464 8.69087 2.14874 8.99637C2.33077 9.14911 2.5575 9.21363 2.77688 9.19444"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.4834 6.28339V1.38232"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 2.49211L2.49194 1"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.98384 2.49196L2.4917 1"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SwipeRightIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "12"}
      height="18"
      viewBox="0 0 12 18"
      fill="none"
    >
      <path
        d="M4.69349 2.46218C5.37341 2.46218 5.92458 1.911 5.92458 1.23109C5.92458 0.551178 5.37341 0 4.69349 0C4.01358 0 3.4624 0.551178 3.4624 1.23109C3.4624 1.911 4.01358 2.46218 4.69349 2.46218Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M11.4643 8.83253H5.92436V3.69398H5.86783C5.9022 3.59728 5.92436 3.4947 5.92436 3.38621C5.92436 2.87626 5.51099 2.46289 5.00105 2.46289C4.4911 2.46289 4.07773 2.87626 4.07773 3.38621C4.07773 3.4947 4.09989 3.59728 4.13427 3.69398H4.07773V9.95839L4.01039 9.65157L3.25601 8.83917L1.64352 8.60625L1 9.23389L1.63004 10.2342L2.84664 12.1378V13.6562L2.78973 13.6583L2.84664 13.977V14.466H2.93398L2.95386 14.5773L3.15441 14.7738L3.79076 16.0214L5.54664 17.1702L7.48305 17.4491L9.58357 16.7271L11.1425 14.8235L11.2172 14.466H11.4643V8.83253Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M7.02271 1.10596C7.02271 1.10596 9.24181 1.10596 11.4609 2.58536L7.02271 1.10596Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M7.02271 1.10596C7.02271 1.10596 9.24181 1.10596 11.4609 2.58536"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3486 0.614746L11.7508 2.84268"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.52271 3.24399L11.7506 2.8418"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4709 13.0942C11.4709 15.4969 9.52314 17.4447 7.12045 17.4447C4.71776 17.4447 2.77002 15.4969 2.77002 13.0942"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4705 12.8624V9.68994"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62207 9.61212C9.64349 9.10153 10.0747 8.70499 10.5853 8.72641C11.0959 8.74784 11.4925 9.17909 11.471 9.68968"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.77246 9.5506C7.79388 9.04004 8.22513 8.64347 8.73573 8.66489C9.24629 8.68631 9.64286 9.11756 9.62144 9.62816"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.77344 9.51522C7.8144 9.00582 8.26058 8.62612 8.76994 8.66709C9.27934 8.70805 9.65904 9.15423 9.61807 9.6636"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.91162 9.42472C5.94434 8.91474 6.38427 8.52784 6.89425 8.56052C7.40423 8.59324 7.79113 9.03317 7.75844 9.54315"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.42968 10.0115C1.07466 9.64391 1.08482 9.05813 1.45239 8.70311C1.81996 8.3481 2.40575 8.35825 2.76076 8.72583"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.06104 3.50976C4.06104 2.99874 4.4753 2.58447 4.98632 2.58447C5.49735 2.58447 5.91161 2.99874 5.91161 3.50976"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.91162 9.41454V3.50977"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.06104 3.50977V10.8568"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.27839 9.31085L2.76074 8.72559"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.89697 10.6245C2.4132 11.1913 2.78881 11.9907 2.76975 13.1418"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.42969 10.0127L1.91123 10.6419"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.47096 1.728C5.56317 1.58433 5.61666 1.41339 5.61666 1.22996C5.61666 0.72001 5.20329 0.306641 4.69334 0.306641C4.18339 0.306641 3.77002 0.72001 3.77002 1.22996C3.77002 1.48492 3.87337 1.71575 4.04046 1.88284"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SwipeLeftIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "15"}
      height="18"
      viewBox="0 0 15 18"
      fill="none"
    >
      <path
        d="M7.86507 2.50065C8.5556 2.50065 9.11539 1.94086 9.11539 1.25032C9.11539 0.559789 8.5556 0 7.86507 0C7.17453 0 6.61475 0.559789 6.61475 1.25032C6.61475 1.94086 7.17453 2.50065 7.86507 2.50065Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M14.742 8.96963H9.11555V3.75081H9.05813C9.09304 3.6526 9.11555 3.54842 9.11555 3.43823C9.11555 2.92032 8.69572 2.50049 8.17781 2.50049C7.65989 2.50049 7.24007 2.92032 7.24007 3.43823C7.24007 3.54842 7.26257 3.6526 7.29749 3.75081H7.24007V10.1131L7.17167 9.80147L6.40551 8.97638L4.76783 8.73982L4.11426 9.37727L4.75414 10.3932L5.98974 12.3265V13.8686L5.93195 13.8708L5.98974 14.1944V14.6911H6.07845L6.09865 14.8042L6.30232 15.0037L6.94862 16.2708L8.73192 17.4375L10.6986 17.7208L12.8319 16.9875L14.4152 15.0542L14.491 14.6911H14.742V8.96963Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M5.72382 1.125C5.72382 1.125 3.47005 1.125 1.21631 2.62751L5.72382 1.125Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M5.72382 1.125C5.72382 1.125 3.47005 1.125 1.21631 2.62751"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.40845 0.625488L1 2.88823"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.26274 3.29715L1 2.88867"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7489 13.2983C14.7489 15.7386 12.7707 17.7167 10.3305 17.7167C7.89028 17.7167 5.91211 15.7386 5.91211 13.2983"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7485 13.0658V9.84375"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8716 9.76464C12.8933 9.24607 13.3313 8.84334 13.8499 8.8651C14.3684 8.88685 14.7712 9.32484 14.7494 9.84341"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9927 9.70214C11.0144 9.1836 11.4524 8.78084 11.971 8.8026C12.4895 8.82435 12.8923 9.26234 12.8705 9.78091"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9937 9.66621C11.0353 9.14886 11.4884 8.76323 12.0057 8.80483C12.5231 8.84644 12.9087 9.29958 12.8671 9.81691"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.10303 9.57155C9.13625 9.0536 9.58306 8.66066 10.101 8.69385C10.619 8.72708 11.0119 9.17388 10.9787 9.69183"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.55092 10.1698C4.19036 9.79646 4.20067 9.20152 4.57399 8.84096C4.94731 8.4804 5.54224 8.49071 5.9028 8.86403"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22363 3.56621C7.22363 3.0472 7.64437 2.62646 8.16338 2.62646C8.68239 2.62646 9.10312 3.0472 9.10312 3.56621"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.10303 9.56538V3.56836"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22363 3.56836V11.0302"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.42856 9.45866L5.90283 8.86426"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.02539 10.793C5.54968 11.3686 5.93116 12.1805 5.91181 13.3496"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.55078 10.1709L5.03985 10.81"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.65524 1.75655C8.74889 1.61064 8.80322 1.43703 8.80322 1.25073C8.80322 0.732816 8.38339 0.312988 7.86548 0.312988C7.34756 0.312988 6.92773 0.732816 6.92773 1.25073C6.92773 1.50967 7.0327 1.74411 7.2024 1.91381"
        stroke="#D9D9D9"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SwipeDownIcon = ({ color, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "18"}
      height="13"
      viewBox="0 0 18 13"
      fill="none"
    >
      <path
        d="M2.73775 3.37264C3.3844 3.37264 3.90861 2.8415 3.90861 2.18632C3.90861 1.53113 3.3844 1 2.73775 1C2.09111 1 1.56689 1.53113 1.56689 2.18632C1.56689 2.8415 2.09111 3.37264 2.73775 3.37264Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M16.5662 5.83128L16.3514 5.55477L16.5024 5.37234L12.3979 1.88281L9.01066 5.9729L5.26707 2.78992L5.23247 2.83168C5.18303 2.74635 5.12182 2.66645 5.04276 2.59922C4.67124 2.28332 4.11735 2.33244 3.8056 2.70884C3.49382 3.08527 3.54227 3.64647 3.91379 3.96233C3.99285 4.02957 4.08117 4.07676 4.1727 4.11128L4.1381 4.15304L8.6997 8.03154L8.43769 7.89264L7.38462 7.94633L6.2291 8.99239L6.29297 9.8562L7.40696 10.0107L9.53768 10.2918L10.6439 11.2323L10.6107 11.2756L10.8777 11.431L11.2339 11.7339L11.2873 11.6695L11.3805 11.7238L11.6463 11.6974L12.9443 12.0004L14.8548 11.4158L16.2418 10.1591L16.9999 8.16126L16.5662 5.83128Z"
        fill={color || "#D9D9D9"}
      />
      <path
        d="M15.5078 4.51855C17.2583 6.00683 17.4866 8.65117 16.0178 10.4248C14.5489 12.1985 11.939 12.4298 10.1885 10.9416"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3392 4.37623L13.0278 2.41113"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8413 3.72688C11.4824 3.39479 11.4571 2.8308 11.7849 2.46716C12.1127 2.10351 12.6693 2.07792 13.0282 2.41"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6655 5.055C10.3066 4.72292 10.2814 4.15893 10.6091 3.79528C10.9369 3.43164 11.4935 3.40604 11.8524 3.73813"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6405 5.03145C10.2945 4.68568 10.2906 4.1211 10.6319 3.77048C10.9731 3.41982 11.5303 3.41588 11.8764 3.76167"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43643 6.34963C9.08487 6.00959 9.07194 5.44516 9.40754 5.08896C9.74315 4.73275 10.3002 4.71965 10.6518 5.05968"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.12418 10.0231C6.63931 10.0575 6.21876 9.68716 6.1848 9.19592C6.15085 8.70468 6.5164 8.27854 7.00123 8.24414"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99554 4.0523C3.62323 3.73575 3.57464 3.17334 3.88706 2.79608C4.19948 2.41885 4.75457 2.36962 5.12691 2.68616"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.42908 6.34363L5.12695 2.68604"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99561 4.05273L9.34852 8.60366"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.74433 8.22461L7.00146 8.24421"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.85693 10.0578C8.58546 10.0279 9.39753 10.2457 10.2246 10.9728"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.12451 10.0229L7.87736 10.0573"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.57484 1.91822C3.52655 1.76111 3.43469 1.61576 3.30106 1.50213C2.92954 1.18627 2.37562 1.23535 2.06387 1.61181C1.75212 1.98824 1.80057 2.54947 2.17212 2.86534C2.35788 3.02327 2.58925 3.08997 2.81312 3.07013"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.53125 4.38574V10.0463"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.04545 8.89697L2.52295 10.4397"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8.89697L2.52265 10.4396"
        stroke="#D9D9D9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArrowUpIcon = ({ width }: IconProps) => {
  return (
    <svg
      width={width || "16"}
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.12 0L8 4.94467L1.88 0L0 1.52227L8 8L16 1.52227L14.12 0Z"
        fill="#FBBA12"
      />
    </svg>
  )
}

export const CancelIcon = ({ width }: IconProps) => {
  return (
    <svg
      width={width || "13"}
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1338 0L12.7931 0.659334L0.659334 12.7922L0 12.1333L12.1338 0Z"
        fill="#FBBA12"
      />
      <path
        d="M0.659334 0L12.7931 12.1329L12.1338 12.7927L0 0.6598L0.659334 0Z"
        fill="#FBBA12"
      />
    </svg>
  )
}

export const FavoriteIcon = ({ color, width, height }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "48"}
    height={height || "48"}
    viewBox="0 0 48 48"
    className="fill__player__svg"
    fill="none"
  >
    <g id="favorite" data-name="favorite icon" transform="translate(0 0)">
      <g id="music-lover" transform="translate(0 0)">
        <path
          id="Path_1199"
          data-name="Path 1199"
          d="M28.251,3.24a8.845,8.845,0,0,0-12.8,0,8.846,8.846,0,0,0-12.8,0A9.474,9.474,0,0,0,0,9.864a9.475,9.475,0,0,0,2.651,6.624l0,0L14.815,28.9a.884.884,0,0,0,1.271,0l12.16-12.407,0,0A9.613,9.613,0,0,0,28.251,3.24ZM26.973,15.161,15.451,26.917,3.929,15.161a7.69,7.69,0,0,1,0-10.6,7.076,7.076,0,0,1,10.239,0l.64.663a.884.884,0,0,0,1.28,0l.64-.663a7.076,7.076,0,0,1,10.24,0A7.69,7.69,0,0,1,26.973,15.161Zm0,0"
          transform="translate(0 -0.5)"
          fill={color || "#eaeaea"}
        />
        <path
          id="Path_1200"
          data-name="Path 1200"
          d="M174.741,118.18l-3.918-2.612a.816.816,0,0,0-1.269.679v4.682a2.992,2.992,0,1,0,1.633,2.665v-5.822l2.65,1.766a.816.816,0,1,0,.905-1.358Zm-6.548,6.775a1.361,1.361,0,1,1,1.361-1.361A1.362,1.362,0,0,1,168.192,124.954Zm0,0"
          transform="translate(-154.69 -107.504)"
          fill={color || "#eaeaea"}
        />
      </g>
    </g>
  </svg>
)

export const ShuffleOutlineIcon = ({ color, width, height }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "48"}
    height={height || "48"}
    viewBox="0 0 48 48"
    className="fill__player__svg"
    fill="none"
  >
    <g
      id="shuffle-icon"
      data-name="shuffle icon"
      transform="translate(-2 -1.59)"
      opacity="0.8"
    >
      <path
        id="Path_1541"
        data-name="Path 1541"
        d="M9.246,8.78H2V6.536h8.457L15.9,14.7l-1.873,1.245ZM26.216,23.361l3.365,3.365H23.917l-4.778-7.167L17.266,20.8l5.44,8.166h6.876l-3.365,3.365L27.8,33.916l6.068-6.068L27.8,21.78ZM29.581,8.78l-3.365,3.365L27.8,13.726l6.068-6.068L27.8,1.59,26.216,3.172l3.365,3.365h-5.72L9.28,26.726H2V28.97h8.424L25.005,8.78Z"
        transform="translate(0 0)"
        fill={color || "#eaeaea"}
      />
    </g>
  </svg>
)

export const DownloadIcon = ({ color, width, height }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "48"}
    height={height || "48"}
    viewBox="0 0 48 48"
    className="fill__player__svg"
    fill="none"
  >
    <g
      id="Icon_feather-download"
      data-name="Icon feather-download"
      transform="translate(-3.75 -3.75)"
    >
      <path
        id="Path_1542"
        data-name="Path 1542"
        d="M36.826,22.5v7.184a3.592,3.592,0,0,1-3.592,3.592H8.092A3.592,3.592,0,0,1,4.5,29.684V22.5"
        transform="translate(0 3.551)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1543"
        data-name="Path 1543"
        d="M10.5,15l8.979,8.979L28.459,15"
        transform="translate(1.184 2.071)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1544"
        data-name="Path 1544"
        d="M18,26.051V4.5"
        transform="translate(2.663 0)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

import styled from "styled-components"
import { FlexibleDiv } from "components"
import Bng from "assets/images/general/udux_bng.png"

export const PaymentHeroWrapper = styled(FlexibleDiv)`
  z-index: 1;
  position: relative;
`

export const BackgroundImageWrapper = styled(FlexibleDiv)`
  width: 100%;
  height: 100vh;
  background: var(--primaryBlack);
  z-index: -1;
  position: absolute;
  top: 0;

  .web__image {
    height: 50%;
    width: 100%;
    object-fit: cover;
  }

  .mobile__image {
    display: none;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    height: calc(100vh - 120px);
  }

  @media (max-width: 600px) {
    height: calc(100vh - 120px);
    .web__image {
      display: none;
    }

    .mobile__image {
      display: block;
      height: 50%;
      width: 100%;
      object-fit: cover;
    }
  }
`

export const PHCWrapper = styled(FlexibleDiv)`
  background: transparent;
  width: 100%;
  height: 100vh;
  padding-top: 100px;

  .content__section {
    padding: 5rem 3.5%;

    .hero__text__section {
      flex: 1;

      .hero__text__1 {
        color: var(--primaryWhite);
        margin: 0;
        font-size: 4rem;
        -webkit-text-stroke: 0.2px var(--primaryWhite);
      }

      .hero__text__2 {
        color: var(--primaryYellow);
        margin: 0;
        font-size: 4rem;

        @media (min-width: 1920px) {
          font-size: 3rem;
        }
      }
    }

    .hero__right__column {
      flex: 1;

      .hero__udux__text {
        font-family: var(--mMedium);
        color: var(--primaryWhite);
        opacity: 0.6;
        margin: 0;
        font-size: 1rem;

        @media (min-width: 1920px) {
          font-size: 1rem;
        }

        @media (max-width: 600px) {
          font-size: 0.85rem;
        }
      }

      .sub__now {
        font-weight: bold;
        font-family: var(--mBold);
        color: var(--primaryYellow);
        opacity: 0.8;
        padding-top: 1rem;
        text-decoration: none;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -3px;
          width: 100%;
          height: 2px;
          background-color: var(--primaryYellow);
          opacity: 0.4;
        }

        @media (max-width: 600px) {
          font-size: 0.85rem;
        }
      }
    }
  }

  .homepage__image__wrapper {
    border: 1px solid var(--primaryGray);
    padding: 0.5rem;
    height: 400px;
    width: 95%;
    margin: 0 auto;

    img {
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .flexible__pricing {
    padding: 5rem;
    background: url(${Bng}) no-repeat center / cover;

    .wrap__color {
      padding: 3rem;
      background-color: var(--primaryBlack);
    }

    .sub__text {
      flex: 1;

      .text__1 {
        color: var(--primaryWhite);
        margin: 0;
        font-size: 2rem;
      }

      .text__2 {
        color: var(--primaryCyan);
        margin: 0;
        font-size: 1.85rem;
      }
    }

    .sub__text__info {
      flex: 1;

      .info__text {
        font-family: var(--mMedium);
        color: var(--primaryWhite);
        opacity: 0.6;
        margin: 0;
        font-size: 0.85rem;

        @media (min-width: 1920px) {
          font-size: 1rem;
        }

        @media (max-width: 600px) {
          font-size: 0.85rem;
        }
      }
    }
  }

  //fix smaller desktop screens
  @media (max-width: 1150px) {
    .content__section {
      padding: 3rem 3.5%;

      .hero__right__column {
        .hero__udux__text {
          font-size: 1rem;
        }
      }

      .hero__text__section {
        .hero__text__1,
        .hero__text__2 {
          font-size: 3em;
        }
      }
    }

    .flexible__pricing {
      padding: 3rem 5rem;
    }
  }

  //ipad screens fix
  @media (min-width: 601px) and (max-width: 912px) {
    .content__section {
      height: 20%;
      width: 100%;
      flex-direction: column;
      align-items: start;

      @media (min-width: 900px) and (max-width: 912px) {
        height: 15%;
      }

      .hero__right__column {
        justify-content: start;

        .hero__udux__text {
          font-size: 1.05rem;
          opacity: 1;

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.15rem;
          }
        }
      }

      .hero__text__section {
        overflow: visible;
        padding-bottom: 2rem;

        .hero__text__1 {
          font-size: 1.8rem;
        }
        .hero__text__2 {
          font-size: 2rem;
        }

        .hero__text__1,
        .hero__text__2 {
          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.8rem;
          }
        }
      }
    }

    .flexible__pricing {
      background: none;
    }
  }

  //mobile devices screens
  @media (max-width: 600px) {
    height: calc(100vh - 120px);
    padding: 2rem 0.5rem;

    .content__section {
      width: 100%;
      flex-direction: column;
      align-items: start;
      gap: 15px;

      @media (max-width: 376px) {
        gap: 10px;
      }

      .hero__right__column {
        height: fit-content;

        .hero__udux__text {
          padding-top: 0.5rem;
          font-size: 13px;
          opacity: 1;

          @media (max-width: 376px) {
            font-size: 11.5px;
          }

          @media (max-width: 281px) {
            font-size: 10px;
          }
        }
      }

      .hero__text__section {
        overflow: visible;

        .hero__text__1,
        .hero__text__2 {
          font-size: 2.2em;
        }

        .hero__text__1 {
          padding-top: 0.5rem;
        }
        .hero__text__2 {
          padding-bottom: 0.5rem;
        }

        @media (max-width: 376px) {
          margin-left: 0;

          .hero__text__1,
          .hero__text__2 {
            font-size: 1.9em;
          }
        }

        @media (max-width: 281px) {
          .hero__text__1,
          .hero__text__2 {
            font-size: 1.5em;
          }
        }
      }
    }

    .homepage__image__wrapper {
      height: 250px;
      border: 1px solid var(--primaryGray);
      padding: 0.2rem;

      @media (max-width: 376px) {
        padding-bottom: 15px;
        padding-top: 20px;
      }

      @media (max-width: 360px) {
        padding-bottom: 31px;
      }
    }

    .flexible__pricing {
      padding: 2rem 1rem;
      flex-wrap: wrap;

      .wrap__color {
        padding: 1rem;
      }

      .sub__text {
        width: 100%;
        flex: none;

        .text__1 {
          font-size: 1.5rem;
        }

        .text__2 {
          font-size: 1.3rem;
        }
      }

      .sub__text__info {
        width: 100%;
        flex: none;

        .info__text {
          font-size: 0.85rem;
        }
      }
    }
  }
`

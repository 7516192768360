import { IconProps } from "types";

export const ShuffleOutlineIcon = ({ color="#D9D9D9", width, height }: IconProps) => (
  <svg
    width={width || "34"}
    height={height || "25"}
    viewBox="0 0 34 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.478 16.1055L32.3337 19.7102L28.478 23.3149"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.478 1.6875L32.3337 5.29221L28.478 8.89693"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.48779 19.7133H8.3308C9.38864 19.7133 10.4301 19.4692 11.3628 19.0025C12.2955 18.5359 13.0905 17.8612 13.6773 17.0383L16.9105 12.5039"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.48779 5.29297H8.3308C9.38864 5.29299 10.4301 5.53715 11.3628 6.00379C12.2955 6.47043 13.0905 7.14512 13.6773 7.96797L20.1436 17.0368C20.7304 17.8597 21.5255 18.5344 22.4582 19.001C23.3908 19.4676 24.4323 19.7118 25.4901 19.7118H29.7627"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.7618 5.29297H25.4892C24.4314 5.29299 23.3899 5.53715 22.4572 6.00379C21.5245 6.47043 20.7295 7.14512 20.1427 7.96797L19.48 8.89768"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

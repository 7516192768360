import { FlexibleDiv, TabContent, TabHeader } from "components";
import { Types, useMainContext } from "context";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getArtist, isEmpty } from "utils";
import { AddToPlaylist } from "../AddToPlaylist";
import { ArtistCatalogue } from "../ArtistCatalogue";
import { FSEMPlayer } from "../FSEMPlayer";
import { ManageQueue } from "../ManageQueue";
import { Recommended } from "../Recommended";
import { WebMusicPlayerWrap } from "./web-music-player.styles";
import { useMediaQuery } from "react-responsive";
import { MdClose } from "react-icons/md";
import {
  AddToPlaylistIcon,
  AddToQueueIcon,
  GotoAlbumIcon,
  SimilarVibesIcon,
} from "assets";

export type PlayerTabRoutes = typeof playerTabRoutes;
const playerTabRoutes = [
  {
    icon: <GotoAlbumIcon />,
    title: "Artist Catalogue",
    hash: "#artist-catalogue",
  },
  {
    icon: <SimilarVibesIcon />,
    title: "Recommended",
    hash: "#recommended",
  },
  {
    icon: <AddToPlaylistIcon />,
    title: "Add To Playlist",
    hash: "#add-to-playlist",
  },
  {
    icon: <AddToQueueIcon />,
    title: "Manage Queue",
    hash: "#manage-queue",
  },
];

export const WebPlayer = () => {
  const navigate = useNavigate();
  const tabContentRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState("#artist-catalogue");
  const {
    state: { player },
    dispatch,
  } = useMainContext();
  const { currentTrack } = player;
  const inTabMode = useMediaQuery({ minWidth: 951, maxWidth: 1221 });

  const artist = getArtist(currentTrack);

  useEffect(() => {
    if (isEmpty(currentTrack)) {
      dispatch({ type: Types.IS_PLAYING, payload: false });
      dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: false });
      navigate("/");
    }
  }, [currentTrack, navigate, dispatch]);

  useEffect(() => {
    if (inTabMode) {
      setActiveTab("");
    } else {
      setActiveTab("#artist-catalogue");
    }
  }, [inTabMode]);

  // Scroll to the top of the page when the component mounts or when activeTab changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  return (
    <WebMusicPlayerWrap>
      <FlexibleDiv className="left__wrap">
        <div className="top__info">
          <h3>Now Playing</h3>
          <p>From Liked Songs</p>
        </div>
        {!inTabMode && <FSEMPlayer />}
        {inTabMode && !activeTab && <FSEMPlayer />}
      </FlexibleDiv>

      <FlexibleDiv flexDir="column" className="right__wrap">
        <TabHeader
          width="100%"
          activeTab={activeTab}
          tabs={playerTabRoutes}
          setActiveTab={(tab) => setActiveTab(tab)}
          noGap
        />

        {inTabMode && activeTab !== "" && (
          <FlexibleDiv className="close__btn__wrap">
            <MdClose onClick={() => setActiveTab("")} className="close-icon" />
          </FlexibleDiv>
        )}

        <TabContent ref={tabContentRef}>
          {activeTab === "#artist-catalogue" && artist && (
            <ArtistCatalogue artist={artist} />
          )}
          {activeTab === "#recommended" && artist && (
            <Recommended artist={artist} />
          )}
          {activeTab === "#add-to-playlist" && <AddToPlaylist />}
          {activeTab === "#manage-queue" && <ManageQueue />}
        </TabContent>
      </FlexibleDiv>
    </WebMusicPlayerWrap>
  );
};

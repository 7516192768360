import { FlexibleDiv, FlexibleDivProps } from "components"
import styled from "styled-components"

export const HeaderWrapper = styled(FlexibleDiv)<FlexibleDivProps>`
  position: fixed;
  height: 100px;
  top: 0;
  z-index: 9999990;
  padding: 0 3.5%;
  background: var(--primaryBlack);
  border-bottom: 1px solid var(--grayLightAlpha);

  .header__section {
    .logo__wrapper {
      width: 80px;

      @media (min-width: 1920px) {
        width: 110px;
      }
    }

    .actions__wrapper {
      @media (max-width: 950px) {
        width: 80%;
        align-items: center;
      }

      @media (max-width: 768px) {
        width: 75%;
      }
      .link__text {
        color: var(--primaryWhite);
        font-family: "Bw ModelicaBold";
        font-weight: 200;
        font-size: 1rem;
        transition: 0.5s;
        text-decoration: none;

        :hover {
          color: #ffa800;
        }

        @media (min-width: 1920px) {
          font-size: 1.3rem;
        }

        @media (max-width: 950px) {
          font-size: 0.9rem;
          margin-right: 10px;
        }

        @media (max-width: 769px) {
          font-size: 0.8rem;
          margin-right: 0;
        }
      }

      .btn__wrap {
        gap: 5%;
      }

      .hello__user {
        justify-content: flex-end;

        p {
          cursor: pointer;
          font-family: var(--mLight);
          text-transform: capitalize;
        }
      }

      .login__btn {
        background: var(--primaryBlack);
        border-radius: 0px;
        border: 1px solid var(--primaryGray);
        width: max-content;
        padding: 0.5rem 1rem;

        span {
          color: var(--primaryWhite);
        }
      }

      .create__acct__btn {
        background: var(--secondaryGreen);
        border-radius: 0px;
        border: 1px solid var(--secondaryGreen);
        width: max-content;
        padding: 0.5rem 1rem;

        ::before {
          left: 0;
          bottom: 0;
          content: "";
          transition: 0.4s;
          position: absolute;
          height: 100%;
          width: 0;
          background-color: #12a24b;
        }

        :hover {
          ::before {
            height: 100%;
            width: 100%;
          }
        }

        @media (min-width: 1920px) {
          padding: 1rem 1.4rem;
        }

        span {
          font-family: var(--mRegular);
          font-size: 1rem;

          @media (max-width: 950px) {
            font-size: 0.9rem;
          }

          @media (min-width: 1920px) {
            font-size: 1.3rem;
          }
        }
      }
    }

    .hamburger__icon {
      display: none;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .mobile__overlay {
    display: none;
  }

  @media (max-width: 600px) {
    height: 70px;
    padding: 0 5%;

    .header__section {
      .logo__wrapper {
        width: 60px;

        @media (max-width: 376px) {
          width: 50px;
        }
      }
      .actions__wrapper {
        display: none;
      }

      .hamburger__icon {
        display: block;
        cursor: pointer;
        width: 25px;

        @media (max-width: 376px) {
          width: 20px;
        }
      }
    }

    .mobile__overlay {
      display: flex;
      background: var(--uduxBackgroundBlack);
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 100vh;
      transition: all 1s;
      z-index: 20;

      .mobile__menu__wrapper {
        display: none;
      }
    }

    input[type="checkbox"]:checked ~ .mobile__overlay {
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      animation-name: menu-animation;
      animation-duration: 1s;
      animation-timing-function: ease;
      padding: 20px;
      z-index: 20;

      .mobile__menu__wrapper {
        display: flex;
        position: relative;
        gap: 15px;
        z-index: 20;

        .link__background {
          background: transparent;
          position: relative;
          transition: all 1s;
          width: fit-content;
          z-index: 9;

          a {
            text-decoration: none;
            font-family: "Bw ModelicaRegular";
            font-size: 2rem;
            color: white;
            padding: 10px;
            z-index: 10;
            transition: all 0.9s;

            :hover {
              color: var(--uduxBackgroundBlack);
              -webkit-text-stroke: 1px var(--uduxBackgroundBlack);
            }
          }

          ::before {
            left: 0;
            bottom: 0;
            content: "";
            transition: 0.4s;
            position: absolute;
            height: 100%;
            width: 0%;
            background-color: #ffa800;
          }

          :hover {
            ::before {
              content: "";
              height: 100%;
              width: 100%;
            }
          }
        }

        .close-button {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;

          span {
            white-space: nowrap;
            display: inline-block;
            font-size: 60px;
            line-height: 40px;
            font-weight: bold;
          }
        }
      }
    }

    @keyframes menu-animation {
      0% {
        opacity: 0;
        transform: scale(0.04) translateY(300%);
      }
      40% {
        transform: scale(0.04) translateY(0);
        transition: ease-out;
      }
      40% {
        transform: scale(0.04) translateY(0);
      }
      60% {
        opacity: 1;
        transform: scale(0.02) translateY(0px);
      }
      61% {
        transform: scale(0.04);
      }
      99.9% {
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%;
      }
      100% {
        transform: scale(2);
        height: 100%;
        padding-bottom: 0;
        border-radius: 0;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 912px) {
    .header__section {
      .actions__wrapper {
        .tablet__wrapper {
          width: 100%;
          justify-content: end;
          .link__text {
            font-size: 1.3rem;
            margin-right: 30px;

            @media (min-width: 910px) and (max-width: 912px) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
`

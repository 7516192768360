import React from "react"
import { Doughnut } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { ChartData, ChartOptions } from "chart.js"
import { FlexibleDiv } from "components"

ChartJS.register(ArcElement, Tooltip, Legend)

interface DoughnutChartProps {
  data: ChartData<"doughnut">
  options?: ChartOptions<"doughnut">
  labelText?: string
  labelDescription?: string
}

export const DoughnutChart: React.FC<DoughnutChartProps> = ({
  data,
  options,
  labelText,
  labelDescription,
}) => {
  const textPlugin = {
    id: "innerLabel",
    afterDraw(chart: any) {
      const { ctx, chartArea } = chart
      if (!chartArea) return

      const { width, height, left, top } = chartArea

      const centerX = left + width / 2
      const centerY = top + height / 2

      ctx.save()
      ctx.font = "bold 28px 'Bw ModelicaBold', sans-serif" // Customize font size and family
      ctx.fillStyle = "white" // Text color
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"

      // First line of text (labelText)
      ctx.fillText(labelText || "", centerX, centerY - 10) // Positioned slightly above center

      // Second line of text (labelDescription)
      ctx.font = "10px 'Bw ModelicaRegular', sans-serif" // Optional: smaller font for description
      ctx.fillText(labelDescription || "", centerX, centerY + 20) // Positioned slightly below center

      ctx.restore()
    },
  }

  return (
    <FlexibleDiv>
      <Doughnut
        data={data}
        options={{
          ...options,
          plugins: {
            ...options?.plugins,
            ...textPlugin,
          },
        }}
        plugins={[textPlugin]}
      />
    </FlexibleDiv>
  )
}

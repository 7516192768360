import styled from "styled-components";

export const CPBannerWrap = styled.div`
  width: 100%;
  height: 100%;

  .swiper {
    width: 100%;
    height: 100%;

    .slider__slide {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img.banner-image {
      cursor: pointer;
        width: 100%;         /* Make image take the full width */
        height: auto;        /* Maintain aspect ratio */
        object-fit: cover;   /* Ensure the image covers the space */
      }
    }
  }
`;

import { IconProps } from "types"

export const FollowIcon = ({ width, height, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 49 49"
    {...props}
  >
    <g id="Group_9947" data-name="Group 9947" transform="translate(-345 -79)">
      <circle
        id="Ellipse_16"
        data-name="Ellipse 16"
        cx="24.5"
        cy="24.5"
        r="24.5"
        transform="translate(345 79)"
        fill="#fdbe28"
      />
      <g
        id="Group_4663"
        data-name="Group 4663"
        transform="translate(358.812 87.291)"
      >
        <g id="add-2">
          <path
            id="Path_1546"
            data-name="Path 1546"
            d="M20.62,20.919H14.835a1.085,1.085,0,1,1,0-2.169H20.62a1.085,1.085,0,1,1,0,2.169Z"
            transform="translate(-0.242 6.56)"
          />
          <path
            id="Path_1547"
            data-name="Path 1547"
            d="M16.835,24.7A1.093,1.093,0,0,1,15.75,23.62V17.835a1.085,1.085,0,0,1,2.169,0V23.62A1.093,1.093,0,0,1,16.835,24.7Z"
            transform="translate(0.651 5.667)"
          />
          <path
            id="Path_1548"
            data-name="Path 1548"
            d="M14.548,16.248h-.116a.785.785,0,0,0-.26,0A7.474,7.474,0,0,1,6.81,8.756,7.367,7.367,0,0,1,8.994,3.463a7.5,7.5,0,1,1,5.6,12.785h-.043ZM14.3,3.419A5.308,5.308,0,0,0,8.98,8.742a5.289,5.289,0,0,0,5.134,5.322,2.407,2.407,0,0,1,.463,0,5.3,5.3,0,0,0,5.062-5.322A5.348,5.348,0,0,0,14.3,3.419Z"
            transform="translate(-3.339 -1.25)"
          />
          <path
            id="Path_1549"
            data-name="Path 1549"
            d="M15.373,27.081A14.2,14.2,0,0,1,7.52,24.9,6.271,6.271,0,0,1,4.41,19.734,6.24,6.24,0,0,1,7.52,14.6a15.247,15.247,0,0,1,15.678,0,1.092,1.092,0,0,1,.3,1.5,1.079,1.079,0,0,1-1.5.3,13.137,13.137,0,0,0-13.277,0,4.135,4.135,0,0,0-2.14,3.326,4.179,4.179,0,0,0,2.14,3.355A12.039,12.039,0,0,0,15.359,24.9a1.092,1.092,0,0,1,.014,2.184Z"
            transform="translate(-4.41 3.739)"
          />
        </g>
      </g>
    </g>
  </svg>
)

import { NewPlaylistIcon } from "assets/icons/NewPlaylistIcon";
import {  Picture } from "types";
import { AlbumJWrap } from "./styles";
import { StandardControls } from "./JumbotronControls";

export type AlbumJumbotronProps = {
  domColors: string[];
  coverArt?: Picture;
  title: string;
  owner?: {
    mainArtist: string;
    artistCover: string;
  },
  releaseDate?: string;
  stats: {
    likes: number;
    songs: number;
    id?: string;
    spins?: number;
    followers?: number;
  };
};
export const AlbumJumbotron = ({
  domColors,
  coverArt,
  title,
  stats,
  owner,
  releaseDate
}: AlbumJumbotronProps) => {
  const hasCoverArt = !!coverArt?.url;

  return (
    <AlbumJWrap dominantColors={domColors}>
      <section className="playlist__content">
        <div className="jumbottron__top">
          <div className="subtile">
            <span>Album</span>
            <span>{releaseDate?.slice(0,4)}</span>
          </div>
          <h2 className="playlist__title">{title}</h2>
        </div>

        <div className="stats__display">
          <p className="curator">
            <div className="main__artist">
              <img src={owner?.artistCover} alt="artist" />
            </div>
            <span className="info">{owner?.mainArtist ?? "uduX"}</span>
          </p>
          <div className="statistics__wrap">
            <p className="statistics">
              <span className="number">{stats.songs}</span>
              <span className="info">Songs</span>
            </p>
            <p className="statistics">
              <span className="number">{stats.likes}</span>
              <span className="info">Likes</span>
            </p>
            <p className="statistics">
              <span className="number">{stats.spins}</span>
              <span className="info">Spins</span>
            </p>
          </div>
        </div>

        <StandardControls />
      </section>

      {!hasCoverArt ? (
        <div className="cover__art">
          <NewPlaylistIcon width={"100"} height={"80"} />
        </div>
      ) : (
        <aside className="cover__art">
          <img src={coverArt?.url} alt="cover art" />
        </aside>
      )}
    </AlbumJWrap>
  );
};

import { useNavigate } from "react-router-dom"
import { truncate } from "utils"
import { ArtisteCardWrap } from "./artiste-card.styles"
import { DominantColors } from "context"
import { Picture } from "types"

export type ArtisteCardProps = {
  id: string
  stageName?: string
  profilePicture: Picture
  profilePictureDominantColors: DominantColors
}
export const ArtisteCard = ({ ...props }: ArtisteCardProps) => {
  const { stageName, profilePicture, id, profilePictureDominantColors } = props
  const navigate = useNavigate()

  return (
    <ArtisteCardWrap
      dominantColors={profilePictureDominantColors.map((ppdc: string) =>
        ppdc.startsWith("#") ? ppdc : `#${ppdc}`
      )}
      onClick={() => navigate(`/artist/${id}`)}
    >
      <div className="picture__box">
        <div className="artbox"></div>
        <div className="image__wrap">
          <img src={profilePicture.url} alt={stageName} />
        </div>
        <div className="artbox"></div>
      </div>

      <div className="name__box">
        <p className="name">{truncate(stageName)}</p>
      </div>
    </ArtisteCardWrap>
  )
}

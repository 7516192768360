import styled  from "styled-components";

export interface TagsWrapProps {
  width?: string;
  height?: string;
  padding?: string
}

export const TagsWrap = styled.div<TagsWrapProps>`
  width: ${({ width }) => width || "100%"};
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  .tag {
    color: #7C7C7C;
    white-space: nowrap;
    cursor: pointer;
    width: fit-content;
    text-align: center;
    border: 1px solid #282828;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: ${({ padding }) => padding|| "8px 16px"};
    transition: all 0.8s ease;

    :not(.active__tag):hover {
      color: var(--uduxYellowPrimary);
    }

    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin: 0;
  }
  .active__tag {
    color: var(--uduxYellowPrimary);
    position: relative;
    cursor: default;
  }
`;

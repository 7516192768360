import React, { Ref } from "react"
import { IoWarningOutline } from "react-icons/io5"
import { truncate } from "utils"
import { AREWrap } from "./auth-response-error.styles"
import { ReadMoreModal } from "./ReadMoreModal"

export type AuthResponseErrorProps = {
  error: string | string[] | Record<string, any>
}
export const AuthResponseError = React.forwardRef(
  ({ error }: AuthResponseErrorProps, ref: Ref<HTMLDivElement>) => {
    const [errorMessages, setErrorMessages] = React.useState<string[]>([])
    const [message, setMessage] = React.useState("")
    const [open, setOpen] = React.useState(false)
    const shouldOpen = Boolean(error)

    const parseMessage = React.useCallback(() => {
      if (typeof error === "string") return error

      if (Array.isArray(error)) {
        setErrorMessages(error)
        return error[0]
      }

      if (error && Array.isArray(error.response?.data?.message)) {
        setErrorMessages(error.response?.data?.message)
        return error.response?.data?.message[0]
      }

      if (error && error?.code?.includes("NETWORK")) {
        return error?.message
      }

      if (error?.response?.data?.message) {
        return error.response?.data?.message
      }

      return "Ops! There was an error."
    }, [error])

    React.useEffect(() => {
      const message = parseMessage()
      setMessage(message)
    }, [error, parseMessage])

    return shouldOpen ? (
      <React.Fragment>
        <AREWrap ref={ref}>
          <IoWarningOutline />
          <p className="message">{truncate(message, 70)}</p>

          {errorMessages.length > 1 && (
            <p className="see__more" onClick={() => setOpen(true)}>
              see more
            </p>
          )}
        </AREWrap>

        <ReadMoreModal
          open={open}
          content={errorMessages}
          handleOk={() => {
            setOpen(false)
          }}
          handleCancel={() => {
            setOpen(false)
          }}
        />
      </React.Fragment>
    ) : null
  }
)

AuthResponseError.displayName = "AuthResponseError"

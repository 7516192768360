import styled from "styled-components"
import { FlexibleDiv, FlexibleDivProps } from "../Box"

export const TPCStyles = styled(FlexibleDiv)<FlexibleDivProps>`
  background-color: transparent;
  flex-direction: column;

  .song__progress__bar {
    margin-top: 3px;
    position: relative;
    height: 4px;
  }

  .track__inner {
    height: 100%;
    position: absolute;
    background-color: var(--yellowPrimary);
    border-radius: 3px;
  }
`

import { IconProps } from "types";



export const AddSongIcon = ({ color, width, height } : IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 35 30"
    fill="none"
  >
    <g
      id="add-song-icon"
      data-name="Add Song Icon"
      transform="translate(2.5 3)"
    >
      <path
        id="Path_1526"
        data-name="path 1526"
        d="M21.387,11.557a.867.867,0,0,0-.739-.977,3.432,3.432,0,0,1-.995-.294,3.578,3.578,0,0,1-1.638-1.591.866.866,0,0,0-1.635.4v6.841a3.973,3.973,0,1,0,1.733,3.278V11.38a5.094,5.094,0,0,0,.805.471,5.176,5.176,0,0,0,1.493.443.865.865,0,0,0,.976-.738Zm-7.251,9.9A2.243,2.243,0,1,1,16.38,19.21a2.246,2.246,0,0,1-2.243,2.243Z"
        transform="translate(-1.444 -1.37)"
        fill={color || "#eaeaea"}
      />
      <path
        id="Path_1527"
        data-name="Path 1527"
        d="M22.948,1.1H7.921A6.828,6.828,0,0,0,1.1,7.921V22.948a6.828,6.828,0,0,0,6.821,6.821H19.1a.866.866,0,1,0,0-1.732H7.921a5.1,5.1,0,0,1-5.089-5.089V7.921A5.1,5.1,0,0,1,7.921,2.832H22.948a5.1,5.1,0,0,1,5.089,5.089V19.1a.866.866,0,1,0,1.732,0V7.921A6.828,6.828,0,0,0,22.948,1.1Z"
        transform="translate(-1.1 -1.1)"
        fill={color || "#eaeaea"}
      />
      <path
        id="Path_1528"
        data-name="Path 1528"
        d="M28.39,24.332H26.065V22.007a.866.866,0,1,0-1.732,0v2.325H22.008a.866.866,0,0,0,0,1.732h2.325v2.325a.866.866,0,0,0,1.732,0V26.064H28.39a.866.866,0,0,0,0-1.732Z"
        transform="translate(-1.861 -1.861)"
        fill={color || "#eaeaea"}
      />
    </g>
  </svg>
);

import React from "react"
import { ActionBoxWrapper } from "./ActionBox.styles"
import { Link } from "react-router-dom"
import { SpinLoader } from "../Loader"

export type ActionBoxProps = {
  setHoverImage?: () => void
  redirectURL?: () => void
  boxIcon?: React.ReactNode
  text?: string
  loading?: boolean
  textURL?: string
}

export const ActionBox = ({
  setHoverImage,
  redirectURL,
  boxIcon,
  text = "", // provide a default value in case text is undefined
  textURL,
  loading,
}: ActionBoxProps) => {
  return (
    <ActionBoxWrapper
      className="card__box"
      alignItems="center"
      onClick={redirectURL}
      onMouseEnter={setHoverImage}
      loading={loading}
    >
      <div className="pad__content">
        <div className="card__icon__box">{boxIcon}</div>
      </div>
      {loading ? (
        <div className="card__text__box--loading">
          <SpinLoader />
        </div>
      ) : (
        <div className="card__text__box">
          {textURL ? (
            <Link to={textURL} className="text" dangerouslySetInnerHTML={{ __html: text as string }} />
          ) : (
            <div className="text" dangerouslySetInnerHTML={{ __html: text as string }} />
          )}
        </div>
      )}
    </ActionBoxWrapper>
  );
};


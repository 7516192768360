import styled from "styled-components";
import { FlexibleDiv, FlexibleDivProps } from "../Box";

export interface TabHeaderWrapProps {
  top?: string;
  margin?: string;
  position?: string;
  padding?: string;
  fsemMobileHeader?: boolean;
  noGap?: boolean;
  pad_link?: string;
  borderBottom?: string;
  background?: string;
  width?: string;
}
export interface CollabPlaylistTHWrapProps extends FlexibleDivProps {
  top?: string;
  margin?: string;
  position?: string;
  padding?: string;
  fsemMobileHeader?: boolean;
  noGap?: boolean;
  pad_link?: string;
}

export const TabHeaderWrap = styled.div<TabHeaderWrapProps>`
  justify-content: flex-start;
  display: flex;
  width: 100%;
  background-color: ${({ background }) => background || "var(--primaryBlack)"};
  max-width: ${({ width }) => width || "100%"};
  overflow-y: hidden;
  @media screen and (max-width: 994px) {
    background-color: #0f0f0f;
  }

  .navigation__routes__web {
    border: 0;
    column-gap: 10px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${({ background }) => background || "var(--primaryBlack)"};
    overflow-y: auto;
    @media screen and (max-width: 994px) {
      column-gap: 5px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .tab__links {
      color: var(--uduxWhiteSecondary);
      cursor: pointer;
      width: fit-content;
      text-align: center;
      font-size: 12px;
      flex: 1;
      padding: 0.6rem 0.2rem;
      transition: all 0.8s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      :not(.active__tab):hover {
        color: var(--primaryYellow);
        svg {
          fill: var(--primaryYellow);
          path {
            fill: var(--primaryYellow);
          }
        }
      }

      p {
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: 0.8rem;
        margin: 0;

        @media screen and (max-width: 994px) {
          flex-direction: column;
          justify-content: center;
          row-gap: 3px;
          font-size: 0.7rem;
        }

        @media screen and (max-width: 765px) {
          font-size: 0.6rem;
        }

        .icon__wrap {
          padding-right: 0.5rem;
          @media screen and (max-width: 994px) {
            padding-right: 0rem;
          }
        }
      }
    }
    .active__tab {
      border-bottom: 1px solid var(--primaryYellow);
      color: var(--primaryYellow);
      position: relative;
      cursor: default;

      svg {
        fill: var(--primaryYellow);
        path {
          fill: var(--primaryYellow);
        }
      }
    }
  }
`;
export const LoginTabHeaderWrap = styled(FlexibleDiv)<TabHeaderWrapProps>`
  justify-content: flex-start;
  top: ${({ top }) => top || "0"};
  background-color: var(--uduxBlackPrimary);
  margin: ${({ margin }) => margin || "0rem auto 2rem auto"};
  position: ${({ position }) => position || "unset"};
  max-width: ${({ width }) => width || "100%"};
  overflow-y: hidden;

  .navigation__routes__web {
    width: 250px;
    column-gap: ${({ noGap }) => (noGap ? "0" : "2%")};
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #030303;
    overflow-y: auto;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .tab__links {
      color: var(--uduxWhiteSecondary);
      white-space: nowrap;
      cursor: pointer;
      width: 60rem;
      text-align: center;
      border-radius: 4px;
      font-size: 13px;
      padding: ${({ pad_link }) => pad_link || "0.8rem 0.2rem"};
      transition: all 0.8s ease;

      :not(.active__tab):hover {
        color: var(--uduxYellowPrimary);
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }
    .active__tab {
      background-color: #161616;
      color: var(--uduxYellowPrimary);
      position: relative;
      cursor: default;
    }
  }
`;
export const TabContentWrap = styled(FlexibleDiv)`
  width: 100%;
  max-width: ${({ width }) => width || "100%"};
`;

export const CollabPlaylistTHWrap = styled(
  FlexibleDiv
)<CollabPlaylistTHWrapProps>`
  justify-content: flex-start;
  top: ${({ top }) => top || "0"};
  background-color: var(--primaryBlack);
  margin: ${({ margin }) => margin || "0rem auto 2rem auto"};
  position: ${({ position }) => position || "unset"};
  max-width: ${({ width }) => width || "100%"};
  overflow-y: hidden;
  border-bottom: 2px solid var(--borderLighter);

  .navigation__routes__web {
    border: 0;
    width: auto;
    margin: 0 auto;
    column-gap: 2%;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab__links {
      color: var(--uduxWhiteSecondary);
      cursor: pointer;
      width: 8rem;
      text-align: center;
      font-size: 13px;
      padding: 0.6rem 0.2rem;
      transition: all 0.8s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      :not(.active__tab):hover {
        color: var(--primaryYellow);
      }

      p {
        display: flex;
        align-items: center;

        .icon__wrap {
          padding-right: 0.5rem;
        }

        font-size: 14px;
        margin: 0;
      }
    }
    .active__tab {
      border-bottom: 1px solid var(--primaryYellow);
      color: var(--primaryYellow);
      position: relative;
      cursor: default;
    }
  }

  @media only screen and (max-width: 600px) {
    .navigation__routes__web {
      width: 100%;
    }
  }
`;

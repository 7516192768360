import { ActionProps } from "context/reducer"
import { Types } from "context/types"
import { ActiveCategoryProps } from "pages/Search/components/SearchFilter/data-helper"

export type SearchState = {
  searchQuery: string
  searchTracks: []
  filterSearch: keyof ActiveCategoryProps
  filterActiveSearch: keyof ActiveCategoryProps
}
export const searchReducer = (
  state: SearchState,
  { type, payload }: ActionProps
) => {
  switch (type) {
    case Types.FILTER_SEARCH:
      return {
        ...state,
        filterSearch: payload,
      }

    case Types.FILTER_ACTIVE_SEARCH:
      return {
        ...state,
        filterActiveSearch: payload,
      }

    case Types.SAVE_SEARCH_TRACK_RESULT:
      return {
        ...state,
        searchTracks: payload,
      }

    default:
      return state
  }
}

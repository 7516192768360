import styled from "styled-components"

export const CameraIconWrap = styled.div`
  background-color: var(--primaryWhite);
  transition: all 0.1s ease-in;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--uduxYellowPrimary);
  }
`

export const CameraIcon = () => (
  <CameraIconWrap>
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82907 4.35956C7.91773 4.23631 8.0162 4.08542 8.14177 3.88196C8.21213 3.76794 8.46885 3.34328 8.51082 3.27464C9.54822 1.57769 10.3023 0.798828 11.6621 0.798828H16.6678C18.0276 0.798828 18.7817 1.57769 19.8191 3.27464C19.861 3.34328 20.1178 3.76794 20.1881 3.88196C20.3137 4.08542 20.4122 4.23631 20.5008 4.35956C20.5593 4.44085 20.6104 4.50543 20.652 4.55306H24.1762C26.2496 4.55306 27.9305 6.23389 27.9305 8.3073V19.57C27.9305 21.6434 26.2496 23.3242 24.1762 23.3242H4.15365C2.08024 23.3242 0.399414 21.6434 0.399414 19.57V8.3073C0.399414 6.23389 2.08024 4.55306 4.15365 4.55306H7.67787C7.71953 4.50543 7.7706 4.44085 7.82907 4.35956ZM4.15365 7.05589C3.46251 7.05589 2.90224 7.61616 2.90224 8.3073V19.57C2.90224 20.2611 3.46251 20.8214 4.15365 20.8214H24.1762C24.8674 20.8214 25.4276 20.2611 25.4276 19.57V8.3073C25.4276 7.61616 24.8674 7.05589 24.1762 7.05589H20.422C19.5906 7.05589 19.03 6.60088 18.469 5.82106C18.34 5.64164 18.2121 5.44572 18.0582 5.1964C17.9805 5.0705 17.72 4.63951 17.6837 4.58008C17.1176 3.6542 16.7763 3.30165 16.6678 3.30165H11.6621C11.5536 3.30165 11.2122 3.6542 10.6462 4.58008C10.6099 4.63951 10.3493 5.0705 10.2716 5.1964C10.1178 5.44572 9.98992 5.64164 9.86085 5.82106C9.29992 6.60088 8.73925 7.05589 7.90788 7.05589H4.15365ZM22.9248 10.8101C23.616 10.8101 24.1762 10.2498 24.1762 9.55871C24.1762 8.86757 23.616 8.3073 22.9248 8.3073C22.2337 8.3073 21.6734 8.86757 21.6734 9.55871C21.6734 10.2498 22.2337 10.8101 22.9248 10.8101ZM14.1649 19.57C10.7093 19.57 7.90788 16.7686 7.90788 13.3129C7.90788 9.85727 10.7093 7.05589 14.1649 7.05589C17.6206 7.05589 20.422 9.85727 20.422 13.3129C20.422 16.7686 17.6206 19.57 14.1649 19.57ZM14.1649 17.0672C16.2383 17.0672 17.9192 15.3864 17.9192 13.3129C17.9192 11.2395 16.2383 9.55871 14.1649 9.55871C12.0915 9.55871 10.4107 11.2395 10.4107 13.3129C10.4107 15.3864 12.0915 17.0672 14.1649 17.0672Z"
        fill="black"
      />
    </svg>
  </CameraIconWrap>
)

import styled from "styled-components"
import SidebarBG from "assets/images/general/sidebar_bng.png"

export interface SidebarWrapProps {
  isPlayerActive?: boolean
  isMenuActive?: boolean
}

export const MobileNav = styled.div`
  position: fixed;
  z-index: 10 !important;
  bottom: 0;
  background: linear-gradient(89.79deg, #000000ce -100%, #000000ef 130.38%),
    url(${SidebarBG}) no-repeat center / cover;
  width: 100vw;
  height: 70px;
`

export const SidebarWrap = styled.div<SidebarWrapProps>`
  width: ${({ isMenuActive }) =>
    isMenuActive ? "262px" : "50px"}; /* Sidebar slides in/out */
  max-width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  background-color: var(--black);
  z-index: 999;
  transition: all 100ms cubic-bezier(0.155, 0.33, 0.885, 0.27);

  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
  ::-webkit-scrollbar-thumb:active {
    display: none;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  ::-webkit-scrollbar-track:hover {
    display: none;
  }
  ::-webkit-scrollbar-track:active {
    display: none;
  }
  .udux__sidebar__logo {
    width: 60px;
    height: 60px;
    transition: all 200ms cubic-bezier(0.155, 0.33, 0.885, 0.27);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 950px) {
    padding-left: 20px;
    width: ${({ isMenuActive }) =>
      isMenuActive
        ? "100%"
        : "262px"}; /* Full-width for smaller screens when active */
    left: ${({ isMenuActive }) =>
      isMenuActive ? "0" : "-262px"}; /* Sidebar slides in/out */
    top: 88px; /* Adjust top position to account for the header */
  }

  .sidebar__subwrap {
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 400px;
    @media (max-width: 950px) {
      padding-top: 20px;
    }
    .sidebar__nav__sectionitems {
      padding: ${({ isMenuActive }) =>
        isMenuActive ? " 10px 20px 10px 20px" : " 10px 20px 10px 0px"};
      margin: 5px 0 6px 0;
      cursor: pointer;
      box-sizing: border-box;
      background: transparent;
      transition: all 100ms cubic-bezier(0.155, 0.33, 0.885, 0.27);

      img {
        width: 30px;
        transition: all 0.3s ease;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
      }

      svg {
        transition: all 0.5s ease;
        color: var(--uduxWhitePrimary);
        min-width: fit-content;
      }
      path {
        transition: all 0.3s ease;
      }
      .nav__section__content {
        margin: 0;
        flex: 1;
        transition: all 0.3s ease;
        margin: 0 14px;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Inter", sans-serif;
        font-size: 1rem;
        font-weight: 500;
        color: #929292;
        white-space: nowrap;
        padding: 0;
        display: ${({ isMenuActive }) =>
          isMenuActive ? "block" : "none"}; /* Sidebar slides in/out */
        transition: all 100ms cubic-bezier(0.155, 0.33, 0.885, 0.27);
      }

      &.sidebar__activenav__sectionitems {
        svg {
          path {
            fill: var(--uduxYellowPrimary);
          }
        }

        .nav__section__content {
          color: var(--yellowPrimary);
        }
      }

      &:hover {
        .nav__section__content {
          color: var(--yellowPrimary);
        }
        svg {
          path {
            fill: var(--uduxYellowPrimary);
          }
        }
      }
    }

    .sidebar__bodywrap {
      height: ${({ isPlayerActive }) =>
        isPlayerActive ? "calc(100vh - 200px)" : "calc(100vh - 90px)"};
      justify-content: flex-start;

      .sidebar__navwrap {
        padding: 0px 25px 0px 0px;

        .searchinput__wrap {
          transition: all 0.3s ease;
          overflow: hidden;
        }
        .searchicon {
          margin-bottom: -10px;
        }

        .sidebar__nav__sections {
          padding-bottom: 20px;

          .sidebar__section__title {
            color: var(--uduxWhitePrimary);
            margin: 0;
            transition: all 0.3s ease;
            margin: 0px;
            font-family: "Bw Modelica", sans-serif;
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap;
            display: ${({ isMenuActive }) =>
              isMenuActive ? "block" : "none"}; /* Sidebar slides in/out */
            transition: all 100ms cubic-bezier(0.155, 0.33, 0.885, 0.27);
          }

          .playlist__btn {
            margin-top: 20px;
            white-space: nowrap;
            border-style: solid;
            border-image-slice: 1;
            display: flex;
            column-gap: 10px;
            align-items: center;
            color: #929292;
            width: 166px;
            font-family: "Inter", sans-serif;
            padding: 10px 20px 10px 20px;
            border-image-source: linear-gradient(
              90deg,
              #ec1b52 0%,
              #fcb816 100%
            );
            border-radius: 0;
            visibility: ${({ isMenuActive }) =>
              isMenuActive ? "visible" : "hidden"}; /* Sidebar slides in/out */
          }

          .list__image__wrap {
            width: 150px;
            height: 50px;
            visibility: ${({ isMenuActive }) =>
              isMenuActive ? "visible" : "hidden"}; /* Sidebar slides in/out */
            @media screen and (max-width: 950px) {
              width: 140px;
              height: 40px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .sidebar__activenav__sectionitems {
            position: relative;

            .nav__section__content {
              color: var(--yellowPrimary);
            }
            .fill__player__svg {
              path {
                fill: var(--uduxYellowPrimary);
              }
            }
            .stroke__player__svg {
              path {
                stroke: var(--uduxYellowPrimary);
              }
            }
            :hover {
              .nav__section__content,
              svg {
                opacity: 1;
              }
            }
          }
        }

        .sidebar__nav__subsection__children {
          padding-left: 5%;
        }
      }
    }
  }
`

import { Form, Select } from "antd"
import {
  BorderedSelectField,
  Button,
  CheckBox,
  DateSequenceSelector,
  FlexibleDiv,
  PhoneInput,
} from "components"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import dayjs from "dayjs"
import { useState } from "react"
import { SignUpPayload } from "types"
import { capitalize, validateBasicField } from "utils"
import SignUpImage from "assets/images/payment/payment-sign-up.png"
import { PaymentRegisterWrapper } from "../../paymentRegister.styles"
import { CheckboxProps } from "antd/lib"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

export type StepTwoProps = {
  goBack: () => void
  registerUser: (payload: SignUpPayload) => void
}

export const StepTwo = ({ goBack, registerUser }: StepTwoProps) => {
  const [signUpForm] = Form.useForm()
  const [dob, setDob] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [countryCode, setCountryCode] = useState("")

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false)

  const setRegistrationData = (values: SignUpPayload) => {
    registerUser({
      ...values,
      dateOfBirth: dob,
      acceptedTermsAndConditions,
      countryCode,
      phoneNumber: `${countryCode}${phoneNumber}`,
    })
  }

  const checkboxChange: CheckboxProps["onChange"] = ({ target }) =>
    setAcceptedTermsAndConditions(target.checked)

  return (
    <PaymentRegisterLayout
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      galleryImage={SignUpImage}
      customGoBack={goBack}
    >
      <PaymentRegisterWrapper>
        <Form
          form={signUpForm}
          onFinish={setRegistrationData}
          className="form_content"
        >
          <FlexibleDiv className="signup__form__box">
            <FlexibleDiv className="select_box gender__input__wrap">
              <FlexibleDiv
                flexWrap="no-wrap"
                className="select__gender"
                alignItems="center"
              >
                <BorderedSelectField
                  radius="0px"
                  name="gender"
                  label="Gender"
                  margin="0 0 10px 0"
                  borderwidth="0.5px"
                  placeholder="What do you identify as?"
                  validator={validateBasicField}
                >
                  {["male", "female", "not-specified"].map((value, idx) => (
                    <Select.Option value={value} key={idx}>
                      {capitalize(value)}
                    </Select.Option>
                  ))}
                </BorderedSelectField>
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv className="phone__input__wrap">
              <PhoneInput
                name="phoneNumber"
                onChange={e => {
                  setPhoneNumber(e)
                }}
                onSetCountryCode={code => setCountryCode(code)}
                bordered
              />
            </FlexibleDiv>

            <FlexibleDiv>
              <DateSequenceSelector
                label="Date of birth"
                handleDateChange={dob => {
                  setDob(dayjs(dob).format("DD/MM/YYYY"))
                }}
              />
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            className="terms__and__conditions"
            flexDir="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <CheckBox
              color="var(--dampWhite)"
              name="acceptedTermsAndConditions"
              checked={acceptedTermsAndConditions}
              onChange={checkboxChange}
            >
              <span className="tac__text">
                I accept that on occasion I can be contacted by uduX
              </span>
            </CheckBox>

            <p className="tac">
              uduX is a personalised music device. By clicking on the sign up
              button, you agree to our{" "}
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions of Use
              </a>
              .
            </p>
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="220px"
              height="50px"
              font_size="16px"
              type="submit"
              color="var(--black)"
              disabled={!acceptedTermsAndConditions}
              contained
              bold
              ltr
            >
              <span>Create Account</span>
            </Button>
          </FlexibleDiv>
        </Form>
      </PaymentRegisterWrapper>
    </PaymentRegisterLayout>
  )
}

export default StepTwo

import { FlexibleDiv, FlexibleDivProps } from "components"
import styled from "styled-components"

export const FooterView = styled.footer`
  background: var(--primaryYellow);
  padding: 2rem 3%;
  width: 100%;
  margin-top: 8rem;
  color: var(--primaryBlack);

  @media (max-width: 600px) {
    margin-top: 3rem;
    padding: 0 3.5%;
    padding-top: 1.4rem;
  }
`

export const FooterContent = styled(FlexibleDiv)<FlexibleDivProps>`
  .sections {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    width: 100%;
    align-items: start;

    a {
      text-decoration: none;
      color: var(--primaryBlack);
    }
  }

  hr {
    border-color: var(--primaryBlack);
    opacity: 0.5;
    width: 100%;
  }

  .logo {
    fill: var(--primaryBlack);
    width: 6rem;
    height: 4rem;
  }

  .logo-container {
    width: 18%;
  }

  .description {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .copyright {
    margin-top: 1.8rem;
    width: 100%;
  }

  .socials {
    display: flex;
    justify-content: space-between;
    max-width: 10rem;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      transition: all 0.3s ease;
      cursor: pointer;

      &:active,
      &:focus,
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .sections {
      flex-direction: column;
      gap: 2rem;
    }

    .logo {
      width: 7.1rem;
      height: 2.3rem;
    }

    .logo-container {
      width: unset;

      p {
        max-width: 25.6rem;
        font-size: 1rem;
      }
    }
    .socials {
      max-width: 15rem;

      svg {
        width: 2rem;
      }
    }

    .copyright {
      margin: 1.8rem auto;
      width: 100%;
    }
  }
`

export const Section = styled.div<any>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* gap: 5px; */

  .title {
    font-weight: 800;
    font-size: 1.4rem;
    font-family: "Bw ModelicaBold", sans-serif;
    margin-bottom: 1rem;

    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  a {
    margin-bottom: 0.8rem;
    color: var(--primaryWhite);
    font-size: 1rem;
    opacity: 0.6;
    text-decoration: none;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
`

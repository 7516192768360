import { IconProps } from "types";

export const LikeIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "25"}
    height={height || "23"}
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0602 4.31276L12.501 5.13658L12.9419 4.31276C13.9773 2.37781 16.0151 1.0625 18.3609 1.0625C21.7522 1.0625 24.5021 3.81233 24.5021 7.20368C24.5021 11.4666 21.5259 15.1475 18.4257 17.8117C16.8879 19.1332 15.3478 20.1803 14.1909 20.8973C13.613 21.2555 13.1321 21.5304 12.7968 21.7153C12.6797 21.7799 12.5805 21.8334 12.501 21.8756C12.4216 21.8334 12.3223 21.7799 12.2053 21.7153C11.87 21.5304 11.3891 21.2555 10.8112 20.8973C9.65431 20.1803 8.11413 19.1332 6.5764 17.8117C3.47622 15.1475 0.5 11.4666 0.5 7.20368C0.5 3.81233 3.24983 1.0625 6.64118 1.0625C8.98695 1.0625 11.0247 2.37781 12.0602 4.31276Z"
      stroke={color}
    />
  </svg>
);

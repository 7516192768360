import React, { ForwardedRef } from "react"
import { BsInfoCircleFill } from "react-icons/bs"
import { IoCloseCircleOutline, IoWarningOutline } from "react-icons/io5"
import { MdCheckCircle } from "react-icons/md"
import { truncate } from "utils"
import { ReadMoreModal } from "./ReadMoreModal"
import { ARBWrap, ARBMain } from "./api-response-banner.styles"
import { APIResponseTypes } from "types"

export type ApiResponseBannerProps = {
  type?: APIResponseTypes
  response?: any // You might want to provide a more specific type based on your API response structure
  message?: string
  open?: boolean
  onClose: () => void
}

const ApiResponseBannerRefEl = React.forwardRef(
  (
    { type, response, message, open, onClose }: ApiResponseBannerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [errorMessages, setErrorMessages] = React.useState<any[]>([])
    const [__message, __setMessage] = React.useState("")
    const [__open, __setOpen] = React.useState(false)

    React.useEffect(() => {
      const parseMessage = () => {
        if (type === "ERROR") {
          if (typeof response === "string") return response

          if (Array.isArray(response)) {
            setErrorMessages(response)
            return response[0]
          }

          if (response && Array.isArray(response.response?.data?.message)) {
            setErrorMessages(response.response?.data?.message)
            return response.response?.data?.message[0]
          }

          if (response && response?.code?.includes("NETWORK")) {
            return response?.message
          }

          if (response?.response?.data?.message) {
            return response.response?.data?.message
          }

          return "Ops! There was an error."
        } else if (type === "SUCCESS") {
          if (typeof response === "string") {
            return response
          }
          if (response?.message) {
            return response.message
          }

          if (response?.data) {
            return response.data.message
          }
        } else if (type === "INFO") {
          return message
        }
      }
      __setMessage(parseMessage)
    }, [type, message, response])

    React.useEffect(() => {
      const timer = setTimeout(() => {
        onClose()
      }, 4000)

      return () => clearTimeout(timer)
    }, [open, onClose])

    return open ? (
      <ARBMain>
        <ARBWrap ref={ref} type={type}>
          {type === "ERROR" && <IoWarningOutline />}
          {type === "SUCCESS" && <MdCheckCircle />}
          {type === "INFO" && <BsInfoCircleFill />}

          <p className="message">{truncate(__message, 70)}</p>

          {errorMessages.length > 1 && (
            <p className="see__more" onClick={() => __setOpen(true)}>
              see more
            </p>
          )}

          {onClose && <IoCloseCircleOutline onClick={onClose} />}
        </ARBWrap>

        <ReadMoreModal
          open={__open}
          content={errorMessages}
          handleOk={() => {
            __setOpen(false)
          }}
          handleCancel={() => {
            __setOpen(false)
          }}
        />
      </ARBMain>
    ) : null
  }
)
ApiResponseBannerRefEl.displayName = "ApiResponseBanner"

export const ApiResponseBanner = React.memo(ApiResponseBannerRefEl)

import { IconProps } from "types"

export const ExpandOutlineIcon = ({ width, height, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "22"}
    height={height || "22"}
    viewBox="0 0 40 40"
    {...props}
  >
    <g id="expand" transform="translate(5 5)">
      <path
        id="Path_1578"
        data-name="Path 1578"
        d="M16.165,314.078,10.651,319.6v-4.954H7.561v10.229H17.79v-3.09H12.836l5.514-5.519Z"
        transform="translate(-7.092 -296.274)"
        fill="#fff"
      />
      <path
        id="Path_1579"
        data-name="Path 1579"
        d="M91.112,317.95l3.868-3.872h0l-3.867,3.871Z"
        transform="translate(-85.907 -296.274)"
        fill="#acd9e5"
      />
      <path
        id="Path_1580"
        data-name="Path 1580"
        d="M9.207,332.622V324.04H7.561v10.229H17.79v-1.646Z"
        transform="translate(-7.092 -305.671)"
        fill="#fff"
      />
      <rect
        id="Rectangle_3981"
        data-name="Rectangle 3981"
        width="5.54"
        height="3.09"
        transform="translate(11.27 19.983) rotate(135)"
        fill="#fff"
      />
      <rect
        id="Rectangle_3982"
        data-name="Rectangle 3982"
        width="1.918"
        height="3.09"
        transform="translate(11.27 19.983) rotate(135)"
        fill="#f5cea4"
      />
      <path
        id="Path_1581"
        data-name="Path 1581"
        d="M11.124,315.267H.043V304.185H3.985v4.351l5.087-5.092,2.788,2.788-5.088,5.093h4.352v3.942ZM.9,314.414h9.376v-2.237H4.716l5.939-5.945L9.073,304.65,3.133,310.6v-5.557H.9Z"
        transform="translate(0 -286.242)"
      />
      <g
        id="Group_9979"
        data-name="Group 9979"
        transform="translate(4.401 19.462)"
      >
        <rect
          id="Rectangle_3983"
          data-name="Rectangle 3983"
          width="0.852"
          height="2.557"
          transform="translate(3.316 2.411) rotate(-135)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3984"
          data-name="Rectangle 3984"
          width="0.852"
          height="1.279"
          transform="translate(1.808 3.918) rotate(-135)"
          fill="#f5cea4"
        />
        <rect
          id="Rectangle_3985"
          data-name="Rectangle 3985"
          width="0.852"
          height="0.852"
          transform="translate(0.603 5.123) rotate(-135)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_1582"
        data-name="Path 1582"
        d="M16.165,18.312l-5.514-5.519v4.954H7.561V7.518H17.79v3.09H12.836l5.514,5.519Z"
        transform="translate(-7.092 -7.092)"
        fill="#f5cea4"
      />
      <path
        id="Path_1583"
        data-name="Path 1583"
        d="M91.112,129.611l3.868,3.872h0l-3.867-3.871Z"
        transform="translate(-85.907 -122.264)"
        fill="#acd9e5"
      />
      <path
        id="Path_1584"
        data-name="Path 1584"
        d="M9.207,9.164v8.582H7.561V7.518H17.79V9.164Z"
        transform="translate(-7.092 -7.092)"
        fill="#fff"
      />
      <rect
        id="Rectangle_3986"
        data-name="Rectangle 3986"
        width="5.54"
        height="3.09"
        transform="translate(9.086 11.225) rotate(-135)"
        fill="#fff"
      />
      <rect
        id="Rectangle_3987"
        data-name="Rectangle 3987"
        width="1.918"
        height="3.09"
        transform="translate(9.086 11.226) rotate(-135)"
        fill="#f5cea4"
      />
      <path
        id="Path_1585"
        data-name="Path 1585"
        d="M9.073,11.823,3.985,6.731v4.35H.043V0H11.124V3.942H6.772L11.86,9.035ZM3.133,4.671l5.94,5.945,1.582-1.582L4.716,3.09h5.556V.852H.9v9.376H3.133S3.133,4.671,3.133,4.671Z"
      />
      <g
        id="Group_9980"
        data-name="Group 9980"
        transform="translate(4.402 4.439)"
      >
        <rect
          id="Rectangle_3988"
          data-name="Rectangle 3988"
          width="2.557"
          height="0.852"
          transform="translate(4.521 5.124) rotate(-135)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3989"
          data-name="Rectangle 3989"
          width="1.279"
          height="0.852"
          transform="translate(2.109 2.712) rotate(-135)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3990"
          data-name="Rectangle 3990"
          width="0.852"
          height="0.852"
          transform="translate(0.603 1.205) rotate(-135)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_1586"
        data-name="Path 1586"
        d="M316.31,314.078l5.514,5.519v-4.954h3.09v10.229H314.685v-3.09h4.954l-5.514-5.519Z"
        transform="translate(-296.277 -296.274)"
        fill="#fff"
      />
      <path
        id="Path_1587"
        data-name="Path 1587"
        d="M356.523,317.95l-3.868-3.872h0l3.867,3.871Z"
        transform="translate(-332.623 -296.274)"
        fill="#acd9e5"
      />
      <path
        id="Path_1588"
        data-name="Path 1588"
        d="M332.581,332.622V324.04h1.646v10.229H324v-1.646Z"
        transform="translate(-305.592 -305.671)"
        fill="#f5cea4"
      />
      <rect
        id="Rectangle_3991"
        data-name="Rectangle 3991"
        width="5.54"
        height="3.09"
        transform="translate(20.02 17.798) rotate(45)"
        fill="#fff"
      />
      <rect
        id="Rectangle_3992"
        data-name="Rectangle 3992"
        width="1.918"
        height="3.09"
        transform="translate(20.02 17.799) rotate(45)"
        fill="#f5cea4"
      />
      <path
        id="Path_1589"
        data-name="Path 1589"
        d="M304.656,315.266h-.426v-3.942h4.352l-5.088-5.093,2.788-2.788,5.087,5.092v-4.351h3.942v11.081Zm.426-3.09v2.238h9.376v-9.376h-2.238v5.557l-5.94-5.945-1.582,1.582,5.939,5.945Z"
        transform="translate(-286.249 -286.242)"
      />
      <g
        id="Group_9981"
        data-name="Group 9981"
        transform="translate(19.58 19.461)"
      >
        <rect
          id="Rectangle_3993"
          data-name="Rectangle 3993"
          width="2.557"
          height="0.852"
          transform="translate(1.808 2.411) rotate(-135)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3994"
          data-name="Rectangle 3994"
          width="1.279"
          height="0.852"
          transform="translate(3.316 3.918) rotate(-135)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3995"
          data-name="Rectangle 3995"
          width="0.852"
          height="0.852"
          transform="translate(4.521 5.124) rotate(-135)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_1590"
        data-name="Path 1590"
        d="M316.31,18.312l5.514-5.519v4.954h3.09V7.518H314.685v3.09h4.954l-5.514,5.519Z"
        transform="translate(-296.277 -7.092)"
        fill="#f5cea4"
      />
      <path
        id="Path_1591"
        data-name="Path 1591"
        d="M356.523,129.611l-3.868,3.872h0l3.867-3.871Z"
        transform="translate(-332.623 -122.264)"
        fill="#acd9e5"
      />
      <path
        id="Path_1592"
        data-name="Path 1592"
        d="M332.581,9.164v8.582h1.646V7.518H324V9.164Z"
        transform="translate(-305.592 -7.092)"
        fill="#fff"
      />
      <rect
        id="Rectangle_3996"
        data-name="Rectangle 3996"
        width="5.54"
        height="3.09"
        transform="translate(17.835 9.041) rotate(-45)"
        fill="#fff"
      />
      <path
        id="Path_1594"
        data-name="Path 1594"
        d="M0,0H1.918V3.09H0Z"
        transform="translate(17.835 9.041) rotate(-45)"
        fill="#f5cea4"
      />
      <path
        id="Path_1593"
        data-name="Path 1593"
        d="M306.282,11.823l-2.788-2.788,5.088-5.093H304.23V0h11.081V11.081h-3.942V6.731ZM304.7,9.035l1.582,1.582,5.94-5.945v5.557h2.238V.852h-9.376V3.09h5.556Z"
        transform="translate(-286.249)"
      />
      <g
        id="Group_9982"
        data-name="Group 9982"
        transform="translate(19.58 4.438)"
      >
        <rect
          id="Rectangle_3998"
          data-name="Rectangle 3998"
          width="0.852"
          height="2.557"
          transform="translate(0.603 5.123) rotate(-135)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3999"
          data-name="Rectangle 3999"
          width="0.852"
          height="1.279"
          transform="translate(3.014 2.713) rotate(-135)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4000"
          data-name="Rectangle 4000"
          width="0.852"
          height="0.852"
          transform="translate(4.522 1.205) rotate(-135)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

import { FlexibleDiv } from "components/lib"
import { FMHWrap } from "./fsem-mobile-header.styles"
import { IoChevronBack, IoChevronForward } from "react-icons/io5"
import { useMainContext } from "context"
import { getDominantColors } from "utils"
import { useNavigate } from "react-router-dom"
import React from "react"

export const FsemMobileHeader = () => {
  const navigate = useNavigate()

  const {
    state: { player },
  } = useMainContext()

  const { currentTrack } = player

  return (
    <FMHWrap dominantColors={getDominantColors(currentTrack)}>
      <FlexibleDiv justifyContent="space-between" className="top-layer">
        <IoChevronBack onClick={() => navigate(-1)} className="arrow" />
        <IoChevronForward onClick={() => navigate(+1)} className="arrow" />
      </FlexibleDiv>

      <FlexibleDiv className="error-message-layer"></FlexibleDiv>
    </FMHWrap>
  )
}

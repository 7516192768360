import { IconProps } from "types";

export const TopAlbumIcon = ({
  color = "#929292",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "16"}
    height={height || "16"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 9H8V11H4V9Z" fill={color} />
    <path
      d="M16 2H15V0H5V2H3V3.25L2.4 4H1V5.75L0 7V16H12L16 11V2ZM2 5H10V7H2V5ZM11 15H1V8H11V15ZM12 7H11V4H4V3H12V7ZM14 4.5L13 5.75V2H6V1H14V4.5Z"
      fill={color}
    />
  </svg>
);

import styled from "styled-components"

export interface UserStylesContainerProps {
  isMobile?: boolean
}
export const Container = styled.div<UserStylesContainerProps>`
  padding: 0 3% 3% 3%;
  overflow-x: hidden;

  section {
    min-height: calc(100vh - 90px);
    padding: 5px;
    border: ${({ isMobile }) => (isMobile ? "" : "1px solid #0f0f0f;")};
  }
`

import { ActionProps } from "context/reducer"
import { Types } from "context/types"
import { AppState } from "./app.types"

export const appReducer = (state: AppState, { type, payload }: ActionProps) => {
  switch (type) {
    case Types.API_RESPONSE_ERROR:
      return {
        ...state,
        apiResponseError: payload,
      }

    case Types.SIGN_UP_FORM_STEP:
      const { signUpFormStep } = state
      return {
        ...state,
        signUpFormStep:
          payload === "ADD" ? signUpFormStep + 1 : signUpFormStep - 1,
      }

    case Types.SCROLL_TO_BOTTOM:
      return {
        ...state,
        scrollToBottom: payload,
      }

    case Types.GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      }

    case Types.SET_BILLING_PLANS:
      return {
        ...state,
        billingPlans: payload,
      }

    case Types.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload,
      }

    case Types.HEADER_ENRICHMENT:
      return {
        ...state,
        headerEnrichment: payload,
      }

    case Types.MSISDN_USER_HAS_ACCOUNT:
      return {
        ...state,
        msisdnUserHasAccount: payload,
      }

    case Types.HE_SERVICE:
      return {
        ...state,
        ...payload,
      }

    case Types.AUTH_STREAMING_WARNING:
      return {
        ...state,
        authStreamingWarning: payload,
      }

    case Types.SET_PRIVATE_ACTION:
      return {
        ...state,
        privateAction: payload,
      }

    case Types.IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload,
      }

    default:
      return state
  }
}

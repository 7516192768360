import { MtnIcon } from "assets/images/Auth"
import DailySubBng from "assets/images/payment/daily-sub.png"
import PayWithStripe from "assets/images/payment/payWithStripe.png"
import { ActionBox } from "components/lib/ActionBox/ActionBox"
import { useMemo, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { PaystackSVG } from "assets/images/payment/SVGExports"
import AuthImage from "assets/images/payment/choosePlatform.png"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { Types, useMainContext } from "context"
import { FaStripeS } from "react-icons/fa"
import { PaymentPlanWrapper } from "./choose-payment-plan.styles"
import { FlexibleDiv } from "components"

export const ChoosePaymentPlan = () => {
  const { dispatch } = useMainContext()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"
  const [activeSlide, setActiveSlide] = useState(1)

  const accentColor = useMemo(() => {
    if (plan === "daily") {
      return "var(--primaryYellow)"
    } else if (plan === "weekly") {
      return "#FDD774"
    } else return "#FED44B"
  }, [plan])

  const navigateToPaymentPage = (paymentMethod: string) => {
    //store the payment mode on the localStorage
    const payload = {
      plan,
      mode,
      method: paymentMethod,
    }

    dispatch({ type: Types.SET_PAYMENT_METHOD, payload })
    localStorage.setItem("udx_pay", JSON.stringify(payload))

    if (isUserLoggedIn()) {
      //go to the subscription confirmation page
      navigate(`/payment/confirm?type=${plan}&mode=${mode}`)
    } else {
      //navigate to the authentication page
      navigate(`/payment/auth?type=${plan}&mode=${mode}`)
    }
  }

  const isUserLoggedIn = () => {
    const tokens = JSON.parse(localStorage.getItem("udx__tokens") ?? "{}")

    return tokens?.accessToken
  }

  let cardsObjects = [
    {
      redirectURL: () => navigateToPaymentPage("paystack"),
      pageHoverImage: DailySubBng,
      boxIcon: <PaystackSVG fill={"white"} />,
      text: "Paystack",
    },
  ]
  cardsObjects =
    mode === "mtn"
      ? [
          {
            redirectURL: () => navigateToPaymentPage("mtn"),
            pageHoverImage: AuthImage,
            boxIcon: <MtnIcon />,
            text: " MTN",
          },
          ...cardsObjects,
        ]
      : [
          ...cardsObjects,
          {
            redirectURL: () => navigateToPaymentPage("stripe"),
            pageHoverImage: PayWithStripe,
            boxIcon: <FaStripeS />,
            text: " Stripe",
          },
        ]

  const handleActionTileHover = (slideIndex: number) => {
    setActiveSlide(slideIndex)
  }

  return (
    <PaymentPlanWrapper>
      <ChoosePlatform
        galleryImages={cardsObjects}
        activeSlide={activeSlide}
        backURL={"/payment"}
        accentColor={accentColor}
      >
        {cardsObjects.map((card, idx) => (
          <ActionBox
            key={idx}
            redirectURL={card.redirectURL}
            setHoverImage={() => handleActionTileHover(idx)}
            boxIcon={card.boxIcon}
            text={card.text}
          />
        ))}

        <FlexibleDiv className="tnc">
          <span>
            By clicking through the payment channel and submitting your payment
            information, you agree to the{" "}
            <Link to="/terms-and-conditions">uduX terms of use.</Link>
          </span>
        </FlexibleDiv>
      </ChoosePlatform>
    </PaymentPlanWrapper>
  )
}

import { MoreIcon } from "assets";
import { FlexibleDiv } from "../Box";
import { TabHeaderWrap } from "./tab.styles";
import { MdClose } from "react-icons/md";
import { PlayerTabRoutes } from "pages/Player";

export type TabHeaderProps = {
  tabs: PlayerTabRoutes;
  activeTab?: string;
  showCloser?: boolean;
  pad_link?: string;
  noGap?: boolean;
  width?: string;
  icon?: React.ReactElement;
  handleClose?: () => void;
  handleOpenMore?: () => void;
  background?: string;
  setActiveTab: (args: string) => void;
}
export const TabHeader = ({
  tabs,
  activeTab,
  setActiveTab,
  handleClose,
  showCloser,
  handleOpenMore,
  ...props
}: TabHeaderProps) => {
  return (
    <TabHeaderWrap {...props}>
      <FlexibleDiv className="navigation__routes__web" flexWrap="nowrap">
        {tabs.map((route, index) => (
          <span
            key={index}
            className={`tab__links ${
              activeTab === route.hash ? "active__tab" : ""
            }`}
            onClick={() => setActiveTab(route.hash)}
          >
            <p>
              <span className="icon__wrap">{route.icon}</span>
              <span>{route.title}</span>
            </p>
          </span>
        ))}
      </FlexibleDiv>

      {showCloser && (
        <FlexibleDiv className="more__and__close">
          <MoreIcon onClick={handleOpenMore} />

          <MdClose onClick={handleClose} className="close-icon" />
        </FlexibleDiv>
      )}
    </TabHeaderWrap>
  );
};

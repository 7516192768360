import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import { StateProps, initialState, mainReducer } from "./reducer"
import { Types } from "./types"
import { PlayerRefProps } from "types/player"

const localState = JSON.parse(
  localStorage.getItem("__udx__store") ?? "{}"
) as StateProps
const lUser = JSON.parse(localStorage.getItem("udx__user") || "{}")

const providerState: StateProps =
  "app" in localState ? localState : initialState

type MainProviderProps = {
  children: React.ReactNode
}

interface MainContextProps {
  state: StateProps
  dispatch: React.Dispatch<any> // Adjust this type as needed
  playerRef: PlayerRefProps
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  clearSearchQuery: () => void
}

const MainContext = createContext<MainContextProps | undefined>(undefined)

export const MainProvider = ({ children }: MainProviderProps) => {
  const playerRef: PlayerRefProps = useRef(null)
  const [state, dispatch] = useReducer(mainReducer, providerState)
  const [searchQuery, setSearchQuery] = useState("")
  const pathname = window.location.pathname
  const clearSearchQuery = useCallback(() => {
    setSearchQuery("")
  }, [])

  const me = state.users.me

  const contextValue: MainContextProps = useMemo(() => {
    return {
      state,
      dispatch,
      playerRef,
      searchQuery,
      setSearchQuery,
      clearSearchQuery,
    }
  }, [
    state,
    dispatch,
    playerRef,
    searchQuery,
    setSearchQuery,
    clearSearchQuery,
  ])

  useEffect(() => {
    if (searchQuery && pathname !== "/search") {
      setSearchQuery("")
    }
  }, [pathname, searchQuery])

  useEffect(() => {
    if (me?.id) return

    if (lUser?.id) {
      dispatch({
        type: Types.CURRENT_USER,
        payload: { ...lUser },
      })
      dispatch({
        type: Types.IS_LOGGED_IN,
        payload: true,
      })
    }
  }, [me?.id])

  return (
    <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
  )
}

export const useMainContext = () => {
  const context = useContext(MainContext)

  if (!context) {
    throw new Error("useMainContext must be used withing a MainProvider")
  }

  return context
}

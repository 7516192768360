import { ActionProps } from "context/reducer"
import { Types } from "context/types"
import { Album } from "types/artist"
import { Playlist } from "types/playlist"

// TODO: create appropriate type for the playlist
export type PlaylistState = {
  current: Playlist
  currentAlbum?: Album
  recentlyPlayed: Array<any>
  creatingPlaylist: boolean
}
export const playlistReducer = (
  state: PlaylistState,
  { type, payload }: ActionProps
) => {
  switch (type) {
    case Types.UPDATE_CURRENT_ALBUM:
      return {
        ...state,
        currentAlbum: payload,
      }

    case Types.UPDATE_CURRENT_PLAYLIST:
      return {
        ...state,
        current: payload,
      }

    case Types.CREATING_PLAYLIST:
      return {
        ...state,
        creatingPlaylist: payload,
      }

    case Types.UPDATE_RECENTLY_PLAYED:
      return {
        ...state,
        recentlyPlayed: [...state.recentlyPlayed, payload],
      }

    default:
      return state
  }
}

import { generalSearch } from "network"
import { useQuery } from "react-query"

export function useSearch(searchQuery: string, filterSearch: string) {
  // see https://react-query.tanstack.com/guides/important-defaults
  // see https://react-query.tanstack.com/guides/paginated-queries
  return useQuery(
    ["search", searchQuery],
    () => generalSearch(filterSearch, searchQuery),
    {
      enabled: !!searchQuery,
    }
  )
}

import { useLeaderboardEngagementService } from "network/hooks/use-leaderboard"
import { InActivePlaylist } from "../InActivePlaylist"
import { CollabLeaderboardTabDisplayWrap } from "./leaderboard-tab.styles"
import { LeaderboardEngagementCard } from "./LeaderboardEngagementCard"
import { FlexibleDiv } from "components"
import { useParams } from "react-router-dom"

export const CollabLeaderboardTabDisplay = () => {
  const { playlistId } = useParams()
  const { data } = useLeaderboardEngagementService(playlistId)

  return (
    <CollabLeaderboardTabDisplayWrap>
      {/* <InActivePlaylist
        title="No leaders without followers"
        description="To see stats you need people"
      /> */}

      <FlexibleDiv className="central__data__display">
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Total Spins by Collaborators</h3>
          <LeaderboardEngagementCard bg="#5f2a29" hoverColor="#542424" />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Total Tuales by Collaborators</h3>
          <LeaderboardEngagementCard bg="#B77F3F" hoverColor="#a67339" />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Highest Engagement by Collaborators</h3>
          <LeaderboardEngagementCard />
        </FlexibleDiv>
      </FlexibleDiv>
    </CollabLeaderboardTabDisplayWrap>
  )
}

import styled from "styled-components"

export const PaymentPlanWrapper = styled.div`
  overflow-y: hidden;

  .tnc {
    margin-top: 15%;

    span {
      font-size: 0.7rem;
      opacity: 0.5;

      a {
        color: var(--primaryWhite);
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    .tnc {
      span {
        font-size: 1rem;
      }
    }
  }
`

import styled from "styled-components"
import { Popconfirm, PopconfirmProps } from "antd"

export const AppGuideWrap = styled.div<any>`
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100vh;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0 0 0 / 0.7);
  }

  .popoverwrap__main {
    position: absolute;
    z-index: 4;
  }
  .popopverwrap__main__one {
    top: 2%;
    left: 10%;
  }
  .popopverwrap__main__two {
    bottom: 5%;
    left: 20%;
  }
  .popopverwrap__main__three {
    bottom: 5%;
    left: 50%;
  }
`

export const PopconfirmContaner = styled(Popconfirm)<PopconfirmProps>``

import styled from "styled-components"
import { FlexibleDiv } from "components"

export const PaymentHomeWrapper = styled(FlexibleDiv)`
  position: relative;

  @media only screen and (max-width: 600px) {
    position: unset;
  }
`

import { IconProps } from "types";

export const DragIcon = ({ width, height}: IconProps) => (
  <svg
    width={width || "21"}
    height={height || "19"}
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1076_9039)">
      <path
        d="M20.8944 0.257812H0.894409V1.99975H20.8944V0.257812Z"
        fill="#D9D9D9"
      />
      <path
        d="M20.8944 16.5156H0.894409V18.2576H20.8944V16.5156Z"
        fill="#D9D9D9"
      />
      <path
        d="M20.8944 8.38672H0.894409V10.1287H20.8944V8.38672Z"
        fill="#D9D9D9"
      />
    </g>
    <defs>
      <clipPath id="clip0_1076_9039">
        <rect
          width="20"
          height="18"
          fill="white"
          transform="translate(0.894409 0.257812)"
        />
      </clipPath>
    </defs>
  </svg>
);

import { Types, useMainContext } from "context"
import React, { useEffect } from "react"
import { WebPlayer } from "./components"
import { MobilePlayer } from "./components"
import { useMediaQuery } from "react-responsive"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"

export const MusicPlayer = () => {
  const { dispatch } = useMainContext()
  const isMobile = useMediaQuery({ maxWidth: 1200 })

  useEffect(() => {
    dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: true })
  }, [dispatch])

  return <PlayerLayout>{getRenderModule()}</PlayerLayout>

  function getRenderModule() {
     return isMobile ? <MobilePlayer /> : <WebPlayer />
  }

}

import {
  AddToQueueIcon,
  EditPlaylistIcon,
  LibraryIcon,
  ShareIcon2,
  SimilarVibesIcon,
} from "assets";
import { DeleteIcon } from "assets/images/general/SVGExports";
import { StyledMenu } from "components";

export type PlaylistMoreMenuProps = {
  isOwner?: boolean;
  handleDelete?: () => void;
};
export const PlaylistMoreMenu = ({
  // isOwner=false,
  handleDelete,
}: PlaylistMoreMenuProps) => {
  const items = [
    {
      label: (
        <div className="menu_box">
          <EditPlaylistIcon width={18} />
          <p>Edit Details</p>
        </div>
      ),
    },
    // HIDE: Till further notice
    // {
    //   label: (
    //     <div className="menu_box">
    //       <PrivateIcon width={18} />
    //       <p>Make Private</p>
    //     </div>
    //   ),
    // },
    {
      label: (
        <div className="menu_box">
          <SimilarVibesIcon width={18} />
          <p>Explore Similar Vibes</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box">
          <AddToQueueIcon width={18} />
          <p>Add to Queue</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box">
          <LibraryIcon width={18} />
          <p>Save to library</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleDelete}>
          <DeleteIcon width={18} />
          <p>Delete</p>
        </div>
      ),
    },
  ];

  items.push({
    label: (
      <div className="menu_box">
        <ShareIcon2 width={18} />
        <p>Share Playlist</p>
      </div>
    ),
  });

  return (
    <StyledMenu
      top="0.5rem"
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
    />
  );
};

import { IconProps } from "types";

export const GoogleIcon = ({
  color = "#fff",
  width = "37.547",
  height = "37.547",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.9656 17.3031L36.7891 16.5539H20.5047V23.4461H30.2344C29.2242 28.243 24.5367 30.768 20.7078 30.768C17.9219 30.768 14.9852 29.5961 13.0414 27.7125C12.0159 26.7028 11.1996 25.5008 10.6392 24.1752C10.0789 22.8497 9.78544 21.4266 9.77578 19.9875C9.77578 17.0844 11.0805 14.1805 12.9789 12.2703C14.8773 10.3602 17.7445 9.29141 20.5953 9.29141C23.8602 9.29141 26.2 11.025 27.075 11.8156L31.9727 6.94375C30.5359 5.68125 26.5891 2.5 20.4375 2.5C15.6914 2.5 11.1406 4.31797 7.81406 7.63359C4.53125 10.8984 2.83203 15.6195 2.83203 20C2.83203 24.3805 4.43984 28.8656 7.62109 32.1562C11.0203 35.6656 15.8344 37.5 20.7914 37.5C25.3016 37.5 29.5766 35.7328 32.6234 32.5266C35.6187 29.3703 37.168 25.0031 37.168 20.425C37.168 18.4977 36.9742 17.3531 36.9656 17.3031Z"
      fill={color}
    />
  </svg>
);

import { FlexibleDiv } from "components"
import styled from "styled-components"
import { CMContainerProps } from "../CheckMail/checkMail.styles"

export const Container = styled(FlexibleDiv)<CMContainerProps>`
  padding-bottom: 5%;
  padding-top: 1rem;
  width: 100%;

  .btn_box {
    width: 100%;
    margin-top: 3rem;
  }
`

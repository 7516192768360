import CollabImage from "assets/images/player/collab-playlist-cover.jpg";
import SoloImage from "assets/images/player/solo-playlist-cover.jpg";
import { FlexibleDiv } from "components";
import { Types, useMainContext } from "context";
import React, { forwardRef, memo } from "react";
import useImageColor from "use-image-color";
import { EnterPlaylistName } from "./EnterPlaylistName";
import { CPTWrap } from "./styles";
import { CollabIcon, SoloIcon } from "assets";
import { IoIosArrowRoundBack } from "react-icons/io";

export type PlaylistType = typeof playlistType;
const playlistType = [
  {
    icon: <CollabIcon color="#fff" />,
    title: "collab",
    desc: "Join forces with one or more friends & build amazing playlists you can enjoy together",
  },
  {
    icon: <SoloIcon color="#fff" />,
    title: "solo",
    desc: "Playlists so good... It won’t be fair if you share",
  },
];

const ChoosePlaylistTypeRefEl = forwardRef(() => {
  const { dispatch } = useMainContext();
  const [selected, setSelected] = React.useState("");
  const { colors: collabColors } = useImageColor(CollabImage, {
    colors: 3,
  });
  const { colors: soloColors } = useImageColor(SoloImage, {
    colors: 3,
  });

  return (
    <CPTWrap
      bng={selected ? (selected === "collab" ? "#FBBA12B2" : "#FEB802") : ""}
      collabColors={collabColors}
      soloColors={soloColors}
    >
      <FlexibleDiv className="title__wrap" justifyContent="space-between">
        <p>Choose a playlist type</p>
        <button
          className="go__back__box"
          onClick={() => {
            if (selected) {
              setSelected("");
            }
            dispatch({ type: Types.CREATING_PLAYLIST, payload: false });
          }}
        >
          <IoIosArrowRoundBack color="var(--primaryWhite)" size={30} />{" "}
          <span>Back</span>
        </button>
      </FlexibleDiv>

      {Boolean(selected) && (
        <EnterPlaylistName
          onClose={() => setSelected("")}
          type={selected}
          bng={selected === "collab" ? CollabImage : SoloImage}
        />
      )}

      <FlexibleDiv className="options__wrap">
        {playlistType.map((type, i) => {
          return (
            <FlexibleDiv
              key={i}
              className={`pl__option ${
                selected === type.title ? "selected" : ""
              }`}
              onClick={() => setSelected(type.title)}
            >
              <span className="icon">{type.icon}</span>
              <FlexibleDiv className="indicator">
                <FlexibleDiv className="checkbox">
                  <span></span>
                </FlexibleDiv>

                <FlexibleDiv
                  className={`print__box ${
                    type.title === "collab" ? "collab__print" : "solo__print"
                  }`}
                >
                  <p>{type.title}</p>
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv className="content">
                <p className="title">{type.title}</p>
                <small className="desc">{type.desc}</small>
              </FlexibleDiv>
            </FlexibleDiv>
          );
        })}
      </FlexibleDiv>
    </CPTWrap>
  );
});

ChoosePlaylistTypeRefEl.displayName = "ChoosePlaylistTypeRefEl";

export const ChoosePlaylistType = memo(ChoosePlaylistTypeRefEl);

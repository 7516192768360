import { FlexibleDiv } from "components"
import { Types, useMainContext } from "context"
import { getBillingPlans } from "network"
import { useCallback, useEffect, useMemo, useState } from "react"
import { MdCheck } from "react-icons/md"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { BillingPlan } from "types"
import {
  PricingPlanWrap,
  SubscriptionPlansWrapper,
} from "./subscription-plans.styles"

export type SubscriptionPlansProps = {
  mtnPlans: BillingPlan[]
  uduxPlans: BillingPlan[]
}

export const SubscriptionPlans = () => {
  const {
    dispatch,
    state: { app },
  } = useMainContext()
  const [mode, setMode] = useState("")
  const [plans, setPlans] = useState<BillingPlan[] | []>([])
  const { data } = useQuery("billing-plans", async () => getBillingPlans(), {
    onSuccess: data => {
      dispatch({
        type: Types.SET_BILLING_PLANS,
        payload: data,
      })

      // Store the data in localStorage
      localStorage.setItem("udx__billing__plans", JSON.stringify(data))
    },
  })

  const tabHeader = [
    { label: "uduX", value: "udux" },
    { label: "MTN Subscribers", value: "mtn" },
  ]

  // Function to assign cardBackground and gradient based on the interval
  const assignStyles = (plan: BillingPlan): BillingPlan => {
    let cardBackground = ""
    let gradient = ""

    switch (plan.interval) {
      case "daily":
        cardBackground = "#FDC474"
        gradient = "#725835"
        break
      case "weekly":
        cardBackground = "#FDD774"
        gradient = "#7b6837"
        break
      default:
        cardBackground = "#FED54D"
        gradient = "#715e21"
        break
    }

    return {
      ...plan,
      cardBackground,
      gradient,
    }
  }

  // Memoized function to filter and style plans
  const filterPlansByProvider = useCallback(
    (plans?: BillingPlan[]): [BillingPlan[], BillingPlan[]] => {
      if (plans) {
        return plans.reduce(
          (acc: [BillingPlan[], BillingPlan[]], plan: BillingPlan) => {
            if (plan.currency === "NGN") {
              const styledPlan = assignStyles(plan) // Apply styles based on interval
              const idx =
                styledPlan.interval === "daily"
                  ? 0
                  : styledPlan.interval === "weekly"
                  ? 1
                  : 2

              if (styledPlan.provider?.toUpperCase() === "UDUX") {
                acc[0][idx] = styledPlan
              } else if (styledPlan.provider?.toUpperCase() === "MTN") {
                acc[1][idx] = styledPlan
              }
            }
            return acc
          },
          [[], []] // Initialize with two empty arrays: one for UDUX and one for MTN
        )
      } else {
        return [[], []]
      }
    },
    [] // No dependencies needed as the function itself is stable
  )

  // Memoized filtering logic using useMemo
  const [uduxPlans, mtnPlans] = useMemo(
    () => filterPlansByProvider(data),
    [data, filterPlansByProvider]
  )

  const isMTNUser = useMemo(() => Boolean(app?.isMtn), [app])

  useEffect(() => {
    setMode(isMTNUser ? "mtn" : "udux")
  }, [isMTNUser])

  useEffect(() => {
    const selectedPlans = mode === "mtn" ? mtnPlans : uduxPlans

    // Update plans only if they have actually changed
    if (JSON.stringify(plans) !== JSON.stringify(selectedPlans)) {
      setPlans(selectedPlans)
    }
  }, [mode, uduxPlans, mtnPlans, plans])

  useEffect(() => {
    const selectedPlans = mode === "mtn" ? mtnPlans : uduxPlans

    // Update plans only if they have actually changed
    if (JSON.stringify(plans) !== JSON.stringify(selectedPlans)) {
      setPlans(selectedPlans)
    }
  }, [mode, uduxPlans, mtnPlans, plans])

  return (
    <SubscriptionPlansWrapper flexDir="column">
      <FlexibleDiv
        className="top__section"
        height="25%"
        width="100%"
        flexDir="column"
        flexWrap="nowrap"
        justifyContent="space-around"
      >
        <FlexibleDiv className="subscription__mode__selector" flexWrap="nowrap">
          {tabHeader.map((th, idx) => (
            <FlexibleDiv
              key={idx}
              className={`mode__text__bg ${
                mode === th.value ? "active__tab" : ""
              }`}
              onClick={() => setMode(th.value)}
              id={`mode__${idx}`}
            >
              <p>{th.label}</p>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv
        className="bottom__section"
        height="65%"
        width="100%"
        justifyContent="space-evenly"
      >
        {plans.map((value, idx) => (
          <PricingPlanWrap
            flipDir={value.provider !== "MTN"}
            bng={value.cardBackground}
            gradient={value.gradient}
            key={idx}
          >
            <FlexibleDiv
              className="price__wrapper"
              width="100%"
              height="22.5%"
              flexDir="column"
              flexWrap="nowrap"
              justifyContent="space-evenly"
            >
              <FlexibleDiv className="card__background">
                <FlexibleDiv className="text__container" flexDir="column">
                  <p className="duration__text">
                    {value.interval?.toString()[0].toUpperCase()}
                    {value.interval?.toString().substring(1)}
                  </p>
                  <p className="price__text">
                    NGN {value.amount ? value.amount / 100 : 0}
                  </p>
                </FlexibleDiv>
                {value.interval === "weekly" && (
                  <span className="most__preferred">Most Preferred</span>
                )}
                {value.interval === "monthly" && (
                  <span className="hot__deal">Hot Deal</span>
                )}
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv className="slot">
              <FlexibleDiv
                width="100%"
                className="subscription__features"
                height="65%"
                flexDir="column"
                justifyContent="center"
              >
                {value["features"]?.map((value, idx) => (
                  <FlexibleDiv
                    key={idx}
                    flexDir="row"
                    className="features__container"
                  >
                    <div className="icon__wrapper">
                      <MdCheck color="var(--primaryBlack)" />
                    </div>

                    <p className="features__text"> {value}</p>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>
              <FlexibleDiv className="upgrade__wrapper" height="10%">
                {mode === "mtn" && (
                  <Link
                    to={`/payment/plan?type=${value.interval
                      ?.toString()
                      .toLowerCase()}&mode=${mode}`}
                    state={{
                      mode,
                      plan: value.interval?.toString(),
                    }}
                    className="upgrade__text"
                  >
                    Choose Plan
                  </Link>
                )}
                {mode === "udux" && (
                  <Link
                    to={`/payment/plan?type=${value.interval
                      ?.toString()
                      .toLowerCase()}&mode=${mode}`}
                    state={{
                      mode,
                      plan: value.interval?.toString(),
                    }}
                    className="upgrade__text"
                  >
                    Choose Plan
                  </Link>
                )}
              </FlexibleDiv>
            </FlexibleDiv>
          </PricingPlanWrap>
        ))}
      </FlexibleDiv>
    </SubscriptionPlansWrapper>
  )
}

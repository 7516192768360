import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export const ArrowCarouselWrap = styled(FlexibleDiv)`
  position: relative;
  overflow-y: hidden;

  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: white;
    font-size: 1.5rem;
    padding: 0.3rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }

  .left-arrow:hover,
  .right-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .cards__carousel {
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    scroll-behavior: smooth;

    /* Fix for scroll bar showing on displayed home page playlist items */
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

import { IconProps } from "types"

export const ShuffleIcon = ({ color, width, height, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "28"}
    height={height || "28"}
    viewBox="0 0 35 35"
    className="fill__player__svg"
    fill="none"
    {...props}
  >
    <g id="shuffle-icon" data-name="shuffle icon" transform="translate(0 0)">
      <path
        id="Path_1541"
        data-name="Path 1541"
        d="M9.246,8.78H2V6.536h8.457L15.9,14.7l-1.873,1.245ZM26.216,23.361l3.365,3.365H23.917l-4.778-7.167L17.266,20.8l5.44,8.166h6.876l-3.365,3.365L27.8,33.916l6.068-6.068L27.8,21.78ZM29.581,8.78l-3.365,3.365L27.8,13.726l6.068-6.068L27.8,1.59,26.216,3.172l3.365,3.365h-5.72L9.28,26.726H2V28.97h8.424L25.005,8.78Z"
        transform="translate(0 0)"
        fill={color}
      />
    </g>
  </svg>
)

import { FlexibleDiv } from "../Box";
import { VolumeControlIcon, VolumeMuteIcon } from "assets";
import { Types, useMainContext } from "context";
import { VolumeController } from ".";
import { VolumeControlWrap } from "./volume-control.styled";
import { useRef } from "react";

export type VolumeControlProps = {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
};

export const VolumeControl = ({ className }: VolumeControlProps) => {
  const {
    state: { player },
    dispatch,
  } = useMainContext();
  const muteRef = useRef<SVGSVGElement | null>(null);
  const unmuteRef = useRef<SVGSVGElement | null>(null);

  const { isPlayerMuted, volume } = player;

  const handleMute = () => {
    if (volume > 0.0001) {
      dispatch({ type: Types.MUTE_PLAYER, payload: !isPlayerMuted });
    } else {
      return;
    }
  };
  return (
    <VolumeControlWrap>
      <FlexibleDiv className={className} style={{ cursor: "pointer" }}>
        <div
          onClick={handleMute}
          className={`icons__wrap ${
            isPlayerMuted || volume === 0.0001
              ? "muted__active"
              : "muted__inactive"
          }`}
        >
          {isPlayerMuted ? (
            <VolumeMuteIcon height={30} ref={muteRef} />
          ) : (
            <VolumeControlIcon height={30} ref={unmuteRef} />
          )}
        </div>
      </FlexibleDiv>
      <VolumeController />
    </VolumeControlWrap>
  );
};

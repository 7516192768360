import { LoadingOutlined } from "@ant-design/icons";
import { Form, notification, Select } from "antd";
import galleryImage from "assets/images/Auth/create_account.png";
import galleryImage2 from "assets/images/Auth/create_account.png";
import {
  BorderedSelectField,
  BorderedTextfield,
  Button,
  CheckBox,
  DateSequenceSelector,
  FlexibleDiv,
  PhoneInput,
} from "components";
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { Config } from "config";
import { SourceService, Types, useMainContext } from "context";
import dayjs from "dayjs";
import { pick } from "lodash";
import { checkAvailableEmail, checkAvailableUsername, signUp } from "network";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { SignUpPayload } from "types";
import {
  capitalize,
  validateBasicField,
  validateConfirmPassword,
  validateEmail,
  validatePassword,
  validateUsername,
} from "utils";
import { Container } from "./signup.styles";

const SignUp = () => {
  const [signUpForm] = Form.useForm();
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const [dob, setDob] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [usernameExists, setUsernameExists] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailExists, setEmailExists] = useState(false);

  const navigate = useNavigate();
  const {
    state: { users, app },
    dispatch,
  } = useMainContext();
  const { me } = users;
  const { signUpFormStep } = app;
  const { mutate, isLoading } = useMutation(signUp);
  const { data: isEmailAvail } = useQuery(
    ["check-emailAddress", emailAddress],
    () => checkAvailableEmail(emailAddress),
    {
      enabled: !!emailAddress,
    }
  );
  const { data: isUsernameAvail } = useQuery(
    ["check-username", username],
    () => checkAvailableUsername(username),
    {
      enabled: !!username,
    }
  );

  const handleFormSubmit = async (values: SignUpPayload) => {
    values.dateOfBirth = dob;

    if (!acceptedTermsAndConditions) {
      notification.error({
        message: "You have to accept our Terms and Conditions to continue.",
      });
    }

    const savedSignUpValues = pick(me, [
      "password",
      "username",
      "lastName",
      "firstName",
      "emailAddress",
    ]) as SignUpPayload;

    const payload = {
      ...savedSignUpValues,
      countryCode: countryCode,
      phoneNumber: `+${phoneNumber}`,
      dateOfBirth: values.dateOfBirth,
      acceptedTermsAndConditions: acceptedTermsAndConditions,
      gender: values.gender,
      sourceService: Config.sourceService as SourceService,
    };
    dispatch({
      type: Types.CURRENT_USER,
      payload,
    });

    mutate(payload, {
      onSuccess: () => {
        localStorage.removeItem("passwordTest");
        dispatch({ type: Types.CURRENT_USER, payload: {} });
        navigate("/check-mail", { state: { prevRoute: "/signup" } });
      },
      onError: (error) => {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error });
      },
    });

    signUpForm.setFieldsValue({
      username: "",
      phoneNumber: "",
      emailAddress: "",
      password: "",
      gender: "",
      dateOfBirth: "",
      acceptedTermsAndConditions: false,
    });
  };

  const handleStep = (values: SignUpPayload) => {
    dispatch({ type: Types.CURRENT_USER, payload: { ...values } });
    dispatch({ type: Types.SIGN_UP_FORM_STEP, payload: "ADD" });
  };

  useEffect(() => {
    setEmailExists(isEmailAvail?.data?.exists ?? false);
  }, [isEmailAvail?.message, isEmailAvail?.data]);

  useEffect(() => {
    setUsernameExists(isUsernameAvail?.data?.exists ?? false);
  }, [isUsernameAvail?.message, isUsernameAvail?.data]);

  useEffect(() => {
    signUpForm.setFieldsValue({
      username: "",
      phoneNumber: "",
      emailAddress: "",
      password: "",
      gender: undefined,
      dateOfBirth: "",
      acceptedTermsAndConditions: false,
    });
  }, [signUpForm]);

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined });
  }, [dispatch]);

  return (
    <AuthLayout
      pt="1rem"
      title="Find Your X!"
      subText="For all the ways music moves you"
      galleryImage={signUpFormStep === 0 ? galleryImage : galleryImage2}
    >
      <Container>
        {signUpFormStep === 0 ? (
          <Form
            form={signUpForm}
            onFinish={handleStep}
            className="form_content"
          >
            <FlexibleDiv className="signup__form__box">
              <FlexibleDiv className="textfield__wrap">
                <BorderedTextfield
                  small
                  type="text"
                  name="username"
                  placeholder="Username"
                  paddingLeft="0"
                  hasError={usernameExists}
                  validator={validateUsername}
                  borderBottom="1px solid#707070"
                  checkAvailability={(e) => setUsername(e)}
                  hasErrorMessage={"Username already exists"}
                />
              </FlexibleDiv>

              <FlexibleDiv className="textfield__wrap">
                <BorderedTextfield
                  small
                  type="email"
                  name="emailAddress"
                  hasError={emailExists}
                  paddingLeft="0"
                  borderBottom="1px solid#707070"
                  validator={validateEmail}
                  placeholder="What's your email"
                  hasErrorMessage={"Email already exists"}
                  checkAvailability={(e) => setEmailAddress(e)}
                />
              </FlexibleDiv>

              <FlexibleDiv className="textfield__wrap">
                <BorderedTextfield
                  small
                  type="password"
                  name="password"
                  paddingLeft="0"
                  borderBottom="1px solid#707070"
                  placeholder="Enter Password"
                  validator={validatePassword}
                />
              </FlexibleDiv>

              <FlexibleDiv className="textfield__wrap">
                <BorderedTextfield
                  small
                  type="password"
                  name="confirmPassword"
                  borderBottom="1px solid #707070"
                  paddingLeft="0"
                  placeholder="Re-enter Password"
                  validator={validateConfirmPassword}
                />
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv
              flexDir="column"
              className="btn__box"
              justifyContent="center"
            >
              <Button
                width="180px"
                height="40px"
                font_size="15px"
                type="submit"
                color="var(--black)"
                contained
                radius="10px"
                bold
                ltr
              >
                <span>Confirm Details</span>
              </Button>

              <Link to="/login/basic" className="existing__user">
                Existing user? Login
              </Link>
            </FlexibleDiv>
          </Form>
        ) : (
          <Form
            form={signUpForm}
            onFinish={handleFormSubmit}
            className="form_content"
          >
            <FlexibleDiv className="select_box gender__input__wrap">
              <BorderedSelectField
                small
                width="200px"
                radius="0px"
                name="gender"
                padding="0.5rem 0.5rem 0rem 0rem"
                borderBottom="1px solid #707070"
                // suffixIcon="empty"
                placeholder="What do you identify as?"
                validator={validateBasicField}
              >
                {["male", "female", "not-specified"].map((value, idx) => (
                  <Select.Option
                    className="gender__dropdown"
                    style={{ width: "200px", borderRadius: "0" }}
                    value={value}
                    key={idx}
                  >
                    {capitalize(value)}
                  </Select.Option>
                ))}
              </BorderedSelectField>
            </FlexibleDiv>

            <FlexibleDiv className="phone__input__wrap">
              <PhoneInput
                label=""
                borderBottom="1px solid #707070"
                onChange={(e) => {
                  setPhoneNumber(e);
                }}
                onSetCountryCode={(code) => setCountryCode(code)}
                small
              />
            </FlexibleDiv>

            <FlexibleDiv className="date__input__wrap">
              <DateSequenceSelector
                small
                label=""
                mb={"0.5rem"}
                borderBottom="1px solid #707070"
                handleDateChange={(dob) => {
                  setDob(dayjs(dob).format("DD/MM/YYYY"));
                }}
              />
            </FlexibleDiv>

            <FlexibleDiv
              className="terms__and__conditions"
              flexDir="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <CheckBox
                color="var(--dampWhite)"
                name="acceptedTermsAndConditions"
                checked={acceptedTermsAndConditions}
                onChange={(e) =>
                  setAcceptedTermsAndConditions(e.target.checked)
                }
              >
                <span className="tac__text">
                  I accept that on occasion I can be contacted by uduX
                </span>
              </CheckBox>

              <p className="tac">
                uduX is a personalised music streaming service. By clicking on
                the sign up button, you agree to our{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions of Use
                </a>
                .
              </p>
            </FlexibleDiv>

            <FlexibleDiv
              flexDir="column"
              className="btn__box"
              justifyContent="center"
            >
              <Button
                width="180px"
                height="40px"
                font_size="15px"
                type="submit"
                radius="10px"
                color="var(--black)"
                disabled={!acceptedTermsAndConditions}
                contained
                bold
                ltr
              >
                <span>
                  {isLoading ? <LoadingOutlined color="#0d0e1d" /> : "Sign Up"}
                </span>
              </Button>
            </FlexibleDiv>
          </Form>
        )}
      </Container>
    </AuthLayout>
  );
};

export default SignUp;

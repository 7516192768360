import { useEffect } from "react"
import { useState } from "react"

export function useDelayUnmount(isMounted: boolean, delayTime = 500) {
  const [shouldRender, setShouldRender] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isMounted && !shouldRender) {
      setShouldRender(true)
    } else if (!isMounted && shouldRender) {
      const id = setTimeout(() => setShouldRender(false), delayTime)
      setTimeoutId(id)
    }

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
    }
  }, [isMounted, delayTime, shouldRender, timeoutId])

  return shouldRender
}

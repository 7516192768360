import { FlexibleDiv, FlexibleDivProps } from "components/lib/Box";
import styled, { css } from "styled-components";

// Define different sizes for the cards
const largeCardStyles = css`
  .image__wrap {
    width: 450px;
    height: 250px;
    .banner__image {
      border-radius: 24px;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
`;

const smallCardStyles = css`
  .image__wrap {
     width: 450px;
     height: 192px;
    .banner__image {
        border-radius: 24px;
        width: 100%;         /* Make image take the full width */
        height: auto;        /* Maintain aspect ratio */
        object-fit: cover; 
    }
  }
`;
export const LotteryCPWrap = styled(FlexibleDiv)<
  FlexibleDivProps & { size?: "large" | "small"; isActive?: boolean }
>`
  cursor: pointer;
  position: relative;

  /* Apply the conditional styling with largeCardStyles as default */
  ${(props) => (props.size === "small" ? smallCardStyles : largeCardStyles)}
`;

import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { useLocation } from "react-router-dom"
import { AlbumModule, PlaylistModule } from "./components"
import { useEffect } from "react";

export const Playlist = () => {
  const { state: urlState, search } = useLocation() as any
  const searchKey = new URLSearchParams(search).get("t")
   
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <PlayerLayout>{getRenderModule()}</PlayerLayout>

  function getRenderModule() {
    if (searchKey === "alb" || urlState?.module === "album") {
      return <AlbumModule />
    }

    if (searchKey === "pl" || urlState?.module === "playlist") {
      return <PlaylistModule />
    }
  }
}

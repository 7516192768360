import styled from "styled-components"
import { FlexibleDiv } from "../../Box/styles"

export interface PlaylistCardWrapProps {
  dominantColors?: Array<string>
}

export const PlaylistCardWrap = styled(FlexibleDiv)<PlaylistCardWrapProps>`
  /* max-width: 200px; */
  min-width: 300px;
  min-height: 280px;

  .card__main {
    width: 100%;
    min-height: 100%;
    cursor: pointer;
    overflow: hidden;
    padding: 12px;
    position: relative;
    border: 0.5px solid var(--uduxGrayLightAlpha);
    transition: all 0.3s ease;

    .card__innerbox__wrap {
      height: 100%;
      position: relative;

      ::before {
        content: "";
        width: 100%;
        height: calc(100% - 60px);
        position: absolute;
        background-color: var(--black);
        border: 0.5px solid var(--uduxGrayLightAlpha);
      }

      .card-play-btn {
        top: 0;
        height: 0;
        z-index: 11;
        overflow: hidden;
        position: absolute;
        transition: all 0.3s;
        background-color: var(--overlayMain);

        svg {
          display: none;
        }
      }

      &:hover .card-play-btn {
        height: 100%;

        svg {
          display: inline-flex;
        }
      }

      .is__playing {
        height: 100%;
        position: absolute;
        background-color: var(--overlayMain);

        svg {
          display: inline-flex;
        }
      }

      .card__image__wrap {
        width: 90%;
        margin-top: 10px;
        position: relative;
        height: calc(100% - 80px);

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .text__content {
        height: 60px;
        padding: 0.5rem 0.5rem;
        background: ${({ dominantColors }) =>
          dominantColors?.length
            ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
            : "var(--gradientPrimary)"};

        p.title {
          text-transform: capitalize;
          color: var(---textPrimary);
          margin: 0;
          font-size: 15px;
          font-weight: 600;
          font-family: "Baguede", sans-serif;
        }

        .artist {
          margin-top: 0.1rem;

          small {
            font-size: 10px;
            color: var(---textPrimary);
            font-family: "Baguede", sans-serif;
          }
        }
      }
    }
  }

  #user__playlist__card {
    & > div {
      z-index: 2;
    }

    ::before {
      top: 0;
      right: 0;
      z-index: 1;
      width: 40%;
      height: 22%;
      content: "";
      position: absolute;
      background: ${({ dominantColors }) =>
        dominantColors?.length
          ? `linear-gradient(90deg, ${dominantColors[1]} 10.39%, ${dominantColors[2]} 100.38%)`
          : "var(--gradientSecondary)"};
    }

    ::after {
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 40%;
      height: 22%;
      content: "";
      position: absolute;
      background: ${({ dominantColors }) =>
        dominantColors?.length
          ? `linear-gradient(90deg, ${dominantColors[1]} 10.39%, ${dominantColors[2]} 100.38%)`
          : "var(--gradientSecondary)"};
    }
  }

  #udux__playlist__card {
    & > div {
      z-index: 2;
    }

    ::before {
      top: 0;
      right: 0;
      z-index: 1;
      width: 40%;
      height: 22%;
      content: "";
      position: absolute;
      background: ${({ dominantColors }) =>
        dominantColors?.length
          ? `linear-gradient(180deg, ${dominantColors[0]} 10.39%, ${dominantColors[1]} 100.38%)`
          : "var(--gradientSecondary)"};
    }

    ::after {
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 40%;
      height: 22%;
      content: "";
      position: absolute;
      background: ${({ dominantColors }) =>
        dominantColors?.length
          ? `linear-gradient(180deg, ${dominantColors[0]} 10.39%, ${dominantColors[1]} 100.38%)`
          : "var(--gradientSecondary)"};
    }
  }
`

import { HeaderWrapper } from "./header.styles"
import { Button, FlexibleDiv } from "components"
import { Link, useNavigate } from "react-router-dom"
import UduxLogo from "assets/images/general/udux_logo.png"
import { Hamburger } from "assets/images/general/SVGExports"
import { useMainContext } from "context"

export const Header = () => {
  const navigate = useNavigate()
  const {
    state: { users, app },
  } = useMainContext()
  const { me } = users
  const { isLoggedIn } = app

  const menuLinks = [
    { name: "Premiums", linkUrl: "#" },
    { name: "FAQs", linkUrl: "#" },
    { name: "About Us", linkUrl: "#" },
    { name: "Download App", linkUrl: "#" },
  ]

  return (
    <HeaderWrapper className="header__wrapper">
      <FlexibleDiv
        className="header__section"
        flexDir="row"
        justifyContent="space-between"
        width="100%"
      >
        <Link to="/">
          <img src={UduxLogo} className="logo__wrapper" alt="udux-logo" />
        </Link>

        <FlexibleDiv
          flexDir="row"
          width="70%"
          justifyContent="space-between"
          className="actions__wrapper"
        >
          <FlexibleDiv
            width="60%"
            justifyContent="space-around"
            className="tablet__wrapper"
          >
            {menuLinks.map((value, idx) => (
              <Link className="link__text" key={idx} to={value.linkUrl}>
                {value.name}
              </Link>
            ))}
          </FlexibleDiv>

          {isLoggedIn ? (
            <FlexibleDiv width="40%" className="hello__user">
              <p onClick={() => navigate("/me")}>
                Hi, {me?.profile?.firstName || me?.username}
              </p>
            </FlexibleDiv>
          ) : (
            <FlexibleDiv width="40%" className="btn__wrap">
              <Button
                className="login__btn"
                color="var(--black)"
                onClick={() => navigate("/login")}
                bold
                ltr
              >
                <span>Login</span>
              </Button>

              <Button
                className="create__acct__btn"
                color="var(--black)"
                onClick={() => navigate("/signup")}
                contained
                bold
                ltr
              >
                <span>Create Account</span>
              </Button>
            </FlexibleDiv>
          )}
        </FlexibleDiv>
        <Hamburger
          className="hamburger__icon"
          onClick={() => {
            const toggleMenu =
              document.querySelector<HTMLInputElement>("#toggle-menu")
            if (toggleMenu) {
              toggleMenu.checked = true
            }
          }}
          stroke="#1CA787"
        />

        {/* Mobile Menu Container */}
      </FlexibleDiv>
      <input type="checkbox" id="toggle-menu" />
      <FlexibleDiv className="mobile__overlay">
        <FlexibleDiv
          width="100%"
          height="100%"
          className="mobile__menu__wrapper"
          flexDir="column"
        >
          <div
            className="close-button"
            onClick={() => {
              const toggleMenu =
                document.querySelector<HTMLInputElement>("#toggle-menu")
              if (toggleMenu) {
                toggleMenu.checked = false
              }
            }}
          >
            <span>&times;</span>
          </div>

          {menuLinks.map((value, idx) => (
            <FlexibleDiv
              flexDir="column"
              className="link__background"
              key={idx}
            >
              <Link to={value.linkUrl}>{value.name}</Link>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </HeaderWrapper>
  )
}

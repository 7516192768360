import { Input } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { IoChevronDownCircleSharp } from "react-icons/io5";
import { validateBasicField } from "utils";
import { FlexibleDiv } from "../Box";
import DateSelector, { DateSelectorProps } from "../DateInput/DateSelector";
import { DSSWrap } from "./date-sequence-selector.styles";
import { DateType, StatusState } from "types";

interface DateState {
  day: Date | Dayjs | string;
  month: Date | Dayjs | string;
  year: Date | Dayjs | string;
}

export type DateSequenceSelectorProps = {
  name?: string;
  label?: string;
  small?: boolean;
  nullable?: boolean;
  defaultValue?: string;
  mb?: string;
  mt?: string;
  borderBottom?:string;
  singleDateLabel?:string;

  handleDateChange: (value: Dayjs) => void;
} & Pick<DateSelectorProps, "format" | "padding" | "validator">;

export const DateSequenceSelector = ({
  handleDateChange,
  small,
  label,
  nullable,
  ...props
}: DateSequenceSelectorProps) => {
  const [date, setDate] = React.useState<DateState>({
    day: "",
    month: "",
    year: "",
  });
  const [error, setError] = React.useState("");
  const [dayStatus, setDayStatus] = React.useState<StatusState>({});
  const [monthStatus, setMonthStatus] = React.useState<StatusState>({});
  const [yearStatus, setYearStatus] = React.useState<StatusState>({});
  const currentYear = dayjs().year();

  const handleChange = (dateType: DateType, newDate: Dayjs) => {

    const updatedDate = {
      day: dateType === "day" ? newDate.format("DD") : date.day,
      month: dateType === "month" ? newDate.format("MM") : date.month,
      year: dateType === "year" ? newDate.format("YYYY") : date.year,
    };
    // Update the date state
    setDate(updatedDate);

    // Check if all parts are available to call handleDateChange
    if (updatedDate.day && updatedDate.month && updatedDate.year) {
      handleDateChange(dayjs(`${updatedDate.year}-${updatedDate.month}-${updatedDate.day}`));
    }
  };

  React.useEffect(() => {
    if (dayStatus?.err || monthStatus?.err || yearStatus?.err) {
      setError("Please select correct date of birth");
    } else {
      setError("");
    }
  }, [dayStatus, monthStatus, yearStatus]);

  const disableYear = (current: any) => {
    return current.year() < currentYear - 80 || current.year() > currentYear;
  };

  return (
    <DSSWrap small={small} hasLabel={Boolean(label)} {...props}>
      {label && (
        <FlexibleDiv className="dss-label-wrap">
          <p className="dss-label">{label}</p>
        </FlexibleDiv>
      )}

      <Input.Group>
        <div className="group__cell day__cell">
          <label htmlFor="day">{props?.singleDateLabel}</label>
          <DateSelector
            name="day"
            format="DD"
            picker="date" // "day" was the option before upgrade
            small={small}
            setStatus={setDayStatus}
            placeholder="Date"
            borderBottom={props?.borderBottom}
            disabledDate={disableYear}
            validator={validateBasicField}
            hasError={Boolean(dayStatus?.err)}
            hasSuccess={Boolean(dayStatus?.success)}
            font_family={'"Bw Modelica", sans-serif'}
            handleChange={newDate => handleChange("day", newDate)} // Updated to "day"
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  dayStatus?.err
                    ? "var(--primaryDanger)"
                    : dayStatus?.success
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
            nullable={nullable}
          />
        </div>

        <div className="group__cell month__cell">
          <label htmlFor="month">{props?.singleDateLabel}</label>
          <DateSelector
            name="month"
            format="MM"
            picker="month"
            small={small}
            borderBottom={props?.borderBottom}
            placeholder="Month"
            setStatus={setMonthStatus}
            disabledDate={disableYear}
            validator={validateBasicField}
            hasError={Boolean(monthStatus?.err)}
            hasSuccess={Boolean(monthStatus?.success)}
            font_family={'"Baguede", sans-serif'}
            handleChange={newDate => handleChange("month", newDate)} // Updated to "month"
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  monthStatus?.err
                    ? "var(--primaryDanger)"
                    : monthStatus?.success
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
            nullable={nullable}
          />
        </div>

        <div className="group__cell year__cell">
          <label htmlFor="year">{props?.singleDateLabel}</label>
          <DateSelector
            name="year"
            format="YYYY"
            picker="year"
            borderBottom={props?.borderBottom}
            small={small}
            placeholder="Year"
            setStatus={setYearStatus}
            validator={validateBasicField}
            hasError={Boolean(yearStatus?.err)}
            hasSuccess={Boolean(yearStatus?.success)}
            disabledDate={disableYear}
            handleChange={newDate => handleChange("year", newDate)} // Updated to "year"
            font_family={'"Bw Modelica", sans-serif'}
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  yearStatus?.err
                    ? "var(--primaryDanger)"
                    : yearStatus?.success
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
            nullable={nullable}
          />
        </div>
      </Input.Group>

      {error && (
        <FlexibleDiv
          className="error__message"
          justifyContent="flex-start"
          margin="5px 0 0"
        >
          <p>{error}</p>
        </FlexibleDiv>
      )}
    </DSSWrap>
  );
};

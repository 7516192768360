import { IconProps } from "types";

export const GotoAlbumIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "16"}
    height={height || "15"}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.53588 0C3.3806 0 0 3.3645 0 7.5C0 11.6355 3.3806 15 7.53588 15C11.6912 15 15.0718 11.6355 15.0718 7.5C15.0718 3.3645 11.6912 0 7.53588 0ZM4.0641 6.04125C3.86742 6.50325 3.76794 6.99375 3.76794 7.5H2.26077C2.26077 6.792 2.40093 6.10425 2.67599 5.45625C2.94201 4.83075 3.32182 4.26975 3.80638 3.7875C4.29093 3.30525 4.85462 2.92725 5.48311 2.6625L6.07016 4.044C5.62102 4.233 5.2186 4.503 4.87195 4.848C4.5253 5.193 4.25401 5.59425 4.0641 6.04125ZM7.53061 9C6.69563 9 6.01816 8.3265 6.01816 7.49475C6.01816 6.663 6.69488 5.9895 7.53061 5.9895C8.36634 5.9895 9.04306 6.66375 9.04306 7.49475C9.04306 8.32575 8.36634 9 7.53061 9Z"
      fill={color}
    />
  </svg>
);

import { DominantColors } from "context";
import styled from "styled-components";

interface PJWrapProps {
  dominantColors?: DominantColors;
}

export const PJWrap = styled.div<PJWrapProps>`
  height: 380px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 30px 10px 0px;
  padding: ${({ dominantColors }) =>
    dominantColors?.length ? `0px 20px 10px 30px` : "0px 30px 10px 0px"};
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 0%, ${dominantColors[1]} 100%)`
      : ""};

  @media screen and (max-width: 950px) {
    padding: 20px;
    height: 300px;
  }

  .playlist__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    min-height: 85%;
    align-items: flex-start;
    @media screen and (max-width: 1244px) {
      font-size: 0.6rem;
      min-height: 100%;
      gap: 15px;
    }
  }

  .jumbottron__top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 1244px) {
      gap: 15px;
      justify-content: space-between;
    }

    .subtile {
      color: var(--primaryWhite);
      font-size: 0.9rem;
      line-height: 25.6px;
      font-weight: 800;
      text-transform: capitalize;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 1244px) {
        font-size: 0.6rem;
      }
    }
    .playlist__title {
      color: var(--primaryWhite);
      line-height: 4rem;
      font-size: 60px;
      text-transform: capitalize;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 1244px) {
        font-size: 1.2rem;
        padding: 0;
        line-height: 1.2rem;
      }
    }

    .playlist__description {
      color: var(--primaryWhite);
      line-height: 28px;
      font-weight: 500;
      max-width: 650px;
      font-size: 1.1rem;
      opacity: 0.8;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 1244px) {
        display: none;
      }
    }
  }

  .stats__display {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1244px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      justify-content: flex-start;
      gap: 30px;
    }

    .curator {
      font-size: 1rem;
      font-weight: 400;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 1244px) {
        font-size: 0.9rem;
      }
    }

    .statistics__wrap {
      display: flex;
      align-items: center;
      gap: 20px;

      @media screen and (max-width: 1244px) {
        gap: 10px;
      }

      .statistics {
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 1rem;
        line-height: 20px;
        font-weight: 400;
        font-family: "Bw Modelica", sans-serif;
        @media screen and (max-width: 1244px) {
          font-size: 0.8rem;
        }
        .info,
        .number {
          color: var(--primaryWhite);
        }
      }
    }
  }

  .mobile__playlist__description {
    display: none;
    color: var(--primaryWhite);
    line-height: 28px;
    font-weight: 500;
    max-width: 95%;
    font-size: 1.1rem;
    opacity: 0.8;
    font-family: "Bw Modelica", sans-serif;
    @media screen and (max-width: 1244px) {
      display: block;
      font-size: 0.9rem;
      line-height: 25px;
    }
  }

  .player__controls {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    @media screen and (max-width: 1244px) {
      display: none;
    }

    .icon__wrap {
      height: 35px;
      width: 35px;
      display: flex;
      color: var(--primaryWhite);
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      svg {
        path {
          transition: all 0.5s ease;
        }
      }

      &:hover {
        color: var(--uduxYellowPrimary);
      }
    }
  }

  .cover__art {
    width: 300px;
    min-width: 300px;
    height: 320px;
    position: absolute;
    bottom: 15px;
    right: 30px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }

    @media screen and (max-width: 1244px) {
      width: 200px;
      min-width: 200px;
      height: 220px;
      top: 20px;
      right: 20px;
    }
    @media screen and (max-width: 765px) {
      width: 130px;
      min-width: 130px;
      height: 150px;
    }
  }
`;
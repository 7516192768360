import { IconProps } from "types";

export const CollabIcon = ({
  color = "#FBBA12",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "27"}
    height={height || "28"}
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.0625 18.2188V17.375H3.375V18.2188C3.375 19.7852 3.99726 21.2875 5.1049 22.3951C6.21254 23.5027 7.71482 24.125 9.28125 24.125H11.8125V22.4375H9.28125C8.16237 22.4375 7.08931 21.993 6.29814 21.2019C5.50697 20.4107 5.0625 19.3376 5.0625 18.2188Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M20.25 9.78125V10.625H21.9375V9.78125C21.9375 8.21482 21.3152 6.71254 20.2076 5.6049C19.1 4.49726 17.5977 3.875 16.0313 3.875H13.5V5.5625H16.0313C16.5853 5.5625 17.1339 5.67162 17.6457 5.88363C18.1575 6.09565 18.6226 6.4064 19.0144 6.79814C19.4061 7.18989 19.7169 7.65496 19.9289 8.1668C20.1409 8.67865 20.25 9.22724 20.25 9.78125Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M9.28125 9.78125H4.21875C3.54742 9.78125 2.90359 10.0479 2.42889 10.5226C1.95418 10.9973 1.6875 11.6412 1.6875 12.3125V14H3.375V12.3125C3.375 12.0887 3.46389 11.8741 3.62213 11.7159C3.78036 11.5576 3.99497 11.4688 4.21875 11.4688H9.28125C9.50503 11.4688 9.71964 11.5576 9.87787 11.7159C10.0361 11.8741 10.125 12.0887 10.125 12.3125V14H11.8125V12.3125C11.8125 11.6412 11.5458 10.9973 11.0711 10.5226C10.5964 10.0479 9.95258 9.78125 9.28125 9.78125Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M6.75 8.9375C7.41751 8.9375 8.07003 8.73956 8.62505 8.36871C9.18007 7.99786 9.61265 7.47076 9.86809 6.85406C10.1235 6.23736 10.1904 5.55876 10.0602 4.90407C9.92993 4.24939 9.60849 3.64802 9.13649 3.17602C8.66448 2.70401 8.06312 2.38258 7.40843 2.25235C6.75374 2.12213 6.07514 2.18896 5.45844 2.44441C4.84174 2.69985 4.31464 3.13244 3.94379 3.68745C3.57294 4.24247 3.375 4.89499 3.375 5.5625C3.375 6.45761 3.73058 7.31605 4.36351 7.94899C4.99645 8.58192 5.85489 8.9375 6.75 8.9375ZM6.75 3.875C7.08376 3.875 7.41002 3.97397 7.68752 4.1594C7.96503 4.34482 8.18132 4.60837 8.30905 4.91672C8.43677 5.22507 8.47019 5.56437 8.40508 5.89172C8.33996 6.21906 8.17924 6.51974 7.94324 6.75574C7.70724 6.99175 7.40656 7.15246 7.07921 7.21758C6.75187 7.28269 6.41257 7.24927 6.10422 7.12155C5.79587 6.99383 5.53232 6.77753 5.3469 6.50003C5.16147 6.22252 5.0625 5.89626 5.0625 5.5625C5.0625 5.11495 5.24029 4.68573 5.55676 4.36926C5.87322 4.05279 6.30245 3.875 6.75 3.875Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M22.7812 21.5938H17.7188C17.0474 21.5938 16.4036 21.8604 15.9289 22.3351C15.4542 22.8098 15.1875 23.4537 15.1875 24.125V25.8125H16.875V24.125C16.875 23.9012 16.9639 23.6866 17.1221 23.5284C17.2804 23.3701 17.495 23.2813 17.7188 23.2813H22.7812C23.005 23.2813 23.2196 23.3701 23.3779 23.5284C23.5361 23.6866 23.625 23.9012 23.625 24.125V25.8125H25.3125V24.125C25.3125 23.4537 25.0458 22.8098 24.5711 22.3351C24.0964 21.8604 23.4526 21.5938 22.7812 21.5938Z"
      fill={color}
      fillOpacity="0.7"
    />
    <path
      d="M16.876 17.375C16.876 18.0425 17.0739 18.695 17.4448 19.25C17.8156 19.8051 18.3427 20.2376 18.9594 20.4931C19.5761 20.7485 20.2547 20.8154 20.9094 20.6852C21.5641 20.5549 22.1655 20.2335 22.6375 19.7615C23.1095 19.2895 23.4309 18.6881 23.5611 18.0334C23.6914 17.3787 23.6245 16.7001 23.3691 16.0834C23.1136 15.4667 22.681 14.9396 22.126 14.5688C21.571 14.1979 20.9185 14 20.251 14C19.3559 14 18.4974 14.3556 17.8645 14.9885C17.2316 15.6215 16.876 16.4799 16.876 17.375ZM21.9385 17.375C21.9385 17.7088 21.8395 18.035 21.6541 18.3125C21.4687 18.59 21.2051 18.8063 20.8968 18.934C20.5884 19.0618 20.2491 19.0952 19.9218 19.0301C19.5944 18.965 19.2937 18.8042 19.0577 18.5682C18.8217 18.3322 18.661 18.0316 18.5959 17.7042C18.5308 17.3769 18.5642 17.0376 18.6919 16.7292C18.8197 16.4209 19.0359 16.1573 19.3135 15.9719C19.591 15.7865 19.9172 15.6875 20.251 15.6875C20.6985 15.6875 21.1278 15.8653 21.4442 16.1818C21.7607 16.4982 21.9385 16.9274 21.9385 17.375Z"
      fill={color}
      fillOpacity="0.7"
    />
  </svg>
);

import {
  AlbumCard,
  AlbumCardProps,
  ArtisteCard,
  ArtisteCardProps,
  FlexibleDiv,
  Loader,
  PlayTrackProps,
  PlaylistCard,
  SongCard,
  SongCardProps,
  TrackListItem,
  UserCard,
  UserCardProps,
} from "components"
import { Types, useMainContext } from "context"
import { isEmpty, uniqBy } from "lodash"
import { getSearchHistory } from "network"
import React from "react"
import { useQuery } from "react-query"
import { nowPlaying, playTrack, topSlideInFrom } from "utils"
import { NoSearchHistory } from "../NoSearchHistory"
import { SearchFilter } from "../SearchFilter"
import { SearchHistoryWrap } from "./search-history.styles"
import { Playlist } from "types/playlist"
import { Track } from "types/artist"

export const SearchHistory = () => {
  const {
    state: { users, search, player },
    playerRef,
    dispatch,
  } = useMainContext()
  const { me } = users
  const { filterSearch } = search
  const mainContentRef = React.useRef(null)

  React.useLayoutEffect(() => {
    topSlideInFrom(mainContentRef, { y: 20 })
  }, [filterSearch])

  const userId = me.id ?? ""

  const { data, isLoading } = useQuery(
    ["search-history"],
    () => getSearchHistory(userId),
    {
      enabled: !!userId,
    }
  )

  const searchHistory = data && data.data
  const playlists = searchHistory?.playlists
  const usersList = searchHistory?.users
  const artists = searchHistory?.artists
  const albums = searchHistory?.albums
  const tracks = searchHistory?.tracks

  return (
    <React.Fragment>
      {isLoading ? <Loader wrapperHeight="85vh" /> : getRenderView()}
    </React.Fragment>
  )

  function getRenderView() {
    if (!isEmpty(searchHistory)) {
      return (
        <SearchHistoryWrap playerActive={player.isPlayerActive}>
          <FlexibleDiv className="make__sticky">
            <FlexibleDiv className="search__header" justifyContent="flex-start">
              <h2>Recent Searches</h2>
            </FlexibleDiv>

            <FlexibleDiv className="search__filter__wrap">
              <FlexibleDiv className="search__filter__wrap__content">
                <SearchFilter />
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>

          <div
            className={`${
              filterSearch !== "tracks"
                ? "result__main"
                : "result__main__tracks"
            }`}
            ref={mainContentRef}
          >
            {filterSearch === "combined" && getAllHistoryView()}

            {filterSearch === "playlists" &&
              uniqBy(playlists, "id").map(pl => (
                <PlaylistCard
                  // handleNowPlaying={() => nowPlaying(pl, dispatch, playerRef)}
                  key={pl.id}
                  {...pl}
                />
              ))}

            {filterSearch === "albums" &&
              uniqBy(albums, "id").map(abl => (
                <AlbumCard
                  handleNowPlaying={() => nowPlaying(abl, dispatch, playerRef)}
                  key={abl.id}
                  {...abl}
                />
              ))}

            {filterSearch === "tracks" &&
              uniqBy(tracks, "id").map((sl, idx) => (
                <TrackListItem
                  handlePlayTrack={handlePlayTrack}
                  key={sl.id}
                  idx={idx}
                  id={sl.id}
                  track={sl as unknown as Track[]}
                />
              ))}

            {filterSearch === "artists" &&
              uniqBy(artists, "id").map(al => (
                <ArtisteCard key={al.id} {...al} />
              ))}

            {filterSearch === "users" &&
              uniqBy(usersList, "id").map(ul => (
                <UserCard key={ul.id} {...ul} />
              ))}
          </div>
        </SearchHistoryWrap>
      )
    } else {
      return <NoSearchHistory />
    }
  }

  function getAllHistoryView() {
    const allHistoryInfo = [
      ...(playlists || []).map(pl => ({ ...pl, type: "playlist" })),
      ...(albums || []).map(al => ({ ...al, type: "album" })),
      ...(artists || []).map(ar => ({ ...ar, type: "artist" })),
      ...(tracks || []).map(tr => ({ ...tr, type: "track" })),
      ...(usersList || []).map(u => ({ ...u, type: "user" })),
    ]

    return allHistoryInfo.map(ahi => {
      if (ahi.type === "playlist") {
        return (
          <PlaylistCard
            // handleNowPlaying={() => nowPlaying(ahi, dispatch, playerRef)}
            key={ahi.id}
            {...(ahi as Playlist)}
          />
        )
      }

      if (ahi.type === "album") {
        return (
          <AlbumCard key={ahi.id} {...(ahi as unknown as AlbumCardProps)} />
        )
      }

      if (ahi.type === "artist") {
        return <ArtisteCard key={ahi.id} {...(ahi as ArtisteCardProps)} />
      }

      if (ahi.type === "track") {
        return (
          <SongCard
            {...(ahi as unknown as SongCardProps)}
            handlePlayTrack={handlePlayTrack}
            key={ahi.id}
          />
        )
      }

      if (ahi.type === "user") {
        return <UserCard key={ahi.id} {...(ahi as UserCardProps)} />
      }

      return []
    })
  }

  function handlePlayTrack({ track }: PlayTrackProps) {
    dispatch({ type: Types.REPLACE_QUEUED_TRACKS, payload: tracks })
    // playTrack(dispatch, playerRef, track)
    playTrack(dispatch, playerRef, track[0])
  }
}

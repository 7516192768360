import { IconProps } from "types";

export const FavoriteIcon = ({
  color = "#eaeaea",
  width,
  height,
  transform,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 35 35"
    fill={color}
    {...props}
  >
    <g
      id="favorite"
      data-name="favorite icon"
      transform={transform || "translate(2 6.5)"}
    >
      <g id="music-lover" transform="translate(0 0)">
        <path
          id="Path_1199"
          data-name="Path 1199"
          d="M28.251,3.24a8.845,8.845,0,0,0-12.8,0,8.846,8.846,0,0,0-12.8,0A9.474,9.474,0,0,0,0,9.864a9.475,9.475,0,0,0,2.651,6.624l0,0L14.815,28.9a.884.884,0,0,0,1.271,0l12.16-12.407,0,0A9.613,9.613,0,0,0,28.251,3.24ZM26.973,15.161,15.451,26.917,3.929,15.161a7.69,7.69,0,0,1,0-10.6,7.076,7.076,0,0,1,10.239,0l.64.663a.884.884,0,0,0,1.28,0l.64-.663a7.076,7.076,0,0,1,10.24,0A7.69,7.69,0,0,1,26.973,15.161Zm0,0"
          transform="translate(0 -0.5)"
          fill={color}
        />
        <path
          id="Path_1200"
          data-name="Path 1200"
          d="M174.741,118.18l-3.918-2.612a.816.816,0,0,0-1.269.679v4.682a2.992,2.992,0,1,0,1.633,2.665v-5.822l2.65,1.766a.816.816,0,1,0,.905-1.358Zm-6.548,6.775a1.361,1.361,0,1,1,1.361-1.361A1.362,1.362,0,0,1,168.192,124.954Zm0,0"
          transform="translate(-154.69 -107.504)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

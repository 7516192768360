import { IconProps } from "types";

export const AddToQueueIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "19"}
    height={height || "18"}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-0.00585938 5.38672V6.73328V16.1592V17.5058H11.6866H13.1482V16.1592V6.73328V5.38672H11.6866H1.4557H-0.00585938ZM1.4557 6.73328H11.6866V16.1592H1.4557V6.73328Z"
      fill={color}
    />
    <path
      d="M2.91602 2.69531V4.04188H14.6085V14.8144H16.0701V4.04188V2.69531H14.6085H2.91602Z"
      fill={color}
    />
    <path
      d="M5.83984 0V1.34656H17.5324V12.1191H18.9939V1.34656V0H17.5324H5.83984Z"
      fill={color}
    />
  </svg>
);

import { FriendsIcon } from "assets/icons/FriendsIcon"
import { HelpIcon } from "assets/icons/HelpIcon"
import { Logo } from "assets/images/general/SVGExports"
import { Button } from "components/lib"
import { useMainContext } from "context"
import { GeneralSearchBar } from "pages/Search/components/GeneralSearchBar"
import React, { useState } from "react"
import { CiMenuBurger } from "react-icons/ci"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FlexibleDiv, Section } from "../../../lib/Box/styles"
import { HeaderWrap } from "./header.styles"
import { NotificationWidget } from "./NotificationWidget"
import { SettingsWidget } from "./SettingsWidget/SettingsWidget"

export type PlayerHeaderProps = {
  footerOnly?: boolean
  isLoggedIn?: boolean
  isMenuActive?: boolean
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const PlayerHeader = ({
  footerOnly,
  isLoggedIn,
  setIsSidebarOpen,
}: PlayerHeaderProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    state: { users },
  } = useMainContext()

  const { me } = users

  const toggleMenu = () => {
    setIsSidebarOpen(prev => !prev)
  }

  React.useEffect(() => {
    setIsSearchOpen(pathname === "/search")
  }, [pathname])

  return (
    <HeaderWrap isSearchOpen={isSearchOpen} footerOnly={footerOnly}>
      {isLoggedIn ? (
        <Section
          height="100%"
          justifyContent="flex-start"
          flexWrap="nowrap"
          className="web__header"
        >
          <FlexibleDiv
            alignItems="center"
            justifyContent="flex-start"
            className="menu__logo__wrap"
          >
            <div onClick={toggleMenu} className="menu__svg__wrap">
              <CiMenuBurger className="menu__svg" />
            </div>
            <div className="udux__logo__wrap" onClick={() => navigate("/")}>
              <Logo className="logo" />
            </div>
          </FlexibleDiv>
          <FlexibleDiv
            className="routechange__wrap"
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            <FlexibleDiv className="playing__check" flexWrap="nowrap">
              <div>
                <Link className="active" to="#">
                  Now Playing
                </Link>
                <Link to="#">Recently Played</Link>
                <Link to="#">Genres</Link>
              </div>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            alignItems="center"
            className="header__actions"
            flexWrap="nowrap"
          >
            <FlexibleDiv
              alignItems="flex-end"
              justifyContent="flex-end"
              className="action action__btns"
              flexWrap="nowrap"
            >
              <Button className="artist__btn">Artist/Label</Button>

              {!me.isPremium && (
                <Button
                  className="premium__btn"
                  onClick={() => navigate("/payment")}
                >
                  Go Premium
                </Button>
              )}
            </FlexibleDiv>

            <FlexibleDiv className="action action__search">
              <GeneralSearchBar />
            </FlexibleDiv>
            <FlexibleDiv
              className="action action__account__loggedIn"
              flexWrap="nowrap"
            >
              <div className="notifications__wrap">
                <NotificationWidget />
                <FriendsIcon width={"24px"} height={"24px"} />
                <HelpIcon width={"24px"} height={"24px"} />
              </div>
              <div>
                <SettingsWidget />
              </div>
            </FlexibleDiv>
          </FlexibleDiv>
        </Section>
      ) : (
        <Section
          height="100%"
          justifyContent="flex-start"
          flexWrap="nowrap"
          className="web__header"
        >
          <FlexibleDiv
            alignItems="center"
            justifyContent="flex-start"
            className="menu__logo__wrap"
          >
            <div onClick={toggleMenu} className="menu__svg__wrap">
              <CiMenuBurger className="menu__svg" />
            </div>
            <div className="udux__logo__wrap" onClick={() => navigate("/")}>
              <Logo className="logo" />
            </div>
          </FlexibleDiv>

          <FlexibleDiv
            className="routechange__wrap"
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            <FlexibleDiv className="playing__check" flexWrap="nowrap">
              <div>
                <Link className="active" to="#">
                  Now Playing
                </Link>
                <Link to="#">Recently Played</Link>
                <Link to="#">Genres</Link>
              </div>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            alignItems="center"
            className="header__actions"
            flexWrap="nowrap"
          >
            <FlexibleDiv className="action action__btns" flexWrap="nowrap">
              <Button className="artist__btn">Artist/Label</Button>
              <Button
                className="premium__btn"
                onClick={() => navigate("/payment")}
              >
                Go Premium
              </Button>
            </FlexibleDiv>

            <FlexibleDiv className="action action__search">
              <GeneralSearchBar />
            </FlexibleDiv>
            <FlexibleDiv className="action action__account" flexWrap="nowrap">
              <Link to="/signup">Create an Account</Link>
              <span className="stick"></span>
              <Link to="/login">Log in</Link>
            </FlexibleDiv>
          </FlexibleDiv>
        </Section>
      )}
    </HeaderWrap>
  )
}

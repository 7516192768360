import { IconProps } from "types";

export const AddSongQueueIcon = ({ width, height }: IconProps) => (
  <svg
    width={width || "27"}
    height={height || "22"}
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2064 7.92969C23.0202 7.92969 26.1118 11.0213 26.1118 14.8351C26.1118 18.6488 23.0202 21.7404 19.2064 21.7404C15.3926 21.7404 12.301 18.6488 12.301 14.8351C12.301 11.0213 15.3926 7.92969 19.2064 7.92969ZM22.8174 15.3783L22.8213 15.3743C22.8932 15.3016 22.9476 15.2179 22.9846 15.1288C23.0214 15.0396 23.0421 14.942 23.0427 14.8397V14.8351V14.8305C23.0421 14.7281 23.0214 14.6305 22.9846 14.5414C22.9471 14.4508 22.8916 14.3661 22.8181 14.2925L19.749 11.2234C19.4493 10.9239 18.9635 10.9239 18.6638 11.2234C18.3642 11.5231 18.3642 12.0089 18.6638 12.3086L20.4231 14.0678H16.1373C15.7137 14.0678 15.3701 14.4114 15.3701 14.8351C15.3701 15.2587 15.7137 15.6023 16.1373 15.6023H20.4231L18.6638 17.3615C18.3642 17.6612 18.3642 18.147 18.6638 18.4467C18.9635 18.7463 19.4493 18.7463 19.749 18.4467L22.8174 15.3783Z"
      fill="#D9D9D9"
    />
    <path
      d="M24.5781 1.40871C24.5781 0.773091 24.0628 0.257812 23.4272 0.257812H1.17653C0.540898 0.257812 0.0256348 0.773091 0.0256348 1.40871C0.0256348 2.04432 0.540914 2.5596 1.17653 2.5596H23.4272C24.0628 2.5596 24.5781 2.04432 24.5781 1.40871Z"
      fill="#D9D9D9"
    />
    <path
      d="M24.5781 6.01027C24.5781 5.37465 24.0628 4.85938 23.4272 4.85938H1.17653C0.540898 4.85938 0.0256348 5.37465 0.0256348 6.01027C0.0256348 6.64589 0.540914 7.16117 1.17653 7.16117H15.6862C16.758 6.66858 17.9504 6.3939 19.2072 6.3939C20.464 6.3939 21.6565 6.66858 22.7282 7.16117H23.4272C24.0628 7.16117 24.5781 6.64589 24.5781 6.01027Z"
      fill="#D9D9D9"
    />
    <path
      d="M10.9065 16.3682H1.17653C0.540914 16.3682 0.0256348 15.8529 0.0256348 15.2173C0.0256348 14.5817 0.540898 14.0664 1.17653 14.0664H10.8017C10.779 14.3191 10.7673 14.5751 10.7673 14.8337C10.7673 15.3577 10.8151 15.8705 10.9065 16.3682Z"
      fill="#D9D9D9"
    />
    <path
      d="M11.3427 11.7666H1.17653C0.540914 11.7666 0.0256348 11.2513 0.0256348 10.6157C0.0256348 9.98014 0.540898 9.46484 1.17653 9.46484H12.6965C12.1309 10.1497 11.6712 10.9254 11.3427 11.7666Z"
      fill="#D9D9D9"
    />
  </svg>
);

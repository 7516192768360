import dayjs from "dayjs"
import uduXWhiteLogo from "assets/images/general/udux_white_logo.svg"
import { truncate } from "utils"
import { isAlbum, isPlaylist } from "types"
import { Album, DisplayArtist, Track } from "types/artist"
import { Playlist } from "types/playlist"

export function getCoverArt(entity?: Album | Playlist) {
  let ca = ""

  if (isPlaylist(entity)) {
    ca = entity.coverArt.url
  } else if (isAlbum(entity)) {
    ca = entity.metadata.coverArt.url
  }

  return ca
}

export function getTracksLength(
  tracks: Track[] | undefined,
  module: "album" | "playlist" | undefined
) {
  if (tracks) {
    if (module === "album") {
      return tracks.length
    }

    if (module === "playlist") {
      return tracks.map(t => t.track).length
    }
  }
}

export function getPlaylistTitle(entity?: Album | Playlist) {
  let title = ""

  if (isPlaylist(entity)) {
    title = truncate(entity.title)
  } else if (isAlbum(entity)) {
    title = truncate(entity.metadata.title)
  }

  return title
}

export function getTypeAndYear(entity?: Album | Playlist) {
  let playlistType = ""

  if (isAlbum(entity)) {
    playlistType = `${entity.metadata.entityType}:
              ${dayjs(entity.metadata.originalReleaseDate).format("YYYY")}`
  } else if (isPlaylist(entity)) {
    if (entity.type.toLowerCase() === "solo") {
      playlistType = "Solo Playlist"
    }

    if (entity.type.toLowerCase() === "collaborative") {
      playlistType = "Collab Playlist"
    }

    if (entity.type.toLowerCase() === "managed") {
      playlistType = "Public Playlist"
    }

    playlistType = "Public Playlist"
  }

  return playlistType
}

export function getPlaylistAvatar(
  entity?: Album | Playlist,
  mainArtist?: DisplayArtist,
  handleClick?: () => void
) {
  if (isAlbum(entity)) {
    return (
      <div className="artist__avatar">
        <img
          src={mainArtist?.artist?.profilePicture?.url || getCoverArt(entity)}
          alt="artist name"
        />
      </div>
    )
  } else if (isPlaylist(entity)) {
    if (entity.type === "MANAGED") {
      return (
        <div className="udux__logo">
          <img src={uduXWhiteLogo} alt="udux" />
        </div>
      )
    } else if (entity.type === "SOLO") {
      return (
        <div className="playlist__creator">
          <div className="avatar">
            <img
              src={entity.user?.profile?.profilePicture?.url}
              alt={entity.user?.username}
            />
          </div>
          <p className="creator__name" onClick={handleClick}>
            {entity?.user?.username}
          </p>
        </div>
      )
    }
  }
}

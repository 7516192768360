import { MetaData } from "types/artist"

export function getTrackDuration(entity?: MetaData) {
  if (entity?.entityType?.toLowerCase() === "track") {
    if (entity.duration) {
      const matches = entity.duration.match(/\d+/g)

      if (matches) {
        const secMatch = matches[1] as unknown as number
        const mins = matches[0]
        const secs = secMatch < 10 ? `0${matches[1]}` : matches[1]

        return `${mins}:${secs}`
      }
    } else if (entity.durationMS) {
      const minutes = Math.floor(entity.durationMS / 60000)
      const seconds = parseInt(
        ((entity.durationMS % 60000) / 1000).toFixed(0),
        10
      )
      const secs = seconds < 10 ? `0${seconds}` : seconds

      return `${minutes}:${secs}`
    }
  }
}

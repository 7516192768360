import { Loader } from "components"
import { Config } from "config"
import { SourceService } from "context"
import { useRegisterService } from "network/hooks/use-payment-register"
import { useState } from "react"
import { SignUpPayload } from "types"
import StepOne from "./Steps/StepOne/StepOne"
import StepTwo from "./Steps/StepTwo/StepTwo"

const PaymentRegister = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [stepOneData, setStepOneData] = useState<Partial<SignUpPayload>>()
  const { mutate, isLoading } = useRegisterService()

  const onFinish = (values: SignUpPayload) => {
    values = { ...stepOneData, ...values }

    // Construct the signup payload
    const payload = {
      password: values.password,
      acceptedTermsAndConditions: values.acceptedTermsAndConditions,
      gender: values.gender,
      countryCode: values.countryCode,
      phoneNumber: values.phoneNumber.startsWith("+234")
        ? values.phoneNumber
        : `${values.countryCode}${values.phoneNumber}`,
      emailAddress: values.emailAddress,
      dateOfBirth: values.dateOfBirth,
      username: values.username,
      sourceService: Config.sourceService as SourceService,
    }

    // Call the signup mutation
    mutate(payload)
  }

  const setStepOne = (values: Partial<SignUpPayload>) => {
    setStepOneData(values)
    setCurrentStep(2)
  }

  const moveToStepOne = () => {
    setCurrentStep(1)
  }

  return (
    <>
      {isLoading ? (
        <Loader variant="green" height="100vh" />
      ) : (
        <>
          {currentStep === 1 ? (
            <StepOne getStepOneData={setStepOne} />
          ) : (
            <StepTwo goBack={moveToStepOne} registerUser={onFinish} />
          )}
        </>
      )}
    </>
  )
}

export default PaymentRegister

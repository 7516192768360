import React from "react"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { ChartData, ChartOptions } from "chart.js"
import { FlexibleDiv } from "components"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface BarChartProps {
  data: ChartData<"bar">
  options?: ChartOptions<"bar">
}

export const BarChart: React.FC<BarChartProps> = ({ data, options }) => {
  return (
    <FlexibleDiv>
      <Bar data={data} options={options} />
    </FlexibleDiv>
  )
}

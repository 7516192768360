import { take } from "lodash"
import React from "react"
import { truncate } from "."
import { isEmpty } from "./isObjEmpty"
import { DisplayArtist } from "types/artist"

export function getDisplayArtist(trackInfo: any, navigate?: any) {
  if (!isEmpty(trackInfo)) {
    let da: DisplayArtist[] | DisplayArtist = []

    if (trackInfo?.track?.metadata?.displayArtists) {
      da = trackInfo.track.metadata.displayArtists
    } else if (trackInfo?.metadata?.displayArtists) {
      da = trackInfo.metadata.displayArtists
    }

    const shouldTruncate = checkNamesLength(take(da as DisplayArtist[], 3))

    return da && Array.isArray(da) ? (
      take(da, 3).map((d, idx, list) => (
        <React.Fragment key={idx}>
          <small
            className="artist"
            key={idx}
            onClick={() => navigate(`/artist/${d.artist ? d.artist.id : d.id}`)}
          >
            {shouldTruncate
              ? idx + 1 < list.length
                ? d.artist.stageName
                : truncate(d.artist.stageName, 5)
              : d.artist.stageName}
            {idx + 1 < list.length ? ", " : list.length > 3 ? ", ..." : ""}{" "}
          </small>{" "}
        </React.Fragment>
      ))
    ) : (
      <small
        className="artist"
        onClick={() => {
          if (!Array.isArray(da)) {
            navigate(`/artist/${da.artist ? da.artist.id : da.id}`)
          }
        }}
      >
        {truncate(da.artist.stageName, 30)}
      </small>
    )
  }
}

export function checkNamesLength(list: any[]) {
  if (Array.isArray(list)) {
    return list.map(d => d.artist.stageName).join(", ").length > 25
  }

  return false
}

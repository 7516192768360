import { IconProps } from "types";

export const GotoArtistIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
    <svg width={width || "10"} height={height || "16"} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.25602 6.01335V6.65307H8.99891V5.75747H8.48469V6.65307H8.22758V6.52513H7.97046V6.26924H8.22758V5.75747H7.90722V2.75079C7.90722 1.13128 6.66434 0 4.88512 0C3.10591 0 1.86355 1.13128 1.86355 2.75079V5.75747H1.54267V6.26924H1.79978V6.52513H1.54267V6.65307H1.28556V5.75747H0.771335V6.65307H0.514224V6.01335H0V7.54868H0.514224V6.90896H0.771335V9.46476C0.771335 12.1703 2.29241 13.4804 4.11379 13.7596V14.0738H4.3709V14.6043C2.63257 14.7171 1.28556 15.3532 1.28556 15.3532H8.48469C8.48469 15.3532 7.13794 14.7171 5.39935 14.6043V14.0738H5.65646V13.7596C7.47835 13.4804 8.99891 12.1703 8.99891 9.46476V6.90896H9.25602V7.54868H9.77025V6.01335H9.25602ZM2.24921 2.75079C2.24921 1.33522 3.30826 0.383831 4.88512 0.383831C6.46199 0.383831 7.52155 1.33522 7.52155 2.75079V5.75747H2.24921V2.75079ZM8.48469 9.51338C8.48469 11.8325 7.2071 12.9778 5.65646 13.2532V12.7944H4.11379V13.2532C2.56315 12.9778 1.28556 11.8322 1.28556 9.51338V6.90896H1.54267V7.0369H1.79978V7.29279H1.54267V7.80457H2.05638V9.21195C2.05638 10.8038 3.28023 12.0267 4.88512 12.0267C6.49053 12.0267 7.71438 10.8038 7.71438 9.21195V7.80457H8.22758V7.29279H7.97046V7.0369H8.22758V6.90896H8.48469V9.51338Z" fill={color}/>
    <path d="M2.81416 4.9894C2.62775 5.00373 2.47657 5.13909 2.47657 5.28904C2.47554 5.43899 2.63083 5.56182 2.82007 5.5631C3.00982 5.56438 3.16151 5.43055 3.15894 5.26525C3.15611 5.1002 3.00159 4.97507 2.81416 4.9894Z" fill={color}/>
    <path d="M4.18966 4.9259C3.99786 4.93102 3.84462 5.07662 3.8477 5.24934C3.85027 5.42181 4.00634 5.56229 4.19609 5.56306C4.38532 5.56332 4.53856 5.41925 4.53805 5.24141C4.53702 5.06331 4.37992 4.92104 4.18966 4.9259Z" fill={color}/>
    <path d="M5.57886 4.9259C5.38757 4.92104 5.23125 5.06331 5.23047 5.24116C5.22945 5.41925 5.38371 5.56306 5.57243 5.56281C5.76167 5.56204 5.9185 5.42156 5.92056 5.24909C5.92339 5.07688 5.77067 4.93076 5.57886 4.9259Z" fill={color}/>
    <path d="M6.953 4.9894C6.76531 4.97507 6.61053 5.1002 6.60744 5.26525C6.60539 5.43055 6.75708 5.56438 6.94683 5.5631C7.13658 5.56182 7.29033 5.43899 7.29033 5.28904C7.28956 5.13909 7.13941 5.00373 6.953 4.9894Z" fill={color}/>
    <path d="M2.80464 4.03181C2.98796 3.99778 3.13811 3.84169 3.13503 3.67587C3.13297 3.51057 2.97947 3.40873 2.79847 3.4576C2.61823 3.50647 2.47656 3.67613 2.47656 3.82582C2.47631 3.97603 2.62132 4.06482 2.80464 4.03181Z" fill={color}/>
    <path d="M4.17237 3.22788C3.97594 3.244 3.82193 3.40495 3.82424 3.57742C3.82682 3.7504 3.98443 3.87578 4.1788 3.86504C4.37266 3.85327 4.52976 3.70511 4.52873 3.52752C4.52899 3.35019 4.36726 3.2115 4.17237 3.22788Z" fill={color}/>
    <path d="M5.59042 3.86504C5.7848 3.87578 5.94292 3.7504 5.94498 3.57742C5.94755 3.40495 5.79328 3.24374 5.59736 3.22788C5.40247 3.2115 5.24075 3.35019 5.24023 3.52752C5.23998 3.70511 5.39707 3.85352 5.59042 3.86504Z" fill={color}/>
    <path d="M6.96797 3.4576C6.7867 3.40873 6.63398 3.51057 6.63089 3.67587C6.62832 3.84143 6.77847 3.99778 6.9618 4.03181C7.14512 4.06508 7.29013 3.97603 7.28961 3.82608C7.28936 3.67664 7.14846 3.50673 6.96797 3.4576Z" fill={color}/>
    <path d="M3.48605 4.13115C3.29682 4.15239 3.14615 4.3072 3.14846 4.47046C3.15078 4.63372 3.30607 4.75475 3.49608 4.74426C3.68531 4.733 3.83855 4.58612 3.83598 4.41237C3.83341 4.23863 3.67554 4.11017 3.48605 4.13115Z" fill={color}/>
    <path d="M4.8851 4.05859C4.69072 4.05859 4.53362 4.20752 4.53517 4.38459C4.53671 4.56192 4.69278 4.70317 4.8851 4.70317C5.07742 4.70317 5.234 4.56192 5.23554 4.38459C5.23657 4.20752 5.07999 4.05859 4.8851 4.05859Z" fill={color}/>
    <path d="M6.2821 4.13115C6.09236 4.11017 5.93449 4.23888 5.93166 4.41263C5.9296 4.58637 6.08233 4.73325 6.27208 4.74451C6.46131 4.755 6.61712 4.63371 6.61969 4.47071C6.62149 4.30746 6.47082 4.15239 6.2821 4.13115Z" fill={color}/>
    <path d="M3.46004 2.49789C3.27029 2.54241 3.12296 2.72128 3.12502 2.88479C3.12759 3.04804 3.28032 3.14503 3.47006 3.11125C3.65981 3.07696 3.81511 2.91626 3.81254 2.74251C3.81048 2.56851 3.64927 2.45336 3.46004 2.49789Z" fill={color}/>
    <path d="M4.88484 2.98468C5.08307 2.98417 5.24351 2.84855 5.24479 2.67096C5.24634 2.49389 5.08564 2.33984 4.88484 2.33984C4.68455 2.33984 4.52437 2.49389 4.5254 2.67096C4.52617 2.84855 4.68738 2.98417 4.88484 2.98468Z" fill={color}/>
    <path d="M6.30956 2.49789C6.12084 2.45336 5.95963 2.56851 5.95706 2.74226C5.95449 2.91626 6.11082 3.07722 6.30005 3.11099C6.4898 3.14503 6.64201 3.04779 6.64458 2.88453C6.64715 2.72128 6.49983 2.54241 6.30956 2.49789Z" fill={color}/>
    <path d="M2.7887 2.5059C2.96559 2.43681 3.11318 2.25795 3.11061 2.09239C3.10855 1.92734 2.95634 1.84827 2.78279 1.93246C2.60898 2.01614 2.47708 2.21957 2.47657 2.369C2.47554 2.51921 2.61207 2.57397 2.7887 2.5059Z" fill={color}/>
    <path d="M4.16349 2.16804C4.36096 2.14475 4.52345 1.99275 4.52242 1.81517C4.52139 1.63758 4.35581 1.50273 4.15681 1.53113C3.95652 1.55928 3.80019 1.73508 3.80277 1.90703C3.80585 2.07976 3.965 2.19056 4.16349 2.16804Z" fill={color}/>
    <path d="M5.60868 2.16829C5.80614 2.19081 5.96632 2.08001 5.96864 1.90703C5.97121 1.73508 5.81437 1.55954 5.61511 1.53113C5.41585 1.50273 5.25052 1.63758 5.25001 1.81517C5.24872 1.99301 5.41096 2.14501 5.60868 2.16829Z" fill={color}/>
    <path d="M6.97845 2.50602C7.15483 2.57408 7.29136 2.51932 7.29084 2.36912C7.29033 2.21968 7.15817 2.01625 6.98462 1.93257C6.8103 1.84813 6.6586 1.92745 6.65629 2.0925C6.65346 2.25806 6.80156 2.43718 6.97845 2.50602Z" fill={color}/>
    <path d="M3.44406 1.47842C3.63381 1.42059 3.79167 1.24633 3.7891 1.07259C3.78704 0.899095 3.62378 0.797251 3.43403 0.864806C3.24428 0.93236 3.09953 1.13605 3.10158 1.29905C3.10416 1.46256 3.25431 1.53523 3.44406 1.47842Z" fill={color}/>
    <path d="M4.88485 1.26254C5.08822 1.26202 5.25354 1.13152 5.25457 0.954191C5.25586 0.776861 5.09079 0.617188 4.88485 0.617188C4.6789 0.617188 4.51435 0.776861 4.51563 0.954191C4.51666 1.13152 4.68147 1.26202 4.88485 1.26254Z" fill={color}/>
    <path d="M6.32554 1.47827C6.51581 1.53533 6.66545 1.4624 6.66802 1.29915C6.67059 1.13589 6.52532 0.932205 6.33557 0.864907C6.14582 0.797097 5.98307 0.899196 5.9805 1.07269C5.97793 1.24592 6.13657 1.42044 6.32554 1.47827Z" fill={color}/>
    </svg>
    
);

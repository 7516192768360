import { useState } from "react"
import { useMutation } from "react-query"
import { notification } from "antd"
import { errorResponseHandler, getPreSignedUrl } from "network"
import { UploadCategory } from "types"

export const useImageUpload = () => {
  const [tempCoverArt, setTempCoverArt] = useState<string | null>(null)
  const { mutate: mutatePreSignUpload, isLoading: isUploading } =
    useMutation(getPreSignedUrl)

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    updateState: (payload: { coverArt: string }) => void
  ) => {
    const target = event.target

    if (target.files) {
      const file = target.files[0]

      if (validateFileInput(file)) {
        const tempCoverArt = URL.createObjectURL(file)
        setTempCoverArt(tempCoverArt)

        const payload = {
          fileName: file.name,
          category: UploadCategory.PROFILE_PICTURES,
          shouldNotPrefixFileName: true,
        }

        const formData = new FormData()
        formData.append("file", file)
        const uploadFile = formData.get("file")

        mutatePreSignUpload(payload, {
          onSuccess: async ({ data }) => {
            try {
              await fetch(data.presignedUrl, {
                method: "PUT",
                body: uploadFile,
              })
              updateState({ coverArt: data?.fileId })
            } catch (error) {
              console.log(error)

              notification.error({
                message: "Upload Error",
                description: "Failed to upload image to server.",
              })
            }
          },
          onError: error => {
            errorResponseHandler(error)
          },
        })
      }
    }
  }

  const validateFileInput = (file: File) => {
    const maxFileLimit = 5000000 // 5MB
    const imageType = /image.*/

    if (!file.type.match(imageType)) {
      notification.error({
        message: "Invalid File Type",
        description: "Only images are allowed. Please upload a valid image.",
      })
      return false
    }

    if (file.size > maxFileLimit) {
      notification.error({
        message: "File Too Large",
        description: "File is too large. Max file size is 5MB.",
      })
      return false
    }

    return true
  }

  return {
    tempCoverArt,
    handleImageUpload,
    isUploading,
  }
}

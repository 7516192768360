import UduXLogo from "assets/images/general/udux_logo.svg"
import {
  ApiResponseBanner,
  ApiResponseBannerProps,
  Button,
  FlexibleDiv,
} from "components"
import { DominantColors, Statistics } from "context"
import { followArtist, unFollowArtist } from "network"
import React from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { Artist } from "types/artist"
import { getErrorMessage, topSlideInFrom, truncate } from "utils"
import { APJWrap } from "./artist-profile-jumbotron.styles"

export type ArtistProfileJumbotronProps = {
  bg?: string
  artist?: Artist
  profilePicture?: string
  statistics?: Statistics
  dominantColors?: DominantColors
}
export const ArtistProfileJumbotron = ({
  bg,
  artist,
  statistics,
  dominantColors,
}: ArtistProfileJumbotronProps) => {
  const [following, setFollowing] = React.useState(artist?.userFollows)
  const isMobile = useMediaQuery({ maxWidth: 950 })
  const { mutate: mutateFollowArtist, isLoading: faLoading } =
    useMutation(followArtist)
  const { mutate: mutateUnFollowArtist, isLoading: ufaLoading } =
    useMutation(unFollowArtist)
  const artistInfoRef = React.useRef(null)
  const artistStatsRef = React.useRef(null)
  const [responseBanner, setResponseBanner] = React.useState<
    Omit<ApiResponseBannerProps, "message" | "onClose">
  >({
    open: false,
    type: "IDLE",
    response: "",
  })

  React.useLayoutEffect(() => {
    topSlideInFrom(artistInfoRef, { y: 20 })
    topSlideInFrom(artistStatsRef, { duration: 1.8, y: -20 })
  }, [])

  const handleFollowArtist = () => {
    if (artist) {
      mutateFollowArtist(artist.id, {
        onSuccess: res => {
          setFollowing(true)
          setResponseBanner({
            open: true,
            type: "SUCCESS",
            response: res?.message,
          })
        },
        onError: error => {
          setFollowing(false)
          setResponseBanner({
            open: true,
            type: "ERROR",
            response: getErrorMessage(error),
          })
        },
      })
    }
  }
  const handleUnFollowArtist = () => {
    if (artist) {
      mutateUnFollowArtist(artist.id, {
        onSuccess: res => {
          setFollowing(false)
          setResponseBanner({
            open: true,
            type: "SUCCESS",
            response: res?.message,
          })
        },
        onError: error => {
          setFollowing(true)
          setResponseBanner({
            open: true,
            type: "ERROR",
            response: getErrorMessage(error),
          })
        },
      })
    }
  }

  return (
    <React.Fragment>
      <APJWrap bg={bg} isMobile={isMobile} dominantColors={dominantColors}>
        <div className="response__banner__wrap">
          <ApiResponseBanner
            onClose={() => setResponseBanner({})}
            {...responseBanner}
          />
        </div>

        <div className="img__bg__content">
          <FlexibleDiv className="artist__info__section">
            <FlexibleDiv className="artist__info">
              <FlexibleDiv className="artist__bloc">
                <div className="artist__name__bio" ref={artistInfoRef}>
                  <div className="verified__artist__wrap">
                    <div className="logo">
                      <img src={UduXLogo} alt="udux" />
                    </div>
                    <p>Verified Artist</p>
                  </div>
                  <h2 className="name">{getName()}</h2>
                  <p className="bio">{getBio()}</p>

                  <FlexibleDiv className="cta__section">
                    <Button
                      onClick={
                        following ? handleUnFollowArtist : handleFollowArtist
                      }
                      loading={faLoading || ufaLoading}
                      color="var(--black)"
                      width="50%"
                      radius="0"
                      contained
                      bold
                    >
                      <span>{following ? "UnFollow" : "Follow"}</span>
                    </Button>
                  </FlexibleDiv>
                </div>

                <aside className={`artist__avatar__wrap`}>
                  <img
                    src={artist?.profilePicture?.url}
                    alt={artist?.stageName}
                    className="artist__avatar"
                  />
                </aside>
              </FlexibleDiv>

              {!isMobile && (
                <FlexibleDiv
                  className="stats__wrap__desktop"
                  ref={artistStatsRef}
                >
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.followers || 0}
                    </span>
                    <span className="stats__info">Followers</span>
                  </FlexibleDiv>
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.likes || 0}
                    </span>
                    <span className="stats__info">Likes</span>
                  </FlexibleDiv>
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.plays || 0}
                    </span>
                    <span className="stats__info">Plays</span>
                  </FlexibleDiv>
                  <FlexibleDiv flexDir="column" className="stats__wrap__item">
                    <span className="stats__count">
                      {statistics?.monthlyListeners || 0}
                    </span>
                    <span className="stats__info">Monthly Listeners</span>
                  </FlexibleDiv>
                </FlexibleDiv>
              )}
            </FlexibleDiv>
          </FlexibleDiv>

          {isMobile && (
            <FlexibleDiv ref={artistStatsRef} className="stats__wrap">
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">
                  {statistics?.followers || 0}
                </span>
                <span className="stats__info">Followers</span>
              </FlexibleDiv>
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">{statistics?.likes || 0}</span>
                <span className="stats__info">Likes</span>
              </FlexibleDiv>
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">{statistics?.plays || 0}</span>
                <span className="stats__info">Plays</span>
              </FlexibleDiv>
              <FlexibleDiv flexDir="column" className="stats__wrap__item">
                <span className="stats__count">
                  {statistics?.monthlyListeners || 0}
                </span>
                <span className="stats__info">Monthly Listeners</span>
              </FlexibleDiv>
            </FlexibleDiv>
          )}
        </div>
      </APJWrap>
    </React.Fragment>
  )

  function getName() {
    if (artist?.stageName?.trim()) {
      return artist.stageName
    }

    return "uduX Artist!"
  }

  function getBio() {
    if (artist?.bio) return truncate(artist.bio, 120)

    return "uduX artists are unique and original, we know you'd love them."
  }
}

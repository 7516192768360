import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import SwiperCore from "swiper";
import { EffectFade } from "swiper/modules";
import { CPBannerWrap } from "./campaign-banners.style";
import { useEffect, useState } from "react";

SwiperCore.use([EffectFade]);

type CampaignBannersProp = {
  banners: string[];
  activeSlide?: number;
};

export const CampaignBanners = ({ banners, activeSlide = 0 }: CampaignBannersProp) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500);
    }
  }, [swiper, activeSlide]);

  return (
    <CPBannerWrap>
      <Swiper
        className="swiper"
        effect="fade"
        slidesPerView={1}
        initialSlide={activeSlide}
        onSwiper={setSwiper}
        allowTouchMove={false}
      >
        {banners.map((image, index) => (
          <SwiperSlide key={index} className="slider__slide">
            <img
              src={image}
              alt={`Banner ${index + 1}`}
              className="banner-image" // Add class for styling
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </CPBannerWrap>
  );
};

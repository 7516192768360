import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import { PaymentForgotPasswordWrapper } from "./payment-forgot-password.styles"
import { BorderedTextfield, Button, FlexibleDiv } from "components"
import { Form } from "antd"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { LoadingOutlined } from "@ant-design/icons"
import GalleryBng from "assets/images/payment/forgot_password.png"
import { useMutation } from "react-query"
import { ResetPasswordRequestPayload } from "types"
import { errorResponseHandler, resetPassword } from "network"
import { SourceService, Types, useMainContext } from "context"
import { Config } from "config"
import { validateBasicField } from "utils"

export const PaymentForgotPassword = () => {
  const [params] = useSearchParams()
  const { dispatch } = useMainContext()
  const navigate = useNavigate()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"

  const [form] = Form.useForm()
  const { mutate, isLoading } = useMutation(resetPassword)

  const handleFormSubmit = async (values: ResetPasswordRequestPayload) => {
    mutate(
      {
        ...values,
        sourceService: Config.sourceService as SourceService,
      },
      {
        onSuccess: res => {
          if (res) {
            navigate(`/payment/check-mail?type=${plan}&mode=${mode}`, {
              state: {
                prevRoute: `/payment/reset-password?type=${plan}&mode=${mode}`,
              },
            })
          }
        },
        onError: error => {
          dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
          errorResponseHandler(error)
        },
      }
    )
  }

  return (
    <PaymentRegisterLayout
      galleryImage={GalleryBng}
      backURL={`/payment/auth?type=${plan}&mode=${mode}`}
    >
      <PaymentForgotPasswordWrapper>
        <FlexibleDiv flexDir="column" className="info__description">
          <h2>Password Reset</h2>
          <p>Will only take a few seconds</p>
        </FlexibleDiv>

        <Form form={form} onFinish={handleFormSubmit} className="form__box">
          <FlexibleDiv className="textfield__wrap">
            <BorderedTextfield
              type="text"
              paddingLeft="5px"
              name="emailAddress"
              placeholder="What’s your email/username"
              validator={validateBasicField}
            />
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              color="var(--black)"
              width="180px"
              height="40px"
              type="submit"
              radius="0px"
              contained
              bold
              ltr
            >
              <span>
                {isLoading ? <LoadingOutlined /> : "Reset My Password"}
              </span>
            </Button>
          </FlexibleDiv>
        </Form>

        <FlexibleDiv
          flexDir="column"
          className="btn__box"
          justifyContent="center"
        >
          <Link to="/payment/register" className="existing__user">
            New user? Create an Account
          </Link>
        </FlexibleDiv>
      </PaymentForgotPasswordWrapper>
    </PaymentRegisterLayout>
  )
}

import { useMainContext } from "context"
// import { VisitorsHomePage } from "."
import { UserHomePage } from "./UserHomePage"
import { VisitorsHomePage } from "./VisitorsHomePage"

export const Home = () => {
  const {
    state: {
      app: { isLoggedIn },
    },
  } = useMainContext()
  return isLoggedIn ? <UserHomePage /> : <VisitorsHomePage />
}

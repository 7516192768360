import { IconProps } from "types";

export const GotoCreditsIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "16"}
    height={height || "14"}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.14646 4.69972L4.29291 8.54495H0L2.14646 4.69972ZM0.429291 0H3.86362V3.41798H0.429291V0ZM2.14646 13.6719C2.60188 13.6719 3.03865 13.4919 3.36068 13.1714C3.68271 12.8509 3.86362 12.4162 3.86362 11.9629C3.86362 11.5097 3.68271 11.075 3.36068 10.7545C3.03865 10.434 2.60188 10.2539 2.14646 10.2539C1.69104 10.2539 1.25427 10.434 0.932238 10.7545C0.610207 11.075 0.429291 11.5097 0.429291 11.9629C0.429291 12.4162 0.610207 12.8509 0.932238 13.1714C1.25427 13.4919 1.69104 13.6719 2.14646 13.6719ZM5.58079 0.854495V2.56348H15.8838V0.854495H5.58079ZM5.58079 12.8174H15.8838V11.1084H5.58079V12.8174ZM5.58079 7.69045H15.8838V5.98146H5.58079V7.69045Z"
      fill={color}
    />
  </svg>
);

import { FlexibleDiv } from "components"
import styled from "styled-components"
import concertBg from "assets/images/general/udux_concert_bg.png"
export const HomeWrap = styled(FlexibleDiv)`
  width: 100%;
  height: 100%;
  position: relative;

  .update-modal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: var(--primaryBlack);

    .update-header {
      width: 50%;
      margin: 0 auto;

      h3 {
        margin-top: 100px;
        font-size: 1.8rem;
      }

      p {
        font-size: 1rem;
        margin: 0 auto;
      }
      h3,
      p {
        text-align: center;
        width: 100%;
        color: var(--primaryWhite);
      }
    }
  }

  section {
    min-height: calc(100vh - 90px);
    padding: 0 5px 5px 5px;
    overflow-y: auto;
    overflow-x: hidden;

    max-width: 1500px;
    margin: 0 auto;

    .user__mini__subheader {
      flex-basis: 50%;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 29px;
      color: var(--uduxWhitePrimary);
      margin: 0 0 30px 0;
      font-family: "Inter", sans-serif;
      @media (max-width: 500px) {
        margin: 0 0 5px 0;
        font-size: 1em;
      }

      .username {
        text-transform: capitalize;
      }
    }

    .banner__wrap {
      height: 350px;

      img {
        width: 100%;
        height: 100%;
      }

      .banner-img__wrap {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .web__banner {
          @media (max-width: 500px) {
            display: none;
          }
        }

        .mobile__banner {
          @media (min-width: 500px) {
            display: none;
          }
        }
      }

      @media (max-width: 500px) {
        display: flex;
        align-items: center;
        justify-content: center;

        .gallery__pane .gallery_pane_img {
          min-height: 300px !important;
          background-size: contain !important;
          background-repeat: no-repeat !important;
        }
      }
    }

    .playerhome__bodysection {
      .mini__subheader {
        flex-basis: 50%;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 29px;
        color: var(--primaryWhite);
        font-family: "Inter", sans-serif;
      }

      .header__withlink {
        margin: 40px 0 10px 0;
        .see__more {
          color: var(--uduxYellowPrimary);
          font-size: 12px;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }

      .data__card__carousel {
        column-gap: 3%;
      }

      .playerhome__cardswrap {
        width: 95%;
        overflow-x: auto;
        justify-content: flex-start;
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0 !important;
        }
        &::-webkit-scrollbar-button {
          width: 0 !important;
          height: 0 !important;
        }
      }
    }
  }

  @media (max-width: 950px) {
    width: 100%;
    padding: 0px 20px;
    section {
      padding: 0;

      .playerhome__bodysection {
        .playerhome__cardswrap {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 502px) {
    section {
      .banner__wrap {
        margin-left: 2px;
        max-height: 180px;
      }
      .playerhome__bodysection {
        .welcomeback__text {
          font-size: 20px;
          margin-bottom: 10px;
        }
        .cheers__text {
          font-size: 16px;
        }
        g .mini__subheader {
          font-size: 1em;
        }

        .header__withlink {
          margin: 30px 0 10px 0;
          width: 100% !important;
          h4 {
            font-size: 18px;
            white-space: nowrap;
          }
          .see__more {
            font-size: 14px;
          }
        }

        .data__card__carousel {
          column-gap: 8%;
        }
      }
    }
  }

  .products {
    display: flex;
    width: 100%;
    margin-top: 60px;
    column-gap: 20px;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 20px;
    }

    .poprev,
    .udux__concerts {
      text-decoration: none;
      flex: 1;
      color: inherit;
      display: block;
      height: 260px;
      min-height: 260px;
      padding: 16px;
      width: 100%;
      border: 1px solid transparent;
      border-image: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%) 1;
      background: #000;
      @media screen and (max-width: 1024px) {
        min-height: 220px;
      }
    }

    .poprev {
      position: relative;
      .poprev__content__left {
        display: flex;
        z-index: 10;
        flex-direction: column;
        justify-content: space-between;
        background: transparent;
        width: 50%;
        height: 100%;
        @media screen and (max-width: 1200px) {
          min-height: 220px;
        }
        @media screen and (max-width: 1024px) {
          min-height: 200px;
        }

        .poprev__btn {
          white-space: nowrap;
          font-family: "Inter", sans-serif;
          font-size: 1rem;
          padding: 5px 10px;
          min-width: 135px;
          min-height: 40px;
          text-transform: capitalize;
          line-height: 20px;
          border: 1px solid transparent;
          border-image: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%) 1;
        }

        p {
          font-size: 1.3rem;
          font-weight: 500;
          text-align: left;
          margin-top: 5px;
          color: var(--primaryWhite);
          line-height: normal;
          font-family: "Inter", sans-serif;
          @media screen and (max-width: 1024px) {
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }

      .poprev__coin__wrap {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 1024px) {
          right: 10px;
        }

        img {
          width: 240px;
          height: 240px;
          object-fit: cover;
          @media screen and (max-width: 1400px) {
            width: 200px;
            height: 200px;
          }
          @media screen and (max-width: 1200px) {
            width: 220px;
            height: 220px;
          }
          @media screen and (max-width: 765px) {
            width: 180px;
            height: 180px;
          }
        }
      }
    }

    .udux__concerts {
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      background-image: url(${concertBg});
      background-size: cover;
      background-position: center;
      padding: 10px 0 20px 0;

      .concert__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;

        p {
          letter-spacing: 5.84px;
          font-family: "Inter", sans-serif;
          font-size: 1rem;
          text-transform: uppercase;
          margin-top: 15px;
          @media screen and (max-width: 1024px) {
            font-size: 0.8rem;
          }
        }
      }

      .image__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        img {
          width: 75.19px;
          height: auto;
        }

        .sticky {
          width: 2px;
          height: 25px;
          background: linear-gradient(90deg, #fcb816, #ec1b52);
          -webkit-text-fill-color: transparent;
          border: 1px solid transparent;
          border-image: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%) 1;
        }

        .coloured {
          font-size: 1.2rem;
          background: linear-gradient(90deg, #fcb816, #ec1b52);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      h2 {
        font-size: 2.8rem;
        background: linear-gradient(90deg, #fcb816, #ec1b52);
        -webkit-background-clip: text;
        font-weight: 800;
        line-height: 44.5px;
        letter-spacing: -1.16px;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width: 1024px) {
          font-size: 2rem;
          line-height: 30.5px;
        }
      }
    }
  }
`

import React from "react";
import { IconProps } from "types";

export const SearchIcon = ({
  color = "#929292",
  width = "22.338",
  height = "22.338",
}: IconProps) => (
  <svg
    width={width || "16"}
    height={height || "16"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.08799 1.33203C3.46237 1.33203 1.33301 3.4614 1.33301 6.08701C1.33301 8.71263 3.46237 10.842 6.08799 10.842C8.7136 10.842 10.843 8.71263 10.843 6.08701C10.843 3.4614 8.7136 1.33203 6.08799 1.33203ZM6.08799 9.97745C3.94306 9.97745 2.19755 8.23194 2.19755 6.08701C2.19755 3.94208 3.94306 2.19657 6.08799 2.19657C8.23292 2.19657 9.97843 3.94208 9.97843 6.08701C9.97843 8.23194 8.23292 9.97745 6.08799 9.97745ZM9.97843 11.2743L11.2752 9.97745L14.6663 12.6452L12.6461 14.6654L9.97843 11.2743Z"
      fill={color}
    />
    <path
      d="M9.97817 10.4114C10.2169 10.4114 10.4104 10.2179 10.4104 9.97915C10.4104 9.74041 10.2169 9.54688 9.97817 9.54688C9.73943 9.54688 9.5459 9.74041 9.5459 9.97915C9.5459 10.2179 9.73943 10.4114 9.97817 10.4114Z"
      fill={color}
    />
  </svg>
);

import { Menu } from "antd"
import styled from "styled-components"

export interface StyledMenuProp {
  top?: string
  item_padding?: string
}
export const StyledMenu = styled(Menu)<StyledMenuProp>`
  background: #0F0F0F !important;
  position: absolute;
  z-index: 1000;
  list-style: none;
  border-radius: 0 !important;
  border: 1px solid #E6E6E6;
  top: ${({ top }) => top || "6px"};
  right: 0px !important;
  // border: 2px solid red !important;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    background-color: transparent;
    color: #d9d9d9;
    font-size: 0.75rem;
    // border-bottom: 0.2px solid var(--borderLighter);
    padding: 10px 9px;
  }

  .ant-dropdown-menu-item-active {
    background-color: transparent;
  }

  .ant-dropdown-menu-item span {
    color: #d9d9d9;
  }

  /* inputs for file uploads */
  .file__uploader__input__wrap {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s;

    svg {
      margin-right: 1rem;
    }

    label {
      display: flex;
      align-items: center;
      transition: all 0.3s;

      svg {
        margin-right: 1rem;
      }
    }

    input[type="file"] {
      color: transparent;
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
    }

    :hover {
      color: var(--yellowPrimary);
      label {
        color: var(--yellowPrimary);
      }
    }
  }
`

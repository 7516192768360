import { FlexibleDiv } from "components"
import styled from "styled-components"

export interface SearchFilterWrapProps {
  mini?: boolean
}

export const SearchFilterWrap = styled(FlexibleDiv)<SearchFilterWrapProps>`
  background: #070606;
  border: 0.2px solid rgba(217, 217, 217, 0.125);
  padding: ${({ mini }) => (mini ? "0.5rem" : "0.5rem 1rem")};
  z-index: 2;

  .filter__list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    width: 100%;

    .filter__list__item {
      flex-grow: 1;
      list-style: none;
      font-family: "Baguede", sans-serif;
      color: rgba(217, 217, 217, 0.9);
      cursor: pointer;
      text-align: center;
      height: ${({ mini }) => (mini ? "25px" : "35px")};
      font-size: ${({ mini }) => (mini ? "0.75rem" : "normal")};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 8px;
      transition: all 0.3s ease;

      :hover {
        background-color: var(--uduxYellowSecondary);
      }
    }

    .active__item {
      background-color: var(--uduxYellowPrimary);
      color: #ffffff;
      font-weight: 700;

      :hover {
        background-color: var(--uduxYellowPrimary);
      }
    }
  }

  @media screen and (min-width: 1921px) {
    .filter__list {
      .filter__list__item {
        height: 40px;
      }
    }
  }

  @media screen and (max-width: 710px) {
    .filter__list {
      .filter__list__item {
        height: 25px;
        font-size: 0.5rem;
      }
    }
  }

  @media screen and (min-width: 711px) and (max-width: 950px) {
    padding: 0.5rem;

    .filter__list {
      .filter__list__item {
        height: 25px;
        font-size: ${({ mini }) => (mini ? "0.65rem" : "0.85rem")};
      }
    }
  }
`

import styled from "styled-components"
import { FlexibleDiv } from "components"
import ArtBoard from "assets/images/general/udux_bng.png"

export const VerifyPaymentWrapper = styled(FlexibleDiv)`
  width: 100%;
  height: 100vh;
  background: url(${ArtBoard}) repeat center / contain;
  overflow: hidden;
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .content__box {
    width: 94%;
    height: 90%;
    background-color: var(--primaryNavyBlue);
    position: relative;

    .logo__wrap {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 4rem;
      opacity: 0.8;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    /* Tabs */
    @media screen and (min-width: 601px) and (max-width: 1024px) {
      height: auto;
      padding: 2rem;
      flex-direction: column;
      align-items: center;

      .logo__wrap {
        width: 5rem;
        position: unset;
        margin-bottom: 15%;
      }
    }

    /* Mobile Device */
    @media screen and (max-width: 600px) {
      height: auto;
      padding: 2rem;
      flex-direction: column;
      align-items: center;

      .logo__wrap {
        position: unset;
        margin-bottom: 25%;
      }
    }
  }
`

export const PaymentSuccessWrapper = styled(FlexibleDiv)`
  background: var(--primaryBlack);
  padding: 3rem 1rem;
  justify-content: space-between;

  .payment__container {
    .top__container {
      height: 130px;

      .congratulations__text {
        color: var(--primaryYellow);
        margin-bottom: 0.5rem;
        text-align: center;
        width: 100%;
        font-size: 1.75rem;
      }

      .payment__text {
        text-align: center;
        width: 70%;
        font-size: 0.65rem;
        color: var(--primaryWhite);
        font-weight: bold;
        padding-bottom: 2rem;
      }

      .button__wrap {
        width: 70%;
      }
    }

    .bottom__container {
      padding: 30px;
      height: 300px;
      margin-top: 1rem;

      .summary__text {
        color: #ffa300;
        margin-bottom: 0;
        font-size: 1rem;
      }

      .receipt__content__wrapper {
        flex: 1;

        .receipt__content {
          p {
            color: #fcfcfc;
            opacity: 0.8;
            letter-spacing: normal;
          }

          .title {
            font-weight: bold;
            font-family: var(--mBold);
            font-size: 0.7rem;
          }

          .content {
            font-size: 0.75rem;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  /* Tabs */
  @media screen and (min-width: 601px) and (max-width: 1024px) {
    width: 100%;

    .payment__container {
      .top__container {
        .congratulations__text {
          font-size: 2rem;
        }

        .payment__text {
          font-size: 0.85rem;
        }
      }
    }
  }

  /* Mobile Device */
  @media screen and (max-width: 600px) {
    width: 100%;

    .payment__container {
      .top__container {
        height: 130px;

        .congratulations__text {
          font-size: 1.5rem;
        }

        .payment__text {
          width: 90%;
          padding-bottom: 2rem;
        }

        .button__wrap {
          width: 80%;
        }
      }

      .bottom__container {
        padding: 15px;
      }
    }
  }
`

export const PaymentFailedWrapper = styled(FlexibleDiv)`
  background: var(--primaryBlack);
  padding: 3rem 1rem;
  justify-content: space-between;

  .payment__container {
    .top__container {
      height: 130px;

      .congratulations__text {
        color: var(--primaryYellow);
        margin-bottom: 0.5rem;
        text-align: center;
        width: 100%;
        font-size: 1.75rem;
      }

      .payment__text {
        text-align: center;
        width: 70%;
        color: var(--primaryWhite);
        font-weight: bold;
      }
    }

    .bottom__container {
      padding: 30px;
      height: auto;

      p {
        text-align: center;
        font-size: 0.85rem;
        opacity: 0.7;
      }

      button {
        margin-top: 2rem;
        width: 60%;
      }
    }
  }

  /* Tabs */
  @media screen and (min-width: 601px) and (max-width: 1024px) {
    width: 100%;

    .payment__container {
      .top__container {
        .congratulations__text {
          font-size: 2rem;
        }

        .payment__text {
          font-size: 0.85rem;
        }
      }
    }
  }

  /* Mobile Device */
  @media screen and (max-width: 600px) {
    width: 100%;

    .payment__container {
      .top__container {
        height: 130px;

        .congratulations__text {
          font-size: 1.3rem;
        }

        .payment__text {
          width: 90%;
          font-size: 0.85rem;
        }

        .button__wrap {
          width: 80%;
        }
      }

      .bottom__container {
        padding: 15px;
      }
    }
  }
`

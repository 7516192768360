import { IconProps } from "types";

export const PrevOutlineIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "31"}
    height={height || "31"}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5122 26.5005C25.3516 26.5016 25.1936 26.4605 25.0539 26.3814L7.63659 16.2977C7.49552 16.2177 7.37817 16.1018 7.29653 15.9617C7.21489 15.8215 7.17188 15.6623 7.17188 15.5001C7.17187 15.338 7.21489 15.1787 7.29653 15.0386C7.37817 14.8985 7.49552 14.7826 7.63659 14.7026L25.0539 4.61891C25.1932 4.53845 25.3513 4.49609 25.5122 4.49609C25.6731 4.49609 25.8312 4.53845 25.9706 4.61891C26.1105 4.69967 26.2265 4.81597 26.307 4.95602C26.3875 5.09607 26.4296 5.25491 26.4289 5.41644V25.5838C26.4289 25.8269 26.3324 26.0601 26.1604 26.232C25.9885 26.4039 25.7554 26.5005 25.5122 26.5005ZM9.92834 15.5001L24.5955 23.9979V7.00233L9.92834 15.5001Z"
      fill={color}
      fillOpacity="0.9"
    />
    <path
      d="M2.5957 4.5H4.4291V26.5008H2.5957V4.5Z"
      fill={color}
      fillOpacity="0.9"
    />
  </svg>
);

import { IconProps } from "types";

export const EditPlaylistIcon = ({
  color="white",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "22"}
    height={height || "22"}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8838 11C17.8838 10.8177 17.9562 10.6428 18.0852 10.5139C18.2141 10.3849 18.389 10.3125 18.5713 10.3125C18.7536 10.3125 18.9285 10.3849 19.0574 10.5139C19.1864 10.6428 19.2588 10.8177 19.2588 11V18.5625C19.2588 18.7448 19.1864 18.9197 19.0574 19.0486C18.9285 19.1776 18.7536 19.25 18.5713 19.25H3.44629C3.26395 19.25 3.08908 19.1776 2.96015 19.0486C2.83122 18.9197 2.75879 18.7448 2.75879 18.5625V3.4375C2.75879 3.25516 2.83122 3.0803 2.96015 2.95136C3.08908 2.82243 3.26395 2.75 3.44629 2.75H11.0088C11.1911 2.75 11.366 2.82243 11.4949 2.95136C11.6239 3.0803 11.6963 3.25516 11.6963 3.4375C11.6963 3.61984 11.6239 3.7947 11.4949 3.92364C11.366 4.05257 11.1911 4.125 11.0088 4.125H4.13379V17.875H17.8838V11Z"
      fill={color}
    />
    <path
      d="M10.1059 11.908L11.2403 11.7457L18.2088 4.77859C18.2744 4.71517 18.3268 4.63931 18.3628 4.55543C18.3989 4.47155 18.4178 4.38134 18.4186 4.29005C18.4194 4.19877 18.402 4.10824 18.3674 4.02375C18.3329 3.93926 18.2818 3.8625 18.2173 3.79794C18.1527 3.73339 18.076 3.68234 17.9915 3.64778C17.907 3.61321 17.8165 3.59581 17.7252 3.59661C17.6339 3.5974 17.5437 3.61636 17.4598 3.6524C17.3759 3.68843 17.3001 3.7408 17.2366 3.80647L10.2668 10.7736L10.1045 11.908H10.1059ZM19.1809 2.83297C19.3725 3.0245 19.5246 3.25193 19.6283 3.50225C19.732 3.75257 19.7854 4.02088 19.7854 4.29184C19.7854 4.5628 19.732 4.83111 19.6283 5.08143C19.5246 5.33175 19.3725 5.55918 19.1809 5.75072L12.0515 12.8801C11.9464 12.9856 11.8098 13.0541 11.6624 13.0753L9.39363 13.3998C9.2879 13.415 9.18008 13.4054 9.07873 13.3716C8.97739 13.3379 8.88529 13.281 8.80976 13.2055C8.73423 13.1299 8.67734 13.0378 8.6436 12.9365C8.60986 12.8351 8.6002 12.7273 8.61538 12.6216L8.93988 10.3528C8.96071 10.2056 9.02874 10.069 9.13376 9.96372L16.2645 2.83434C16.6513 2.44768 17.1758 2.23047 17.7227 2.23047C18.2696 2.23047 18.7941 2.44768 19.1809 2.83434V2.83297Z"
      fill={color}
    />
  </svg>
);

import { FlexibleDiv } from "components"
import HomePagePicture from "assets/images/general/payment-hero-cover.png"
import HomePageBG from "assets/images/general/udux_bng.png"
import HomePageBGMobile from "assets/images/general/udux_bng.png"
import {
  PaymentHeroWrapper,
  BackgroundImageWrapper,
  PHCWrapper,
} from "./payment-hero.styles"

export const PaymentHero = () => {
  return (
    <PaymentHeroWrapper>
      <FlexibleDiv className="landing__page__container">
        <BackgroundImageWrapper flexDir="column" justifyContent="end">
          <img src={HomePageBG} className="web__image" alt="background pic" />
          <img
            src={HomePageBGMobile}
            className="mobile__image"
            alt="background pic"
          />
        </BackgroundImageWrapper>

        <PHCWrapper
          flexDir="column"
          justifyContent="start"
          alignItems="start"
          flexWrap="nowrap"
        >
          <FlexibleDiv
            className="content__section"
            height="25%"
            justifyContent="space-between"
          >
            <FlexibleDiv
              className="hero__text__section"
              flexDir="column"
              alignItems="start"
              justifyContent="start"
            >
              <h1 className="hero__text__1">For all the ways</h1>
              <h1 className="hero__text__2">Music Moves You</h1>
            </FlexibleDiv>
            <FlexibleDiv
              height="100%"
              flexDir="column"
              flexWrap="nowrap"
              alignItems="start"
              className="hero__right__column"
            >
              <p className="hero__udux__text">
                uduX goves you payment options that work for you and your music.
                <br />
                Daily, monthly and weekly plans that start from just 30 naira.
              </p>

              <a href="http://" className="sub__now">
                Subscribe Now
              </a>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv className="homepage__image__wrapper" width="100%">
            <img src={HomePagePicture} alt="homepage design" />
          </FlexibleDiv>

          <FlexibleDiv
            className="flexible__pricing"
            height="25%"
            justifyContent="space-between"
          >
            <FlexibleDiv className="wrap__color">
              <FlexibleDiv
                className="sub__text"
                flexDir="column"
                alignItems="start"
                justifyContent="start"
              >
                <h3 className="text__1">Flexible pricing</h3>
                <h3 className="text__2">On Your Terms</h3>
              </FlexibleDiv>
              <FlexibleDiv
                height="100%"
                flexDir="column"
                flexWrap="nowrap"
                alignItems="start"
                className="sub__text__info"
              >
                <p className="info__text">
                  uduX gives you payment options that work for you and your
                  music. Daily, monthly and weekly plans that start from just 30
                  naira.
                </p>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </PHCWrapper>
      </FlexibleDiv>
    </PaymentHeroWrapper>
  )
}

import { FlexibleDiv } from "components/lib/Box"
import styled from "styled-components"

export interface UserCardWrapWrapProps {
  dominantColors?: Array<string>
}

export const UserCardWrap = styled(FlexibleDiv)<UserCardWrapWrapProps>`
  max-width: 200px;
  min-width: 200px;
  height: 280px;
  cursor: pointer;
  flex-direction: column;

  .picture__box {
    width: 100%;
    height: calc(280px - 65px);
    border: 0.5px solid var(--uduxGrayLightAlpha);
    border-bottom: 0;
    display: flex;
    align-items: center;
  }

  .image__wrap {
    width: 90%;
    height: 90%;
    margin: 0 auto;

    img {
      float: left;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.3s ease-in;
    }
  }

  :hover {
    .image__wrap {
      img {
        filter: opacity(0.5);
      }
    }
  }

  .name__box {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border: ${({ dominantColors }) =>
      dominantColors?.length
        ? `0.5px solid linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "0.5px solid transparent"};
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : ""};

    p {
      margin: 0;
      font-size: 12px;
      font-family: "Baguede", sans-serif;
    }

    .name__and__follow {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .name {
      font-size: 16px;
      text-transform: capitalize;
    }

    .tuale__box {
      height: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
`

import {
  AddSongQueueIcon,
  DragIcon,
  FavoriteFilledIcon,
  LikeIcon,
} from "assets";
import { CloseOutlineIcon } from "assets/images/player/SVGExports";
import { FlexibleDiv } from "components";
import { forwardRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import { likeItem, truncate, unLikeItem } from "utils";
import { QTIWrap } from "./queue-track-item.styles";
import { Types, useMainContext } from "context";
import { Artist, Track } from "types/artist";

export type QueueItemRefElProps = {
  track: Track;
  position: number;
  changeSong?: () => void;
  pushToPlayNext?: () => void;
  removeFromQueue: (pos: number) => void;
  isMobile?: boolean;
};
const QueueItemRefEl = forwardRef(
  (
    {
      track,
      position,
      // changeSong,
      // pushToPlayNext,
      removeFromQueue,
    }: QueueItemRefElProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const navigate = useNavigate();
    const metadata = track?.track?.metadata;
    const {
      state: { app },
      dispatch,
    } = useMainContext();

    const { isLoggedIn } = app;

    return (
      <QTIWrap justifyContent="space-between" flexWrap="nowrap" ref={ref}>
        <FlexibleDiv className="selectable--wrap wrap--layer">
          <div
            className="closeicon__wrap"
            onClick={() => {
              removeFromQueue(position);
            }}
          >
            <CloseOutlineIcon color="var(--primaryWhite)" width={10} />
          </div>

          <div className="image--wrap">
            <img src={metadata?.coverArt?.url} alt="title" />
          </div>

          <FlexibleDiv className="info--wrap">
            <p
              className="title"
              // onClick={() => changeSong(idx)}
            >
              {metadata?.title}
            </p>

            <span>
              {metadata?.displayArtists?.map(
                ({ artist }: { artist: Artist }, idx: number) => (
                  <span
                    className="artist"
                    onClick={() => navigate(`/artist/${artist.id}`)}
                    key={idx}
                  >
                    {truncate(artist?.stageName, 5)}
                    {metadata?.displayArtists?.length > 1 &&
                      idx !== metadata?.displayArtists?.length - 1 &&
                      ", "}{" "}
                  </span>
                )
              )}
            </span>
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv className="duration--wrap wrap--layer">
          <p className="duration">3:00</p>
        </FlexibleDiv>

        <FlexibleDiv
          justifyContent="space-between"
          flexWrap="nowrap"
          className="action__icons__wrap"
        >
          <div
            className="icon__wrap"
            onClick={() => {
              if (!isLoggedIn) {
                return dispatch({
                  type: Types.SET_PRIVATE_ACTION,
                  payload: true,
                });
              }
            }}
          >
            <AddSongQueueIcon width={25} height={25} />
          </div>
          {track.userLiked ? (
            <div
              className="icon__wrap"
              onClick={() => {
                if (!isLoggedIn) {
                  return dispatch({
                    type: Types.SET_PRIVATE_ACTION,
                    payload: true,
                  });
                }
                unLikeItem(track.id, "Track");
              }}
            >
              <FavoriteFilledIcon width={25} height={25} />
            </div>
          ) : (
            <div
              className="icon__wrap"
              onClick={() => {
                if (!isLoggedIn) {
                  return dispatch({
                    type: Types.SET_PRIVATE_ACTION,
                    payload: true,
                  });
                }
                likeItem(track.id, "Track");
              }}
            >
              <LikeIcon width={25} height={25} />
            </div>
          )}

          <div className="icon__wrap">
            <DragIcon width={25} height={25} />
          </div>
        </FlexibleDiv>
      </QTIWrap>
    );
  }
);

QueueItemRefEl.displayName = "QueueItemRefEl";

export const QueueTrackItem = memo(QueueItemRefEl);

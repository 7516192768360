import { isEmpty } from "lodash"
import { Track } from "types/artist"

export function getCurrentTrackID(currentTrack?: Track) {
  if (!isEmpty(currentTrack)) {
    if (currentTrack?.track?.id) {
      return currentTrack.track.id
    } else if (currentTrack?.id) {
      return currentTrack.id
    }
  }
}

import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore from "swiper"
import {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Virtual,
} from "swiper/modules"

import "swiper/css"
import "swiper/swiper-bundle.css"
import { CarouselWrap } from "./carousel.styles"
import { useState } from "react"

export type ImagesArrayProps = {
  id: number
  img: string
}

export type CarouselProps = {
  imagesArray: ImagesArrayProps[]
}

function Carousel({ imagesArray }: CarouselProps) {
  const [activeSlide] = useState(1)
  const [swiper, setSwiper] = useState<SwiperCore>()
  SwiperCore.use([Navigation, Pagination, Autoplay, Virtual, EffectFade])

  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500)
    }
  }, [swiper, activeSlide])

  return (
    <CarouselWrap>
      <Swiper
        //  style={{
        //   --swiperNavigationize: "25px",
        // }}
        className="swiper"
        id="swiper"
        effect="fade"
        // virtual
        navigation
        autoplay
        loop={true}
        spaceBetween={10}
        slidesPerView={1}
        initialSlide={activeSlide}
        onSwiper={setSwiper}
        allowTouchMove={true}
      >
        {imagesArray?.map(imagesObj => (
          <SwiperSlide className="slider__slide " key={imagesObj.id}>
            {/* <div className="banner-img__wrap"> */}
            <img src={imagesObj.img} />
            {/* </div> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </CarouselWrap>
  )
}

export default Carousel

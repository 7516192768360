import React from "react";
import { IconProps } from "types";

export const LikedSongsIcon = ({
  color = "#D9D9D9",
  width = "22.338",
  height = "22.198",
}: IconProps) => (
  <svg
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.93801 2.25C6.43108 2.25354 5.94616 2.45763 5.58925 2.81764C5.23224 2.45761 4.74723 2.25353 4.24021 2.25C3.12276 2.25 2.2168 3.2409 2.2168 4.46565C2.2168 5.6904 4.5774 7.78885 5.58925 8.52749C6.60082 7.78885 8.96143 5.68927 8.96143 4.46565C8.96143 3.24203 8.05546 2.25 6.93801 2.25Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5816 9.89063C14.0746 9.89417 13.5897 10.0983 13.2328 10.4583C12.8758 10.0982 12.3908 9.89416 11.8838 9.89063C10.7663 9.89062 9.86035 10.8815 9.86035 12.1063C9.86035 13.331 12.221 15.4295 13.2328 16.1681C14.2444 15.4295 16.605 13.3299 16.605 12.1063C16.605 10.8827 15.699 9.89063 14.5816 9.89063Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.79492 9.70312V10.2694"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.79492 11.4023V11.9686"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66211 10.8359H7.22834"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.36133 10.8359H8.92756"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0506 2.62651C11.4415 2.62651 11.7583 2.30962 11.7583 1.91872C11.7583 1.52782 11.4415 1.21094 11.0506 1.21094C10.6597 1.21094 10.3428 1.52782 10.3428 1.91872C10.3428 2.30962 10.6597 2.62651 11.0506 2.62651Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.70779 17.3492C2.09869 17.3492 2.41557 17.0323 2.41557 16.6414C2.41557 16.2505 2.09869 15.9336 1.70779 15.9336C1.31689 15.9336 1 16.2505 1 16.6414C1 17.0323 1.31689 17.3492 1.70779 17.3492Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.67756 8.03312C9.67756 8.09298 9.65981 8.15149 9.62656 8.20126C9.5933 8.25103 9.54604 8.28982 9.49073 8.31273C9.43543 8.33564 9.37458 8.34163 9.31587 8.32995C9.25716 8.31827 9.20324 8.28945 9.16091 8.24712C9.11858 8.2048 9.08976 8.15087 9.07808 8.09216C9.0664 8.03345 9.0724 7.9726 9.0953 7.9173C9.11821 7.862 9.157 7.81473 9.20677 7.78147C9.25654 7.74822 9.31506 7.73047 9.37492 7.73047C9.45518 7.73047 9.53216 7.76235 9.58892 7.81911C9.64568 7.87587 9.67756 7.95285 9.67756 8.03312Z"
      fill={color}
    />
    <path
      d="M17.3221 7.46671C17.3221 7.52657 17.3043 7.58508 17.2711 7.63485C17.2378 7.68462 17.1906 7.72342 17.1353 7.74632C17.08 7.76923 17.0191 7.77522 16.9604 7.76355C16.9017 7.75187 16.8478 7.72304 16.8054 7.68072C16.7631 7.63839 16.7343 7.58446 16.7226 7.52576C16.7109 7.46705 16.7169 7.40619 16.7398 7.35089C16.7627 7.29559 16.8015 7.24832 16.8513 7.21507C16.9011 7.18181 16.9596 7.16406 17.0194 7.16406C17.0997 7.16406 17.1767 7.19595 17.2335 7.25271C17.2902 7.30946 17.3221 7.38644 17.3221 7.46671Z"
      fill={color}
    />
    <path
      d="M2.31721 0.955559C2.3171 1.01539 2.29925 1.07385 2.26593 1.12355C2.23261 1.17324 2.1853 1.21195 2.12999 1.23477C2.07468 1.25759 2.01384 1.2635 1.95517 1.25176C1.8965 1.24002 1.84263 1.21115 1.80036 1.1688C1.75809 1.12645 1.72932 1.07252 1.71769 1.01383C1.70606 0.955136 1.71208 0.894313 1.73501 0.839044C1.75793 0.783775 1.79672 0.736541 1.84648 0.70331C1.89624 0.67008 1.95473 0.652344 2.01456 0.652344C2.05435 0.652344 2.09376 0.660191 2.13051 0.675436C2.16727 0.690681 2.20066 0.713024 2.22877 0.741188C2.25688 0.769351 2.27916 0.802782 2.29434 0.839566C2.30951 0.876351 2.31729 0.915767 2.31721 0.955559Z"
      fill={color}
    />
    <path
      d="M17.0389 16.2441C17.0389 16.3039 17.0211 16.3624 16.9879 16.4122C16.9546 16.462 16.9074 16.5008 16.8521 16.5237C16.7968 16.5466 16.7359 16.5526 16.6772 16.5409C16.6185 16.5292 16.5646 16.5004 16.5222 16.4581C16.4799 16.4157 16.4511 16.3618 16.4394 16.3031C16.4277 16.2444 16.4337 16.1835 16.4566 16.1282C16.4795 16.0729 16.5183 16.0257 16.5681 15.9924C16.6179 15.9592 16.6764 15.9414 16.7362 15.9414C16.776 15.9414 16.8153 15.9492 16.8521 15.9644C16.8888 15.9797 16.9221 16.0019 16.9502 16.0301C16.9784 16.0582 17.0006 16.0915 17.0159 16.1282C17.0311 16.165 17.0389 16.2043 17.0389 16.2441Z"
      fill={color}
    />
    <path
      d="M2.03401 8.88468C2.03401 8.94454 2.01626 9.00305 1.983 9.05282C1.94975 9.10259 1.90248 9.14138 1.84718 9.16429C1.79188 9.1872 1.73102 9.19319 1.67232 9.18151C1.61361 9.16984 1.55968 9.14101 1.51735 9.09869C1.47503 9.05636 1.4462 9.00243 1.43453 8.94372C1.42285 8.88502 1.42884 8.82416 1.45175 8.76886C1.47466 8.71356 1.51345 8.66629 1.56322 8.63304C1.61299 8.59978 1.6715 8.58203 1.73136 8.58203C1.81163 8.58203 1.88861 8.61392 1.94537 8.67068C2.00212 8.72743 2.03401 8.80441 2.03401 8.88468Z"
      fill={color}
    />
    <path
      d="M13.4679 5.02734C13.2731 5.02869 13.0867 5.10706 12.9495 5.24534C12.8121 5.1071 12.6257 5.02874 12.4308 5.02734C12.2148 5.03762 12.0117 5.13307 11.866 5.29279C11.7202 5.45251 11.6437 5.66349 11.6531 5.87952C11.6531 6.34977 12.5591 7.15693 12.9495 7.44089C13.3382 7.15778 14.2456 6.34977 14.2456 5.87952C14.2551 5.66349 14.1785 5.45251 14.0328 5.29279C13.887 5.13307 13.6839 5.03762 13.4679 5.02734V5.02734Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5821 1.06641C16.3873 1.06776 16.201 1.14613 16.0638 1.2844C15.9264 1.14616 15.74 1.06781 15.5451 1.06641C15.3291 1.07669 15.126 1.17214 14.9802 1.33186C14.8345 1.49158 14.7579 1.70255 14.7674 1.91858C14.7674 2.38883 15.6733 3.19599 16.0638 3.47996C16.4525 3.19684 17.3599 2.38883 17.3599 1.91858C17.3693 1.70255 17.2928 1.49158 17.147 1.33186C17.0013 1.17214 16.7981 1.07669 16.5821 1.06641V1.06641Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.40832 10.9766C4.21351 10.9779 4.02716 11.0563 3.88994 11.1946C3.75258 11.0563 3.56615 10.978 3.37127 10.9766C3.15528 10.9868 2.95216 11.0823 2.8064 11.242C2.66063 11.4017 2.5841 11.6127 2.59356 11.8287C2.59356 12.299 3.50094 13.1061 3.88994 13.3901C4.27865 13.107 5.18603 12.299 5.18603 11.8287C5.19549 11.6127 5.11896 11.4017 4.97319 11.242C4.82743 11.0823 4.62431 10.9868 4.40832 10.9766V10.9766Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.08898 14.3711C7.89418 14.3724 7.70782 14.4508 7.5706 14.5891C7.43324 14.4508 7.24681 14.3725 7.05194 14.3711C6.83594 14.3814 6.63283 14.4768 6.48706 14.6365C6.3413 14.7963 6.26476 15.0072 6.27422 15.2233C6.27422 15.6935 7.1816 16.5007 7.5706 16.7846C7.95932 16.5015 8.8667 15.6935 8.8667 15.2233C8.87616 15.0072 8.79962 14.7963 8.65386 14.6365C8.50809 14.4768 8.30498 14.3814 8.08898 14.3711V14.3711Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { FlexibleDiv } from "components"
import { LeaderboardEngagementCardWrap } from "./leaderboard-engagement-card.styles"
import { Avatar } from "antd"
import JonesAbbey from "assets/images/general/jones_abbey.png"

const spinList = [
  {
    username: "JonesAbbey",
    followers: 90,
    totalSpins: 700,
    profilePic: JonesAbbey,
  },
  {
    username: "JonesAbbey",
    followers: 90,
    totalSpins: 700,
    profilePic: JonesAbbey,
  },
  {
    username: "JonesAbbey",
    followers: 90,
    totalSpins: 700,
    profilePic: JonesAbbey,
  },
  {
    username: "JonesAbbey",
    followers: 90,
    totalSpins: 700,
    profilePic: JonesAbbey,
  },
  {
    username: "JonesAbbey",
    followers: 90,
    totalSpins: 700,
    profilePic: JonesAbbey,
  },
]

export type LeaderboardEngagementCardProps = {
  bg?: string
  hoverColor?: string
}
export const LeaderboardEngagementCard = ({
  bg,
  hoverColor,
}: LeaderboardEngagementCardProps) => {
  return (
    <LeaderboardEngagementCardWrap bg={bg} hoverColor={hoverColor}>
      <FlexibleDiv className="header">
        <div className="title__desc">
          <h2 className="total">0</h2>
          <span className="subtitle">
            X'ers have listened to this playlist in the past 30 days
          </span>
        </div>

        <div className="user__avatar">
          <p className="winner">Winner</p>
          <Avatar className="winner__avatar" src={JonesAbbey} />
        </div>
      </FlexibleDiv>

      <FlexibleDiv className="list__items">
        {spinList.map((spin, idx) => (
          <FlexibleDiv className="list__item" key={idx}>
            <div className="user__data">
              <span className="number">{idx + 1}.</span>
              <Avatar className="avatar" src={spin.profilePic} />
              <p className="username">{spin.username}</p>
            </div>

            <div className="info__stats">
              <p className="info">{spin.followers} Followers</p>
              <p className="info">{spin.totalSpins} Spins</p>
            </div>
          </FlexibleDiv>
        ))}
      </FlexibleDiv>
    </LeaderboardEngagementCardWrap>
  )
}

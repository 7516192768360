import { Tooltip } from "antd"
import {
  AlbumCard,
  ArtisteCard,
  FlexibleDiv,
  Loader,
  NoDataDisplay,
  PlayTrackProps,
  PlaylistCard,
  TrackListItem,
  UserCard,
} from "components"
import { Types, useMainContext } from "context"
import { take } from "lodash"
import React from "react"
import { TfiClose as CloseIcon } from "react-icons/tfi"
import { useNavigate } from "react-router-dom"
import { playTrack, topSlideInFrom, useDebounce, useSearch } from "utils"
import { NoSearchResult } from "../NoSearchResult"
import { SearchCategoryFilter, SearchFilter } from "../SearchFilter"
import {
  albumCategoryFilter,
  playlistCategoryFilter,
  userCategoryFilter,
} from "../SearchFilter/data-helper"
import { ActiveSearchWrap } from "./active-search.styles"

export const ActiveSearch = React.memo(() => {
  const [artistReleaseFilter, setArtistReleaseFilter] = React.useState("all")
  const [playlistFilter, setPlaylistFilter] = React.useState("all")
  const [userFilter, setUserFilter] = React.useState("all")
  const {
    state: { search },
    dispatch,
    playerRef,
    searchQuery,
    clearSearchQuery,
  } = useMainContext()
  const { filterActiveSearch } = search
  const debouncedSearch = useDebounce(searchQuery, 300)
  const navigate = useNavigate()
  const mainContentRef = React.useRef(null)
  const subContentRef = React.useRef(null)

  const { isLoading, data } = useSearch(debouncedSearch, filterActiveSearch)

  React.useLayoutEffect(() => {
    if (isLoading === false) {
      topSlideInFrom(mainContentRef, { y: 20 })
    }
  }, [isLoading])

  React.useLayoutEffect(() => {
    topSlideInFrom(subContentRef, { duration: 0.5, y: 8 })
  }, [filterActiveSearch])

  const hasResult = data?.data
    ? Object.values(data.data).some(d => Boolean(d.length))
    : false
  const usersResult =
    filterActiveSearch === "users" || filterActiveSearch === "combined"
      ? data?.data?.users
      : []
  const albumsResult =
    filterActiveSearch === "albums" || filterActiveSearch === "combined"
      ? data?.data?.albums
      : []
  const artistsResult =
    filterActiveSearch === "artists" || filterActiveSearch === "combined"
      ? data?.data?.artists
      : []
  const playlistsResult =
    filterActiveSearch === "playlists" || filterActiveSearch === "combined"
      ? data?.data?.playlists
      : []
  const tracksResult =
    filterActiveSearch === "tracks" || filterActiveSearch === "combined"
      ? take(data?.data?.tracks, 5)
      : []
  // const xProfileResults =
  //   filterActiveSearch === "tracks" || filterActiveSearch === "combined"
  //     ? take(data?.data?.x, 5)
  //     : []

  return isLoading ? (
    <Loader wrapperHeight="85vh" />
  ) : (
    <ActiveSearchWrap ref={mainContentRef}>
      <FlexibleDiv className="active__search__filter__wrap">
        <FlexibleDiv className="active__search__filter__wrap__content">
          <SearchFilter activeSearch />
        </FlexibleDiv>

        <div className="close__active__search" onClick={clearSearchQuery}>
          <Tooltip placement="bottom" title="Close this active search window">
            <CloseIcon color="white" />
          </Tooltip>
        </div>
      </FlexibleDiv>

      {hasResult ? (
        <React.Fragment>
          {!!tracksResult.length && (
            <FlexibleDiv
              ref={subContentRef}
              className="display__result songs__display"
            >
              <FlexibleDiv className="tracks">
                <FlexibleDiv className="result__title">
                  <h2 className="songs">Songs</h2>
                </FlexibleDiv>

                <FlexibleDiv className="mapped__songs song__gap">
                  {tracksResult.map((track, idx) => (
                    <TrackListItem
                      playlistId=""
                      handlePlayTrack={handlePlayTrack}
                      key={track.id}
                      idx={idx}
                      track={track}
                      {...track}
                    />
                  ))}
                </FlexibleDiv>
              </FlexibleDiv>

              {/* {!!xProfileResults.length && (
                <FlexibleDiv className="x__profile">
                  <FlexibleDiv className="result__title">
                    <h2 className="match__your__x">
                      Results that match your X
                    </h2>
                  </FlexibleDiv>
                </FlexibleDiv>
              )} */}
            </FlexibleDiv>
          )}

          {!!artistsResult?.length && (
            <FlexibleDiv
              ref={subContentRef}
              className="display__result artists__display"
            >
              <FlexibleDiv className="result__title">
                <h2 className="artists">Artists</h2>

                <span
                  className="see__all"
                  onClick={() =>
                    navigate(`/search/see-all?type=artists&q=${searchQuery}`, {
                      state: {
                        type: "artists",
                        list: artistsResult,
                      },
                    })
                  }
                >
                  see all
                </span>
              </FlexibleDiv>

              <FlexibleDiv ref={subContentRef} className="mapped__content">
                {artistsResult.map(artist => (
                  <ArtisteCard key={artist.id} {...artist} />
                ))}
              </FlexibleDiv>
            </FlexibleDiv>
          )}

          {!!playlistsResult?.length && (
            <FlexibleDiv className="display__result playlists__display">
              <FlexibleDiv className="result__title">
                <h2 className="playlists">Playlists</h2>
              </FlexibleDiv>

              <FlexibleDiv className="category__filter">
                <FlexibleDiv className="category__filter__content">
                  <SearchCategoryFilter
                    activeLabel={playlistFilter}
                    filterBy={playlistCategoryFilter}
                    setActiveLabel={pf => setPlaylistFilter(pf)}
                  />
                </FlexibleDiv>
                <span
                  className="see__all"
                  onClick={() =>
                    navigate(
                      `/search/see-all?type=playlists&q=${searchQuery}`,
                      {
                        state: {
                          type: "playlists",
                          list: playlistsResult,
                        },
                      }
                    )
                  }
                >
                  see all
                </span>
              </FlexibleDiv>

              <FlexibleDiv ref={subContentRef} className="mapped__content">
                {getFilteredPlaylists()}
              </FlexibleDiv>
            </FlexibleDiv>
          )}

          {!!albumsResult?.length && (
            <FlexibleDiv className="display__result artist__release">
              <FlexibleDiv className="result__title">
                <h2 className="artists__release">Artist Release</h2>
              </FlexibleDiv>

              <FlexibleDiv className="category__filter">
                <FlexibleDiv className="category__filter__content">
                  <SearchCategoryFilter
                    activeLabel={playlistFilter}
                    filterBy={albumCategoryFilter}
                    setActiveLabel={acf => setArtistReleaseFilter(acf)}
                  />
                </FlexibleDiv>
                <span
                  className="see__all"
                  onClick={() =>
                    navigate(
                      `/search/see-all?type=artist-release&q=${searchQuery}`,
                      {
                        state: {
                          type: "albums",
                          list: albumsResult,
                        },
                      }
                    )
                  }
                >
                  see all
                </span>
              </FlexibleDiv>

              <FlexibleDiv ref={subContentRef} className="mapped__content">
                {getFilteredAlbums()}
              </FlexibleDiv>
            </FlexibleDiv>
          )}

          {!!usersResult?.length && (
            <FlexibleDiv className="display__result user__profile__display">
              <FlexibleDiv className="result__title">
                <h2 className="user__profiles">User Profiles</h2>
              </FlexibleDiv>

              <FlexibleDiv className="category__filter">
                <FlexibleDiv className="category__filter__content">
                  <SearchCategoryFilter
                    activeLabel={userFilter}
                    filterBy={userCategoryFilter}
                    setActiveLabel={uf => setUserFilter(uf)}
                  />
                </FlexibleDiv>
                <span
                  className="see__all"
                  onClick={() =>
                    navigate(`/search/see-all?type=users&q=${searchQuery}`, {
                      state: {
                        type: "users",
                        list: usersResult,
                      },
                    })
                  }
                >
                  see all
                </span>
              </FlexibleDiv>

              <FlexibleDiv ref={subContentRef} className="mapped__content">
                {usersResult.map(user => (
                  <UserCard key={user.id} {...user} />
                ))}
              </FlexibleDiv>
            </FlexibleDiv>
          )}
        </React.Fragment>
      ) : (
        <NoSearchResult />
      )}
    </ActiveSearchWrap>
  )

  function getFilteredPlaylists() {
    const filteredRes = playlistsResult?.filter(
      p =>
        (p.type === "MANAGED" && playlistFilter === "by_udux") ||
        ((p.type === "SOLO" || p.type === "COLLABORATIVE") &&
          playlistFilter === "by_xers") ||
        playlistFilter === "all"
    )

    return filteredRes?.length ? (
      filteredRes.map(playlist => (
        <PlaylistCard key={playlist.id} {...playlist} />
      ))
    ) : (
      <NoDataDisplay
        height="280px"
        text={`No results for ${playlistFilter} at this time.`}
      />
    )
  }

  function getFilteredAlbums() {
    const filteredRes = albumsResult?.filter(
      alb =>
        (alb.type === "Album" && artistReleaseFilter === "album") ||
        (alb.type === "Single" && artistReleaseFilter === "ep") ||
        artistReleaseFilter === "all"
    )

    return filteredRes?.length ? (
      filteredRes.map(album => (
        <AlbumCard key={album.id} {...album} handleNowPlaying={() => {}} />
      ))
    ) : (
      <NoDataDisplay
        height="280px"
        text={`No results for ${artistReleaseFilter} at this time.`}
      />
    )
  }

  function handlePlayTrack({ track }: PlayTrackProps) {
    dispatch({ type: Types.REPLACE_QUEUED_TRACKS, payload: tracksResult })
    // playTrack(dispatch, playerRef, track)
    playTrack(dispatch, playerRef, track[0])
  }
})

ActiveSearch.displayName = "ActiveSearch"

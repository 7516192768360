import { Button, FlexibleDiv, Loader } from "components"
import { pollSubscriptionStatus } from "network"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { MySubscriptionWrap } from "./my-subscription.styles"
import React from "react"
import dayjs from "dayjs"

export const MySubscription = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useQuery(
    ["user-subscription"],
    () => pollSubscriptionStatus() // FIXME: This api does not return the required data
  )
  const today = new Date()

  const subscriptionData = data?.data
  const userSubscribed = dayjs(subscriptionData?.endingAt).isAfter(today)
  const billingPlan = subscriptionData?.transaction?.billingPlan

  return isLoading ? (
    <Loader variant="purple" wrapperHeight="100%" />
  ) : (
    <MySubscriptionWrap>
      <FlexibleDiv className="main__wrap top__layer">
        {!userSubscribed && (
          <FlexibleDiv className="section__info">
            <h2 className="title">Your Subscription</h2>
            <p>You are currently on the uduX Free Plan.</p>
            <p>
              To enjoy uninterrupted music, unlimited collab Playlists - upgrade
              your account
            </p>
            <FlexibleDiv className="btn__wrap">
              <Button
                background="var(--pinkPrimary)"
                color="var(--black)"
                onClick={() => navigate("/payment")}
                radius="0"
                bold
              >
                <span>Go Premium</span>
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>
        )}
        {userSubscribed && (
          <FlexibleDiv className="section__info">
            <h2 className="title">Your Subscription</h2>
            <p>You are currently on {billingPlan?.description}</p>
            <h5>Subscription Details:</h5>
            <p className="details">
              <div>
                <span>Provider:</span> <small>{billingPlan?.provider}</small>
              </div>
              <div>
                <span>Amount:</span>
                <small>
                  {billingPlan?.currency}
                  {billingPlan?.amount ? billingPlan.amount / 100 : ""}
                </small>
              </div>
              <div>
                <span>Plan Type:</span> <small>{billingPlan?.planType}</small>
              </div>
              <div>
                <span>Duration: </span>
                <small>{billingPlan?.interval}</small>
              </div>
              <div>
                <span>Expiry Date:</span>
                <small>WIP</small>
                {/* <small>{calculateExpiryDate(startDate, endingAt)}</small> */}
              </div>
              <div>
                <span>Status:</span> <small>{billingPlan?.status}</small>
              </div>
            </p>

            <FlexibleDiv className="btn__wrap">
              <Button
                background="var(--pinkPrimary)"
                color="var(--black)"
                onClick={() => navigate("/payment")}
                radius="0"
                bold
              >
                <span>Change Plan</span>
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>
        )}
        <FlexibleDiv flexDir="column" className="image__section">
          <div className="subscription__image__wrap" />
        </FlexibleDiv>
      </FlexibleDiv>
      <FlexibleDiv className="divider"></FlexibleDiv>
    </MySubscriptionWrap>
  )
}

import styled  from "styled-components";
import bannerBg from "assets/images/general/banner1.png";

export const BannerWrap  = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url(${bannerBg}); /* Corrected interpolation */
  border: 1px solid; /* Border thickness */
  border-image-slice: 1; /* Necessary for border-image to work */
  border-image-source: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%);
  width: 100%;
  padding: 20px 40px;
  @media screen and (max-width: 950px) {
    white-space: wrap;
    padding: 20px 20px;
  }

  .banner__content {
    gap: 25px;
    @media screen and (max-width: 950px) {
        gap: 5px;
      }
    .udux__logo__wrap {
      flex: 1;
      max-width: 262px;
      .logo {
        fill: var(--primaryWhite);
        width: 3rem;
        height: 2rem;

        @media only screen and (max-width: 600px) {
          width: 2.5rem;
          height: 2rem;
        }
      }
    }
    .highlight {
      color: var(--uduxYellowPrimary);
    }

    .banner__title {
      font-weight: bold;
      line-height: 23px;
      font-weight: 800;
      font-size: 2rem;
      white-space: nowrap;
      font-family: "Bw ModelicaSS01Bold", sans-serif;
      @media screen and (max-width: 950px) {
        white-space: wrap;
        font-size: 1.5rem;
      }
      & .highlight1 {
        color: #fcb816;
      }
    }

    .banner__description {
      line-height: 28px;
      max-width: 600px;
      margin-top: 15px;
      font-size: 1.3rem;
      font-weight: 500;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 950px) {
        white-space: wrap;
        font-size: 1rem;
      }
      & .highlight2 {
        color: var(--uduxYellowPrimary);
      }
    }
  }

  .listen__wrap {
    @media screen and (max-width: 950px) {
      justify-content: flex-start !important;
      align-items: flex-start !important;
      width: 100%;
    }
    .list__image__wrap {
      width: 160px;
      height: 50px;
      @media screen and (max-width: 950px) {
        width: 140px;
        height: 40px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

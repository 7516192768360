import { ReactionFavoriteQueries } from "types/player"
import { instance } from "./axios"
import {
  ResponseWithAlbums,
  ResponseWithTracks,
  AddAsFavoritePayload,
  ResponseReaction,
  RemoveFavoritePayload,
  ResponseReactions,
  ResponsePlaylistCategories,
  ResponsePlaylists,
} from "types"

export const getPlayList = async () => {
  const { data } = await instance.get<ResponseWithTracks>(`/streaming/tracks`)

  return data
}

export const getPlaylistCategories = async (category: string) => {
  const { data: categories } = await instance.get<ResponsePlaylistCategories>(
    "/streaming/categories"
  )

  const singleCategory = categories.data.find(
    ({ name }: { name: string }) => name === category
  )

  const { data } = await instance.get<ResponsePlaylists>(
    `/streaming/playlists?category=${singleCategory?.id}`
  )

  return data
}

export const getAllUserPlaylists = async () => {
  const { data } = await instance.get<ResponseWithAlbums>(`/streaming/albums`)

  return data
}

export const getArtistPlaylists = async (payload: any) => {
  const { data } = await instance.get(
    `/release-resources?displayArtist=${payload.id}`
  )

  return data
}

export const addToFavorites = async (payload: AddAsFavoritePayload) => {
  const { data } = await instance.post<ResponseReaction>(
    `/social/reactions`,
    payload
  )

  return data
}
export const removeFromFavorites = async (payload: RemoveFavoritePayload) => {
  const { data } = await instance.delete<ResponseReaction>(
    `/social/reactions`,
    {
      data: payload,
    }
  )

  return data
}

export const addReaction = async (payload: AddAsFavoritePayload) => {
  const { data } = await instance.post<ResponseReaction>(
    `/social/reactions`,
    payload
  )

  return data
}

export const removeReaction = async (payload: RemoveFavoritePayload) => {
  const { data } = await instance.delete<ResponseReaction>(
    `/social/reactions`,
    { data: payload }
  )

  return data
}

export const getFavoriteStatus = async (payload: ReactionFavoriteQueries) => {
  const { data } = await instance.get<ResponseReactions>(
    `/social/reactions?entity=${payload.entity}&entityType=${payload.entityType}&status=ACTIVE`
  )

  return data
}

export const getTopTracks = async (payload: string | number) => {
  const { data } = await instance.get<ResponseWithTracks>(
    `/streaming/artists/${payload}/tracks`
  )

  return data
}

export const getPlaylistFeatArtist = async (artistId: string) => {
  const { data } = await instance.get<ResponsePlaylists>(
    `/streaming/playlists/features-artist/${artistId}`
  )

  return data.data
}

import axios from "axios"
import { Config } from "config"
import {
  BaseNetworkResponse,
  InitiatePaymentPayload,
  PaymentGatewayOptions,
  ResponseBillingPlans,
  ResponseInitiatePayment,
  ResponseSubscriptionData,
  ResponseVerifyPayment,
  ResponseWithUser,
} from "types"
import { instance } from "./axios"

export const getBillingPlans = async () => {
  const { data } = await instance.get<ResponseBillingPlans>(
    `/core/billing-plan`
  )
  return data.data
}

export const initiatePayment = async (
  provider: PaymentGatewayOptions,
  data: InitiatePaymentPayload
) => {
  const res = await instance.post<ResponseInitiatePayment>(
    `/core/payment/${provider}/initiate-payment`,
    data
  )

  return res.data
}

export const checkUserPhoneNumber = async (phoneNumber: string) => {
  const res = await instance.get<ResponseWithUser>(
    `/core/user/check-phone-exist/${phoneNumber}`
  )

  return res.data
}

export const subscribeMTNPhone = async (data: {
  planId: string
  msisdn: string
}) => {
  const res = await instance.post<BaseNetworkResponse>(
    "/core/mtn-subscription",
    data
  )

  return res.data
}

export const verifyPayment = async (
  provider: PaymentGatewayOptions,
  reference: string
) => {
  if (provider === "mtn") return

  const res = await instance.get<ResponseVerifyPayment>(
    `/core/payment/${provider}/verify-payment/${reference}`
  )

  return res.data
}

export const headerEnrichment = async (url: string) => {
  const res = await axios.get(url)

  return res.data
}

export const saveMTNEnrichedUser = async (data: any) => {
  const res = await axios.post(Config.loggerUrl, data)

  return res.data
}

export const pollSubscriptionStatus = async () => {
  const res = await instance.get<ResponseSubscriptionData>(
    `/core/user/subscription`
  )
  return res.data
}

import { FlexibleDiv } from "components"
import styled from "styled-components"

export const CollabStatsTabDisplayWrap = styled(FlexibleDiv)`
  width: 90%;

  .central__data__display {
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: flex-start;
  }

  .data__block {
    justify-content: flex-start;
    width: 48%;

    .lead__title {
      padding-bottom: 1rem;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;

    .central__data__display {
      justify-content: center;
    }

    .data__block {
      width: 90%;
    }
  }
`

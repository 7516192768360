import { Types } from "context"
import {
  addToFavorites,
  errorResponseHandler,
  removeFromFavorites,
  successResponseHandler,
} from "network"
import { Dispatch } from "react"
import { Track } from "types/artist"
import { PlayerRefProps } from "types/player"
import { getErrorMessage } from "./get-error-message"
import { ReactionType } from "types"

export const playAudio = (
  playerRef: PlayerRefProps,
  dispatch: Dispatch<{ type: string }>
) => {
  dispatch({ type: Types.SET_PLAY_TRACK })
  playerRef?.current?.play()
}

export const pauseAudio = (
  playerRef: PlayerRefProps,
  dispatch: Dispatch<{ type: string }>
) => {
  playerRef?.current?.pause()
  dispatch({ type: Types.SET_PAUSE_TRACK })
}

export const addToQueue = (
  dispatch: Dispatch<{ type: string; payload: Track[] }>,
  payload: Track[]
) => {
  dispatch({ type: Types.ADD_TO_QUEUE, payload })
}

export const nowPlaying = (
  playlist: any, // TODO: provide better typing
  dispatch: Dispatch<{ type: string; payload?: any }>,
  playerRef: PlayerRefProps
) => {
  dispatch({ type: Types.SET_PLAY_TRACK })
  dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: true })
  dispatch({ type: Types.SET_CURRENT_PLAYING, payload: playlist.tracks[0] })
  playerRef?.current?.play()

  if (Array.isArray(playlist.tracks)) {
    dispatch({ type: Types.SET_PLAYING_PLAYLIST, payload: playlist })
    addToQueue(dispatch, playlist.tracks)
  }
}

export const playTrack = (
  dispatch: Dispatch<{ type: string; payload?: any }>,
  playerRef: PlayerRefProps,
  track: Track
) => {
  dispatch({ type: Types.SET_PLAY_TRACK })
  dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: true })
  dispatch({ type: Types.SET_CURRENT_PLAYING, payload: track })
  playerRef?.current?.play()
}

export const changeRange = (
  e: any, // TODO: provide better typing
  playerRef: PlayerRefProps,
  currentTimeType: string,
  duration: number,
  dispatch: Dispatch<{ type: string; payload: any }>
) => {
  let sum
  if (e.length > 1) {
    sum = (e[0] / 100) * duration
  } else {
    sum = (e[0] / 100) * duration
  }

  if (playerRef?.current) {
    playerRef.current.currentTime = sum
    dispatch({ type: currentTimeType, payload: sum })
  }
}

export const changeVolume = (
  e: number,
  playerRef: PlayerRefProps,
  currentVolumeType: string,
  dispatch: Dispatch<{ type: string; payload: number }>
) => {
  if (playerRef?.current) {
    playerRef.current.volume = e
    dispatch({ type: currentVolumeType, payload: e })
  }
}

export const calculateTime = (secs: number) => {
  const minutes = Math.floor(secs / 60)
  const returnedMinutes = minutes < 10 ? `${minutes}` : `${minutes}`
  const seconds = Math.floor(secs % 60)
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
  return `${returnedMinutes}:${returnedSeconds}`
}

export const playNextTrack = (
  dispatch: Dispatch<{ type: string; payload?: Track }>,
  { track }: { track?: Track; checkAutoEnd?: boolean }
) => {
  dispatch({ type: Types.PLAY_NEXT_TRACK, payload: track })
}

export const playPreviousTrack = (
  dispatch: Dispatch<{ type: string; payload: Track }>,
  { track }: { track: Track }
) => {
  dispatch({ type: Types.PLAY_PREV_TRACK, payload: track })
}

export const playNext = async (
  dispatch: Dispatch<{ type: string; payload: any }>,
  type: string,
  tracks: any, // TODO: provide better typing
  isPlayingType: string,
  checkAutoEnd?: boolean
) => {
  if (tracks.nextTrack) {
    localStorage.setItem("just-played", JSON.stringify(tracks.currentTrack))

    dispatch({
      type: type,
      payload: {
        ...tracks,
        prevTrack: tracks.currentTrack,
        currentTrack: tracks.nextTrack,
        nextTrack:
          tracks.allTracks[tracks.allTracks.indexOf(tracks.nextTrack) + 1],
      },
    })
    dispatch({
      type: isPlayingType,
      payload: true,
    })
  } else {
    dispatch({
      type: type,
      payload: {
        ...tracks,
      },
    })
    if (checkAutoEnd) {
      dispatch({
        type: isPlayingType,
        payload: false,
      })
    }
  }
}

export const playPrevious = async (
  dispatch: Dispatch<{ type: string; payload: any }>,
  type: string,
  tracks: any, // TODO: provide better typing
  isPlayingType: string
) => {
  if (tracks.prevTrack) {
    localStorage.setItem("just-played", JSON.stringify(tracks.currentTrack))

    dispatch({
      type: type,
      payload: {
        ...tracks,
        prevTrack:
          tracks.allTracks[tracks.allTracks.indexOf(tracks.prevTrack) - 1],
        nextTrack: tracks.currentTrack,
        currentTrack: tracks.prevTrack,
      },
    })

    dispatch({
      type: isPlayingType,
      payload: true,
    })
  } else {
    dispatch({
      type: type,
      payload: {
        ...tracks,
      },
    })
  }
}

export const shuffleTrack = (
  tracks: Track[],
  dispatch: Dispatch<{ type: string; payload: any }>
) => {
  dispatch({
    type: Types.SHUFFLE,
    payload: true,
  })

  const sortedTracks = Array.from(tracks)
  sortedTracks.sort(() => (Math.random() > 0.5 ? 1 : -1))

  dispatch({
    type: Types.PLAYING_LIST_ACTIVE_TRACKS,
    payload: sortedTracks,
  })
}

export const unShuffleTrack = (
  tracks: Track[],
  dispatch: Dispatch<{ type: string; payload: any }>
) => {
  dispatch({
    type: Types.SHUFFLE,
    payload: false,
  })

  const sortedTracks = Array.from(tracks)
  sortedTracks.sort((a, b) => a.sequence - b.sequence)

  dispatch({
    type: Types.PLAYING_LIST_ACTIVE_TRACKS,
    payload: sortedTracks,
  })
}

export const repeatTrack = (
  type: string,
  dispatch: Dispatch<{ type: string; payload: boolean }>,
  repeat: boolean
) => {
  dispatch({ type, payload: !repeat })
}

export const getFromPage = (path: string) => {
  if (path === "/") {
    return "Home"
  }
  if (path === "/favorites") {
    return "Liked Songs"
  }
  if (path === "/discover") {
    return "Discover"
  }
  if (path === "/library") {
    return "Library"
  }
  if (path === "/recently-played") {
    return "Recently Played"
  }
  // if (path.includes("playlist")) {
  //   return ( "My Playlist");
  // }
}

export const pushToPlayNext = (
  tracks: any, // TODO: provide better typing
  dispatch: Dispatch<{ type: string; payload: any }>,
  trackType: string,
  track: any, // TODO: provide better typing
  trackIdx: number
) => {
  const element = tracks?.allTracks?.splice(trackIdx, 1)[0]

  tracks?.allTracks?.splice(
    tracks.allTracks.indexOf(tracks.currentTrack) + 1,
    0,
    element
  )

  dispatch({
    type: trackType,
    payload: {
      ...tracks,
      allTracks: [...tracks.allTracks],
      // allTracks: [...tracks?.allTracks],
      nextTrack: track,
      prevTrack: tracks?.prevTrack,
    },
  })
}

export const likeItem = async (entity: string, entityType: string) => {
  const payload = {
    entity,
    entityType,
    type: ReactionType.LIKE,
  }

  try {
    const result = await addToFavorites(payload)
    successResponseHandler(result)
  } catch (e) {
    errorResponseHandler(e)
  }
}

export const unLikeItem = async (entity: string, entityType: string) => {
  const payload = {
    entity: entity,
    entityType: entityType,
  }
  try {
    await removeFromFavorites(payload)
  } catch (e) {
    console.log(`Error occurred: ${getErrorMessage(e)}`)
  }
}

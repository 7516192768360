import { FlexibleDiv } from "components/lib/Box"
import { take } from "lodash"
import { memo } from "react"
import { useNavigate } from "react-router-dom"
import { ArtisteCard } from "../ArtisteCard"
import { PlaylistCard } from "../PlaylistCard"
import { TrackListItem } from "../TrackListItem"
import { DDCMobileWrap, DDCWrap } from "./data-display-card.styles"
import { Types, useMainContext } from "context"
import { playTrack } from "utils"
import { useMediaQuery } from "react-responsive"

export type DataDisplayProps = {
  title?: string
  header?: any
  playlist?: any
  artistData?: any
  cardData?: any[]
  emptyBg?: string
  isEmpty?: boolean
  isLarge?: boolean
  minimize?: boolean
  emptyText?: string
  isArtist?: boolean
  isPlaylist?: boolean
  emptyDisplayIcon?: string
}

const DataDisplay = ({
  cardData = [],
  header,
  isEmpty,
  emptyBg,
  isArtist,
  playlist,
  emptyText,
  isPlaylist,
  artistData,
  emptyDisplayIcon,
}: DataDisplayProps) => {
  const navigate = useNavigate()
  const { playerRef, dispatch } = useMainContext()
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const __colorPicker = {
    isPlaylist: playlist,
    isArtist: artistData,
  }
  const flagSelector = isPlaylist ? "isPlaylist" : "isArtist"

  if (isMobile) {
    return (
      <DDCMobileWrap
        mobile_bg={emptyBg}
        backgroundImage={playlist.coverArt?.url}
        isPlaylist={isPlaylist || isArtist}
      >
        {header && header}

        <FlexibleDiv
          flexWrap="no-wrap"
          className="ddc__card__main"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <div className="tracks__container">
            {isEmpty ? (
              <FlexibleDiv className="is__empty">
                <div className="empty__image__wrap">
                  <img src={emptyDisplayIcon} alt="empty" />
                </div>

                <p className="empty__text">{emptyText}</p>
              </FlexibleDiv>
            ) : (
              <FlexibleDiv className="tracks__list" flexDir="column">
                {take(cardData, 5).map((item, i) => {
                  return (
                    <TrackListItem
                      minimize
                      handlePlayTrack={handlePlayTrack}
                      key={i}
                      {...item}
                    />
                  )
                })}
              </FlexibleDiv>
            )}
          </div>
        </FlexibleDiv>
      </DDCMobileWrap>
    )
  }

  return (
    <DDCWrap
      mobile_bg={emptyBg}
      dominantColors={
        (isPlaylist || isArtist) &&
        __colorPicker[flagSelector].coverArtDominantColors.map((cadc: string) =>
          cadc.startsWith("#") ? cadc : `#${cadc}`
        )
      }
      isPlaylist={isPlaylist || isArtist}
    >
      {header && header}

      <FlexibleDiv
        flexWrap="no-wrap"
        className="ddc__card__main"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {isPlaylist || isArtist ? (
          <div className="playlist__display">
            <div className="playlist__box">
              {isPlaylist && <PlaylistCard {...playlist} />}
              {isArtist && <ArtisteCard {...artistData} />}
            </div>

            <small className="goto-playlist" onClick={handleRedirect}>
              Go to {isPlaylist ? "playlist" : "artist"}
            </small>
          </div>
        ) : (
          <div className="main__display__image ">
            <img src={emptyBg} alt="ndsp" />
          </div>
        )}

        <div className="tracks__container">
          {isEmpty ? (
            <FlexibleDiv className="is__empty">
              <div className="empty__image__wrap">
                <img src={emptyDisplayIcon} alt="empty" />
              </div>

              <p className="empty__text">{emptyText}</p>
            </FlexibleDiv>
          ) : (
            <FlexibleDiv className="tracks__list" flexDir="column">
              {take(cardData, 5).map((item, i) => {
                return (
                  <TrackListItem
                    minimize
                    handlePlayTrack={handlePlayTrack}
                    key={i}
                    {...item}
                  />
                )
              })}
            </FlexibleDiv>
          )}
        </div>
      </FlexibleDiv>
    </DDCWrap>
  )

  function handleRedirect() {
    if (isPlaylist) {
      return navigate(`/playlist/${playlist.id}?t=pl`, {
        state: {
          module: "playlist",
        },
      })
    } else if (isArtist) {
      return navigate(`/artist/${artistData.id}`)
    }
  }

  function handlePlayTrack(track: any) {
    dispatch({ type: Types.SET_PLAYING_PLAYLIST, payload: playlist })
    dispatch({ type: Types.REPLACE_QUEUED_TRACKS, payload: cardData })
    playTrack(dispatch, playerRef, track)
  }
}

export const DataDisplayCard = memo(DataDisplay)

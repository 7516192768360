import { IconProps } from "types"

export const PausePlayingIcon = ({ width, height, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "30"}
    height={height || "30"}
    viewBox="0 0 65 65"
    {...props}
  >
    <g
      id="pause-playing-icon"
      data-name="Pause Playing"
      transform="translate(17282 -1607)"
    >
      <g id="pause" transform="translate(-17282 1607)">
        <rect
          id="Rectangle_3975"
          data-name="Rectangle 3975"
          width="6.757"
          height="20.427"
          transform="translate(22.073 21.811)"
          fill="#fff"
        />
        <path
          id="Path_1560"
          data-name="Path 1560"
          d="M170.428,183.935H163.67a1.817,1.817,0,0,1-1.817-1.817V161.691a1.817,1.817,0,0,1,1.817-1.817h6.758a1.817,1.817,0,0,1,1.817,1.817v20.427A1.817,1.817,0,0,1,170.428,183.935Zm-4.941-3.634h3.124V163.508h-3.124Z"
          transform="translate(-141.597 -139.88)"
          fill="#fdbe28"
        />
        <path
          id="Path_1564"
          data-name="Path 1564"
          d="M0,0H6.757V20.427H0Z"
          transform="translate(35.246 21.811)"
          fill="#fff"
        />
        <g id="Group_9964-2" data-name="Group 9964" transform="translate(0 0)">
          <path
            id="Path_1561"
            data-name="Path 1561"
            d="M275.685,183.935h-6.758a1.817,1.817,0,0,1-1.817-1.817V161.691a1.817,1.817,0,0,1,1.817-1.817h6.758a1.817,1.817,0,0,1,1.817,1.817v20.427A1.817,1.817,0,0,1,275.685,183.935Zm-4.941-3.634h3.124V163.508h-3.124Z"
            transform="translate(-233.681 -139.88)"
            fill="#fdbe28"
          />
          <path
            id="Path_1562"
            data-name="Path 1562"
            d="M32.04,64.166A31.941,31.941,0,0,1,9.385,54.8a1.817,1.817,0,0,1,2.57-2.57,28.392,28.392,0,1,0-7.34-12.672,1.817,1.817,0,0,1-3.509.945A32.043,32.043,0,0,1,54.7,9.486,32.025,32.025,0,0,1,32.04,64.166Z"
            transform="translate(-0.002 -0.117)"
            fill="#fdbe28"
          />
          <path
            id="Path_1563"
            data-name="Path 1563"
            d="M84.5,109.036a24.534,24.534,0,1,1,21.276-36.758,1.817,1.817,0,1,1-3.149,1.813A20.906,20.906,0,1,0,105.4,84.5a1.817,1.817,0,0,1,3.634,0A24.561,24.561,0,0,1,84.5,109.036Z"
            transform="translate(-52.463 -52.477)"
            fill="#fdbe28"
          />
        </g>
      </g>
    </g>
  </svg>
)

import { capitalize } from "lodash"
import { useNavigate } from "react-router-dom"
import { PaymentSuccessWrapper } from "./verify-payment.styles"
import { Button, FlexibleDiv } from "components"
import { useMainContext } from "context"
import moment from "moment"
import { PaymentProvider, TransactionStatus } from "types"

export type PaymentSuccessProps = {
  transactionStatus: TransactionStatus
  startDate: string
  endDate: string
  paymentProvider: PaymentProvider
  currency: string
  reference: string
  interval: string
  amount: string
  plan: string
}
export const PaymentSuccess = ({
  plan,
  amount,
  currency,
  startDate,
  endDate,
  paymentProvider,
}: PaymentSuccessProps) => {
  const {
    state: {
      users: { me },
    },
  } = useMainContext()
  const navigate = useNavigate()

  return (
    <PaymentSuccessWrapper width="50%" flexDir="column">
      <FlexibleDiv height="100%" width="100%" className="payment__container">
        <FlexibleDiv className="top__container">
          <h2 className="congratulations__text">
            Congratulations {capitalize(me?.username)}
          </h2>

          <FlexibleDiv className="button__wrap">
            <small className="payment__text">
              Your payment has successfully been received. We will email a
              confirmation of your receipt shortly.
            </small>

            <Button
              font_size="0.85rem"
              color="var(--black)"
              contained
              onClick={() => navigate("/")}
            >
              <span>Continue to uduX</span>
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          className="bottom__container"
          flexDir="column"
          alignItems="flex-start"
          flexWrap="nowrap"
        >
          <p className="summary__text">Summary</p>
          <FlexibleDiv className="receipt__content__wrapper">
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
            >
              <p className="title">Plan</p>
              <p className="content">{plan}</p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
            >
              <p className="title">Amount</p>
              <p className="content">
                {currency} {amount}
              </p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
            >
              <p className="title">Date of Purchase</p>
              <p className="content">
                {moment(startDate).format("ddd, do MMM YYYY")}
              </p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
              id="break-flex"
            >
              <p className="title">Date of Expiry</p>
              <p className="content">
                {moment(endDate).format("ddd, do MMM YYYY")}
              </p>
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              className="receipt__content"
              alignItems="center"
              id="break-flex"
            >
              <p className="title">Payment Platform</p>
              <p className="content">{paymentProvider?.toLowerCase()}</p>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </PaymentSuccessWrapper>
  )
}

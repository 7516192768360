import { Loader, OverflowScroll } from "components"
import { Types, useMainContext } from "context"
import {
  getAlbumById,
  getAlbumTracksById,
  // getArtistAlbums,
  // getSimilarArtists,
} from "network"
import { PlaylistWrap } from "pages/Playlist/playlist.styles"
import React from "react"
import { useQueries } from "react-query"
import { useParams } from "react-router-dom"
import { AlbumJumbotron } from "../PlaylistJumbotron/components/AlbumJumbotron"
import { AlbumOrganizer } from "../PlaylistOrganizer"

export const AlbumModule = () => {
  const { playlistId } = useParams()
  const {
    state: { playlist, users },
    dispatch,
  } = useMainContext()
  const { me } = users

  const albumInfo = useQueries([
    {
      queryKey: ["album", playlistId],
      enabled: !!playlistId,
      queryFn: () => getAlbumById(playlistId),
    },
    {
      queryKey: ["album-tracks"],
      enabled: !!playlistId,
      queryFn: () => getAlbumTracksById(playlistId),
    },
  ])

  const isLoading = albumInfo.some(o => o.isLoading)
  const albumData = albumInfo[0].data
  const tracks = albumInfo[1]?.data || []

  const album =
    playlist?.currentAlbum?.id && playlist.currentAlbum.id === playlistId
      ? playlist.currentAlbum
      : albumData
  const coverArt = album?.metadata?.coverArt?.url
  const da = album?.metadata?.displayArtists
  const mainArtist = da?.find(d => d.isMainArtist)

  const isOwner = album?.user === me?.id // Album.user is the userId coming from the API

  // const extraInfo = useQueries([
  //   {
  //     queryKey: ["more-from"],
  //     enabled: !!mainArtist?.artist?.id,
  //     queryFn: () => getArtistAlbums(mainArtist?.artist?.id),
  //   },
  //   {
  //     queryKey: ["similar"],
  //     enabled: !!mainArtist?.artist?.id,
  //     queryFn: () => getSimilarArtists(mainArtist?.artist?.id ?? ""),
  //   },
  // ])

  // const extraInfoLoading = extraInfo.some(o => o.isLoading)
  // const moreFromData = extraInfo[0].data
  // const similarFromData = extraInfo[1].data

  React.useEffect(() => {
    dispatch({
      type: Types.UPDATE_CURRENT_ALBUM,
      payload: { ...albumData, tracks },
    })
  }, [albumData, tracks, dispatch])

  const jumboData = {
    domColors: [`${album?.metadata?.coverArtDominantColors[0]}`, `#000`],
    coverArt: album?.metadata?.coverArt,
    title: album?.metadata?.title || "",
    owner: {
      mainArtist: mainArtist?.artist?.stageName || "",
      artistCover: mainArtist?.artist?.coverArt?.url || "",
    },
    stats: {
      likes: album?.statistics?.likes || 0,
      songs: album?.tracks?.length || 0,
      spins: album?.statistics?.spins || 0,
    },
    releaseDate: album?.metadata?.originalReleaseDate
  }

  return (
    <OverflowScroll>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <PlaylistWrap>
          <AlbumJumbotron {...jumboData} />

          <AlbumOrganizer
            module="album"
            tracks={tracks}
            isOwner={isOwner}
            coverArt={coverArt}
            loading={isLoading}
            // similarFromData={similarFromData}
            // suggestedTracks={suggestedTracks}
          />
        </PlaylistWrap>
      )}
    </OverflowScroll>
  )
}

import { FlexibleDiv } from "components";
import { DominantColors } from "context";
import styled from "styled-components";

interface PJSoloWrapProps {
  dominantColors?: DominantColors;
}

export const PJSoloWrap = styled(FlexibleDiv)<PJSoloWrapProps>`
  height: 350px;
  position: relative;
  align-items: flex-start;
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(90deg, ${dominantColors[0]}, ${dominantColors[1]})`
      : ""};

  .camera__icon {
    position: absolute;
    top: 3%;
    right: 5%;
    z-index: 99;
  }

  .upload__in__progress {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: not-allowed;
    background-color: var(--overlayDark);
  }

  .art__info__section {
    flex: 1;
    display: flex;
    margin-left: 370px;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .hero__details {
      height: 100%;
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      width: 50%;
    }

    .type__and__year {
      font-size: 1rem;
      margin-bottom: 0rem;
    }

    .title {
      color: white;
      font-size: 2.5rem;
      text-transform: capitalize;
      font-family: "Baguede", sans-serif;
    }

    .artist {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;

      .artist__avatar {
        width: 60px;
        height: 60px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .artist__names {
        display: flex;
        column-gap: 2px;
        margin-bottom: 0;
        margin-left: 1rem;
        justify-content: flex-start;
      }

      .artist__name {
        color: white;
        font-size: 1rem;
        cursor: pointer;
        width: max-content;
        text-transform: capitalize;

        :hover {
          text-decoration: underline;
        }
      }

      .udux__logo {
        width: 60px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .playlist__creator {
        display: flex;
        align-items: center;

        .avatar {
          width: 60px;
          height: 60px;

          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .creator__name {
          color: white;
          font-size: 1rem;
          cursor: pointer;
          width: max-content;
          text-transform: capitalize;

          :hover {
            text-decoration: underline;
          }
        }
      }
    }

    .bio__section {
      width: 45%;
      height: 50%;
      padding-right: 2rem;

      .content__editable {
        outline: none;
      }
    }
  }

  .cover__art__playlist {
    width: 300px;
    height: 300px;
    position: absolute;
    left: 3%;
    bottom: 3%;
    z-index: 9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .solo__playlist__wrap {
    padding-right: 5rem;
    padding-left: 370px;
    height: 65px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    background-color: black;
    border: 0.2px solid var(--borderLight);
    border-top: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .stats__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .stats__display {
    display: flex;
    align-items: center;
    column-gap: 13px;
    padding-right: 2rem;

    .statistics {
      margin: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    span {
      font-family: "Baguede", sans-serif;
    }

    .number {
      font-size: 17px;
    }

    .info {
      opacity: 0.8;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1679px) {
    flex-direction: row;

    .cover__art__playlist {
      max-width: 260px;
      min-width: 200px;
      /* width: 30%; */
      height: 85%;
    }

    .art__info__section {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 320px;
    }

    .solo__playlist__wrap {
      padding-left: 320px;
      padding-right: 2rem;

      .stats__wrap {
      }
    }
  }

  @media screen and (max-width: 1132px) {
    .album__art {
      .hero__details {
      }

      .type__and__year {
        font-size: 1rem;
      }

      .title {
        font-size: 2rem;
      }

      .artist {
        margin-bottom: 5rem;

        .artist__avatar {
          width: 45px;
          height: 45px;
        }

        .artist__names {
        }

        .artist__name {
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 739px) {
    height: 230px;

    .cover__art__playlist {
      display: none;
    }

    .art__info__section {
      padding: 0;
      margin-left: 0;
      flex-direction: row;

      .hero__details {
        justify-content: center;
        padding: 1rem 0;
        padding-left: 1rem;
        width: 100%;
      }

      .type__and__year {
        font-size: 1rem;
        margin-bottom: 0;
      }

      .title {
        font-size: 1.5rem;
      }

      .artist {
        /* margin-top: 1rem; */
        margin-bottom: 1rem;

        .artist__avatar {
          width: 25px;
          height: 25px;
        }

        .artist__names {
          width: 140px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .artist__name {
          font-size: 0.85rem;
        }
      }

      .bio__section {
        width: 100%;
        height: 100%;
        justify-content: center;
        padding-left: 1rem;
        padding-top: 14%;

        p {
          font-size: 10px;
        }
      }
    }

    .solo__playlist__wrap {
      padding: 1rem;

      .stats__wrap {
        flex-direction: row-reverse;
      }

      .player__controls,
      .stats__display {
        width: 48%;
        column-gap: 8px;
      }

      .player__controls {
        .icon__wrap {
          height: 25px;
          width: 25px;
        }
      }

      .player__controls {
        max-width: 200px;
      }

      .stats__display {
        padding-right: 0;

        .number {
          font-size: 12px;
        }

        .info {
          font-size: 8px;
        }
      }
    }
  }
`;

interface AlbumJumboWrapProps {
  dominantColors?: DominantColors;
}

export const AlbumJWrap = styled(FlexibleDiv)<AlbumJumboWrapProps>`
  height: 380px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
  padding: ${({ dominantColors }) =>
    dominantColors && dominantColors[0] !== "undefined"
      ? `0px 20px 10px 30px`
      : "0px 30px 10px 0px"};
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 0%, ${dominantColors[1]} 100%)`
      : ""};

  @media screen and (max-width: 950px) {
    padding: 20px;
    height: 300px;
  }

  .playlist__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    min-height: 85%;
    align-items: flex-start;
    @media screen and (max-width: 1244px) {
      font-size: 0.6rem;
      min-height: 100%;
      gap: 15px;
    }
  }

  .jumbottron__top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 1244px) {
      gap: 15px;
      justify-content: space-between;
    }

    .subtile {
      display: flex;
      column-gap: 40px;

      span {
        color: var(--primaryWhite);
        font-size: 0.9rem;
        line-height: 25.6px;
        font-weight: 800;
        text-transform: capitalize;
        font-family: "Bw Modelica", sans-serif;
        @media screen and (max-width: 1244px) {
          font-size: 0.6rem;
        }
      }
    }
    .playlist__title {
      color: var(--primaryWhite);
      line-height: 4rem;
      font-size: 60px;
      text-transform: capitalize;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 1244px) {
        font-size: 1.2rem;
        padding: 0;
        line-height: 1.2rem;
      }
    }

    .playlist__description {
      color: var(--primaryWhite);
      line-height: 28px;
      font-weight: 500;
      max-width: 650px;
      font-size: 1.1rem;
      opacity: 0.8;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 1244px) {
        display: none;
      }
    }
  }

  .stats__display {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1244px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      justify-content: flex-start;
      gap: 30px;
    }

    .curator {
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      font-family: "Bw Modelica", sans-serif;
      @media screen and (max-width: 1244px) {
        font-size: 0.9rem;
      }

      .main__artist {
        width: 45px;
        height: 45px;
        position: relative;
        column-gap: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .statistics__wrap {
      display: flex;
      align-items: center;
      gap: 20px;

      @media screen and (max-width: 1244px) {
        gap: 10px;
      }

      .statistics {
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 1rem;
        line-height: 20px;
        font-weight: 400;
        font-family: "Bw Modelica", sans-serif;
        @media screen and (max-width: 1244px) {
          font-size: 0.8rem;
        }
        .info,
        .number {
          color: var(--primaryWhite);
        }
      }
    }
  }

  .mobile__playlist__description {
    display: none;
    color: var(--primaryWhite);
    line-height: 28px;
    font-weight: 500;
    max-width: 95%;
    font-size: 1.1rem;
    opacity: 0.8;
    font-family: "Bw Modelica", sans-serif;
    @media screen and (max-width: 1244px) {
      display: block;
      font-size: 0.9rem;
      line-height: 25px;
    }
  }

  .player__controls {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    @media screen and (max-width: 1244px) {
      display: none;
    }

    .icon__wrap {
      height: 35px;
      width: 35px;
      display: flex;
      color: var(--primaryWhite);
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      svg {
        path {
          transition: all 0.5s ease;
        }
      }

      &:hover {
        color: var(--uduxYellowPrimary);
      }
    }
  }

  .cover__art {
    width: 300px;
    min-width: 300px;
    height: 320px;
    position: absolute;
    bottom: 15px;
    right: 30px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }

    @media screen and (max-width: 1244px) {
      width: 200px;
      min-width: 200px;
      height: 220px;
      top: 20px;
      right: 20px;
    }
    @media screen and (max-width: 765px) {
      width: 130px;
      min-width: 130px;
      height: 150px;
    }
  }
`;

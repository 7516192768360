import { IconProps } from "types";

export const MoreIcon = ({ color="#ffffff", width, height, ...props }: IconProps) => {
  return (
    <svg
      width={width || "7"}
      height={height || "27"}
      viewBox="0 0 7 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.9">
        <path
          d="M2.43773 2.49834C1.99345 2.49834 1.63329 2.84949 1.63329 3.28267V4.85132C1.63329 5.28449 1.99345 5.63564 2.43773 5.63564H4.0466C4.49088 5.63564 4.85104 5.28449 4.85104 4.85132V3.28267C4.85104 2.84949 4.49088 2.49834 4.0466 2.49834H2.43773ZM2.43773 0.929688H4.0466C5.37943 0.929688 6.45991 1.98315 6.45991 3.28267V4.85132C6.45991 6.15083 5.37943 7.2043 4.0466 7.2043H2.43773C1.10489 7.2043 0.0244141 6.15083 0.0244141 4.85132V3.28267C0.0244141 1.98315 1.10489 0.929688 2.43773 0.929688ZM2.43773 11.9103C1.99345 11.9103 1.63329 12.2614 1.63329 12.6946V14.2632C1.63329 14.6964 1.99345 15.0476 2.43773 15.0476H4.0466C4.49088 15.0476 4.85104 14.6964 4.85104 14.2632V12.6946C4.85104 12.2614 4.49088 11.9103 4.0466 11.9103H2.43773ZM2.43773 10.3416H4.0466C5.37943 10.3416 6.45991 11.3951 6.45991 12.6946V14.2632C6.45991 15.5627 5.37943 16.6162 4.0466 16.6162H2.43773C1.10489 16.6162 0.0244141 15.5627 0.0244141 14.2632V12.6946C0.0244141 11.3951 1.10489 10.3416 2.43773 10.3416ZM2.43773 21.3222C1.99345 21.3222 1.63329 21.6733 1.63329 22.1065V23.6751C1.63329 24.1083 1.99345 24.4595 2.43773 24.4595H4.0466C4.49088 24.4595 4.85104 24.1083 4.85104 23.6751V22.1065C4.85104 21.6733 4.49088 21.3222 4.0466 21.3222H2.43773ZM2.43773 19.7535H4.0466C5.37943 19.7535 6.45991 20.807 6.45991 22.1065V23.6751C6.45991 24.9747 5.37943 26.0281 4.0466 26.0281H2.43773C1.10489 26.0281 0.0244141 24.9747 0.0244141 23.6751V22.1065C0.0244141 20.807 1.10489 19.7535 2.43773 19.7535Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

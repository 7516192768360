import {
  AuthResponse,
  BaseNetworkResponse,
  ChangePasswordPayload,
  ConfirmEmailUpdateStatus,
  PreSignedURLPayload,
  ResetPasswordConfirmTokenPayload,
  ResponseCheckAvail,
  ResponseFollowUser,
  ResponseFollowUsers,
  ResponsePlaylists,
  ResponsePresignedUrl,
  ResponseUserLibrary,
  ResponseWithUser,
  ResponseWithUsers,
  UpdatePasswordPayload,
  UpdateUserPayload,
} from "types"
import { instance } from "./axios"

/* Auth services */
export const changePassword = async (data: ChangePasswordPayload) => {
  const response = await instance.post<BaseNetworkResponse>(
    "/auth/auth/change-password",
    data
  )

  return response.data
}

export const confirmResetPasswordToken = async (
  data: ResetPasswordConfirmTokenPayload
) => {
  const response = await instance.post<BaseNetworkResponse>(
    "/auth/auth/reset-password/confirm-token",
    data
  )

  return response.data
}

export const completeForgotPassword = async (data: UpdatePasswordPayload) => {
  const response = await instance.post<BaseNetworkResponse>(
    "/auth/auth/reset-password/update-password",
    data
  )

  return response.data
}

export const confirmEmailWithToken = async (data: ConfirmEmailUpdateStatus) => {
  const response = await instance.post<AuthResponse>(
    "/auth/auth/confirm-email/update-status",
    data
  )

  return response.data
}

export const resendEmailConfirmationCode = async (
  data: Omit<ConfirmEmailUpdateStatus, "confirmEmailToken">
) => {
  const response = await instance.post<BaseNetworkResponse>(
    "/auth/auth/confirm-email/request",
    data
  )

  return response.data
}

/* Profile Services */
export const getUserProfile = async () =>
  await instance.get<ResponseWithUser>("/core/user/profile")
export const getUserProfileWithID = async (userId: string) => {
  const response = await instance.get<ResponseWithUser>(
    `/core/user/profile/${userId}`
  )

  return response.data
}

export const updateUserProfile = async (data: UpdateUserPayload) => {
  const response = await instance.patch<ResponseWithUser>(
    "/core/user/profile",
    data
  )

  return response.data
}

export const getPreSignedUrl = async (data: PreSignedURLPayload) => {
  const response = await instance.post<ResponsePresignedUrl>(
    "/core/uploader/presigned-url",
    data
  )

  return response.data
}

export const followUser = async (userId: string) => {
  const response = await instance.post<ResponseFollowUser>(
    `/social/follow/user/${userId}`
  )

  return response.data
}

export const unFollowUser = async (userId: string) => {
  const response = await instance.delete<ResponseFollowUser>(
    `/social/unfollow/user/${userId}`
  )

  return response.data
}

export const checkAvailableUsername = async (username: string) => {
  const response = await instance.get<ResponseCheckAvail>(
    `/core/user/check-username-exist/${username}`
  )

  return response.data
}

export const checkAvailableEmail = async (emailAddress: string) => {
  const response = await instance.get<ResponseCheckAvail>(
    `/core/user/check-email-exist/${emailAddress}`
  )

  return response.data
}

export const checkAvailablePhone = async (phoneNumber: string) => {
  const { data } = await instance.get<ResponseCheckAvail>(
    `/core/user/check-phone-exist/${phoneNumber}`
  )

  return data.data
}

export const userLibraryData = async () => {
  const response = await instance.get<ResponseUserLibrary>(`/core/user/library`)
  return response.data
}

export const getLikedPlaylists = async () => {
  const { data } = await instance.get<ResponsePlaylists>(
    `/core/user/liked/playlists`
  )
  return data.data
}

export const fetchArtistsIFollow = async (userId?: string) => {
  const url = `/social/follow/followed-artists${userId ? `/${userId}` : ""}`
  const response = await instance.get<ResponseFollowUsers>(url)

  return response.data
}

export const getUsersToFollow = async () => {
  const { data } = await instance.get<ResponseWithUsers>(`/core/user/random`)

  return data.data
}

import styled from "styled-components"
import { Slider } from "antd"
import { SliderBaseProps } from "antd/lib/slider"

export const ProgressSlider = styled(Slider)<SliderBaseProps>`
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;

  .ant-slider-rail,
  .ant-slider-rail:hover {
    border-radius: 3px;
    border: 0.5px solid var(--primaryGray);
    background: transparent;
    height: 100%;
  }

  .ant-slider-track,
  .ant-slider:hover .ant-slider-track {
    background-color: var(--uduxYellowPrimary) !important;
    border-radius: 3px;
    height: 100%;
  }

  .ant-slider-handle {
    background-color: var(--uduxYellowPrimary);
    border: none;
    transition: opacity 0.3s ease;
    width: 4px;
    height: 4px;
    opacity: 0;
    transform: translateX(0) !important;

    ::before {
      width: 4px;
      height: 4px;
    }

    ::after {
      top: -3px;
      box-shadow: 0 0 0 1px #cb9c21;
      background-color: var(--uduxYellowPrimary);
    }

    :hover,
    :active,
    :focus {
      ::before {
        width: 4px;
        height: 4px;
      }

      ::after {
        top: -3px;
        box-shadow: 0 0 0 2px #cb9c21;
      }
    }
  }

  :hover,
  :focus {
    .ant-slider-handle {
      opacity: 1;

      ::after {
        box-shadow: 0 0 0 2px #cb9c21;
      }
    }

    .ant-slider-rail,
    .ant-slider-rail:hover {
      background: transparent;
    }
  }
`

export const VerticalProgressSlider = styled(Slider)`
  .ant-slider-rail,
  .ant-slider-rail:hover {
    border-radius: 5px;
    border: 0.5px solid var(--primaryGray);
    background: transparent;
    height: 100%;
  }

  .ant-slider-track,
  .ant-slider:hover .ant-slider-track {
    background-color: var(--uduxYellowPrimary) !important;
    border-radius: 5px;
    height: 100%;
  }

  .ant-slider-handle {
    background-color: var(--uduxYellowPrimary);
    border: none;
    transition: opacity 0.3s ease;
    width: 6px;
    height: 6px;

    ::before {
      width: 8px;
      height: 8px;
    }

    ::after {
      box-shadow: 0 0 0 2px #cb9c21;
      background-color: var(--uduxYellowPrimary);
    }
    :hover,
    :active,
    :focus {
      ::after {
        box-shadow: 0 0 0 4px #cb9c21;
      }
    }
  }

  :hover,
  :focus,
  :active {
    .ant-slider-handle {
      opacity: 1;

      ::after {
        box-shadow: 0 0 0 2px #cb9c21;
      }
    }

    .ant-slider-rail,
    .ant-slider-rail:hover {
      background: transparent;
    }
  }
`

import { TrackDurationIcon } from "assets/icons/TrackDurationIcon"
import { TLIHeaderWrap } from "./track-list-item.styles"

export const TrackListItemHeader = ()=>{
    return (
        <TLIHeaderWrap>
            <div className="number__idx heading">#</div>
            <div className="heading name__and__cover">title</div>
            <div className="heading artist">creator(s)</div>
            <div className="heading album">albums</div>
            <div className="heading spins">spins</div>
            <div className="heading duration">
              <TrackDurationIcon />
            </div>
            <div className="actions" />
        </TLIHeaderWrap>
    )
}
import { useNavigate } from "react-router-dom"
import { truncate } from "utils"
import { UserCardWrap } from "./user-card.styles"
import { User } from "context"

export type UserCardProps = User

export const UserCard = ({ ...props }: UserCardProps) => {
  const { username, statistics, profile, id } = props
  const navigate = useNavigate()

  const { fullName, profilePicture, profilePictureDominantColors } = profile
  const name = fullName?.trim() ? fullName : username

  return (
    <UserCardWrap
      dominantColors={profilePictureDominantColors.map(pdc =>
        pdc.startsWith("#") ? pdc : `#${pdc}`
      )}
      onClick={() => navigate(`/user/${id}`)}
    >
      <div className="picture__box">
        <div className="image__wrap">
          <img src={profilePicture?.url} alt={username} />
        </div>
      </div>

      <div className="name__box">
        <div className="name__and__follow">
          <p className="name">{truncate(name)}</p>
          <p className="followers">
            {statistics.followers}{" "}
            {statistics.followers <= 1 ? "Follower" : "Followers"}
          </p>
        </div>

        <div className="tuale__box">
          <p className="tuale">
            {statistics.tuales} {statistics.tuales <= 1 ? "Tuale" : "Tuales"}
          </p>
        </div>
      </div>
    </UserCardWrap>
  )
}

import styled from "styled-components";

export const TopSongsWrap = styled.div`
  width: 100%;

  .header {
    margin-bottom: 15px;
    .subheader {
      width: 100%;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 29px;
      color: var(--primaryWhite);
      text-align: left;
      font-family: "Inter", sans-serif;
    }

    @media (max-width: 1024px) {
      .header {
        .subheader {
          font-size: 18px;
          white-space: nowrap;
        }
      }
    }
  }

  .top__songs {
    width: 100%;
    max-width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 60px;
    row-gap: 20px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 20px;


    .top__songs {
      max-width: 100%;
      grid-template-columns: 1fr;
    }
  }
`;

import { IconProps } from "types";

export const FsePlayingIcon = ({ color="#D9D9D9", width, height, ...props}: IconProps) => (
  <svg
    width={width || "17"}
    height={height || "25"}
    viewBox="0 0 17 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0H3.64286V25H0V0ZM17 0V25H13.3571V0H17Z" fill={color} />
  </svg>
);
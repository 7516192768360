import React, { useEffect, useState } from "react"
import { LineChart } from "../charts/line-chart"
import { FlexibleDiv } from "components"

export const TotalPlayChart: React.FC = () => {
  const [chartData, setChartData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "",
        data: [45, 59, 120, 81, 56, 55, 120, 59, 80, 95, 76, 130],
        fill: false,
        borderColor: "#B77F3F",
        borderWidth: 1,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#fff",
        pointBorderWidth: 1,
        pointRadius: 2,
        tension: 0.1,
      },
    ],
  })

  const options = {
    responsive: true,
    maintainAspectRation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#fff",
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        ticks: {
          color: "#fff",
        },
        grid: {
          display: false,
        },
      },
    },
  }

  useEffect(() => {
    const handleResize = () => {
      // Force a re-render on resize
      setChartData({ ...chartData })
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [chartData])

  return (
    <FlexibleDiv>
      <LineChart data={chartData} options={options} />
    </FlexibleDiv>
  )
}

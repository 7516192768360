import { IconProps } from "types";

export const HelpIcon = ({ color = "#848484", width, height }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="help__svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L9.96803 8.55382C10.5635 8.20193 11.2582 8 12 8C12.7418 8 13.4365 8.20193 14.032 8.55382L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L15.4462 9.96803C15.7981 10.5635 16 11.2582 16 12C16 12.7418 15.7981 13.4365 15.4462 14.032L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L14.032 15.4462C13.4365 15.7981 12.7418 16 12 16C11.2582 16 10.5635 15.7981 9.96803 15.4462L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L8.55382 14.032C8.20193 13.4365 8 12.7418 8 12C8 11.2582 8.20193 10.5635 8.55382 9.96803L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289Z"
      fill={color}
    />
  </svg>
);

import { FlexibleDiv } from "components"
import styled from "styled-components"

export const RegisterContent = styled(FlexibleDiv)`
  max-height: 270px;

  .confirm__text {
    font-size: 0.85rem;
    text-align: center;
    padding-bottom: 2rem;

    @media (max-width: 376px) {
      font-size: 0.7rem;
    }
  }

  .change__method__text {
    font-size: 0.65rem;
    text-decoration: none;
    color: var(--primaryWhite);
    opacity: 0.7;
    padding-top: 10%;
  }

  .subscribe__btn {
    width: fit-content;
    background: #ffa300;
    text-transform: none;
    border-radius: 0;
    transition: transform 1s;
    margin-top: 2rem;

    @media (max-width: 376px) {
      font-size: 0.7rem;
    }

    span {
      font-size: 0.75rem;
      color: var(--primaryBlack);

      @media (max-width: 376px) {
        font-size: 0.65rem;
      }
    }

    :hover {
      transform: scale(1.1);
    }
  }

  .logout__class {
    font-size: 0.65rem;
    text-decoration: none;
    color: var(--primaryWhite);
    font-family: "Bw ModelicaRegular";
    opacity: 0.7;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

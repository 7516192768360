export const formatReleaseDate = (dateString: string): string => {
    if (!dateString) return ""; // Handle cases where dateString might be null or undefined
    const date = new Date(dateString);
    
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
  
    // Format the date as "Aug. 8, 2024" and add the "Released" prefix
    return `${date.toLocaleDateString("en-US", options).replace(",", ".")}`;
};
  
import { searchUser } from "network/search"
import { useQuery, useQueryClient, UseQueryOptions } from "react-query"
import { UserResponseSearch } from "types"

export const useSearchUser = (
  query: string,
  config?: UseQueryOptions<UserResponseSearch>
) => {
  const queryClient = useQueryClient()

  return useQuery<UserResponseSearch>(
    query, // Cache key
    () => searchUser(query), // Query with dynamic filters
    {
      initialData: () => {
        // Check if data is cached already
        const cachedData = queryClient.getQueryData<UserResponseSearch>(query)
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
      ...config,
    }
  )
}

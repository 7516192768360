import emptyBg from "assets/images/general/empty-top-artist.svg";
import { FlexibleDiv } from "components/lib/Box";
import { Button } from "components/lib/Button";
import { NDDWrap, NDDWrapProps } from "./no-data-display.styles";

export type NoSoloPlaylistDisplayProps = {
  isOwner?: boolean;
} & NDDWrapProps;


export const NoSoloPlaylistDisplay = ({ isOwner, ...props } : NoSoloPlaylistDisplayProps) => {
  return (
    <NDDWrap {...props}>
      <div className="no__display__image">
        <img src={emptyBg} />
      </div>
      <p className="text__content">It all comes to life with one playlist</p>

      {isOwner && (
        <FlexibleDiv className="button__wrap">
          <Button contained>
            <span>Create Playlist</span>
          </Button>
        </FlexibleDiv>
      )}
    </NDDWrap>
  );
};

import { Skeleton } from "antd"
import { FlexibleDiv, PlainImageTextCard } from "components"
import { Track, Album } from "types"
import { useNavigate } from "react-router-dom"
import { Playlist } from "types/playlist"

export type TrackListProps = {
  loading: boolean
  albums?: Album[]
  tracks?: Track[]
  playlists?: Playlist[]
  size?: "large" | "small" | "chart"
}

export const TrackList = ({
  loading,
  albums,
  tracks,
  playlists,
  size = "small",
}: TrackListProps) => {
  const navigate = useNavigate()

  return loading ? (
    <FlexibleDiv minHeight="300px">
      <Skeleton active={loading} />
    </FlexibleDiv>
  ) : (
    <>
      {tracks !== undefined &&
        tracks?.map((item, idx) => {
          return (
            <FlexibleDiv
              className="playercard"
              width="max-content"
              key={idx}
              margin={idx !== 0 ? "0 0 0 30px" : "0"}
            >
              <PlainImageTextCard
                {...item}
                track={item}
                size={size}
                coverArt={item?.metadata?.coverArt?.url}
                title={item?.metadata?.title}
                subTitle={item?.metadata?.displayArtists[0]?.artist?.stageName}
                // handleClick={() =>
                //   navigate(`/playlist/${item.id}?t=alb`, {
                //     state: {
                //       module: "ablum",
                //     },
                //   })
                // }
              />
            </FlexibleDiv>
          )
        })}

      {playlists !== undefined &&
        playlists?.map((item, idx) => {
          return (
            <FlexibleDiv
              className="playercard"
              width="max-content"
              key={idx}
              margin={idx !== 0 ? "0 0 0 30px" : "0"}
            >
              <PlainImageTextCard
                {...item}
                size={size}
                coverArt={item?.coverArt?.url}
                title={item?.title}
                subTitle={item?.description}
                handleClick={() =>
                  navigate(`/playlist/${item.id}?t=pl`, {
                    state: {
                      module: "playlist",
                    },
                  })
                }
              />
            </FlexibleDiv>
          )
        })}

      {albums !== undefined &&
        albums?.map((item, idx) => {
          return (
            <FlexibleDiv
              className="playercard"
              width="max-content"
              key={idx}
              margin={idx !== 0 ? "0 0 0 30px" : "0"}
            >
              <PlainImageTextCard
                {...item}
                size="small"
                coverArt={item?.metadata?.coverArt?.url}
                title={item?.metadata?.title}
                subTitle={item?.metadata?.displayArtists[0]?.artist?.stageName}
                handleClick={() =>
                  navigate(`/playlist/${item.id}?t=alb`, {
                    state: {
                      module: "ablum",
                    },
                  })
                }
              />
            </FlexibleDiv>
          )
        })}
    </>
  )
}

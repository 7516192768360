import styled from "styled-components"

export const WebMusicPlayerWrap = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  column-gap: 40px;
  padding-top: 10px;

  .left__wrap {
    width: 400px;
    min-width: 400px;
    margin-right: 60px;
    .top__info{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 10px;

      h3{
        font-size: 1.4rem;
        font-family: "Bw Modelica", sans-serif;
      }

      p{
        font-size: 0.9rem;
        opacity: 0.9;
        font-family: "Bw Modelica", sans-serif;
      }
    }
  }

  .right__wrap {
    overflow: hidden;
    flex: 1;
    /* width: 480px; */
    /* min-width: 350px; */
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
  }

`

import { IconProps } from "types";

export const SimilarVibesIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "18"}
    height={height || "17"}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.76175 4.37578V6.39453H4.62658V4.37578H6.76175ZM7.82933 3.36641H3.559V7.40391H7.82933V3.36641ZM6.76175 9.75911V11.7779H4.62658V9.75911H6.76175ZM7.82933 8.74974H3.559V12.7872H7.82933V8.74974ZM12.4555 4.37578V6.39453H10.3204V4.37578H12.4555ZM13.5231 3.36641H9.25277V7.40391H13.5231V3.36641ZM9.25277 8.74974H10.3204V9.75911H9.25277V8.74974ZM10.3204 9.75911H11.3879V10.7685H10.3204V9.75911ZM11.3879 8.74974H12.4555V9.75911H11.3879V8.74974ZM9.25277 10.7685H10.3204V11.7779H9.25277V10.7685ZM10.3204 11.7779H11.3879V12.7872H10.3204V11.7779ZM11.3879 10.7685H12.4555V11.7779H11.3879V10.7685ZM12.4555 9.75911H13.5231V10.7685H12.4555V9.75911ZM12.4555 11.7779H13.5231V12.7872H12.4555V11.7779ZM15.6583 4.71224H14.2348V2.69349H12.0997V1.34766H15.6583V4.71224ZM15.6583 14.806V11.4414H14.2348V13.4602H12.0997V14.806H15.6583ZM1.42383 14.806H4.98244V13.4602H2.84727V11.4414H1.42383V14.806ZM1.42383 1.34766V4.71224H2.84727V2.69349H4.98244V1.34766H1.42383Z"
      fill={color}
      fillOpacity="0.9"
    />
  </svg>
);

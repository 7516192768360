import { LoadingOutlined } from "@ant-design/icons"
import { Form } from "antd"
import LoginPagePicture from "assets/images/payment/payment_login.png"
import { BorderedTextfield, Button, FlexibleDiv } from "components"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import { Types, useMainContext } from "context"
import { useLoginService } from "network/hooks"
import { useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { LoginPayload } from "types"
import { validateBasicField, validatePassword } from "utils"
import { PLAWrapper } from "./payment-logging-auth.styles"

export const PaymentLoginAuth = () => {
  const [params] = useSearchParams()
  const { dispatch } = useMainContext()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"
  const [form] = Form.useForm()
  const { mutate, isLoading } = useLoginService()

  const handleFormSubmit = async (values: LoginPayload) => mutate(values)

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined })
  }, [dispatch])

  return (
    <PaymentRegisterLayout
      galleryImage={LoginPagePicture}
      backURL={`/payment/auth?type=${plan}&mode=${mode}`}
    >
      <PLAWrapper>
        <FlexibleDiv className="info__description">
          <h2>You're one step closer to Premium</h2>
          <p>Please confirm the details attached to your uduX account</p>
        </FlexibleDiv>

        <Form form={form} onFinish={handleFormSubmit} className="form__box">
          <FlexibleDiv className="textfield__wrap">
            <BorderedTextfield
              type="text"
              paddingLeft="5px"
              name="emailAddress"
              placeholder="What’s your email/username"
              validator={validateBasicField}
            />
          </FlexibleDiv>

          <FlexibleDiv className="textfield__wrap">
            <BorderedTextfield
              type="password"
              name="password"
              placeholder="Password"
              paddingLeft="5px"
              validator={validatePassword}
            />
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              color="var(--black)"
              width="180px"
              height="40px"
              type="submit"
              radius="0px"
              contained
              bold
              ltr
            >
              <span>{isLoading ? <LoadingOutlined /> : "Confirm Details"}</span>
            </Button>
          </FlexibleDiv>
        </Form>

        <FlexibleDiv
          flexDir="column"
          className="btn__box"
          justifyContent="center"
        >
          <Link
            to={`/payment/forgot-password?type=${plan}&mode=${mode}`}
            className="existing__user"
          >
            Forgot your Password
          </Link>

          <Link
            to={`/payment/register?type=${plan}&mode=${mode}`}
            className="existing__user"
          >
            Create an Account
          </Link>
        </FlexibleDiv>
      </PLAWrapper>
    </PaymentRegisterLayout>
  )
}

import { Button, DropDownProps } from "antd"
import React from "react"
import { DropdownWrap } from "./dropdown.styles"

export type DropdownProps = {
  children?: React.ReactNode
  buttonClick?: () => void
} & DropDownProps
export const Dropdown = ({
  trigger,
  overlay,
  buttonClick,
  placement,
  children,
  onVisibleChange,
}: DropdownProps) => {
  return (
    <DropdownWrap
      overlay={overlay}
      trigger={trigger}
      placement={placement}
      onVisibleChange={onVisibleChange}
    >
      <Button onClick={buttonClick}>{children}</Button>
    </DropdownWrap>
  )
}

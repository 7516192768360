import { DominantColors } from "context"
import styled from "styled-components"

export interface APJWrapProps {
  bg?: string
  isMobile?: boolean
  dominantColors?: DominantColors
}
export const APJWrap = styled.div<APJWrapProps>`
  width: 100%;
  height: 395px;
  background: ${({ bg, isMobile }) =>
    bg
      ? `url(${bg})`
      : !bg && isMobile
      ? "rgba(42, 42, 42, 0.75);"
      : "rgba(15, 15, 15, 0.8);"};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: ${({ isMobile }) => (isMobile ? "0 1.5rem" : "")};
  position: relative;

  ::before {
    content: "";
    bottom: 0;
    height: 15px;
    width: 100%;
    background-color: ${({ dominantColors }) =>
      dominantColors?.length ? dominantColors[0] : "var(--pinkDark)"};
    position: absolute;
  }

  .response__banner__wrap {
    position: relative;
    width: 100%;
  }

  .img__bg__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    background: rgba(0, 0, 0, 0.8);
    justify-content: space-between;
  }

  .artist__info__section,
  .cta__section {
    height: 100%;
    align-items: center;
  }

  .artist__info__section {
    height: 250px;
    width: 100%;
  }

  .artist__info {
    flex: 1;
    height: 100%;
    justify-content: space-around;
    align-items: flex-start;
    column-gap: 2%;
    position: relative;
  }

  .artist__bloc {
    width: 60%;
    flex-wrap: nowrap;
  }

  .artist__name__bio {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .verified__artist__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .logo {
      width: 40px;
      margin-right: 0.5rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      margin-bottom: -0.3rem;
      font-size: 12px;
      font-family: "Baguede", sans-serif;
    }
  }

  .cta__section {
    max-width: 70%;
    margin-top: 2rem;
    justify-content: flex-start;
  }

  .artist__avatar__wrap {
    width: 240px;
    height: 240px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name,
  .bio {
    margin-bottom: 0;
    color: var(--white);
    -webkit-text-stroke: var(--w400);
    font-family: "Baguede", sans-serif;
  }

  .name {
    font-size: 2rem;
    text-transform: capitalize;
  }

  .bio {
    -webkit-text-stroke: 0;
    margin-top: 1rem;
    width: 95%;
  }

  .stats__wrap__desktop {
    width: 35%;
    column-gap: 5px;
    align-self: flex-end;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2%;

    &__item {
      width: max-content;
      align-items: flex-start;
    }

    .stats__count,
    .stats__info {
      -webkit-text-stroke: var(--w300);
      font-family: "Baguede", sans-serif;
    }

    .stats__count {
      font-size: 1.58rem;
      color: var(--yellowPrimary);
    }
  }

  .stats__wrap {
    width: 40%;
    column-gap: 5px;
    align-items: center;
    justify-content: space-between;

    &__item {
      width: max-content;
      align-items: flex-start;
    }

    .stats__count,
    .stats__info {
      -webkit-text-stroke: var(--w300);
      font-family: "Baguede", sans-serif;
    }

    .stats__count {
      font-size: 1.58rem;
      color: var(--yellowPrimary);
    }

    .stats__info {
      /* font-size: 1rem; */
    }
  }

  .camera__icon {
    position: absolute;
  }
  .cover__art__dropdown {
    right: 1.5%;
    top: 8%;
  }
  .profile__avatar__dropdown {
    right: 2%;
    top: 2%;
  }

  @media screen and (min-width: 951px) and (max-width: 1220px) {
    height: 395px;

    .img__bg__content {
      padding: 1rem 2rem;
      flex-direction: column;
      justify-content: center;
    }

    .artist__info__section {
      width: 100%;
      height: auto;
      flex-direction: row;
    }

    .artist__bloc {
      width: 100%;
    }

    .stats__wrap__desktop {
      width: 100%;
      margin-top: 1.5rem;
    }

    .cta__section {
      width: 50%;
      align-self: flex-start;
    }

    .artist__avatar__wrap {
      width: 240px;
      height: 240px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .artist__info {
      flex: 1;
      height: 100%;
      justify-content: space-around;
      align-items: flex-start;
    }

    .name,
    .bio {
      margin-bottom: 0;
      color: var(--white);
      -webkit-text-stroke: var(--w400);
      font-family: "Baguede", sans-serif;
    }

    .bio {
      margin-top: 1rem;
      -webkit-text-stroke: 0px;
    }

    .stats__wrap {
      width: 100%;
      max-width: 100%;
      column-gap: 5px;
      align-items: center;
      justify-content: space-between;

      &__item {
        width: max-content;
        align-items: flex-start;
      }

      .stats__count,
      .stats__info {
        -webkit-text-stroke: var(--w300);
        font-family: "Baguede", sans-serif;
      }

      .stats__count {
        font-size: 1rem;
        color: var(--yellowPrimary);
      }

      .stats__info {
        font-size: 0.65rem;
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    height: 320px;
    padding: 0;

    .img__bg__content {
      padding: 1rem 2rem;
      flex-direction: column;
      justify-content: center;
    }

    .artist__info__section {
      width: 100%;
      height: 220px;
      flex-direction: row;
    }

    .artist__avatar__wrap {
      width: 200px;
      height: 210px;
    }

    .artist__bloc {
      width: 100%;
    }

    .cta__section {
      width: 100%;
      height: auto;
      justify-content: flex-start;

      button {
        width: 80%;
        max-width: 180px;
        border-radius: 0;
        padding: 3px 12px;
        -webkit-text-stroke: 0;
      }
    }

    .artist__info {
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
    }

    /* .artist__bloc {
      width: 100%;
    }

    .stats__wrap__desktop {
      width: 100%;
    } */

    .stats__wrap {
      width: 100%;
      max-width: 100%;
      margin: 1rem auto;
      align-items: center;
      justify-content: space-between;

      .stats__count {
        font-size: 1.4rem;
      }

      .stats__info {
        font-size: 0.65rem;
      }
    }
    .name {
      font-size: 2rem;
    }

    .bio {
      margin-top: 1rem;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 600px) {
    height: 320px;
    padding: 0;

    .img__bg__content {
      padding: 1rem;
      flex-direction: column;
      justify-content: center;
    }

    .artist__info__section {
      width: 100%;
      height: 220px;
      flex-direction: row;
    }

    .artist__avatar__wrap {
      width: 160px;
      height: 180px;
    }

    .artist__bloc {
      width: 100%;
      align-items: flex-start;
    }

    .cta__section {
      width: 100%;
      height: auto;
      margin-top: 1.55rem;
      justify-content: flex-start;

      button {
        width: 80%;
        max-width: 180px;
        border-radius: 0;
        padding: 3px 12px;
        -webkit-text-stroke: 0;
      }
    }

    .artist__info {
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }

    .stats__wrap {
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .stats__count {
        font-size: 1.2rem;
      }

      .stats__info {
        font-size: 0.65rem;
      }
    }
    .name {
      width: 120px;
      overflow: hidden;
      font-size: 1.25rem;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .bio {
      margin-top: 1rem;
      font-size: 10px;
    }
  }

  @media screen and (max-width: 399px) {
    height: 280px;

    .artist__info__section {
      height: 160px;
    }

    .img__bg__content {
      padding: 1rem;
    }

    .artist__avatar__wrap {
      width: 35%;
      height: 85%;
    }

    .cta__section {
      margin-top: 1.2rem;

      button {
        width: 70%;
        max-width: 160px;
        padding: 3px 12px;
      }
    }

    .artist__info {
      width: 50%;
      flex-direction: column;
      justify-content: center;
    }

    .stats__wrap {
      margin-top: 0.8rem;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    .name {
      margin-bottom: 0.2 rem;
      width: 180px;
    }
    .bio {
      margin-top: 0.5rem;
    }
  }
`

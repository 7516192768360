import { Modal } from "antd";
import styled from "styled-components";

export interface CPMWrapProp {
  skinBanner?: string;
  playlistCover?: string;
}
export const CPMWrap = styled(Modal)<CPMWrapProp>`
  height: 200px;
  min-width: 700px;
  @media screen and (max-width: 765px) {
    min-width: fit-content;
    margin: 0 40px;
  }
  .ant-modal-content {
    background-color: #212121;
    border-radius: 0;
  }
  .modal__wrap {
    background-color: #212121;
  }
  .modal__header {
    margin-bottom: 20px;
    h2 {
      color: var(--primaryWhite);
      font-weight: 500;
      font-size: 1.8rem;
      font-family: "Inter", sans-serif;
      line-height: 33px;
      @media screen and (max-width: 765px) {
        font-size: 1.4rem;
      }
    }

    .close__icon {
      color: var(--primaryWhite);
      font-weight: 700;
      font-size: 1rem;
      cursor: pointer;
    }
  }
  .content {
    background-color: #212121;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    /* min-width: 500px; */
    align-items: center; /* Align the top edges of both the form and image */

    @media screen and (max-width: 765px) {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .playlist__image__wrap {
      min-width: 220px; /* Set a fixed width for the image */
      min-height: 240px; /* Ensure the image wrap is always taller than the form */
      display: flex;
      border-radius: 10px;
      align-items: center;
      position: relative;
      justify-content: center;
      background-image: ${({ playlistCover }) =>
        playlistCover ? `url(${playlistCover})` : "none"};
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-color: #282828;
      @media screen and (max-width: 765px) {
        margin: 0 auto;
        min-width: 200px; /* Set a fixed width for the image */
        min-height: 210px;
      }

      .file__uploader__input__wrap {
        cursor: pointer;
        border-radius: 10px;
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        transition: all 0.3s;

        svg {
          margin-right: 1rem;
        }

        label {
          display: flex;
          align-items: center;
          transition: all 0.3s;
          border-radius: 10px;
          width: 100%;
          height: 100%;

          svg {
            margin-right: 1rem;
          }
        }

        input[type="file"] {
          color: transparent;
          position: absolute;
          margin: 0;
          padding: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          outline: none;
          opacity: 0;
          cursor: pointer;
        }

        :hover {
          background: rgba(0, 0, 0, 0.6);
          color: var(--yellowPrimary);
          label {
            color: var(--yellowPrimary);
          }
        }
      }
    }

    .form_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 10px;
      height: auto; /* Let the height be determined by the content of the form */
      flex-grow: 0; /* Prevent the form from growing to match the image height */
    }

    .btn__box {
      margin-top: 30px;
      gap: 10px;
      flex-wrap: nowrap;

      .cancel__btn {
        border: none;

        &:hover {
          background-color: var(--uduxYellowPrimary);
          color: var(--black);
        }
      }

      .create__btn {
        font-weight: 600;
      }
    }
  }
`;

import { InActivePlaylist } from "../InActivePlaylist"
import { CollabSongsTabDisplayWrap } from "../styles"

export const CollabSongsTabDisplay = () => {
  return (
    <CollabSongsTabDisplayWrap>
      <InActivePlaylist
        title="Your Collab Playlist isn't Active"
        description="You won't be able to add songs to this playlist till someone says yes!"
      />
    </CollabSongsTabDisplayWrap>
  )
}

import { Draggable, DropResult } from "@hello-pangea/dnd"
import { DragAndDrop, FlexibleDiv } from "components"
import { Types, useMainContext } from "context"
import gsap from "gsap"
import { useEffect, useRef, useState } from "react"
import { getTrackID, shuffleTrack, unShuffleTrack } from "utils"
import { QueueTrackItem } from "."
import { ManageQueueWrap } from "./manage-queue.styles"
import { FSEMWrap } from "../shared/styles"
import { Track } from "types/artist"
import { useFetchPlaylistById } from "network/hooks"

export type ManageQueueProps = {
  isMobile?: boolean
}
export const ManageQueue = ({ isMobile }: ManageQueueProps) => {
  const [currentTab, setCurrentTab] = useState("autoplay")

  const {
    state: { player },
    dispatch,
  } = useMainContext()


  const { data } = useFetchPlaylistById("1f9dccc2-8b12-49d6-a23c-d783ca64391b")

  const trackss = data?.tracks || []

  const { shuffle, playingList, currentTrack } = player
  const p__tracks = playingList?.tracks
  const [deletedTrack] = useState<Track>()
  const [tracks, setTracks] = useState<Track[]>([])
  
  const itemsRef = useRef<HTMLElement[]>([])
  itemsRef.current = []
  const trackId = getTrackID(currentTrack)

  const addToItemsRef = (el: HTMLDivElement) => {
    if (el && !itemsRef.current.includes(el)) {
      itemsRef.current.push(el)
    }
  }

  const reorder = <T,>(
    list: T[],
    startIndex: number,
    endIndex: number
  ): T[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return

    const newList = reorder(
      tracks,
      result.source.index,
      result.destination.index
    )

    //store reordered state.
    setTracks(newList)
    dispatch({
      type: Types.PLAYING_LIST_ACTIVE_TRACKS,
      payload: newList,
    })
  }

  useEffect(() => {
    const filteredTracks = p__tracks?.filter((t: any) => t.track.id !== trackId)
    setTracks(filteredTracks)
  }, [p__tracks, deletedTrack?.id, trackId])

  const removeFromQueue = (pos: number) => {
    const refList = Array.from(itemsRef.current)
    const delRefEl = refList[pos]
    const otherRefItems = refList.slice(pos + 1)

    const updateList = Array.from(tracks)
    updateList.splice(pos, 1)

    gsap.to(delRefEl, {
      duration: 0.5,
      opacity: 0,
      onComplete: tweenFinished,
      onCompleteParams: [otherRefItems, pos],
    })

    function tweenFinished(otherItems: HTMLElement[], rIdx: number) {
      const tl = gsap.timeline({
        onComplete: onAnimationComplete,
        onCompleteParams: [rIdx],
      })

      otherItems.forEach(item => {
        const tween = gsap.to(item, {
          y: "-50",
          clearProps: "y",
        })

        tl.add(tween, 0)
      })
    }

    function onAnimationComplete() {
      dispatch({
        type: Types.PLAYING_LIST_ACTIVE_TRACKS,
        payload: updateList,
      })
    }
  }

  return (
    <FSEMWrap isMobile={isMobile} noSmPad>
        <ManageQueueWrap
          activeTab={shuffle}
          flexDir="column"
          justifyContent="flex-start"
          flexWrap="nowrap"
          isMobile={isMobile}
        >
          <FlexibleDiv className="tabchange__wrap__mobile">
            <FlexibleDiv
              width="max-content"
              flexWrap="nowrap"
              className="headertext__wrap"
            >
              <h4>Manage Queue</h4>
            </FlexibleDiv>
            <FlexibleDiv className="tab tab__mobile">
              <p
                className={currentTab === "autoplay" ? "currenttab" : ""}
                onClick={() => {
                  setCurrentTab("autoplay")
                  unShuffleTrack(
                    tracks,
                    dispatch
                    // Types.PLAYING_LIST_ACTIVE_TRACKS
                  )
                }}
              >
                Autoplay
              </p>
              <p
                className={currentTab === "shuffle" ? "currenttab" : ""}
                onClick={() => {
                  setCurrentTab("shuffle")
                  shuffleTrack(
                    tracks,
                    dispatch
                    // Types.PLAYING_LIST_ACTIVE_TRACKS
                  )
                }}
              >
                Shuffle
              </p>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            className="tabchange__wrap__web"
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            <FlexibleDiv
              width="max-content"
              flexWrap="nowrap"
              className="headertext__wrap"
            >
              <h4>Manage Queue: {trackss?.length} in Queued</h4>
            </FlexibleDiv>
            <FlexibleDiv className="tab tab__web">
              <p
                className={currentTab === "autoplay" ? "currenttab" : ""}
                onClick={() => {
                  setCurrentTab("autoplay")
                  unShuffleTrack(
                    tracks,
                    dispatch
                    // Types.PLAYING_LIST_ACTIVE_TRACKS
                  )
                }}
              >
                Autoplay
              </p>
              <p
                className={currentTab === "shuffle" ? "currenttab" : ""}
                onClick={() => {
                  setCurrentTab("shuffle")
                  shuffleTrack(
                    tracks,
                    dispatch
                    // Types.PLAYING_LIST_ACTIVE_TRACKS
                  )
                }}
              >
                Shuffle
              </p>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            className="managequeue__contentwrap"
            flexWrap="nowrap"
          >
            <DragAndDrop
              withDroppable
              droppableId="manage-queue"
              onDragEnd={onDragEnd}
            >
              {trackss?.length &&
                trackss?.map((item, idx) => (
                  <Draggable draggableId={item.id} key={item.id} index={idx}>
                    {provided => (
                      <div
                        className="draggable--items--wrap"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <QueueTrackItem
                          track={item}
                          position={idx}
                          removeFromQueue={removeFromQueue}
                          ref={addToItemsRef}
                          isMobile={isMobile}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
            </DragAndDrop>
          </FlexibleDiv>
        </ManageQueueWrap>
    </FSEMWrap>
  )
}

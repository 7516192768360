import { CollabPlaylistIcon, SoloPlaylistIcon } from "assets"
import {
  ApiResponseBanner,
  ApiResponseBannerProps,
  Button,
  Dropdown,
  FlexibleDiv,
  StyledMenu,
} from "components"
import { Types, useMainContext } from "context"
import { useState } from "react"
import { CreatePlaylistDialog } from "."
import { FJWrap } from "./styles"

export const FeedJumbotron = () => {
  const {
    state: { users },
    dispatch,
  } = useMainContext()
  const [openDialog, setOpenDialog] = useState(false)
  const [createType, setCreateType] = useState("solo")
  const [responseBanner, setResponseBanner] = useState<
    Omit<ApiResponseBannerProps, "onClose" | "message">
  >({
    open: false,
    type: "IDLE",
    response: "",
  })

  const { me } = users
  const bg = me?.profile?.coverArt?.url

  const createPlaylistMenu = (
    <StyledMenu
      className="upload__menu"
      top="0.3rem"
      items={[
        {
          key: "1",
          label: (
            <FlexibleDiv
              justifyContent="flex-start"
              className="file__uploader__input__wrap"
              onClick={() => {
                setOpenDialog(true)
                setCreateType("collab")
                dispatch({ type: Types.CREATING_PLAYLIST, payload: true })
              }}
            >
              <CollabPlaylistIcon /> Collab Playlist
            </FlexibleDiv>
          ),
        },
        {
          key: "2",
          label: (
            <FlexibleDiv
              justifyContent="flex-start"
              className="file__uploader__input__wrap"
              onClick={() => {
                setOpenDialog(true)
                setCreateType("solo")
                dispatch({ type: Types.CREATING_PLAYLIST, payload: true })
              }}
            >
              <SoloPlaylistIcon /> Solo Playlist
            </FlexibleDiv>
          ),
        },
      ]}
    />
  )

  return (
    <FJWrap
      bg={bg}
      dominantColors={me?.profile?.coverArtDominantColors?.map(cadc =>
        cadc.startsWith("#") ? cadc : `#${cadc}`
      )}
    >
      <ApiResponseBanner
        onClose={() => setResponseBanner({})}
        {...responseBanner}
      />

      <FlexibleDiv className="info-section" flexDir="column">
        <h2 className="title">My Playlist</h2>
        <p className="lead-text">
          Explore your existing playlist or Create a new one
        </p>
      </FlexibleDiv>

      <FlexibleDiv className="cta-section">
        <Dropdown
          trigger={["click"]}
          overlay={createPlaylistMenu}
          placement="bottomRight"
        >
          <Button color={"var(--black)"} contained>
            <span>Create Playlist</span>
          </Button>
        </Dropdown>
      </FlexibleDiv>

      {openDialog && (
        <CreatePlaylistDialog
          open={openDialog}
          type={createType}
          leadText={`Create ${createType} playlist`}
          handleOk={() => {
            setOpenDialog(false)
            dispatch({ type: Types.CREATING_PLAYLIST, payload: false })
          }}
          handleClose={() => {
            setOpenDialog(false)
            dispatch({ type: Types.CREATING_PLAYLIST, payload: false })
          }}
          setResponseBanner={setResponseBanner}
        />
      )}
    </FJWrap>
  )
}

import { DominantColors } from "context"
import styled from "styled-components"

export interface UPJWrapProps {
  bg?: string
  isMobile?: boolean
  dominantColors?: DominantColors
}
export const UPJWrap = styled.div<UPJWrapProps>`
  width: 100%;
  height: 395px;
  background: ${({ bg, isMobile }) =>
    bg
      ? `url(${bg})`
      : !bg && isMobile
      ? "rgba(42, 42, 42, 0.75);"
      : "rgba(15, 15, 15, 0.8);"};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: ${({ isMobile }) => (isMobile ? "0 1.5rem" : "")};
  position: relative;

  ::before {
    content: "";
    bottom: 0;
    height: 15px;
    width: 100%;
    background-color: ${({ dominantColors }) =>
      dominantColors?.length ? dominantColors[0] : "var(--yellowPrimary)"};
    position: absolute;
  }

  .img__bg__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 4rem;
    background: rgba(0, 0, 0, 0.8);
    justify-content: space-between;
  }

  .user__info__section,
  .cta__section {
    height: 100%;
    align-items: center;
  }

  .user__info__section {
    height: 250px;
    width: 70%;
  }
  .cta__section {
    width: 30%;
  }
  .cta__section__desktop {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
  }

  .follow__section {
    position: absolute;

    svg {
      cursor: pointer;
    }
  }

  .fs__mobile {
    top: 0%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .fs__desktop {
    top: 15%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }

  .user__avatar__wrap {
    width: 240px;
    height: 240px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .upload__in__progress {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: not-allowed;
    background-color: var(--overlayDark);
  }

  .upload__in__progress__cover {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: not-allowed;
    background-color: var(--overlayLight);

    div[title] {
      opacity: 0.4;
    }
  }

  .is__edit {
    position: relative;

    ::before {
      content: "";
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      position: absolute;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
    }

    :hover::before {
      width: 100%;
      height: 100%;
      background-color: var(--overlayLight);
    }
  }

  .user__info {
    flex: 1;
    height: 100%;
    margin-left: 4rem;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
  }

  .name,
  .bio {
    margin-bottom: 0;
    color: var(--white);
    -webkit-text-stroke: var(--w400);
    font-family: "Baguede", sans-serif;
  }

  .name {
    font-size: 2rem;
    text-transform: capitalize;
  }

  .bio {
    margin-top: 1rem;
  }

  .stats__wrap {
    width: 100%;
    max-width: 540px;
    column-gap: 5px;
    align-items: center;
    justify-content: space-between;

    &__item {
      width: max-content;
      align-items: flex-start;
    }

    .stats__count,
    .stats__info {
      -webkit-text-stroke: var(--w300);
      font-family: "Baguede", sans-serif;
    }

    .stats__count {
      font-size: 1.58rem;
      color: var(--yellowPrimary);
    }

    .stats__info {
      /* font-size: 1rem; */
    }
  }

  .camera__icon {
    position: absolute;
  }
  .cover__art__dropdown {
    right: 1.5%;
    top: 8%;
  }
  .profile__avatar__dropdown {
    right: 2%;
    top: 2%;
  }

  @media screen and (min-width: 951px) and (max-width: 1201px) {
    height: 395px;

    .img__bg__content {
      padding: 1rem 4rem;
      flex-direction: column;
      justify-content: center;
    }

    .user__info__section {
      width: 100%;
      height: 250px;
      flex-direction: row;
    }

    .cta__section {
      width: 50%;
      align-self: center;
    }

    .user__avatar__wrap {
      width: 240px;
      height: 240px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user__info {
      flex: 1;
      height: 100%;
      margin-left: 4rem;
      justify-content: space-around;
      align-items: flex-start;
    }

    .name,
    .bio {
      margin-bottom: 0;
      color: var(--white);
      -webkit-text-stroke: var(--w400);
      font-family: "Baguede", sans-serif;
    }

    .bio {
      margin-top: 1rem;
    }

    .stats__wrap {
      width: 100%;
      max-width: 100%;
      column-gap: 5px;
      align-items: center;
      justify-content: space-between;

      &__item {
        width: max-content;
        align-items: flex-start;
      }

      .stats__count,
      .stats__info {
        -webkit-text-stroke: var(--w300);
        font-family: "Baguede", sans-serif;
      }

      .stats__count {
        font-size: 1rem;
        color: var(--yellowPrimary);
      }

      .stats__info {
        font-size: 0.65rem;
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    height: 320px;
    padding: 0;

    .img__bg__content {
      padding: 1rem 4rem;
      flex-direction: column;
      justify-content: center;
    }

    .user__info__section {
      width: 100%;
      height: 220px;
      flex-direction: row;
    }

    .user__avatar__wrap {
      width: 200px;
      height: 210px;
    }

    .cta__section {
      width: 100%;
      height: auto;
      margin-top: 4rem;
      justify-content: flex-start;

      button {
        width: 80%;
        max-width: 180px;
        border-radius: 0;
        padding: 3px 12px;
        -webkit-text-stroke: 0;
      }
    }

    .user__info {
      width: 100%;
      margin-left: 1rem;
      flex-direction: column;
      justify-content: center;
    }

    .stats__wrap {
      width: 100%;
      max-width: 100%;
      margin: 1rem auto;
      align-items: center;
      justify-content: space-between;

      .stats__count {
        font-size: 1.4rem;
      }

      .stats__info {
        font-size: 0.65rem;
      }
    }
    .name {
      font-size: 2rem;
    }

    .bio {
      margin-top: 1rem;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 600px) {
    height: 320px;
    padding: 0;

    .img__bg__content {
      padding: 1rem 2rem;
      flex-direction: column;
      justify-content: center;
    }

    .user__info__section {
      width: 100%;
      height: 220px;
      flex-direction: row;
    }

    .user__avatar__wrap {
      width: 160px;
      height: 180px;
    }

    .cta__section {
      width: 100%;
      height: auto;
      margin-top: 3rem;
      justify-content: flex-start;

      button {
        width: 80%;
        max-width: 180px;
        border-radius: 0;
        padding: 3px 12px;
        -webkit-text-stroke: 0;
      }
    }

    .user__info {
      width: 100%;
      margin-left: 1rem;
      flex-direction: column;
      justify-content: center;
    }

    .stats__wrap {
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .stats__count {
        font-size: 1.2rem;
      }

      .stats__info {
        font-size: 0.65rem;
      }
    }
    .name {
      width: 120px;
      overflow: hidden;
      font-size: 1.25rem;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .bio {
      margin-top: 0.8rem;
      font-size: 10px;
    }
  }

  @media screen and (max-width: 399px) {
    height: 280px;

    .user__info__section {
      height: 160px;
    }

    .img__bg__content {
      padding: 1rem;
    }

    .user__avatar__wrap {
      width: 35%;
      height: 85%;
    }

    .cta__section {
      margin-top: 1.2rem;

      button {
        width: 70%;
        max-width: 160px;
        padding: 3px 12px;
      }
    }

    .user__info {
      width: 50%;
      margin-left: 1rem;
      flex-direction: column;
      justify-content: center;
    }

    .stats__wrap {
      margin-top: 0.8rem;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    .name {
      margin-bottom: 0.2 rem;
      width: 180px;
    }
    .bio {
      margin-top: 0;
    }
  }
`

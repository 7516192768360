import { FlexibleDiv } from "components"
import styled from "styled-components"
import { CMContainerProps } from "../CheckMail/checkMail.styles"

export const Container = styled(FlexibleDiv)<CMContainerProps>`
  width: 100%;
  padding-bottom: 5%;

  .form_box {
    width: 100%;
    /* margin: 9% 0 0; */
  }

  .textfield__wrap {
    padding-bottom: 2rem;
  }

  .action_text,
  .action_text_one {
    color: var(--uduWhiteSecondary);
    font-size: ${({ checkHeight }) => {
      return checkHeight ? "20px" : "15px"
    }};
    font-weight: 600;
    /* margin-top: 5%; */

    :hover {
      color: var(--uduxYellowPrimary);
      transition: all 0.4s;
    }
  }

  .action_text_one {
    /* margin-top: 5%; */
  }

  .btn__box {
    margin-top: 2rem;
  }

  .cta__btn__box {
    margin-top: 0.5rem;
  }

  .existing__user {
    margin-top: 0.5rem;
    color: var(--white);
    opacity: 0.7;
    text-decoration: underline;
    font-size: 12px;
  }

  @media screen and (max-width: 600px) {
    .form_box {
    }

    .action_text_box {
      display: none;
    }
  }
`

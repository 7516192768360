import { useMediaQuery } from "react-responsive";
import { FsPWrap } from "./full-screen-player.styles";
import { FlexibleDiv, TrackProgressControl, VolumeControl } from "components";
import {
  ShrinkOutlined,
} from "@ant-design/icons";
import {
  AddSongIcon,
  AddToQueueIcon,
  FavoriteFilledIcon,
  FsePausePlayIcon,
  FsePlayingIcon,
  LikeIcon,
  NextOutlineIcon,
  PrevOutlineIcon,
  RepeatOutlineIcon,
  ShuffleOutlineIcon,
} from "assets";
import { Types, useMainContext } from "context";
import {
  getDisplayArtist,
  getTrackID,
  getTrackTitle,
  isEmpty,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
  repeatTrack,
} from "utils";
import {  useState } from "react";
import { useMutation } from "react-query";
import { addReaction, removeReaction } from "network";
import { useNavigate, useParams } from "react-router-dom";
import { ReactionType } from "types";
import { useFetchPlaylistById } from "network/hooks";

export const FullScreenPlayer = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { playlistId } = useParams() as { playlistId: string };
  const navigate = useNavigate();
  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext();
  const [,setLiked] = useState(false);
  const [,setOpenAddToPlaylistModal] = useState(false);
  const { mutate: mutateLike } = useMutation(addReaction);
  const { mutate: mutateUnlike } = useMutation(removeReaction);

  const { isLoggedIn } = app;
  const {
    isPlaying,
    repeat,
    shuffle,
    playingList,
    currentTrack,
    queuedTracks,
    prevTracks,
  } = player;
  const { data } = useFetchPlaylistById(playlistId);
  const trackId = getTrackID(currentTrack);
  const domColors = [`${data?.coverArtDominantColors[0]}`, `#000`];


  const exitFullScreenMode = () => {
    const statePayload = {
        prevTracks: prevTracks,
        playingList: playingList,
        queuedTracks: queuedTracks,
      };
      dispatch({ type: Types.SET_FSEM, payload: true });
      navigate(`/playlist/${trackId}`, {
        state: statePayload,
      })    
    // Exit fullscreen
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  return (
    <FsPWrap dominantColors={domColors} bgCover={getImageDisplay() || ""}>
      <FlexibleDiv alignItems="center" className="fsem__wrap">
        {currentTrack && (
          <FlexibleDiv className="track__image__wrap">
            <div className="name__and__cover">
              <div className="cover__art">
                <img
                  src={getImageDisplay()}
                  alt={`${getTrackTitle(currentTrack, true)} cover`}
                />
              </div>
              <div className="title__and__album__artist">
                {currentTrack && (
                  <p className="title">{getTrackTitle(currentTrack, true)}</p>
                )}

                <p className="album">Album</p>
                <p className="artist">{getDisplayArtist(currentTrack)}</p>
              </div>
            </div>
          </FlexibleDiv>
        )}

        <FlexibleDiv
          alignItems="center"
          className="track__controls__wrap"
          flexDir="column"
        >
            <FlexibleDiv justifyContent="center" className="controls__main">
              <div
                className="control__icons repeat__icon"
                onClick={() => repeatTrack(Types.REPEAT, dispatch, repeat)}
              >
                <RepeatOutlineIcon
                  width={25}
                  height={25}
                  color={repeat ? "var(--uduxYellowPrimary)" : "#D9D9D9"}
                />
              </div>

              <div
                className="control__icons"
                onClick={() => {
                  if (currentTrack) {
                    playPreviousTrack(dispatch, {
                      track: currentTrack,
                    });
                  }
                }}
              >
                <PrevOutlineIcon width={25} height={25} />
              </div>

              {isPlaying ? (
                <div
                  className="control__icons pause__icon"
                  onClick={() => pauseAudio(playerRef, dispatch)}
                >
                  <FsePlayingIcon width={25} height={25} />
                </div>
              ) : (
                <div
                  className="control__icons play__icon"
                  onClick={() => playAudio(playerRef, dispatch)}
                >
                  <FsePausePlayIcon width={25} color="#fff" height={25} />
                </div>
              )}

              <div
                className="control__icons"
                onClick={() => {
                  playNextTrack(dispatch, {
                    track: currentTrack,
                  });
                }}
              >
                <NextOutlineIcon width={25} height={25} />
              </div>
              {shuffle ? (
                <div className="control__icons shuffle__icon">
                  <ShuffleOutlineIcon
                    width={25}
                    height={25}
                    color="var(--yellowPrimary)"
                  />
                </div>
              ) : (
                <div className="control__icons shuffle__icon">
                  <ShuffleOutlineIcon width={25} height={25} />
                </div>
              )}
            </FlexibleDiv>

          <FlexibleDiv className="song__progress__wrap" flexDir="column">
            {!isMobile && <TrackProgressControl displayProgressTime />}
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv className="visual__controls">
          <div className="v__icon__wrap">
            {currentTrack?.userLiked ? (
              <div onClick={handleUnlikeTrack}>
                <FavoriteFilledIcon width={25} height={25} />
              </div>
            ) : (
              <div onClick={handleLikeTrack}>
                <LikeIcon width={25} height={25} />
              </div>
            )}
          </div>
          <div
            className="v__icon__wrap"
            onClick={exitFullScreenMode}
          >
            <ShrinkOutlined className="minimise" />
          </div>
          <div
            className="v__icon__wrap"
            onClick={() => {
              if (isLoggedIn) {
                setOpenAddToPlaylistModal(true);
              } else {
                dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
              }
            }}
          >
            <AddSongIcon width={25} height={25} />
          </div>

          <div className="v__icon__wrap">
            <AddToQueueIcon width={25} height={25} />
          </div>
          <div>
            <VolumeControl />
          </div>
        </FlexibleDiv>
      </FlexibleDiv>
    </FsPWrap>
  );

  function getImageDisplay() {
    if (!isEmpty(currentTrack)) {
      if (currentTrack?.track?.metadata) {
        return currentTrack.track.metadata.coverArt.url;
      }

      if (currentTrack?.metadata) {
        return currentTrack.metadata.coverArt.url;
      }
    }
  }

  function handleUnlikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(false);
    const payload = {
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateUnlike(payload, {
      onSuccess: () => {},
      onError: (err) => {
        setLiked(false);
        console.error(err, "error un-liking from mini player");
      },
    });
  }

  function handleLikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(true);
    const payload = {
      type: ReactionType.LIKE,
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateLike(payload, {
      onSuccess: (res) => {
        console.log(res, "liked from mini player");
      },
      onError: (err) => {
        setLiked(false);
        console.error(err, "error liking from mini player");
      },
    });
  }
};

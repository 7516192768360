import { Loader, Section } from "components"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { User, useMainContext } from "context"
import {
  fetchArtistsIFollow,
  getPlaylistsByQuery,
  getRecentlyPlayedTracks,
  getTopStreamedTracks,
  getUserProfile,
} from "network"
import React from "react"
import { useQueries, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { MetricsLayout, UserProfileJumbotron } from "../components"
import { MeWrap } from "./me.styles"

export const Me = () => {
  const [userProfile, setUserProfile] = React.useState<User>()
  const {
    state: { users },
  } = useMainContext()
  const navigate = useNavigate()
  const { me } = users

  const { data, isLoading } = useQuery(["user-profile"], () => getUserProfile())

  const streamInfo = useQueries([
    {
      queryKey: ["top-streamed-tracks"],
      queryFn: () => getTopStreamedTracks(),
    },
    {
      queryKey: ["recently-played"],
      queryFn: () => getRecentlyPlayedTracks(),
    },
  ])
  const isFetching = streamInfo.some(o => o.isLoading)
  const topTracks = streamInfo[0].data
  const recentlyPlayed = streamInfo[1].data

  const playlistInfo = useQueries([
    {
      queryKey: ["fetch-artists-I-follow"],
      queryFn: () => fetchArtistsIFollow(),
    },
    {
      enabled: !!me?.id,
      queryKey: ["fetch-user-playlist"],
      queryFn: () => getPlaylistsByQuery(`type=SOLO&user=${me?.id}`),
    },
    {
      enabled: !!me?.id,
      queryKey: ["fetch-user-collab-playlist"],
      queryFn: () => getPlaylistsByQuery(`type=COLLABORATIVE&user=${me?.id}`),
    },
  ])

  const isFetchingUsrPl = playlistInfo.some(o => o.isLoading)
  const artistIFollow = playlistInfo[0].data
  const usrPlaylistData = playlistInfo[1].data
  const usrCollabPlaylistData = playlistInfo[2].data

  React.useEffect(() => {
    if (data && data.data) {
      const { data: userProfile } = data.data
      setUserProfile({
        ...users.me,
        ...userProfile,
      })
    }
  }, [data, users.me])

  const bg = userProfile?.profile?.coverArt?.url
  const statistics = userProfile?.statistics
  const coverArtDominantColors = userProfile?.profile?.coverArtDominantColors

  return (
    <PlayerLayout>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <MeWrap>
          <Section
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <UserProfileJumbotron
              handleClick={() => navigate("/settings#my-details")}
              dominantColors={coverArtDominantColors}
              statistics={statistics}
              user={userProfile}
              bg={bg}
              isOwner
            />

            <MetricsLayout
              isOwner
              user={userProfile}
              ddcLoading={isFetching}
              usrPlLoading={isFetchingUsrPl}
              topTracks={topTracks?.data || []}
              soloPlaylist={usrPlaylistData || []}
              following={artistIFollow?.data || []}
              recentlyPlayed={recentlyPlayed?.data || []}
              collabPlaylist={usrCollabPlaylistData || []}
            />
          </Section>
        </MeWrap>
      )}
    </PlayerLayout>
  )
}

import { MtnIcon } from "assets/images/Auth"
import { PaystackSVG, StripeSVG } from "assets/images/payment/SVGExports"

import { Button, FlexibleDiv, SubscriptionPlanBanner } from "components"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { ActionBox } from "components/lib/ActionBox"
import { Types, useMainContext } from "context"
import { initiatePayment } from "network"
import { useCallback, useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import BackgroundImage from "assets/images/payment/confirm_payment_option.png"
import { RegisterContent } from "./payment-confirmation.styles"
import { PaymentGatewayOptions, PaymentMethod } from "types"

export const PaymentConfirmation = () => {
  const {
    state: { users, app },
    dispatch,
  } = useMainContext()
  const [link, setLink] = useState<string>()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const plan = params.get("type") || ("weekly" as string)
  const mode = params.get("mode") || ("udux" as string)
  const lStore = localStorage.getItem("udx_pay") ?? ""
  const [activeSlide] = useState(0)

  const accentColor = useMemo(() => {
    if (plan === "daily") {
      return "var(--primaryYellow)"
    } else if (plan === "weekly") {
      return "#FDD774"
    } else return "#FED44B"
  }, [plan])

  const provider = JSON.parse(lStore.replace(/\\/g, "")) as PaymentMethod
  const preferredMethod = provider.method as PaymentGatewayOptions

  const { me } = users
  const { isLoggedIn, billingPlans } = app

  const generateLink = useCallback(async () => {
    if (mode === "mtn") {
      return
    }

    if (link) return

    if (billingPlans.length) {
      const currentPlan = billingPlans?.find(
        singlePlan =>
          singlePlan?.interval?.toLowerCase() === plan.toLowerCase() &&
          singlePlan?.provider?.toLowerCase() === mode.toLowerCase()
      )

      const data = {
        callbackUrl: `${window.location.origin}/payment/complete${window.location.search}`,
        emailAddress: me.emailAddress as string,
        billingPlanId: currentPlan!.id,
      }

      // FIXME: api does not work for stripe payment initiation - throws a 500 error
      try {
        const response = await initiatePayment(preferredMethod, data)
        setLink(response?.data?.authorizationUrl)
      } catch (error) {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      }
    }
  }, [
    link,
    billingPlans,
    mode,
    plan,
    preferredMethod,
    me?.emailAddress,
    dispatch,
  ])

  useEffect(() => {
    if (me?.id && isLoggedIn) {
      welcomeUserToast()
    }

    generateLink()
  }, [me, link, isLoggedIn, generateLink])

  const welcomeUserToast = () => {
    toast.success("You are logged in", {
      duration: 3000,
      position: "top-right",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#0a0",
        secondary: "#fff",
      },
      id: "loggedIn1",
      // styling
      style: {
        border: "1px solid #713200",
        padding: "5px 10px",
        color: "#713200",
        // minWidth: '300px'
      },
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    })
  }

  const galleryImages = [
    {
      pageHoverImage: BackgroundImage,
    },
    {
      pageHoverImage: BackgroundImage,
    },
  ]

  const cardsObjects = {
    mtn: {
      boxIcon: <MtnIcon fill={"white"} />,
      text: "MTN",
    },
    paystack: {
      boxIcon: <PaystackSVG fill={"white"} />,
      text: "Paystack",
    },
    stripe: {
      boxIcon: <StripeSVG fill={"white"} />,
      text: "Stripe",
    },
  }

  const logoutUser = () => {
    localStorage.removeItem("udx__user")
    localStorage.removeItem("udx__tokens")
    navigate(`/payment/auth?type=${plan}&mode=${mode}`)
  }

  return (
    <>
      {provider?.method && (
        <ChoosePlatform
          galleryImages={galleryImages}
          activeSlide={activeSlide}
          accentColor={accentColor}
          rightImage={BackgroundImage}
          backURL={`/payment/plan?type=${plan}&mode=${mode}`}
          hideSubscriptionBox
        >
          <RegisterContent
            width="100%"
            height="100%"
            flexDir="column"
            justifyContent="space-between"
          >
            <FlexibleDiv className="greeting__wrap">
              <h3 className="greeting">
                Hi {me?.profile?.firstName || me?.username}
              </h3>
              <p>
                A little birdie told us you’re interested in a premium account.
                Heres what we know so far... You chose
              </p>
            </FlexibleDiv>

            <SubscriptionPlanBanner />

            <p className="subscribe__via">
              And you're choosing to subscribe via
            </p>

            <FlexibleDiv
              height="fit-content"
              width="80%"
              className="payment__method__wrapper"
            >
              <ActionBox
                boxIcon={cardsObjects[preferredMethod].boxIcon}
                text={cardsObjects[preferredMethod].text}
              />
            </FlexibleDiv>

            <Link
              className="change__method__text"
              to={`/payment/plan?type=${plan}&mode=${mode}`}
            >
              Change Payment Method
            </Link>

            <Button
              className="subscribe__btn"
              onClick={() => {
                if (provider.method === "stripe") {
                  return dispatch({
                    type: Types.API_RESPONSE_ERROR,
                    payload:
                      "Sorry we could not communicate with this provider. Please use another payment option.",
                  })
                } else if (mode === "mtn") {
                  return navigate(`/payment/mtn?type=${plan}&mode=${mode}`)
                } else if (link) {
                  return (window.location.href = `${link}`)
                } else {
                  return navigate(`/payment/auth?type=${plan}&mode=${mode}`)
                }
              }}
            >
              <span>Confirm Payment</span>
            </Button>

            <p className="logout__class">
              Not this account? <span onClick={logoutUser}>Logout</span>
            </p>
          </RegisterContent>
        </ChoosePlatform>
      )}
    </>
  )
}

import { SearchIcon } from "assets/images/player/SVGExports";
import { FlexibleDiv } from "components";
import { useMainContext } from "context/mainContext";
import React from "react";
import { CiCircleRemove as ClearIcon } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import { GeneralSearchBarWrap } from "./general-search-bar.styles";
import { SearchIcon2 } from "assets/icons/SearchIcon2";

export const GeneralSearchBar = () => {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const { setSearchQuery, searchQuery, clearSearchQuery } = useMainContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(target.value);
  };

  React.useEffect(() => {
    setIsSearchOpen(pathname.includes("/search"));
  }, [pathname]);

  return (
    <GeneralSearchBarWrap
      justifyContent="flex-start"
      className="search__wrap"
      alignItems="flex-start"
      active={isSearchOpen}
    >
      {isSearchOpen ? (
        <FlexibleDiv
          className="searchinput__wrap"
        >
          <div className="searchinput__icon__wrap">
            <SearchIcon
              color={isSearchOpen ? "var(--uduxYellowPrimary)" : "#D9D9D9"}
            />
          </div>

          {isSearchOpen && (
            <input
              name="searchQuery"
              value={searchQuery}
              onChange={handleChange}
              placeholder="Search songs, artists, collabs"
              className="search__input"
              autoComplete="off"
            />
          )}

          {searchQuery && (
            <div className="clear__search" onClick={clearSearchQuery}>
              <ClearIcon color="white" />
            </div>
          )}
        </FlexibleDiv>
      ) : (
        <div
          className="searchicon__wrap"
          onClick={() => {
            pathname !== "/search" && navigate("/search");
          }}
        >
          <SearchIcon2 width={"24px"} height={"24px"} />
        </div>
      )}
    </GeneralSearchBarWrap>
  );
};

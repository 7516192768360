import styled from "styled-components"
import ArtBoard from "assets/images/general/udux_bng.png"

export interface PaymentRegisterLayoutWrapperProps {
  checkHeight?: boolean
  smallHeight: boolean
  galleryImage?: string
  bng?: string
  accentColor?: string
}

export const PaymentRegisterLayoutWrapper = styled.div<PaymentRegisterLayoutWrapperProps>`
  width: 100%;
  height: 100vh;
  background: ${({ bng }) =>
    bng
      ? `url(${bng}) repeat center / contain`
      : `url(${ArtBoard}) repeat center / contain`};
  overflow: hidden;
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .content__box {
    z-index: 1;
    width: 94%;
    height: 90%;

    .gallery__pane,
    .activity__pane {
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .gallery__pane {
      background-color: ${({ accentColor }) =>
        accentColor || `var(--primaryYellow)`};

      .image__holder {
        background: ${({ galleryImage }) =>
          `url(${galleryImage}) no-repeat center / cover`};
        width: 100%;
        height: 100%;
      }
    }

    .activity__pane {
      padding: 7% 2rem 0;
      background-color: var(--primaryBlack);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      .logo__wrap {
        position: absolute;
        right: 5%;
        top: 5%;
        width: 65px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .go__back__box {
        left: 5%;
        top: 5%;
        outline: 0;
        border: none;
        display: flex;
        cursor: pointer;
        padding: 0px 4px;
        position: absolute;
        width: max-content;
        align-items: center;
        justify-content: space-around;
        background-color: transparent;

        .go__back {
          cursor: pointer;
          width: max-content;

          span {
            opacity: 0.6;
            font-size: 1.35rem;
          }
        }
      }
    }

    .content__box__layout {
      width: 95%;
      height: 100%;
    }

    .content__layout {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .title {
        color: var(--primaryYellow);
        font-size: 1.5rem;
        text-align: center;
        font-family: var(--mBold);
      }

      .sub__text {
        font-size: 0.85rem;
        font-family: var(--mBold);
        color: var(--gray-2);
        text-align: center;
        opacity: 0.6;
      }

      .auth__response__error__main {
        margin: 2rem auto 1.3rem;
        width: 100%;
      }

      .form__wrap {
        margin-top: 0rem;
        overflow: auto;
        height: 100%;
        width: 75%;

        ::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    .content__box {
      .activity__pane {
        width: 90%;
        padding: 15% 2rem 0;

        .go__back__box {
          color: var(--primaryWhite);
        }
      }

      .content__box__layout {
        width: 90%;
        height: auto;
      }

      .content__layout {
        .auth__response__error__main {
          margin: 1rem auto 1rem;
        }

        .form__wrap {
          margin-top: 2rem;
          width: 80%;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .content__box {
      .activity__pane {
        width: 90%;
        padding: 10% 1rem 0;

        .logo__wrap {
          top: 2%;
          width: 45px;
        }

        .go__back__box {
          left: 2%;
          top: 1%;
          color: white;

          .go__back {
            span {
              font-size: 1rem;
            }
          }
        }
      }

      .content__box__layout {
        width: 100%;
        height: auto;
      }

      .content__layout {
        padding-top: 10%;

        .title {
          font-size: 1.2rem;
        }

        .sub__text {
          font-size: 0.65rem;
        }

        .auth__response__error__main {
          margin: 1rem auto;
        }

        .form__wrap {
          width: 85%;
        }
      }
    }
  }
`

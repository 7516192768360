import styled from "styled-components"
import { FlexibleDivProps } from "../Box"

export const CarouselWrap = styled("div")<FlexibleDivProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;

    .slider__slide {
      /* width: 70%; */
      //  border: 2px solid green;

      img {
        object-fit: cover;
      }
    }
    // border: 2px solid red;

    .gallery_pane_img {
      width: 100%;
      height: 250px;
      background-size: 100% 100%;
    }
  }
`

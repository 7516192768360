import { FlexibleDivProps } from "../Box";
import { TagsWrap } from "./tags.styles";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

export type TagsProps = {
  tags: { title: string; href?: string }[]; // Made href optional
  activeTag?: string;
  setActiveTag: (args: string) => void;
} & FlexibleDivProps;

export const Tags = ({ tags, activeTag, setActiveTag, ...props }: TagsProps) => {
  const navigate = useNavigate(); // Get the navigate function

  return (
    <TagsWrap {...props}>
      {tags.map((tag, index) => {
        return (
          <span
            onClick={() => {
              setActiveTag(tag.title);
              if (tag.href) {
                navigate(tag.href); // Navigate only if href is provided
              }
            }}
            className={`tag ${activeTag === tag.title ? "active__tag" : ""}`}
            key={index}
          >
            {tag.title}
          </span>
        );
      })}
    </TagsWrap>
  );
};

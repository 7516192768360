import UduXGrayLogo from "assets/images/Auth/udux_logo_gray.svg"
import BackgroundImage from "assets/images/general/udux_bng.png"
import { FlexibleDiv, SubscriptionPlanBanner } from "components/lib"
import { useMainContext } from "context"
import { AuthResponseError } from "pages/Auth/AuthResponseError"
import React from "react"
import { IoIosArrowRoundBack } from "react-icons/io"
import { useMediaQuery } from "react-responsive"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import SwiperCore from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import { EffectFade } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { topSlideInFrom } from "utils/data-helpers/animations"
import { GalleryImagesProps } from "../AuthLayout/AuthLayout"
import { ChoosePlatformWrapper } from "./choose-platform.styles"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

export type ChoosePlatformProps = {
  children?: React.ReactNode
  galleryImages?: GalleryImagesProps[]
  accentColor?: string
  rightImage?: string
  activeSlide?: number
  hideSubscriptionBox?: boolean
  backURL?: string
}

export const ChoosePlatform = ({
  children,
  galleryImages,
  activeSlide = 0,
  hideSubscriptionBox = false,
  backURL,
  accentColor,
}: ChoosePlatformProps) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const apiResponseErrorRef = React.useRef(null)
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const {
    state: { app },
  } = useMainContext()
  const { apiResponseError } = app
  const showBackButton = pathname !== "/login" && pathname !== "/oauth"
  const [searchParams] = useSearchParams()

  const mode = searchParams.get("mode")
  const type = searchParams.get("type")
  const [swiper, setSwiper] = React.useState<SwiperCore | null>(null)
  SwiperCore.use([EffectFade])

  React.useEffect(() => {
    topSlideInFrom(apiResponseErrorRef, { y: 30 })
  }, [apiResponseError])

  //if the mode or type does not exist
  React.useEffect(() => {
    const reference = searchParams.get("reference")
    const trxRef = searchParams.get("trxref")

    if (reference || trxRef) return

    if (!mode || !type) {
      navigate("/payment")
    }
  }, [mode, type, navigate, searchParams])

  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500)
    }
  }, [swiper, activeSlide])

  const handleGoBack = () => {
    if (backURL) {
      return navigate(backURL)
    }

    return navigate(-1)
  }

  return (
    <ChoosePlatformWrapper
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      bng={BackgroundImage}
      accentColor={accentColor}
    >
      <FlexibleDiv className="content__box">
        {!isMobile && (
          <div className="gallery__pane" id="gallery__pane">
            <Swiper
              className="swiper"
              effect="fade"
              slidesPerView={1}
              initialSlide={activeSlide}
              onSwiper={setSwiper}
              allowTouchMove={false}
            >
              {galleryImages &&
                galleryImages?.map((image, index) => (
                  <SwiperSlide className="slider__slide " key={index}>
                    <div
                      className="gallery_pane_img"
                      style={{
                        backgroundImage: `url(${image?.pageHoverImage})`,
                      }}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}

        <div className="activity__pane">
          {showBackButton && (
            <button className="go__back__box" onClick={handleGoBack}>
              <IoIosArrowRoundBack color="var(--primaryWhite)" size={30} />{" "}
              <span>Back</span>
            </button>
          )}

          <div className="logo__wrap">
            <img src={UduXGrayLogo} alt="uduX" />
          </div>

          <div className="content__box__layout">
            <div className="content__layout">
              {!hideSubscriptionBox && <SubscriptionPlanBanner />}
              <div className="auth__response__error__main">
                {apiResponseError && (
                  <AuthResponseError
                    ref={apiResponseErrorRef}
                    error={apiResponseError}
                  />
                )}
              </div>

              <div className="form__wrap">{children}</div>
            </div>
          </div>
        </div>
      </FlexibleDiv>
    </ChoosePlatformWrapper>
  )
}

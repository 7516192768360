import { FlexibleDiv, FlexibleDivProps } from "components";
import { DominantColors } from "context";
import styled from "styled-components";

interface MiniPlayerWrapProps extends FlexibleDivProps {
  dominantColors?: DominantColors;
  isActive?: boolean;
  footerOnly?: boolean;
  playerMode?: boolean;
}
export const MiniPlayerWrap = styled(FlexibleDiv)<MiniPlayerWrapProps>`
  width: 100%;
  min-height: 90px;
  padding: 2px 0;
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, #${dominantColors[0]} 0%, #000 100%)`
      : "#0b0b0a"};
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;

  .fsem__wrap {
    width: 100%;
    min-height: 90px;
    padding: 2px 40px;
    flex-wrap: nowrap;

    .track__image__wrap {
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;

      .name__and__cover {
        display: flex;
        align-items: center;
        column-gap: 5px;
        min-height: 65px;

        .cover__art {
          width: 60px;
          min-height: 100%;
          border-radius: 4px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        .title__and__album__artist {
          display: flex;
          padding: 5px;
          flex-direction: column;
          justify-content: flex-start;
          min-height: 40px;
          align-items: flex-start;
          gap: 5px;
          row-gap: 5px;

          .title {
            font-size: 0.7rem;
            opacity: 0.7;
            font-family: "Bw Modelica", sans-serif;
            letter-spacing: 4%;
          }

          .album {
            font-size: 0.8rem;
            font-weight: 700;
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
          }

          .artist {
            font-size: 0.6;
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
            opacity: 0.6;
          }
        }
      }

      .caret {
        :hover {
          color: var(--yellowPrimary);
        }

        &.activePlayerMode {
          color: var(--yellowPrimary);
        }
        cursor: pointer;
        margin-left: 30px;
        font-size: 40px;
      }
    }

    .track__controls__wrap {
      gap: 5px;
      flex: 1;

      .controls__main {
        column-gap: 20px;
        justify-content: center;
        .control__icons {
          cursor: pointer;
        }

        .repeat__icon,
        .shuffle__icon {
          display: flex;
        }
      }

      .song__progress__bar {
        position: relative;
        height: 6px;

        .track__inner {
          height: 100%;
          position: absolute;
          background-color: var(--yellowPrimary);
        }
      }
    }

    .visual__controls {
      align-items: center;
      justify-content: flex-end;
      margin-right: 40px;
      flex: 1;
      flex-wrap: nowrap;
      column-gap: 20px;

      .v__icon__wrap {
        cursor: pointer;
      }
    }
  }
`;

interface MobileMiniPlayerWrapProps extends FlexibleDivProps {
  dominantColors?: DominantColors;
  isActive?: boolean;
  footerOnly?: boolean;
  playerMode?: boolean;
}
export const MobileMiniPlayerWrap = styled(
  FlexibleDiv
)<MobileMiniPlayerWrapProps>`
  width: 100%;
  min-height: fit-content;
  padding: 15px 5px;
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, #${dominantColors[0]} 0%, #000 100%)`
      : "#0b0b0a"};
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;

  .fsem__wrap {
    width: 100%;
    min-height: fit-content;
    padding: 0px 10px;
    flex-wrap: nowrap;

    .track__image__wrap {
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;

      .name__and__cover {
        display: flex;
        align-items: center;
        column-gap: 5px;
        min-height: 60px;

        .cover__art {
          width: 50px;
          min-height: 100%;
          border-radius: 4px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        .title__and__album__artist {
          display: flex;
          padding: 5px;
          flex-direction: column;
          justify-content: flex-start;
          min-height: 100%;
          align-items: flex-start;
          gap: 5px;
          row-gap: 2px;

          .title {
            font-size: 0.6rem;
            opacity: 0.7;
            font-family: "Bw Modelica", sans-serif;
            letter-spacing: 4%;
          }

          .album {
            font-size: 0.4;
            font-weight: 400;
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
          }

          .artist {
            font-size: 0.6rem;
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
            opacity: 0.6;
          }
        }
      }
    }

    .track__controls__wrap {
      gap: 5px;
      flex: 1;

      .controls__main {
        column-gap: 10px;
        justify-content: flex-end;
        .control__icons {
          cursor: pointer;
        }
      }
    }
  }

  .mobile__song__progress__wrap {
    width: 100%;
    padding: 0 10px;
    display: flex;
  }
`;

interface NowPlayingMobileMiniPlayerWrapProps extends FlexibleDivProps {
  isMobile?: boolean;
  dominantColors?: DominantColors;
  isActive?: boolean;
  footerOnly?: boolean;
  playerMode?: boolean;
}
export const NowPlayingMobileMiniPlayerWrap = styled(
  FlexibleDiv
)<NowPlayingMobileMiniPlayerWrapProps>`
  .mini__player {
    padding: 15px 10px;
    align-items: center;
    position: fixed;
    background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, #${dominantColors[0]} 0%, #000 100%)`
      : "#0b0b0a"};
    top: 0;
    left: 0;
    z-index: 99999;

    .track__image__wrap {
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;

      .name__and__cover {
        display: flex;
        align-items: center;
        column-gap: 5px;
        min-height: 60px;

        .cover__art {
          width: 50px;
          min-height: 100%;
          border-radius: 4px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        .title__and__album__artist {
          display: flex;
          padding: 5px;
          flex-direction: column;
          justify-content: flex-start;
          min-height: 100%;
          align-items: flex-start;
          gap: 5px;
          row-gap: 2px;

          .title {
            font-size: 0.4;
            font-weight: 400;
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
          }

          .artist {
            font-size: 0.6rem;
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
            opacity: 0.6;
          }
        }
      }
    }

    .track__controls__wrap {
      gap: 5px;
      flex: 1;

      .controls__main {
        column-gap: 15px;
        justify-content: flex-end;
        .control__icons {
          cursor: pointer;
        }
      }
    }
  }
`;

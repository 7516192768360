import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export interface DSSWrapProps {
  width?: string
  color?: string
  border?: string
  height?: string
  margin?: string
  mt?: string
  mb?: string

  small?: boolean
  hasLabel?: boolean
}




export const DSSWrap = styled(FlexibleDiv)<DSSWrapProps>`
  margin-top: ${({ mt }) => mt || "0"};
  margin-bottom: ${({ mb }) => mb || "3rem"};

  .ant-picker-input{
    &::placeholder{
      color: #FFFFFF !important;
    }
  }
  .ant-input-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
  }


  .group__cell {
    flex: 1;

    label {
      display: inline-flex;
      color: var(--dampWhiteLight);
      font-family: "Baguede", sans-serif;
      font-size: ${({ small }) => (small ? "10px" : "14px")};
      margin-bottom: ${({ hasLabel }) => (hasLabel ? "0" : "0.5rem")};

      @media (max-width: 550px) {
        font-size: 11px;
      }
    }
  }

  .error__message {
    p {
      color: var(--primaryDanger);
      font-size: 0.75rem;
      line-height: 1.2;
      text-align: left;
      margin: 0;
    }
  }

  .dss-label-wrap {
    justify-content: flex-start;
    align-items: center;
  }

  .dss-label {
    color: var(--dampWhiteLight);
    margin-bottom: 0;
    display: inline-flex;
    font-size: ${({ small }) => (small ? "12px" : "14px")};
    font-family: "Baguede", sans-serif;
    text-transform: capitalize;

    @media (max-width: 550px) {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 649px) {
    margin-bottom: 1rem;

    .ant-input-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
      margin-top: 25px;

      @media (max-width: 376px) {
        margin-top: 10px;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    margin-bottom: 0.65rem;

    .group__cell {
      & > div {
        height: 2.5em;
      }
    }
  }
`

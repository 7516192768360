import { getCollabPlaylistEngagements } from "network/playlist"
import { useQuery, useQueryClient } from "react-query"
import { ResponseCollabPlaylistEngagements } from "types"

export const useLeaderboardEngagementService = (collabPlaylistId?: string) => {
  const queryClient = useQueryClient()

  return useQuery<ResponseCollabPlaylistEngagements>(
    ["leaderboard-engagement", collabPlaylistId], // Cache key
    () => getCollabPlaylistEngagements(collabPlaylistId), // Query with dynamic filters
    {
      enabled: !!collabPlaylistId,
      initialData: () => {
        // Check if data is cached already
        const cachedData =
          queryClient.getQueryData<ResponseCollabPlaylistEngagements>([
            "leaderboard-engagement",
            collabPlaylistId,
          ])
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}

export const getUserAgent = (): string => {
  // Opera 8.0+
  const isOpera =
    (!!(window as any).opr && !!(window as any).opr.addons) ||
    !!(window as any).opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0

  // Firefox 1.0+
  const isFirefox = navigator.userAgent.toLowerCase().includes("firefox")

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test((window as any).HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(
      !(window as any)["safari"] ||
        (typeof (window as any)["safari"] !== "undefined" &&
          (window as any)["safari"].pushNotification)
    )

  // Internet Explorer 6-11
  const isIE =
    /*@cc_on!@*/ false ||
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!(document as any).documentMode

  // Edge 20+
  const isEdge = !isIE && !!(window as any).StyleMedia

  // Chrome 1 - 71
  const isChrome =
    !!(window as any).chrome &&
    (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime)

  // Blink engine detection
  const isBlink = (isChrome || isOpera) && !!window.CSS

  if (isOpera) {
    return "Opera"
  } else if (isFirefox) {
    return "FireFox"
  } else if (isSafari) {
    return "Safari"
  } else if (isIE) {
    return "Internet Explorer"
  } else if (isEdge) {
    return "Edge"
  } else if (isBlink) {
    return "Blink"
  } else if (isChrome) {
    return "Chrome"
  } else {
    return window?.navigator?.userAgent || ""
  }
}

import { Playlist } from "types/playlist"
import { AlbumState, albumReducer } from "./album"
import { AppState, appReducer } from "./app"
import { ArtistState, artistReducer } from "./artist"
import { PlayerState, playerReducer } from "./player"
import { PlaylistState, playlistReducer } from "./playlist"
import { SearchState, searchReducer } from "./search"
import { Types } from "./types"
import { UserState, userReducer } from "./user"
import { Album } from "types/artist"

export type StateProps = {
  users: UserState
  artist: ArtistState
  search: SearchState
  player: PlayerState
  playlist: PlaylistState
  app: AppState
  album: AlbumState
}

export type ActionProps = {
  type: Types
  payload: any
}

export const initialState: StateProps = {
  users: {
    me: {
      id: "",
    },
  },

  artist: {},

  album: {} as AlbumState,

  search: {
    searchQuery: "",
    searchTracks: [],
    filterSearch: "combined",
    filterActiveSearch: "combined",
  },

  player: {
    volume: 1,
    fsem: false,
    repeat: false,
    shuffle: false,
    currentTime: 0,
    prevTracks: [],
    playingList: {},
    isPaused: false,
    queuedTracks: [],
    currentTrack: undefined,
    isPlaying: false,
    isPlayerMuted: false,
    isPlayerActive: false,
  },

  playlist: {
    current: {} as Playlist,
    currentAlbum: {} as Album,
    recentlyPlayed: [],
    creatingPlaylist: false,
  },

  app: {
    msisdn: "",
    isMtn: false,
    categories: "",
    billingPlans: [],
    paymentMethod: {
      plan: "",
      mode: "",
      method: "paystack",
    },
    isEnriched: false,
    isLoggedIn: false,
    signUpFormStep: 0,
    headerEnrichment: {},
    scrollToBottom: false,
    apiResponseError: undefined,
    authStreamingWarning: false,
    msisdnUserHasAccount: false,
  },
}

export const mainReducer = (
  state: StateProps,
  action: ActionProps
): StateProps => {
  const { users, playlist, artist, player, app, search, album } = state

  return {
    app: appReducer(app, action),
    users: userReducer(users, action),
    album: albumReducer(album, action),
    artist: artistReducer(artist, action),
    search: searchReducer(search, action),
    player: playerReducer(player, action),
    playlist: playlistReducer(playlist, action),
  }
}

import { memo } from "react";
import { PITWrap } from "./image-text-card.styles";
import { FlexibleDiv } from "components/lib/Box";
import { pauseAudio, playAudio, playTrack } from "utils";
import { useMainContext } from "context";
import { FsePlayingIcon,  PlayIcon } from "assets";
import { Track } from "types";

export type ImageTextCardProps = {
  coverArt?: string;
  title?: string;
  subTitle?: string;
  track?: Track;
  handleClick?: () => void;
  size?: "large" | "small" | "chart"; // Add 'chart' to the size prop
  rank?: number; // Add rank for chart cards
};

const ImageTextCard = ({
  handleClick,
  size,
  rank,
  ...props
}: ImageTextCardProps) => {
  const {
    dispatch,
    playerRef,
    state: { player },
  } = useMainContext();
  const { track } = props;
  const { title, coverArt, subTitle } = props;
  const { currentTrack, isPlaying, isPaused } = player;
  const isPlayingTrack =
    (currentTrack?.track?.id === track?.id || currentTrack?.id === track?.id) &&
    isPlaying;

  return (
    <PITWrap onClick={handleClick} size={size}>
      <FlexibleDiv className="card__play__btn">
        {isPlayingTrack ? (
          <div className="icon__wrap">
            <FsePlayingIcon
              onClick={() => pauseAudio(playerRef, dispatch)}
              width={20}
              height={20}
              color="var(--primaryBlack)"
            />
          </div>
        ) : (
          <div className="icon__wrap">
            {isPaused && currentTrack?.track?.id === track?.id ? (
              <PlayIcon
                width="50px"
                height="50px"
                color="var(--primaryBlack)"
                onClick={() => playAudio(playerRef, dispatch)}
              />
            ) : (
              <PlayIcon
                width="50px"
                height="50px"
                color="var(--primaryBlack)"
                onClick={() => handlePlayTrack(props)} // TODO: should pass the Track item to be played
              />
            )}
          </div>
        )}
        {/* <div className="icon__wrap">
          <IoMdPlay size={50} color="var(--primaryWhite)" />
        </div> */}
      </FlexibleDiv>
      <div className="image__wrap">
        {rank !== undefined && <div className="rank-badge">{rank}</div>}{" "}
        {/* Render rank badge if provided */}
        <img src={coverArt} alt="" />
      </div>
      <div className="card__footer">
        <h3 className="title">{title}</h3>
        {subTitle && <h4 className="subtitle">{subTitle}</h4>}
      </div>
    </PITWrap>
  );

  function handlePlayTrack(payload: any) {
    playTrack(dispatch, playerRef, payload);
    // playTrack(dispatch, playerRef, { track: payload, entity })
    // addToQueue(dispatch, tracks);
  }
};

export const PlainImageTextCard = memo(ImageTextCard);

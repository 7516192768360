import { ActionProps } from "context/reducer"
import { Types } from "context/types"
import { User } from "./user.types"

export type UserState = {
  me: Partial<User>
  xer?: Partial<User>
}
export const userReducer = (
  state: UserState,
  { type, payload }: ActionProps
) => {
  switch (type) {
    case Types.CURRENT_USER:
      return {
        ...state,
        me: payload,
      }

    case Types.UPDATE_MY_PROFILE:
      return {
        ...state,
        me: {
          ...state.me,
          profile: {
            ...state.me.profile,
            ...payload,
          },
        },
      }

    case Types.ACTIVE_USER_PROFILE:
      return {
        ...state,
        xer: {
          ...state.xer,
          ...payload,
        },
      }

    default:
      return state
  }
}

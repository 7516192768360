import { Loader, Section } from "components"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import {
  fetchArtistsIFollow,
  getPlaylistsByQuery,
  getRecentlyPlayedTracks,
  getTopStreamedTracks,
  getUserProfileWithID,
} from "network"
import { useQueries, useQuery } from "react-query"
import { useMediaQuery } from "react-responsive"
import { useParams } from "react-router-dom"
import { MetricsLayout, UserProfileJumbotron } from "../components"
import { Container } from "./user.styles"

export const UserProfile = () => {
  const isMobile = useMediaQuery({ maxWidth: 950 })
  const { userId } = useParams() as unknown as Record<string, string>

  const { data, isLoading } = useQuery(["user-profile", userId], () =>
    getUserProfileWithID(userId)
  )

  const user = data && data.data
  const bg = user?.profile?.coverArt?.url
  const statistics = user?.statistics
  const coverArtDominantColors = user?.profile?.coverArtDominantColors

  const streamInfo = useQueries([
    {
      queryKey: ["top-streamed-tracks", userId],
      queryFn: () => getTopStreamedTracks(userId),
    },
    {
      queryKey: ["recently-played", userId],
      queryFn: () => getRecentlyPlayedTracks(userId),
    },
  ])
  const isFetching = streamInfo.some(o => o.isLoading)
  const topTracks = streamInfo[0].data
  const recentlyPlayed = streamInfo[1].data

  const playlistInfo = useQueries([
    {
      queryKey: ["fetch-artists-user-follows", userId],
      queryFn: () => fetchArtistsIFollow(),
    },
    {
      enabled: !!userId,
      queryKey: ["fetch-user-playlist", userId],
      queryFn: () => getPlaylistsByQuery(`type=SOLO&user=${userId}`),
    },
    {
      enabled: !!userId,
      queryKey: ["fetch-user-collab-playlist", userId],
      queryFn: () => getPlaylistsByQuery(`type=COLLABORATIVE&user=${userId}`),
    },
  ])

  const isFetchingUsrPl = playlistInfo.some(o => o.isLoading)
  const artistIFollow = playlistInfo[0].data
  const usrPlaylistData = playlistInfo[1].data
  const usrCollabPlaylistData = playlistInfo[2].data

  return (
    <PlayerLayout>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <Container isMobile={isMobile}>
          <Section
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <UserProfileJumbotron
              dominantColors={coverArtDominantColors}
              statistics={statistics}
              user={user}
              bg={bg}
            />

            <MetricsLayout
              user={user}
              ddcLoading={isFetching}
              usrPlLoading={isFetchingUsrPl}
              topTracks={topTracks?.data}
              soloPlaylist={usrPlaylistData}
              following={artistIFollow?.data || []}
              recentlyPlayed={recentlyPlayed?.data || []}
              collabPlaylist={usrCollabPlaylistData || []}
            />
          </Section>
        </Container>
      )}
    </PlayerLayout>
  )
}

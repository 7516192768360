import {
  FsePausePlayIcon,
  FsePlayingIcon,
  NextOutlineIcon,
  PrevOutlineIcon,
} from "assets";
import {
  FlexibleDiv,
  TrackProgressControl,
} from "components";
import { Types, useMainContext } from "context";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDisplayArtist,
  getTrackID,
  getTrackTitle,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
} from "utils";
import { MobileMiniPlayerWrap } from "./mini-player.styles";

export const MobileMiniPlayer = React.memo(() => {
  const { playlistId } = useParams() as { playlistId: string };
  const navigate = useNavigate();
  const {
    state: { player  },
    playerRef,
    dispatch,
  } = useMainContext();
  const [playerMode ] = useState(false);
  const [,setPlayerModeActivated] = useState(false);

  const {
    isPlaying,
    currentTrack,
  } = player;
  const trackId = getTrackID(currentTrack);
  const domColors = currentTrack?.metadata?.coverArtDominantColors


  const handlePlayerMode = () => {
    setPlayerModeActivated(true);
    dispatch({ type: Types.SET_FSEM, payload: true }); // Set fullscreen mode in context
    navigate(`/player/${trackId}/playlist/${playlistId}`);
  };

  useEffect(() => {
    if (playerMode) {
      setPlayerModeActivated(true);
    }
  }, [playerMode]);

  return (
    <MobileMiniPlayerWrap onClick={handlePlayerMode} playerMode={playerMode} dominantColors={domColors}>
      <FlexibleDiv alignItems="center" className="fsem__wrap">
        {currentTrack && (
          <FlexibleDiv className="track__image__wrap">
            <div className="name__and__cover">
              <div className="cover__art">
                <img
                  src={getImageDisplay()}
                  alt={`${getTrackTitle(currentTrack, true)} cover`}
                />
              </div>
              <div className="title__and__album__artist">
                {currentTrack && (
                  <p className="title">{getTrackTitle(currentTrack, true)}</p>
                )}

                <p className="album">Album</p>
                <p className="artist">{getDisplayArtist(currentTrack)}</p>
              </div>
            </div>
          </FlexibleDiv>
        )}

        <FlexibleDiv
          alignItems="center"
          className="track__controls__wrap"
          flexDir="column"
        >
          <FlexibleDiv justifyContent="center" className="controls__main">
            <div
              className="control__icons"
              onClick={() => {
                if (currentTrack) {
                  playPreviousTrack(dispatch, {
                    track: currentTrack,
                  });
                }
              }}
            >
              <PrevOutlineIcon width={25} height={25} />
            </div>

            {isPlaying ? (
              <div
                className="control__icons pause__icon"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from bubbling up
                  pauseAudio(playerRef, dispatch);
                }}
              >
                <FsePlayingIcon width={25} height={25} />
              </div>
            ) : (
              <div
                className="control__icons play__icon"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from bubbling up
                  playAudio(playerRef, dispatch);
                }}
              >
                <FsePausePlayIcon width={25} color="#fff" height={25} />
              </div>
            )}

            <div
              className="control__icons"
              onClick={(e) => {
                e.stopPropagation(); // Prevent click from bubbling up
                playNextTrack(dispatch, {
                  track: currentTrack,
                });
              }}
            >
              <NextOutlineIcon width={25} height={25} />
            </div>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="mobile__song__progress__wrap">
        <TrackProgressControl displayProgressTime={false} />
      </FlexibleDiv>
    </MobileMiniPlayerWrap>
  );

  function getImageDisplay() {
    if (!isEmpty(currentTrack)) {
      if (currentTrack?.track?.metadata) {
        return currentTrack.track.metadata.coverArt.url;
      }

      if (currentTrack?.metadata) {
        return currentTrack.metadata.coverArt.url;
      }
    }
  }
});

MobileMiniPlayer.displayName = "MobileMiniPlayer";

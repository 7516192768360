import styled from "styled-components"
import { FlexibleDiv } from "../../components/lib/Box/styles"
import ArtBoardImg from "assets/images/Auth/artboard_6@2x.png"

export const SettingsWrap = styled(FlexibleDiv)`
  padding-top: 2%;

  .upload__menu {
  }
`

export const TabSelectionWrap = styled(FlexibleDiv)`
  background-color: var(--primaryBlack);
  margin: 4rem auto;
  justify-content: flex-start;

  .tab__holder {
    border: 0.5px solid var(--borderLight);
    padding: 1rem 2rem;
    max-width: 655px;
  }

  .navigation__routes__web {
    width: 100%;
    justify-content: space-between;

    .tab__links {
      color: var(--uduxWhiteSecondary);
      white-space: nowrap;
      cursor: pointer;
      font-size: 13px;
      width: max-content;
      padding: 0.85rem 0.75rem;
      transition: all 0.8s ease;

      :not(.active__tab):hover {
        color: var(--uduxYellowPrimary);
      }

      p {
        font-family: "Baguede", sans-serif;
        font-size: 1rem;
        margin: 0;
      }
    }
    .active__tab {
      background-color: var(--uduxYellowPrimary);
      color: var(--primaryWhite);
      position: relative;
      cursor: default;
    }
  }

  @media only screen and (max-width: 600px) {
    margin: 2rem auto;

    .tab__holder {
      border: 0.5px solid var(--borderLight);
      padding: 1rem;
      width: 100%;
    }

    .navigation__routes__web {
      width: 100%;
      justify-content: space-between;

      .tab__links {
        color: var(--uduxWhiteSecondary);
        white-space: nowrap;
        cursor: pointer;
        width: max-content;
        padding: 0.5rem;
        transition: all 0.3s ease;

        :not(.active__tab):hover {
          color: var(--uduxYellowPrimary);
        }

        p {
          font-size: 0.65rem;
        }
      }
      .active__tab {
        background-color: var(--uduxYellowPrimary);
        color: var(--primaryWhite);
      }
    }
  }
`

export const SettingsSubSectionWrap = styled(FlexibleDiv)`
  background: var(--yellowDeep);
  padding: 2rem;
  position: relative;

  .action__wrap {
    background: url(${ArtBoardImg}) no-repeat center / cover;
    padding: 2.5rem 1rem;
  }

  h4 {
    color: white;
  }

  @media only screen and (max-width: 600px) {
    padding: 1rem;

    .action__wrap {
      padding: 1rem;
    }
  }
`

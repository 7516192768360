import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { FlexibleDiv, MediaCard } from "components";
import { Skeleton } from "antd";
import { TopSongsWrap } from "./top-songs.styles";
import { getAllTracks } from "network";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
export const TopSongs = () => {
    const navigate = useNavigate();
    const { data: topTracks, isLoading: isTracksLoading } = useQuery(
        ["tracks"],
        getAllTracks
      )
  return (
    <PlayerLayout>
      <TopSongsWrap>
        <FlexibleDiv alignItems="flex-start" className="header">
          <h4 className="subheader">Top Songs</h4>
        </FlexibleDiv>
        <div className="top__songs">
          {isTracksLoading ? (
            <FlexibleDiv minHeight="300px">
              <Skeleton active={isTracksLoading} />
            </FlexibleDiv>
          ) : (
            topTracks?.data?.map((trk, index) => {
              return (
                <MediaCard
                  rank={index + 1}
                  type="songs"
                  track={trk}
                  key={index}
                  handleNavigate={() =>
                    navigate(`/playlist/${trk?.album?.id}?t=alb`, {
                      state: {
                        module: "album",
                      },
                    })
                  }
                />
              );
            })
          )}
        </div>
      </TopSongsWrap>
    </PlayerLayout>
  );
};

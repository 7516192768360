import React, { useRef } from "react"
import { PaymentInfoWrapper } from "./payment-info.styles"
import { FlexibleDiv } from "components"
import FullPhoneFrame from "assets/images/general/udux_mobile_app.png"

export const PaymentInfo = () => {
  const uduxFeatures = [
    {
      title: "Manage your playlist",
      subtitle:
        "Add your favourite songs to a playlist and just be expressive.",
    },
    {
      title: "Listen on all your devices",
      subtitle:
        "Access your songs on all your devicea, at any location and without restrictions.",
    },
    {
      title: "Ranked playlists",
      subtitle:
        "Have your friends contribute to your playlist and create magic together.",
    },
    {
      title: "Share playlist and get votes",
      subtitle:
        "Those songs shouldn’t just sit pretty in your playlist, let the world see the magic you create behind doors.",
    },
  ]

  const rateCardRef = useRef(null)

  const onRateCardsScroll = () => {
    if (rateCardRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = rateCardRef.current
      const totalDocScrollLength = scrollWidth - clientWidth
      const scrollPosition = Math.floor(
        (scrollLeft / totalDocScrollLength) * 100
      )

      if (scrollPosition <= 50) {
        const leftIndicator = document.querySelector(
          ".left__indicator"
        ) as HTMLElement | null
        const rightIndicator = document.querySelector(
          ".right__indicator"
        ) as HTMLElement | null

        if (leftIndicator) {
          leftIndicator.style.backgroundColor = "#FDA300"
        }

        if (rightIndicator) {
          rightIndicator.style.backgroundColor = "transparent"
        }
      } else {
        const rightIndicator = document.querySelector(
          ".right__indicator"
        ) as HTMLElement | null
        const leftIndicator = document.querySelector(
          ".left__indicator"
        ) as HTMLElement | null

        if (rightIndicator) {
          rightIndicator.style.backgroundColor = "#FDA300"
        }

        if (leftIndicator) {
          leftIndicator.style.backgroundColor = "transparent"
        }
      }
    }
  }

  return (
    <PaymentInfoWrapper
      flexDir="row"
      width="100%"
      flexWrap="nowrap"
      justifyContent="start"
      alignItems="start"
    >
      <FlexibleDiv
        width="65%"
        height="100%"
        className="info__section__wrapper"
        flexDir="column"
        justifyContent="start"
      >
        <FlexibleDiv
          className="top__container"
          width="100%"
          flexDir="column"
          alignItems="start"
          flexWrap="nowrap"
          justifyContent="space-around"
        >
          <small className="small__udux__text">Why uduX?</small>

          <p className="big__subtitle__text">
            Non-stop plug for <br />
            <span>
              <b>Trending</b> Songs
            </span>
          </p>
        </FlexibleDiv>

        <FlexibleDiv
          className="bottom__container"
          width="100%"
          height="60%"
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          onScroll={onRateCardsScroll}
          ref={rateCardRef}
        >
          {uduxFeatures.map((value, idx) => (
            <FlexibleDiv
              key={idx}
              width="45%"
              alignItems="space-between"
              className="box__wrapper"
            >
              <h4 className="container__title">{value.title}</h4>
              <p className="container__subtitle">{value.subtitle}</p>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="image__wrapper" width="35%" height="100%">
        <img
          src={FullPhoneFrame}
          alt="phone_frame"
          className="background__overlay"
        />
      </FlexibleDiv>
    </PaymentInfoWrapper>
  )
}

import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { Button, FlexibleDiv, PhoneInput } from "components";
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout";
import { Types, useMainContext } from "context";
import { loginWithPhone } from "network";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Container } from "./phone.styles";
import galleryImage from "assets/images/Auth/continue_with_phone.png";

const LoginWithPhoneNumber = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [, setCountryCode] = useState("");
  const { mutate, isLoading } = useMutation(loginWithPhone);
  const { dispatch } = useMainContext();

  useEffect(() => {
    return () =>
      dispatch({ type: Types.API_RESPONSE_ERROR, payload: undefined });
  }, [dispatch]);

  return (
    <AuthLayout
      title="We Just Need Your Number"
      subText="Our algorithms will handle the rest"
      galleryImage={galleryImage}
    >
      <Container>
        <Form onFinish={handleFormSubmit} form={form} className="form_box">
          <FlexibleDiv className="input">
            <PhoneInput
              borderBottom="1px solid #707070"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e);
              }}
              label=""
              onSetCountryCode={(code) => setCountryCode(code)}
              small
            />
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="180px"
              height="40px"
              font_size="16px"
              type="submit"
              contained
              radius="10px"
              color="var(--uduxBlackPrimary)"
              bold
              ltr
            >
              <span>
                {isLoading ? (
                  <LoadingOutlined color="var(--uduxYellowPrimary)" />
                ) : (
                  "Continue"
                )}
              </span>
            </Button>
          </FlexibleDiv>
        </Form>
      </Container>
    </AuthLayout>
  );

  async function handleFormSubmit() {
    const payload = {
      phoneNumber: `+${phoneNumber}`,
    };

    mutate(payload, {
      onSuccess: () => {
        navigate("/login/verify-otp", { state: payload });
      },
      onError: (error) => {
        dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload: error,
        });
      },
    });
  }
};

export default LoginWithPhoneNumber;

import { AddToPlaylistIcon, AddToQueueIcon } from "assets"
import { StyledMenu } from "components"

export type AddToPlaylistAndQueueProps = {
  handleAddToPlaylist?: () => void
  handleAddToQueue?: () => void
  top: string
}

export const AddToPlaylistAndQueueMenu = ({
  handleAddToPlaylist,
  handleAddToQueue,
  top,
  ...props
}: AddToPlaylistAndQueueProps) => {
  const items = [
    {
      label: (
        <div className="menu_box" onClick={handleAddToPlaylist}>
          <AddToPlaylistIcon width={18} />
          <p>Add to Playlist</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleAddToQueue}>
          <AddToQueueIcon width={18} />
          <p>Add to Queue</p>
        </div>
      ),
    }
  ]

  return (
    <StyledMenu
      top={top || "1rem"}
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
      {...props}
    />
  )
}

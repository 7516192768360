import {
  CollabRequestStatus,
  InviteCollabUserPayload,
  ResponseInviteCollabUser,
  ResponseReceivedCollaboration,
  ResponseSentCollaborations,
} from "types"
import { instance } from "./axios"

export const getCollabRequestReceived = async (query?: CollabRequestStatus) => {
  const q = query ? `/status=${query}` : ""
  const { data } = await instance.get<ResponseReceivedCollaboration>(
    `/social/collaboration/received${q}`
  )

  return data.data
}

export const getCollabRequestSent = async (query?: CollabRequestStatus) => {
  const q = query ? `/status=${query}` : ""
  const { data } = await instance.get<ResponseSentCollaborations>(
    `/social/collaboration/sent${q}`
  )

  return data.data
}

export const inviteCollabUser = async (data: InviteCollabUserPayload) => {
  const r = await instance.post<ResponseInviteCollabUser>(
    "/social/collaboration/invite-by-email",
    data
  )

  return r.data
}

export const inviteCollabExistingUser = async (
  data: InviteCollabUserPayload
) => {
  const r = await instance.post<ResponseInviteCollabUser>(
    "/social/collaboration/existing-playlist/request",
    data
  )

  return r.data
}

import emptyBg from "assets/images/general/empty-top-artist.svg"
import { NDDWrap, NDDWrapProps } from "./no-data-display.styles"
import emptyInvite from "../../../assets/images/player/emptyInvite.svg"

export type NoDataDisplayProps = {
  text?: string
  image?: string
  inviteImg?: boolean
} & NDDWrapProps

export const NoDataDisplay = ({
  text,
  image,
  ...props
}: NoDataDisplayProps) => {
  const { inviteImg } = props

  return (
    <NDDWrap {...props}>
      <div className="no__display__image large__display">
        {!inviteImg ? (
          <img src={image || emptyBg} alt="no data" />
        ) : (
          <img src={emptyInvite} alt="no data" />
        )}
      </div>
      <p className="text__content">{text}</p>
    </NDDWrap>
  )
}

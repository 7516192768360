import { IconProps } from "types"

export const NextOutlineIcon = ({
  color = "#D9D9D9",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "25"}
    height={height || "23"}
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.2502 22.5005C1.00708 22.5005 0.773913 22.4039 0.601999 22.232C0.430084 22.0601 0.333503 21.8269 0.333503 21.5838V1.41644C0.332862 1.25491 0.374915 1.09607 0.455404 0.956021C0.535893 0.81597 0.651961 0.699674 0.791853 0.618908C0.931209 0.538451 1.08929 0.496094 1.2502 0.496094C1.41112 0.496094 1.5692 0.538451 1.70855 0.618908L19.1258 10.7026C19.2669 10.7826 19.3843 10.8985 19.4659 11.0386C19.5475 11.1787 19.5906 11.338 19.5906 11.5001C19.5906 11.6623 19.5475 11.8215 19.4659 11.9617C19.3843 12.1018 19.2669 12.2177 19.1258 12.2977L1.70855 22.3814C1.56884 22.4605 1.41079 22.5016 1.2502 22.5005ZM2.1669 3.00233V19.9979L16.8341 11.5001L2.1669 3.00233Z"
      fill={color}
      fillOpacity="0.9"
    />
    <path
      d="M22.3354 0.5H24.1688V22.5008H22.3354V0.5Z"
      fill={color}
      fillOpacity="0.9"
    />
  </svg>
)

import { IconProps } from "types"

export const PauseIcon = ({
  color = "#eaeaea",
  width,
  height,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "48"}
    height={height || "48"}
    viewBox="0 0 48 48"
    className="fill__player__svg"
    fill="none"
    {...props}
  >
    <g transform={props.transform || "translate(0, 0)"}>
      <path d="M0 0H2.78571V30H0V0ZM13 0V30H10.2143V0H13Z" fill={color} />
    </g>
  </svg>
)

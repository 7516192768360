import { FlexibleDiv } from "components/lib/Box"
import styled from "styled-components"

type SPBWrapperProps = {
  accentColor?: string
  mt?: string
}

export const SPBWrapper = styled(FlexibleDiv)<SPBWrapperProps>`
  margin-top: ${({ mt }) => mt || "1rem"};
  height: 50px;

  .left__wrapper {
    background-color: ${({ accentColor }) =>
      accentColor || "var(--primaryYellow)"};

    .type__text {
      font-size: 1rem;
      font-family: var(--mBold);
      color: var(--primaryBlack);
    }
  }

  .right__wrapper {
    padding: 1rem;
    background-color: var(--primaryBlack);
    border: 1px solid
      ${({ accentColor }) => accentColor || "var(--primaryYellow)"};

    .content__text {
      font-size: 0.8rem;
      font-family: var(--mLight);
      color: var(--primaryWhite);
      text-align: left;
    }
  }

  /* Tabs */
  @media screen and (min-width: 601px) and (max-width: 1024px) {
    .left__wrapper {
      .type__text {
        font-size: 1.2rem;
      }
    }

    .right__wrapper {
      padding: 1rem;

      .content__text {
        font-size: 1.2rem;
      }
    }
  }

  /* Mobile Device */
  @media screen and (max-width: 600px) {
    margin-top: 2rem;

    .right__wrapper {
      padding: 0.8rem;
      border: 0.5px solid
        ${({ accentColor }) => accentColor || "var(--primaryYellow)"};

      .content__text {
        font-size: 0.7rem;
      }
    }
  }
`

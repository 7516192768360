import React, { useState } from "react"
import { IoChevronDownCircleSharp } from "react-icons/io5"
import { SetStatus, countryCode, validateCountryCode } from "utils"
import { FlexibleDiv } from "../Box"
import {
  CountryCodeSelect,
  CountryFormItem,
  CountrySelectorWrap,
  Option,
  SearchCountryField,
} from "./country-selector.styles"
import { Rule } from "antd/lib/form"

export type CountrySelectorProps = {
  bordered?: boolean
  small?: boolean
  listHeight?: number
  nullable?: boolean
}

export type RuleType =
  | Rule
  | Rule[]
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

export const CountrySelector = ({
  bordered = true,
  small,
  listHeight = 300,
}: CountrySelectorProps) => {
  const [filtered, setFiltered] = useState(countryCode)
  // const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [status, setStatus] = useState<SetStatus>()

  const handleFilter = (e: string) => {
    const final = countryCode.filter(({ name }) => {
      const lowerCountry = name.toLowerCase()
      const lowerInput = e.toLowerCase()
      return !!lowerCountry.match(lowerInput)
    })

    setFiltered(final)
  }

  return (
    <CountrySelectorWrap small={small}>
      <label htmlFor="countryOfResidence">Country Code</label>

      <FlexibleDiv className="country__code__input__wrap">
        <CountryFormItem
          name={"countryOfResidence"}
          hasError={Boolean(status?.err)}
          hasSuccess={Boolean(status?.success)}
          rules={[
            {
              validator: (rules: RuleType, value: string) => {
                const countryObj = {
                  rule: rules,
                  value: value,
                  setStatus: setStatus,
                }

                validateCountryCode(countryObj)
              },
            },
          ]}
          bordered={bordered}
        >
          <CountryCodeSelect
            // items="center"
            placeholder="Select Country"
            placement="bottomLeft"
            listHeight={listHeight}
            dropdownRender={menu => (
              <React.Fragment>
                <SearchCountryField>
                  <input
                    placeholder="search..."
                    onChange={e => {
                      handleFilter(e.target.value)
                    }}
                  />
                </SearchCountryField>
                {menu}
              </React.Fragment>
            )}
            bordered={bordered}
            // onChange={(e) => setSelectedCountryCode(e)}
            suffixIcon={
              <IoChevronDownCircleSharp
                color={
                  status?.err
                    ? "var(--primaryDanger)"
                    : status?.success
                    ? "var(--successPrimary)"
                    : "var(--pinkPrimary)"
                }
              />
            }
          >
            {filtered.map((country, i) => (
              <Option key={i} value={country.name}>
                <span className="name">{country.name}</span>
              </Option>
            ))}
          </CountryCodeSelect>
        </CountryFormItem>
      </FlexibleDiv>
    </CountrySelectorWrap>
  )
}

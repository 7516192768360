import { Types, useMainContext } from "context"
import { changeVolume } from "utils"
import { FlexibleDiv } from "../Box"
import { VerticalProgressSlider } from "../ProgressSlider"
import { VolumeControllerStyles } from "./volume-control.styled"

export const VolumeController = () => {
  const {
    state: { player },
    playerRef,
    dispatch,
  } = useMainContext()
  const { isPlayerMuted, volume } = player


  return (
    <VolumeControllerStyles size={"small"} align="center">
      <FlexibleDiv className="volume__bar">
        <VerticalProgressSlider
          max={1}
          min={0.001}
          value={isPlayerMuted ? 0.001 : volume}
          onChange={e => {
            changeVolume(e, playerRef, Types.CURRENT_VOLUME, dispatch)
          }}
          step={0.02}
          handleStyle={{
            border: "none",
          }}
          vertical
        />
      </FlexibleDiv>
{/* 
      <Divider className="volume__divider" />

      <div
        onClick={handleMute}
        className={`icons__wrap ${
          isPlayerMuted || volume === 0.0001
            ? "muted__active"
            : "muted__inactive"
        }`}
      >
        {isPlayerMuted ? (
          <VolumeMuteIcon height={18} ref={muteRef} />
        ) : (
          <VolumeControlIcon height={18} ref={unmuteRef} />
        )}
      </div> */}
    </VolumeControllerStyles>
  )
}

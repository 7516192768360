import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export interface ActionBoxWrapperProps {
  loading?: boolean
}

export const ActionBoxWrapper = styled(FlexibleDiv)<ActionBoxWrapperProps>`
  border: 0.75px solid #70707070;
  height: 50px;
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
  justify-content: stretch;

  &:not(:first-child) {
    margin-top: 25px;
  }

  &:hover .pad__content {
    transition: background-color 0.5s ease-in-out;
    background-color: var(--primaryYellow);
  }

  .pad__content {
    padding: 0.5rem;
    display: flex;
    height: 100%;
    width: 20%;
    background-color: var(--secondaryGray);

    svg {
      width: 60%;
      height: 60%;
    }
  }

  .card__icon__box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .white_logo {
      width: 50px;
      height: auto;
    }

    .exist_icon {
      width: 33px;
      height: auto;
    }
  }

  .card__text__box {
    padding: 1rem 0 1rem 1rem;
    width: 70%;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;

    .text {
      font-size: 16px;
      color: var(--primaryWhite);
      text-decoration: none;

      span{
        color: var(--uduxYellowPrimary);
      }
    }

    .udux__yellow {
      color: var(--uduxYellowPrimary);
    }

    &--loading {
      display: flex;
      justify-content: center;
      width: 65%;

      svg {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: 40px;

    .card__text__box {
      .text {
        font-size: 14px;
      }

      &--loading {
        svg {
          font-size: 0.8rem;
        }
      }
    }
  }
`

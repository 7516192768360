import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
  OnDragStartResponder,
  OnDragUpdateResponder,
} from "@hello-pangea/dnd"
import { DroppableWrap } from "./drag-and-drop.styles"

export type DragAndDropProps = {
  withDroppable?: boolean
  droppableId: string
  children: React.ReactNode
  onDragStart?: OnDragStartResponder
  onDragUpdate?: OnDragUpdateResponder
  onDragEnd: OnDragEndResponder
}

export const DragAndDrop = ({
  withDroppable,
  droppableId,
  children,
  onDragEnd,
  onDragStart,
  onDragUpdate,
}: DragAndDropProps) => {
  return withDroppable ? (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      <Droppable
        droppableId={droppableId}
        // style={{ width: "100%" }}
      >
        {provided => (
          <DroppableWrap
            className="droppable"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
          </DroppableWrap>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      {children}
    </DragDropContext>
  )
}

import { Modal } from "antd"
import styled from "styled-components"

export interface ASMWrapProp {
  skinBanner?: string
}
export const ASMWrap = styled(Modal)<ASMWrapProp>`
  top: 30%;

  .ant-modal-content {
    color: var(--dampWhiteLight);
    background-color: var(--modalMain);

    .ant-modal-close-x {
      color: var(--dampWhiteLight);
    }
  }

  .ant-modal-header {
    color: var(--primaryWhite);
    background-color: var(--modalMain);
    border-bottom: 0;
  }

  .ant-modal-title {
    text-transform: capitalize;
    color: var(--primaryWhite);
    font-family: "Baguede", sans-serif;
    text-align: center;
  }

  .ant-modal-body {
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    :before {
      position: absolute;
      bottom: 0;
      content: "";
      left: 0;
      background: ${({ skinBanner }) =>
        `url(${skinBanner}) no-repeat center / cover`};
      width: 15px;
      height: 100%;
    }
  }

  .text__content {
    padding-bottom: 2rem;
    width: 80%;
    text-align: center;
    font-family: "Baguede", sans-serif;
  }

  .btn__wrap {
    width: 80%;
  }

  @media screen and (max-width: 600px) {
    width: 80%;

    .text__content {
      width: 85%;
    }
  }
`

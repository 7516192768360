import BackgroundImage from "assets/images/payment/ChoosePlatformImage.png"
// import GalleryImage from "assets/images/payment/checkMTNPhoneNumber.png"
import { FlexibleDiv } from "components"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { pollSubscriptionStatus } from "network"
import { useQuery } from "react-query"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { MSPSWrapper } from "./mtn-subscription-poll-status.styles"
import { useMainContext } from "context"

export const MtnSubscriptionPollStatus = () => {
  const navigate = useNavigate()
  const { state } = useLocation() as any
  const [params] = useSearchParams()
  const {
    state: { users },
  } = useMainContext()
  const lStore = localStorage.getItem("udx_pay") as string
  const provider = JSON.parse(lStore.replace(/\\/g, ""))

  const { me } = users

  const mode = params.get("mode") || "udux" || state?.mode || provider?.mode
  const plan = params.get("type") || "weekly" || state?.plan || provider?.plan

  useQuery(["poll-user-sub"], () => pollSubscriptionStatus(), {
    refetchInterval: 500,
    onSuccess: res => {
      const transaction = res?.data?.transaction
      const billingInfo = transaction?.billingPlan

      if (
        transaction?.status === "SUCCESS" &&
        billingInfo?.status === "ACTIVE"
      ) {
        if (mode === "mtn" && me?.id) {
          navigate("/")
        } else {
          navigate(`/payment/complete?type=${plan}&mode=${mode}`, {
            state: {
              mode,
              plan,
            },
          })
        }
      }
    },
  })

  return (
    <ChoosePlatform
      // galleryImage={GalleryImage}
      accentColor="#00725E"
      rightImage={BackgroundImage}
      backURL={`/payment/plan?type=${plan}`}
      hideSubscriptionBox
    >
      <MSPSWrapper height="100%" width="100%">
        <FlexibleDiv flexDir="column">
          <p className="mtn__header">Please check your MTN Phone</p>
          <p className="mtn__subtext">Confirm your payment options</p>
        </FlexibleDiv>
      </MSPSWrapper>
    </ChoosePlatform>
  )
}

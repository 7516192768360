import { Form, Select } from "antd"
import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export interface CountrySelectorWrapProps {
  small?: boolean
}

export interface CountryFormItemProps {
  width?: string
  height?: string
  bordered?: boolean
  borderColor?: string
  hasError?: boolean
  hasSuccess?: boolean
}

export const CountrySelectorWrap = styled(
  FlexibleDiv
)<CountrySelectorWrapProps>`
  flex-direction: column;
  align-items: flex-start;

  label {
    color: var(--dampWhiteLight);
    margin-bottom: 0.5rem;
    display: inline-flex;
    font-size: ${({ small }) => (small ? "10px" : "14px")};
    font-family: "Bw ModelicaBold", sans-serif;
  }
`

export const CountryCodeSelect = styled(Select)`
  display: flex;
  height: 100%;
  color: var(--white);
  background: none;
  width: 100%;
  padding: 0;
  position: relative;
  width: 30%;
  margin-right: 1rem;

  svg {
    transition: 0.3s ease;
  }
  &:hover svg {
    stroke: var(--uduxYellowPrimary);
  }
  .name {
  }

  &:hover {
    border-color: var(--uduxYellowPrimary);
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: var(--primaryWhite);
    font-family: "Baguede", sans-serif;
    font-size: 0.85rem;
    padding-left: 1rem;

    span {
      font-family: "Baguede", sans-serif;
    }
  }
  .ant-select-selection-search {
    display: none;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
  }
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--primary-color);
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    )
    .ant-select-selector,
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ).ant-select-open
    .ant-select-selector {
    border: none;
    background: none;
    box-shadow: none;
  }
  &.ant-select .ant-select-selector {
    padding: 0;
    height: 100%;
  }
`

export const CountryFormItem = styled(Form.Item)<CountryFormItemProps>`
  width: ${({ width }) => width || "100%}"};
  height: ${({ height }) => height || "100%"};
  border: ${({ bordered }) =>
    bordered ? "0.5px solid var(--dampWhiteLight)" : "none"};
  border-color: ${({ borderColor, hasError, hasSuccess }) => {
    return hasSuccess
      ? "var(--successPrimary)"
      : hasError
      ? "var(--primaryDanger)"
      : borderColor || "var(--dampWhiteLight)"
  }};
  position: relative;
  transition: 0.3s ease;

  .ant-select-selection-item {
    height: 100% !important;
  }

  &:hover {
    /* This adds yellow border all around components in the profile pages */
    /* border: 0.5px solid var(--uduxYellowPrimary); */
  }

  .ant-form-item-control-input {
    height: ${({ height }) => height ?? "45px"};

    @media only screen and (min-width: 500px) {
      height: 45px;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
  }
  .ant-select-in-form-item {
    margin-left: 5px;
    margin-right: 0;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: var(--primaryWhite);
    font-family: "Baguede", sans-serif;
    font-size: 0.85rem;
    padding-left: 1rem;

    span {
      font-family: "Baguede", sans-serif;
    }
  }

  .ant-input-group {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .flags__img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`

export const Option = styled(Select.Option)`
  .ant-select-item.ant-select-item-option {
    color: #fff;
  }
`

export const SearchCountryField = styled.div`
  padding: 1rem 0.5rem;
  background: #000;
  border-radius: 0;
  border: none;
  outline: none;
  color: var(--primaryWhite);
  font-family: "Bw ModelicaRegular", sans-serif;
  width: 100%;

  input {
    width: 100%;
    height: 100%;
    background: none;
    padding: 1rem 0.5rem;
    border-radius: 0;
    outline: none;
    border: 0.5px solid var(--grayPrimary);
  }
`

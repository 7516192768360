import styled from "styled-components";

export interface PlayerLayoutWrapProps {
  fsemMobileHeader?: boolean;
  isSidebarOpen?: boolean;
  playerActive?: boolean;
  footerOnly?: boolean;
}
export const PlayerLayoutWrap = styled.div<PlayerLayoutWrapProps>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $background-color;

  ::-webkit-scrollbar {
    display: none;
  }
  
  .layout-content {
    display: flex;
    padding: 0 40px;
    flex: 1;
    overflow: hidden;
    margin-top: 140px;
    @media screen and (max-width: 950px) {
      margin-top: 90px;
      padding: 0px;
    }
  }

  .player__layout__main {
    width: 100%;
    margin-bottom: ${({ playerActive }) => (playerActive ? "6.65rem" : "0")};
    overflow-y: auto;
    margin-left: ${({ isSidebarOpen }) =>
    isSidebarOpen ? "262px" : "100px"}; /* Sidebar slides in/out */
    ::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
      background: var(--borderLight);
      border-radius: 10px;
      width: 2px;
    }
    ::-webkit-scrollbar-thumb:active {
      background: var(--borderMain);
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    ::-webkit-scrollbar-track:active {
      background: var(--black);
    }

    @media screen and (max-width: 950px) {
      margin-left: 0px;
      padding-right: 0;
    }

    @media screen and (max-width: 600px) {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

import { Dropdown, notification } from "antd"
import {
  FavoriteFilledIcon,
  FavoriteIcon,
  MoreIcon,
  PauseIcon,
  PlayIcon,
  ShuffleIcon,
  UploadCameraIcon,
} from "assets"
import { DeleteIcon, UploadIcon } from "assets/images/general/SVGExports"
import {
  ApiResponseBanner,
  ApiResponseBannerProps,
  FlexibleDiv,
  Loader,
  StyledMenu,
} from "components"
import { Types, useMainContext } from "context"
import {
  addReaction,
  deletePlaylist,
  errorResponseHandler,
  getPreSignedUrl,
  removeReaction,
  successResponseHandler,
  updatePlaylistInfo,
} from "network"
import { Fragment, memo, useState } from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import {
  capitalize,
  nowPlaying,
  pauseAudio,
  processImage,
  shuffleTrack,
  truncate,
  unShuffleTrack,
} from "utils"
import { PlaylistMoreMenu } from "."
import {
  getCoverArt,
  getPlaylistAvatar,
  getPlaylistTitle,
  getTracksLength,
  getTypeAndYear,
} from "./pj-utils.lib"
import { Album, DisplayArtist, Track } from "types/artist"
import { Playlist } from "types/playlist"
import { ReactionType, UploadCategory, isAlbum, isPlaylist } from "types"

export type SoloPlaylistJumbotronProps = {
  entity?: Album | Playlist
  tracks?: Track[]
  module?: "album" | "playlist"
}
export const SoloPlaylistJumbotron = memo(
  ({ entity, tracks, module }: SoloPlaylistJumbotronProps) => {
    const navigate = useNavigate()
    const inSmMode = useMediaQuery({ maxWidth: 739 })
    const [editing, setEditing] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [playlistImage, setPlaylistImage] = useState(getCoverArt(entity))
    const [responseBanner, setResponseBanner] = useState<
      Omit<ApiResponseBannerProps, "onClose" | "message">
    >({
      open: false,
      type: "IDLE",
      response: "",
    })

    const {
      state: { player, album, users },
      playerRef,
      dispatch,
    } = useMainContext()
    const { mutate: mutateLike } = useMutation(addReaction)
    const { mutate: mutateUnlike } = useMutation(removeReaction)
    const { mutate: mutatePreSignUpload } = useMutation(getPreSignedUrl)
    const { mutate: mutatePlaylistInfo } = useMutation(updatePlaylistInfo)
    const { mutate: mutateDeletePlaylist, isLoading: dpLoading } =
      useMutation(deletePlaylist)

    const { isPlaying, shuffle, playingList } = player
    const { me } = users

    const da = getDisplayArtists()
    const mainArtist = getMainArtist()
    const isOwner =
      typeof entity?.user === "string"
        ? entity?.user === me?.id
        : entity?.user?.id === me?.id

    function getDisplayArtists() {
      if (module === "album" && isAlbum(entity)) {
        return entity?.metadata?.displayArtists
      }

      if (module === "playlist" && isPlaylist(entity)) {
        return [entity?.user] as unknown as DisplayArtist[]
      }

      return []
    }

    function getMainArtist() {
      if (module === "album" && isAlbum(entity)) {
        return da?.find(d => d.isMainArtist)
      }
    }

    const playlistCIMenu = (
      <StyledMenu
        item_padding="0.5rem 1rem"
        top="0.3rem"
        style={{
          border: "0.5px solid var(--uduxGrayLightAlpha)",
          width: 160,
        }}
        items={[
          {
            key: "1",
            label: (
              <FlexibleDiv
                justifyContent="flex-start"
                className="file__uploader__input__wrap"
              >
                <input
                  type="file"
                  name="playlistImage"
                  id="playlistImage"
                  onChange={handlePlaylistImageChange}
                  accept="image/*"
                  hidden
                />
                <label htmlFor="playlistImage">
                  <UploadIcon />
                  Change Picture
                </label>
              </FlexibleDiv>
            ),
          },
          {
            key: "2",
            label: (
              <FlexibleDiv
                justifyContent="flex-start"
                className="file__uploader__input__wrap"
              >
                <DeleteIcon width="13" /> Delete Picture
              </FlexibleDiv>
            ),
          },
        ]}
      />
    )

    return dpLoading ? (
      <Loader wrapperHeight="100%" />
    ) : (
      <Fragment>
        <ApiResponseBanner
          {...responseBanner}
          onClose={() =>
            setResponseBanner({
              open: false,
              type: "IDLE",
              response: "",
            })
          }
        />

        <aside className="cover__art__playlist">
          {isOwner && (
            <Dropdown
              trigger={["click"]}
              overlay={playlistCIMenu}
              placement="bottomRight"
            >
              <div className={"camera__icon"}>
                <UploadCameraIcon width="25" />
              </div>
            </Dropdown>
          )}

          {isOwner && fetching && (
            <div className="upload__in__progress">
              <Loader variant="purple" width={100} height={120} />
            </div>
          )}
          <img src={playlistImage} alt="cover art" />
        </aside>

        <section className="art__info__section">
          <aside className="hero__details">
            <aside>
              <p className="type__and__year">{getTypeAndYear(entity)}</p>
              <h2 className="title">{getPlaylistTitle(entity)}</h2>
            </aside>

            <div className="artist">
              {getPlaylistAvatar(entity, mainArtist, handleNameClick)}
            </div>
          </aside>

          {isPlaylist(entity) ? (
            <div className="bio__section">
              {isOwner ? (
                <p
                  contentEditable={editing}
                  className="content__editable"
                  onBlur={handleSaveDescription}
                  onClick={() => setEditing(true)}
                  suppressContentEditableWarning={true}
                >
                  {entity?.description
                    ? truncate(entity.description, inSmMode ? 40 : 150)
                    : "Enter a Playlist Description"}
                </p>
              ) : (
                <p
                  contentEditable={editing}
                  className="content__editable"
                  onBlur={handleSaveDescription}
                  onClick={() => setEditing(true)}
                  suppressContentEditableWarning={true}
                >
                  {entity?.description &&
                    truncate(entity.description, inSmMode ? 40 : 150)}
                </p>
              )}
            </div>
          ) : null}
        </section>

        <section className="solo__playlist__wrap">
          <div className="stats__wrap">
            <div className="player__controls">
              {isPlaying && playingList.id === entity?.id ? (
                <div
                  className="icon__wrap hover__fill"
                  onClick={() => pauseAudio(playerRef, dispatch)}
                >
                  <PauseIcon
                    width={30}
                    height={30}
                    transform={"translate(6 4)"}
                  />
                </div>
              ) : (
                <div className="icon__wrap hover__fill">
                  <PlayIcon
                    onClick={() => nowPlaying(entity, dispatch, playerRef)}
                    width={inSmMode ? "18" : "25"}
                    color="#fff"
                  />
                </div>
              )}

              <div className="icon__wrap hover__fill">
                <ShuffleIcon
                  width={inSmMode ? "18" : "22"}
                  color={shuffle ? "var(--yellowPrimary)" : "#fff"}
                  onClick={() => {
                    if (entity?.tracks) {
                      shuffle
                        ? unShuffleTrack(entity.tracks, dispatch)
                        : shuffleTrack(entity.tracks, dispatch)
                    }
                  }}
                />
              </div>
              <div
                className="icon__wrap hover__fill"
                onClick={() => {
                  if (entity?.id) {
                    toggleLike(entity.id, capitalize(module))
                  }
                }}
              >
                {entity?.userLiked ? (
                  <FavoriteFilledIcon
                    width={inSmMode ? "23" : "30"}
                    height="30"
                  />
                ) : (
                  <FavoriteIcon
                    height="25"
                    transform={"translate(2 4)"}
                    width={inSmMode ? "23" : "30"}
                    color={`${
                      entity?.userLiked ? "var(--uduxYellowPrimary)" : "#fff"
                    }`}
                  />
                )}
              </div>
              {/* <div className="icon__wrap hover__stroke">
              <DownloadIcon width={inSmMode ? "18" : "25"} color="#fff" />
            </div> */}
              <Dropdown
                trigger={["click"]}
                placement="bottomLeft"
                overlay={
                  <PlaylistMoreMenu
                    isOwner={isOwner}
                    handleDelete={handleDeletePlaylist}
                  />
                }
              >
                <div className="icon__wrap hover__fill">
                  <MoreIcon height={inSmMode ? "15" : "19"} color="#fff" />
                </div>
              </Dropdown>
            </div>

            <div className="stats__display">
              <p className="statistics">
                <span className="number">
                  {getTracksLength(tracks, module)}
                </span>
                <span className="info">Songs</span>
              </p>
              <p className="statistics">
                <span className="number">{entity?.statistics?.likes}</span>
                <span className="info">Likes</span>
              </p>
              <p className="statistics">
                <span className="number">{entity?.statistics?.spins}</span>
                <span className="info">Spins</span>
              </p>
              <p className="statistics">
                <span className="number">
                  {entity?.statistics?.streamTime || 0}hr
                </span>
                <span className="info">Runtime</span>
              </p>
            </div>
          </div>
        </section>
      </Fragment>
    )

    function toggleLike(entityId: string, entityType: string) {
      const payload = { entity: entityId, entityType }

      if (entity?.userLiked) {
        mutateUnlike(payload, {
          onSuccess: () => {
            dispatch({
              type: Types.UPDATE_CURRENT_ALBUM,
              payload: { ...album, userLiked: false },
            })
            successResponseHandler({
              message: `${entityType} un-liked successfully.`,
            })
          },
          onError: error => errorResponseHandler(error),
        })
      } else {
        mutateLike(
          { ...payload, type: ReactionType.LIKE },
          {
            onSuccess: () => {
              dispatch({
                type: Types.UPDATE_CURRENT_ALBUM,
                payload: { ...album, userLiked: true },
              })
              successResponseHandler({
                message: `${entityType} liked successfully.`,
              })
            },
            onError: error => errorResponseHandler(error),
          }
        )
      }
    }

    function handleNameClick() {
      if (isOwner) {
        navigate("/me")
      } else if (entity?.user) {
        const id =
          typeof entity.user === "string" ? entity.user : entity.user.id
        navigate(`/user/${id}`)
      }
    }

    async function handlePlaylistImageChange({
      target,
    }: React.ChangeEvent<HTMLInputElement>) {
      if (target.files && entity) {
        const file = target.files[0]
        const oldProfileImage = isPlaylist(entity)
          ? entity?.coverArt?.url
          : entity?.metadata?.coverArt?.url

        if (validateFileInput(file)) {
          setFetching(true)

          const tempProfileImage = URL.createObjectURL(file)
          setPlaylistImage(tempProfileImage)

          const payload = {
            fileName: file.name,
            category: UploadCategory.ARTWORKS,
          }

          const dominantColors = await processImage(tempProfileImage)

          const formData = new FormData()
          formData.append("file", file)
          const uploadFile = formData.get("file")

          mutatePreSignUpload(payload, {
            onSuccess: async ({ data }) => {
              await fetch(data.presignedUrl, {
                method: "PUT",
                body: uploadFile,
              })

              mutatePlaylistInfo(
                {
                  id: entity.id,
                  data: {
                    coverArt: data.fileId,
                    coverArtDominantColors: dominantColors,
                  },
                },
                {
                  onSuccess: async ({ data: plData }) => {
                    dispatch({
                      type: Types.UPDATE_CURRENT_PLAYLIST,
                      payload: plData,
                    })

                    setResponseBanner({
                      open: true,
                      type: "SUCCESS",
                      response: "Cover picture uploaded.",
                    })
                    setFetching(false)
                  },
                  onError: error => {
                    setFetching(false)
                    dispatch({
                      type: Types.UPDATE_CURRENT_PLAYLIST,
                      payload: {
                        ...entity,
                        coverArt: {
                          ...(isPlaylist(entity)
                            ? entity.coverArt
                            : entity.metadata.coverArt),
                          profilePicture: {
                            url: oldProfileImage,
                          },
                        },
                      },
                    })
                    setResponseBanner({
                      open: true,
                      type: "ERROR",
                      response: error,
                    })
                  },
                }
              )
            },
            onError: error => {
              setFetching(false)
              dispatch({
                type: Types.UPDATE_CURRENT_PLAYLIST,
                payload: {
                  ...entity,
                  coverArt: {
                    ...(isPlaylist(entity)
                      ? entity.coverArt
                      : entity.metadata.coverArt),
                    profilePicture: {
                      url: oldProfileImage,
                    },
                  },
                },
              })
              setResponseBanner({
                open: true,
                type: "ERROR",
                response: error,
              })
            },
          })
        }
      }
    }

    function validateFileInput(file: File) {
      const maxFileLimit = 5000000 // 5mb
      const imageType = /image.*/

      if (!file) {
        return
      }

      if (!file?.type?.match(imageType)) {
        notification.error({
          message: "error",
          description:
            "Only Images are allowed. Please upload an image instead.",
        })
        return
      }
      if (file?.size > maxFileLimit) {
        notification.error({
          message: "error",
          description: "File is too large, Max file size is 1mb",
        })
        return
      }

      return true
    }

    function handleSaveDescription({
      target,
    }: React.ChangeEvent<HTMLParagraphElement>) {
      if (target.textContent === "Enter a playlist description") {
        setEditing(false)
        return
      } else {
        if (entity?.id && target.textContent) {
          mutatePlaylistInfo({
            id: entity.id,
            data: {
              description: target.textContent,
            },
          })
          setEditing(false)
        }
      }
    }

    function handleDeletePlaylist() {
      mutateDeletePlaylist(entity?.id, {
        onSuccess: () => {
          dispatch({ type: Types.UPDATE_CURRENT_PLAYLIST, payload: {} })
          navigate("/")
        },
        onError: err => {
          setResponseBanner({
            open: true,
            type: "ERROR",
            response: err,
          })
        },
      })
    }
  }
)

SoloPlaylistJumbotron.displayName = "SoloPlaylistJumbotron"

import { FlexibleDiv } from "components"
import styled from "styled-components"

export const RegisterContent = styled(FlexibleDiv)`
  height: auto;

  .greeting__wrap {
    padding-bottom: 1rem;
  }

  .greeting {
    color: var(--primaryYellow);
    padding-bottom: 1rem;
    text-transform: capitalize;
  }

  p {
    font-size: 0.85rem;
    text-align: center;
  }

  .payment__method__wrapper {
    padding-bottom: 1rem;
  }

  .subscribe__via {
    padding: 2rem 0;
  }

  .change__method__text {
    font-size: 0.65rem;
    text-decoration: none;
    color: var(--primaryWhite);
    opacity: 0.7;
    padding-bottom: 2rem;
  }

  .subscribe__btn {
    width: fit-content;
    background: var(--primaryYellow);
    text-transform: none;
    transition: transform 1s;

    @media (max-width: 376px) {
      font-size: 0.7rem;
    }

    span {
      font-size: 0.75rem;
      color: var(--primaryBlack);

      @media (max-width: 376px) {
        font-size: 0.65rem;
      }
    }

    :hover {
      transform: scale(1.1);
    }
  }

  .logout__class {
    padding-top: 2rem;
    font-size: 0.65rem;
    text-decoration: none;
    color: var(--primaryWhite);
    opacity: 0.7;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

import React from "react"
import { IconProps } from "types"

export const FacebookSVG = ({ stroke, fill, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "40"}
      height={height ?? "40"}
      viewBox="0 0 40 40"
      fill={fill ?? "none"}
      stroke={stroke ?? "none"}
    >
      <path
        d="M23.3333 15.5V20.4167H27.7167C28.0333 20.4167 28.2667 20.7 28.2 21.0166L27.5667 24.1834C27.5167 24.4167 27.3167 24.5833 27.0833 24.5833H23.3333V36.6667H18.3333V24.5833H15.5C15.2166 24.5833 15 24.3667 15 24.0834V20.9166C15 20.6333 15.2166 20.4167 15.5 20.4167H18.3333V15C18.3333 12.2333 20.5667 10 23.3333 10H27.8334C28.1167 10 28.3333 10.2166 28.3333 10.5V14.5C28.3333 14.7834 28.1167 15 27.8334 15H23.8333C23.55 15 23.3333 15.2166 23.3333 15.5Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.3335 21.383V24.9997C3.3335 33.333 6.66683 36.6663 15.0002 36.6663H25.0002C33.3335 36.6663 36.6668 33.333 36.6668 24.9997V14.9997C36.6668 6.66634 33.3335 3.33301 25.0002 3.33301H15.0002C6.66683 3.33301 3.3335 6.66634 3.3335 14.9997"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const InstagramSVG = ({ stroke, fill, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "40"}
      height={height ?? "40"}
      viewBox="0 0 40 40"
      fill={fill ?? "none"}
      stroke={stroke ?? "none"}
    >
      <path
        d="M3.3335 21.7163V24.9997C3.3335 33.333 6.66683 36.6663 15.0002 36.6663H25.0002C33.3335 36.6663 36.6668 33.333 36.6668 24.9997V14.9997C36.6668 6.66634 33.3335 3.33301 25.0002 3.33301H15.0002C6.66683 3.33301 3.3335 6.66634 3.3335 14.9997"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9998 25.8337C23.2215 25.8337 25.8332 23.222 25.8332 20.0003C25.8332 16.7787 23.2215 14.167 19.9998 14.167C16.7782 14.167 14.1665 16.7787 14.1665 20.0003C14.1665 23.222 16.7782 25.8337 19.9998 25.8337Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3937 11.6663H29.413"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const YoutubeSVG = ({ stroke, fill, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "40"}
      height={height ?? "40"}
      viewBox="0 0 40 40"
      fill={fill ?? "none"}
      stroke={stroke ?? "none"}
    >
      <path
        d="M3 13.4156C3 7.16625 6.33333 3 11.3333 3H28C33 3 36.3333 7.16625 36.3333 13.4156V25.9144C36.3333 32.1638 33 36.33 28 36.33H11.3333C6.33333 36.33 3 32.1638 3 25.9144V22.9772"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0333 15.8171L23.1501 18.2838C24.7167 19.2338 24.7167 20.7672 23.1501 21.7172L19.0333 24.1838C17.3667 25.1838 16 24.4172 16 22.4672V17.5172C16 15.5838 17.3667 14.8171 19.0333 15.8171Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const GooglePlaySVG = ({ stroke, fill, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "40"}
      height={height ?? "40"}
      viewBox="0 0 40 40"
      fill={fill ?? "none"}
      stroke={stroke ?? "none"}
    >
      <path
        d="M35.6669 21.5837L28.1336 25.3503L22.8169 20.0337L28.1836 14.667L35.6669 18.417C36.9836 19.067 36.9836 20.9337 35.6669 21.5837Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 15.1004V5.11711C3.3335 3.78377 4.71683 2.93374 5.91683 3.51707L28.2002 14.6671L22.8335 20.0337L6.86683 36.0004L5.90016 36.4838C4.71683 37.0671 3.3335 36.2171 3.3335 34.8838V22.6171"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.15 25.3499L6.8667 35.9999L22.8334 20.0332L28.15 25.3499Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8336 20.0332L6.81689 4.0166"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1999 25.3996L28.1499 25.3496"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PaystackSVG = ({ fill = "white", width, height }: IconProps) => {
  return (
    <svg
      id="Group_3700"
      data-name="Group 3700"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "40"}
      height={height ?? "40"}
      viewBox="0 0 40 40"
    >
      <path
        d="M35.8689 0H2.15213C1.07607 0 0.0896722 0.902934 0.0896722 2.07675V5.86907C0.0896722 7.04289 1.07607 8.03612 2.15213 8.03612H35.8689C37.0346 8.03612 37.9314 7.04289 38.021 5.86907V2.16704C38.021 0.902934 37.0346 0 35.8689 0ZM35.8689 21.3093H2.15213C1.6141 21.3093 1.07607 21.5801 0.627706 21.9413C0.269017 22.3025 0 22.8442 0 23.4763V27.2686C0 28.4424 0.986395 29.4357 2.06246 29.4357H35.7792C36.945 29.4357 37.8417 28.5327 37.9314 27.2686V23.4763C38.021 22.2122 37.0346 21.3093 35.8689 21.3093ZM21.1626 31.8736H2.15213C1.6141 31.8736 1.07607 32.0542 0.717378 32.5056C0.358689 32.8668 0.0896722 33.4086 0.0896722 34.0406V37.833C0.0896722 39.0068 1.07607 40 2.15213 40H21.073C22.2387 40 23.1354 39.0068 23.1354 37.833V33.9503C23.2251 32.8668 22.3284 31.8736 21.1626 31.8736ZM38.021 10.6546H2.15213C1.6141 10.6546 1.07607 10.8352 0.717378 11.2867C0.358689 11.6479 0.0896722 12.1896 0.0896722 12.8217V16.614C0.0896722 17.7878 1.07607 18.781 2.15213 18.781H37.9314C39.0971 18.781 39.9938 17.7878 39.9938 16.614V12.8217C40.0835 11.5576 39.1868 10.6546 38.021 10.6546Z"
        fill={fill}
      />
    </svg>
  )
}

export const StripeSVG = ({ fill, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "42"}
      height={height ?? "42"}
      viewBox="0 0 85.557 35.484"
    >
      <g id="stripe-3" transform="translate(0 -0.667)">
        <path
          id="Path_1170"
          data-name="Path 1170"
          d="M8.941,16.629c-1.844-.682-2.854-1.212-2.854-2.046,0-.707.581-1.111,1.616-1.111a12.425,12.425,0,0,1,5.177,1.389l.758-4.672A14.559,14.559,0,0,0,7.4,8.85a7.994,7.994,0,0,0-5.152,1.591,5.66,5.66,0,0,0-2,4.546c0,3.435,2.1,4.9,5.506,6.137,2.2.783,2.93,1.339,2.93,2.2,0,.833-.707,1.313-2,1.313A13.912,13.912,0,0,1,.758,22.842L0,27.564a15.394,15.394,0,0,0,7.021,1.692A8.571,8.571,0,0,0,12.4,27.716a5.864,5.864,0,0,0,2.147-4.874c0-3.511-2.147-4.975-5.607-6.213Zm17.941-2.753.758-4.647h-4V3.587l-5.378.885-.777,4.756-1.892.307-.708,4.34h2.592v9.117c0,2.374.606,4.016,1.844,5.026a7.164,7.164,0,0,0,4.622,1.237,11.63,11.63,0,0,0,3.283-.455V23.877a8.618,8.618,0,0,1-1.818.278c-1.237,0-1.768-.631-1.768-2.071V13.876h3.242ZM38.894,8.957a3.736,3.736,0,0,0-3.738,2.6l-.379-2.324H29.3V28.878H35.56V16.123a4.037,4.037,0,0,1,3.41-1.307,6,6,0,0,1,1.111.076V9.109A5.1,5.1,0,0,0,38.894,8.957Zm5.859-1.673a3.308,3.308,0,1,0-3.309-3.308,3.3,3.3,0,0,0,3.309,3.308ZM41.6,9.229h6.289V28.878H41.6ZM65.706,11A5.5,5.5,0,0,0,61.084,8.85a6.521,6.521,0,0,0-4.9,2.349l-.328-1.97H50.35V36.151l6.263-1.035V28.8a9.569,9.569,0,0,0,2.829.455,7.472,7.472,0,0,0,5.607-2.323c1.692-1.844,2.551-4.7,2.551-8.461,0-3.334-.632-5.859-1.894-7.476ZM60.5,23.119a2.459,2.459,0,0,1-2.2,1.465,3.706,3.706,0,0,1-1.692-.379V14.861a3.408,3.408,0,0,1,2.374-1.237c1.591,0,2.374,1.717,2.374,5.076a9.076,9.076,0,0,1-.859,4.42Zm25.054-4.243a11.881,11.881,0,0,0-2.021-7.349A7.2,7.2,0,0,0,77.525,8.85c-5.329,0-8.637,3.94-8.637,10.254,0,3.536.884,6.188,2.626,7.88,1.566,1.515,3.814,2.273,6.718,2.273a12.69,12.69,0,0,0,6.718-1.667L84.269,23.3a11.116,11.116,0,0,1-5.354,1.288,3.826,3.826,0,0,1-2.652-.783A3.942,3.942,0,0,1,75.1,21.048h10.38c.025-.3.076-1.717.076-2.172ZM75.05,17.209c.177-2.8.934-4.117,2.374-4.117,1.414,0,2.147,1.339,2.248,4.117Z"
          fill={fill ?? "none"}
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

import { Skeleton } from "antd"
import EmptyTopTracks from "assets/images/general/empty-top-tracks.svg"
import NoTopTracksImage from "assets/images/general/top-tracks-image.png"
import {
  AlbumCard,
  DataDisplayCard,
  FlexibleDiv,
  Loader,
  NoDataDisplay,
  PlaylistCard,
  Section,
} from "components"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { take } from "lodash"
import {
  getArtistAlbums,
  getArtistProfile,
  getArtistTopTracks,
  getPlaylistFeatArtist,
  getPlaylistsByQuery,
  getSimilarArtistTracks,
} from "network"
import { SearchCategoryFilter } from "pages/Search"
import { artistAlbumCategoryFilter } from "pages/Search/components/SearchFilter/data-helper"
import { useState } from "react"
import { useQueries, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { capitalize } from "utils"
import { ArtistProfileJumbotron } from "../components"
import { MLWrap } from "../components/MetricsLayout/metrics-layout.styles"
import { ArtistProfileWrap } from "./artist-profile.styles"

export const ArtistProfile = () => {
  const { artistId } = useParams()
  const [artistAlbumFilter, setArtistAlbumFilter] = useState("all")
  const { data, isLoading } = useQuery(["artist-profile", artistId], () =>
    getArtistProfile(artistId ?? "")
  )

  const artistProfileInfo = useQueries([
    {
      queryKey: ["fetch-artists-top-tracks", artistId],
      queryFn: () => getArtistTopTracks(artistId ?? "", `sortByPopularity=asc`),
    },
    {
      queryKey: ["fetch-artists-albums", artistId],
      queryFn: () =>
        getArtistAlbums(artistId ?? "", `sortByPopularity=asc&released=true`),
    },
    {
      queryKey: ["playlists-feat-artist", artistId],
      queryFn: () => getPlaylistFeatArtist(artistId ?? ""),
    },
    {
      queryKey: ["playlists-created-by-artist", artistId],
      queryFn: () => getPlaylistsByQuery(`artist=${artistId}`),
    },
    {
      queryKey: ["similar-artist-tracks", artistId],
      queryFn: () =>
        getSimilarArtistTracks(`artist=${artistId}`, "sortByPopularity=asc"),
    },
  ])

  const isFetchingArtistInfo = artistProfileInfo.some(o => o.isLoading)
  const artistTopTracks = artistProfileInfo[0].data
  const artistAlbums = artistProfileInfo[1].data
  const playlistsFeatArtist = artistProfileInfo[2].data
  const playlistsCreatedByArtist = artistProfileInfo[3].data
  const similarArtistTracks = artistProfileInfo[4].data

  const artistData = data?.data
  const bg = artistData?.coverArt?.url
  const profilePicture = artistData?.profilePicture?.url
  const dominantColors = artistData?.coverArtDominantColors

  return (
    <PlayerLayout>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <ArtistProfileWrap>
          <Section
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <ArtistProfileJumbotron
              bg={bg}
              artist={artistData}
              profilePicture={profilePicture}
              dominantColors={dominantColors}
            />

            <MLWrap>
              {isFetchingArtistInfo ? (
                <FlexibleDiv minHeight="300px">
                  <Skeleton active={isFetchingArtistInfo} />
                </FlexibleDiv>
              ) : (
                !!artistTopTracks?.length && (
                  <FlexibleDiv
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="display__row top__tracks__recent__play"
                  >
                    <FlexibleDiv
                      flexWrap="nowrap"
                      alignItems="center"
                      className="explorer__box explorer__box__mobile"
                      justifyContent="flex-start"
                    >
                      <DataDisplayCard
                        emptyBg={bg || NoTopTracksImage}
                        emptyDisplayIcon={EmptyTopTracks}
                        emptyText="Please wait while we curate this content for you."
                        header={
                          <FlexibleDiv className="section__display__controls">
                            <h1 className="title">
                              {capitalize(artistData?.stageName)}'s Top Tracks
                            </h1>

                            <span className="see__all">See All</span>
                          </FlexibleDiv>
                        }
                        isEmpty={artistTopTracks?.length === 0}
                        cardData={artistTopTracks}
                        isLarge
                      />
                    </FlexibleDiv>
                  </FlexibleDiv>
                )
              )}

              {isFetchingArtistInfo ? (
                <FlexibleDiv minHeight="280px">
                  <Skeleton active={isFetchingArtistInfo} />
                </FlexibleDiv>
              ) : (
                !!artistAlbums?.length && (
                  <FlexibleDiv
                    flexDir="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="display__row"
                  >
                    <FlexibleDiv className="section__display__controls">
                      <h1 className="title">
                        Explore more by {capitalize(artistData?.stageName)}
                      </h1>

                      <div className="category__filter__content">
                        <SearchCategoryFilter
                          setActiveLabel={aac => setArtistAlbumFilter(aac)}
                          filterBy={artistAlbumCategoryFilter}
                          activeLabel={artistAlbumFilter}
                        />
                      </div>
                    </FlexibleDiv>

                    <FlexibleDiv
                      flexWrap="nowrap"
                      alignItems="center"
                      className="explorer__box"
                      justifyContent="flex-start"
                    >
                      {getFilteredAlbums(artistAlbums)}
                    </FlexibleDiv>
                  </FlexibleDiv>
                )
              )}

              {isFetchingArtistInfo ? (
                <FlexibleDiv minHeight="300px" style={{ columnGap: "5%" }}>
                  <Skeleton
                    active={isFetchingArtistInfo}
                    style={{ width: "45%" }}
                  />
                  <Skeleton
                    active={isFetchingArtistInfo}
                    style={{ width: "45%" }}
                  />
                </FlexibleDiv>
              ) : (
                !!playlistsFeatArtist?.length && (
                  <FlexibleDiv
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="display__row top__tracks__recent__play"
                  >
                    <FlexibleDiv className="section__display__controls">
                      <h1 className="title">
                        Playlists feat {capitalize(artistData?.stageName)}
                      </h1>

                      <span className="see__all">See All</span>
                    </FlexibleDiv>

                    <FlexibleDiv
                      flexWrap="nowrap"
                      alignItems="center"
                      className="explorer__box explorer__box__mobile"
                      justifyContent="flex-start"
                    >
                      {playlistsFeatArtist.map((pfa, idx) => (
                        <DataDisplayCard
                          key={`${idx}-${pfa.id}`}
                          emptyDisplayIcon={EmptyTopTracks}
                          emptyText="Please wait while we curate this content for you."
                          cardData={pfa.tracks.map(t => t.track)}
                          playlist={pfa}
                          isPlaylist
                          isLarge
                        />
                      ))}
                    </FlexibleDiv>
                  </FlexibleDiv>
                )
              )}

              {isFetchingArtistInfo ? (
                <FlexibleDiv minHeight="280px">
                  <Skeleton active={isFetchingArtistInfo} />
                </FlexibleDiv>
              ) : (
                !!playlistsCreatedByArtist?.length && (
                  <FlexibleDiv
                    flexDir="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="display__row"
                  >
                    <FlexibleDiv className="section__display__controls">
                      <h1 className="title">
                        Curated playlist by {capitalize(artistData?.stageName)}{" "}
                        just for you
                      </h1>

                      <span className="see__all">See All</span>
                    </FlexibleDiv>

                    <FlexibleDiv
                      flexWrap="nowrap"
                      alignItems="center"
                      className="explorer__box"
                      justifyContent="flex-start"
                    >
                      {playlistsCreatedByArtist.map((pcba, idx) => (
                        <PlaylistCard key={`${idx}-${pcba.id}`} {...pcba} />
                      ))}
                    </FlexibleDiv>
                  </FlexibleDiv>
                )
              )}

              {isFetchingArtistInfo ? (
                <FlexibleDiv minHeight="300px" style={{ columnGap: "5%" }}>
                  <Skeleton
                    active={isFetchingArtistInfo}
                    style={{ width: "45%" }}
                  />
                  <Skeleton
                    active={isFetchingArtistInfo}
                    style={{ width: "45%" }}
                  />
                </FlexibleDiv>
              ) : (
                !!similarArtistTracks?.length && (
                  <FlexibleDiv
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="display__row top__tracks__recent__play"
                  >
                    <FlexibleDiv className="section__display__controls">
                      <h1 className="title">
                        Similar artists to {capitalize(artistData?.stageName)}
                      </h1>

                      <span className="see__all">See All</span>
                    </FlexibleDiv>

                    <FlexibleDiv
                      flexWrap="nowrap"
                      alignItems="center"
                      className="explorer__box explorer__box__mobile"
                      justifyContent="flex-start"
                    >
                      {take(similarArtistTracks, 10)
                        .sort((a, b) => b.tracks.length - a.tracks.length)
                        .map(sat => (
                          <DataDisplayCard
                            key={sat.artist.id}
                            emptyDisplayIcon={EmptyTopTracks}
                            emptyText="Please wait while we curate this content for you."
                            artistData={sat.artist}
                            cardData={sat.tracks}
                            isArtist
                            isLarge
                          />
                        ))}
                    </FlexibleDiv>
                  </FlexibleDiv>
                )
              )}
            </MLWrap>
          </Section>
        </ArtistProfileWrap>
      )}
    </PlayerLayout>
  )

  function getFilteredAlbums(list: Array<any>) {
    if (list?.length) {
      if (artistAlbumFilter === "all") {
        return list.map((l, idx) => <AlbumCard key={`${idx}-${l.id}`} {...l} />)
      }

      const filtered = list.filter(
        l =>
          l.type.toLowerCase() === artistAlbumFilter ||
          (l.type.toLowerCase() === "ep" && artistAlbumFilter === "album")
      )

      return filtered.length ? (
        filtered.map((l, idx) => <AlbumCard key={`${idx}-${l.id}`} {...l} />)
      ) : (
        <NoDataDisplay
          height={"280px"}
          text={`No results based on "${artistAlbumFilter}" filter`}
        />
      )
    }
  }
}

import React from "react"
import { useMediaQuery } from "react-responsive"
import { ICWrap } from "./invitation-card.styles"

export type InvitationCardProps = {
  idx?: number
  photo: string
  invitedByName?: string
  dateOfInvite?: string
  playlistName?: string
}

export const InvitationCard = ({
  photo,
  dateOfInvite,
  playlistName,
  invitedByName,
  ...props
}: InvitationCardProps) => {
  const isMobile = useMediaQuery({ maxWidth: 600 })

  return (
    <ICWrap {...props}>
      <React.Fragment>
        {isMobile ? (
          <React.Fragment>
            <div className="mobile__display__only">
              <div className="name__and__cover">
                <div className="cover__art">
                  <img src={photo} alt="cover" />
                </div>
              </div>
              <div className="mobile__title__and__album">
                <p className="mobile__title">{playlistName}</p>
                <p className="mobile__artist">{invitedByName}</p>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="name__and__cover">
              <div className="title__and__album">
                <p className="title">{playlistName}</p>
              </div>
            </div>
            <div className="artist">{invitedByName}</div>
            <div className="artist">{dateOfInvite}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    </ICWrap>
  )
}

import { InputIcon } from "assets/images/Auth"
import BackgroundImage from "assets/images/payment/ChoosePlatformImage.png"
import authSignUp from "assets/images/payment/signUpImage2.png"
import WeNeedSomeInfoBng from "assets/images/payment/we_need_some_info.png"
import { FlexibleDiv } from "components"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { ActionBox } from "components/lib/ActionBox/ActionBox"
import React from "react"
import { AiOutlineUserAdd } from "react-icons/ai"
import { useNavigate, useSearchParams } from "react-router-dom"
import { PaymentAuthenticationBox } from "./paymentAuthentication.styles"

const PaymentAuthentication = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"
  const [activeSlide, setActiveSlide] = React.useState(1)
  const handleActionTileHover = (slideIndex: number) => {
    setActiveSlide(slideIndex)
  }
  const cardsObjects = [
    {
      redirectURL: () => navigate(`/payment/login?type=${plan}&mode=${mode}`),
      boxIcon: <InputIcon />,
      text: "Existing User? Login",
      pageHoverImage: WeNeedSomeInfoBng,
    },
    {
      redirectURL: () =>
        navigate(`/payment/register?type=${plan}&mode=${mode}`),
      boxIcon: <AiOutlineUserAdd />,
      text: "Sign up for Free",
      pageHoverImage: authSignUp,
    },
  ]

  return (
    <ChoosePlatform
      galleryImages={cardsObjects}
      activeSlide={activeSlide}
      accentColor="var(--primaryYellow)"
      rightImage={BackgroundImage}
      backURL={`/payment/plan?type=${plan}&mode=${mode}`}
      hideSubscriptionBox
    >
      <PaymentAuthenticationBox>
        <FlexibleDiv className="info__desc">
          <h2>We need some more info</h2>
          <p>
            To proceed with the subscription, we’ll need you to either Login or
            Create an account{" "}
          </p>
        </FlexibleDiv>

        {cardsObjects.map((card, idx) => (
          <ActionBox
            key={idx}
            redirectURL={card.redirectURL}
            setHoverImage={() => handleActionTileHover(idx)}
            boxIcon={card.boxIcon}
            text={card.text}
          />
        ))}
      </PaymentAuthenticationBox>
    </ChoosePlatform>
  )
}

export default PaymentAuthentication

import {
  FsePausePlayIcon,
  FsePlayingIcon,
  NextOutlineIcon,
  
} from "assets";
import {
  FlexibleDiv,
} from "components";
import { useMainContext } from "context";
import { isEmpty } from "lodash";
import React, {  useState } from "react";
import {
  getDisplayArtist,
  getTrackTitle,
  pauseAudio,
  playAudio,
  playNextTrack,
} from "utils";
import { NowPlayingMobileMiniPlayerWrap } from "./mini-player.styles";
import { useMediaQuery } from "react-responsive";

export type NowPlayingMiniPlayerProps = {
  handleMaximise?: () => void;
}
export const NowPlayingMiniPlayer = React.memo(({handleMaximise}: NowPlayingMiniPlayerProps) => {
  const {
    state: { player },
    playerRef,
    dispatch,
  } = useMainContext();
  const [playerMode] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const {
    isPlaying,
    currentTrack,
  } = player;
  const domColors = currentTrack?.metadata?.coverArtDominantColors

  return (
    <NowPlayingMobileMiniPlayerWrap
      playerMode={playerMode}
      dominantColors={domColors}
      isMobile={isMobile}
      onClick={handleMaximise}
    >
      <FlexibleDiv
        justifyContent="space-between"
        alignItems="center"
        className="mini__player"
      >
        <FlexibleDiv className="track__image__wrap">
          <div className="name__and__cover">
            <div className="cover__art">
              <img
                src={getImageDisplay()}
                alt={`${getTrackTitle(currentTrack, true)} cover`}
              />
            </div>
            <div className="title__and__album__artist">
              {currentTrack && (
                <p className="title">{getTrackTitle(currentTrack, true)}</p>
              )}
              <p className="artist">{getDisplayArtist(currentTrack)}</p>
            </div>
          </div>
        </FlexibleDiv>
        <FlexibleDiv className="track__controls__wrap">
          <FlexibleDiv justifyContent="center" className="controls__main">
            {isPlaying ? (
              <div
                className="control__icons pause__icon"
                onClick={() => pauseAudio(playerRef, dispatch)}
              >
                <FsePlayingIcon width={25} height={25} />
              </div>
            ) : (
              <div
                className="control__icons play__icon"
                onClick={() => playAudio(playerRef, dispatch)}
              >
                <FsePausePlayIcon width={25} color="#fff" height={25} />
              </div>
            )}

            <div
              className="control__icons"
              onClick={() => {
                playNextTrack(dispatch, {
                  track: currentTrack,
                });
              }}
            >
              <NextOutlineIcon width={25} height={25} />
            </div>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </NowPlayingMobileMiniPlayerWrap>
  );

  function getImageDisplay() {
    if (!isEmpty(currentTrack)) {
      if (currentTrack?.track?.metadata) {
        return currentTrack.track.metadata.coverArt.url;
      }

      if (currentTrack?.metadata) {
        return currentTrack.metadata.coverArt.url;
      }
    }
  }
});

NowPlayingMiniPlayer.displayName = "NowPlayingMiniPlayer";

import { FlexibleDiv, FlexibleDivProps } from "components/lib/Box";
import styled, { css } from "styled-components";

// Define different sizes for the cards
const largeCardStyles = css`
  width: 320px;
  .image__wrap {
    height: 300px;
  }

  .title {
    font-size: 1rem;
    font-weight: 800;
  }
  .subtitle {
    font-size: 1.1rem;
    font-weight: 500;
  }

  @media screen and (max-width: 950px) {
    width: 290px;
    .image__wrap {
      height: 280px;
    }
  }
`;

const smallCardStyles = css`
  width: 220px;
  .image__wrap {
    height: 220px;
  }

  .title {
    font-size: 1rem;
    font-weight: 800;
  }
  .subtitle {
    font-size: 0.9rem;
    font-weight: 500;
  }

  @media screen and (max-width: 950px) {
    width: 180px;
    .image__wrap {
      height: 180px;
    }
  }
`;
const chartCardStyles = css<{ isActive?: boolean }>`
  width: 220px;
  position: relative;
  border-radius: 4px 4px 0 0;

  .image__wrap {
    height: 235px;
    border-radius: 4px;
    &:hover {
      border: 1px solid #fcb816; /* Border applied only when the card is hovered */
      border-image-slice: 1;
      border-image-source: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%);
    }
  }

  /* Badge for rank */
  .rank-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--uduxYellowPrimary); /* Customize as per design */
    color: black;
    padding: 5px 10px;
    font-size: 1rem;
    font-weight: bold;
  }

  .title {
    font-size: 1rem;
    font-weight: 800;
  }

  .subtitle {
    font-size: 0.9rem;
    font-weight: 500;
  }

  @media screen and (max-width: 950px) {
    width: 180px;

    .image__wrap {
      height: 210px;
    }
  }
`;

export const PITWrap = styled(FlexibleDiv)<FlexibleDivProps & { size?: "large" | "small" | "chart"; isActive?: boolean }>`
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;

  &:hover .card__play__btn {
    /* height: 100%; */
    opacity: 1;
    transform: scale(1.1);

    svg {
      display: inline-flex;
    }
  }

  &:hover .card__footer{
    z-index: 30;
  }

  .card__play__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: flex-end;
    z-index: 20;
    justify-content: flex-end;
    opacity: 0;
    padding-right: 10%;
    padding-bottom: 120px;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.3s ease, transform 0.3s ease;

    .icon__wrap {
      width: 55px;
      height: 55px;
      width: ${({ size }) => (size === "large" ? "50px" : "45px")};
      height: ${({ size }) => (size === "large" ? "50px" : "45px")};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
      background: var(--primaryYellow);
    }

    svg {
      display: none;
    }
  }

  .image__wrap {
    overflow: hidden;
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  .card__footer {
    height: 100px;
    width: 100%;
    min-height: 100px;
    position: relative;
    .title {
      font-family: "Bw Modelica", sans-serif;
      color: var(--uduxWhitePrimary);
      margin: 15px 0 7px 0;
      text-align: center;
      line-height: 1.2em;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .subtitle {
      margin: 0;
      text-align: center;
      color: #717171;
      font-family: "Bw Modelica", sans-serif;
    }
  }

  /* Apply the conditional styling with largeCardStyles as default */
  ${(props) =>
    props.size === "small"
      ? smallCardStyles
      : props.size === "chart"
      ? chartCardStyles
      : largeCardStyles}
`;

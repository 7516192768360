import BackgroundImage from "assets/images/payment/ChoosePlatformImage.png"
import { Button, FlexibleDiv, PhoneInput } from "components"
import { ChoosePlatform } from "components/layouts/AuthLayout-Platform/ChoosePlatform"
import { Types, useMainContext } from "context"
import { checkAvailablePhone, subscribeMTNPhone } from "network"
import { useEffect, useState, Fragment, useMemo } from "react"
import { useMutation, useQuery } from "react-query"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { subscriptionPlans } from "utils"
import { RegisterContent } from "./PaymentMTNConfirmationPage.styles"
import { getBillingPlans } from "network"
import { EnrichedData } from "types"
import DailySubBng from "assets/images/payment/daily-sub.png"

const PaymentMTNConfirmationPage = () => {
  const navigate = useNavigate()
  const { state } = useLocation() as any
  const [, setCountryCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const { data: cpeData, refetch } = useQuery(
    ["check-phone-exists", phoneNumber],
    async () => checkAvailablePhone(phoneNumber),
    {
      enabled: !!phoneNumber,
    }
  )
  const {
    dispatch,
    state: { app, users },
  } = useMainContext()
  const { mutate, isLoading } = useMutation(subscribeMTNPhone)
  const [params] = useSearchParams()
  const mode = params.get("mode") || "udux" || state?.mode
  const plan = params.get("type") || "weekly" || state?.plan
  const msisdnParam = params.get("msisdn")

  const { me } = users
  const isMtnUser = Boolean(app?.isMtn)
  const udx__pay__esn = JSON.parse(
    localStorage.getItem("udx__pay__esn") || "{}"
  ) as EnrichedData

  const msisdn = useMemo(() => {
    if (isMtnUser) {
      return app?.msisdn
    } else if (udx__pay__esn?.isMtn) {
      return udx__pay__esn?.msisdn
    } else {
      return msisdnParam ?? ""
    }
  }, [app?.msisdn, isMtnUser, udx__pay__esn, msisdnParam])

  useEffect(() => {
    if (msisdn) {
      setPhoneNumber(msisdn.startsWith("+") ? msisdn : `+${msisdn}`)
    }
  }, [msisdn])

  const fetchPlanId = async () => {
    try {
      const billingPlans = await getBillingPlans()

      const planSelected = billingPlans.find(
        singlePlan =>
          singlePlan["interval"] === plan &&
          singlePlan["provider"] === mode.toUpperCase()
      )

      return planSelected?.id
    } catch (err) {
      console.log("err: ", err)
    }
  }

  const subscribeMTNNumber = async (value: string) => {
    const planId = await fetchPlanId()

    if (planId) {
      const payload = {
        planId,
        msisdn: value.startsWith("+") ? value.substring(1) : value,
      }

      if (phoneNumber && !cpeData?.exists) {
        await refetch()
      }

      console.log({ value, me })

      if (value === me?.phoneNumber) {
        dispatch({
          type: Types.MSISDN_USER_HAS_ACCOUNT,
          payload: cpeData?.exists,
        })

        console.log({ payload })

        mutate(payload, {
          onSuccess: () => {
            navigate(`/payment/mtn/check-phone?type=${plan}&mode=${mode}`, {
              state: {
                plan,
                mode,
              },
            })
          },
          onError: error => {
            dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
          },
        })
      } else {
        // The user has a different phone number attached to their account from what they've provided or browsing with
        return dispatch({
          type: Types.API_RESPONSE_ERROR,
          payload:
            "Sorry, we cannot proceed with this subscription for this account!",
        })
      }
    }
  }

  return (
    <Fragment>
      {Object.keys(subscriptionPlans).includes(plan) && (
        <ChoosePlatform
          galleryImages={[{ pageHoverImage: DailySubBng }]}
          rightImage={BackgroundImage}
          backURL={`/payment`}
        >
          <RegisterContent
            width="100%"
            height="100%"
            flexDir="column"
            justifyContent="space-between"
          >
            <p className="confirm__text">
              Please Confirm this is your MTN Number
            </p>

            <FlexibleDiv
              width="90%"
              height="fit-content"
              className="payment__method__wrapper"
            >
              <PhoneInput
                value={phoneNumber}
                onChange={e => {
                  setPhoneNumber(e)
                }}
                onSetCountryCode={code => setCountryCode(code)}
                bordered
                small
              />
            </FlexibleDiv>

            <Button
              loading={isLoading}
              className="subscribe__btn"
              onClick={() => subscribeMTNNumber(phoneNumber)}
            >
              <span>Subscribe me to Udux</span>
            </Button>
            <Link
              className="change__method__text"
              to={`/payment/plan?type=${plan}&mode=${mode}`}
            >
              Change Payment Method
            </Link>
          </RegisterContent>
        </ChoosePlatform>
      )}
    </Fragment>
  )
}

export default PaymentMTNConfirmationPage

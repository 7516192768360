import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { FlexibleDiv, MediaCard } from "components";
import { TopCollabWrap } from "./top-collab.styles";
import { Skeleton } from "antd";
import { useQuery } from "react-query";
import { getTopCollabPlaylists } from "network";
import { useNavigate } from "react-router-dom";
export const TopCollab = () => {
  const navigate = useNavigate();
  const { data: topCollabs, isLoading } = useQuery(
    ["top-collab"],
    getTopCollabPlaylists
  );
  return (
    <PlayerLayout>
      <TopCollabWrap>
        <FlexibleDiv alignItems="flex-start" className="header">
          <h4 className="subheader">Top Collab</h4>
        </FlexibleDiv>
        <div className="top__collabs">
          {isLoading ? (
            <FlexibleDiv minHeight="300px">
              <Skeleton active={isLoading} />
            </FlexibleDiv>
          ) : (
            topCollabs?.data?.map((pl, index) => {
              return (
                <MediaCard
                  type="collab"
                  key={index}
                  collabPlaylist={pl}
                  handleNavigate={() =>
                    navigate(`/playlist/${pl.id}?t=pl`, {
                      state: {
                        module: "playlist",
                      },
                    })
                  }
                />
              );
            })
          )}
        </div>
      </TopCollabWrap>
    </PlayerLayout>
  );
};

import { User } from "context"
import { Artist } from "./artist"

export type FollowRecord = {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  status: string
  entityFollowedType: string
  followedUser: User
  followedArtist: Artist
  follower: User
}

export enum ReactionType {
  TUALE = "TUALE",
  LIKE = "LIKE",
  ADD_TO_LIBRARY = "ADD_TO_LIBRARY",
}

export type InviteCollabUserPayload = {
  playlistId: string
  emailAddress?: string
  userId?: string
  message?: string
}

export type Collaboration = {
  id: string
  createdAt: Date
  updatedAt: Date
  deleted: true
  expires: number
  status: CollabRequestStatus
  message: string
  sender: CollabRequestRecSen
  receiver: CollabRequestRecSen
  potentialNewUserEmail: string
  playlist: string
}

// Collab request receiver or sender
export type CollabRequestRecSen = {
  id: string
  emailAddress: string
  username: string
}

export type CollabPlaylistEngagement = {
  user: User
  totalSpins: number
  totalTuales: number
  totalStreamTime: number
}

export type CollabPlaylistStat = {
  user: User
  totalSpins: number
  totalTuales: number
  totalStreamTime: number
}

export enum CollabRequestStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
}

import {
  HomeIcon,
  SearchIcon,
  QueueIcon,
  ArtistCatalogueIcon,
  RecommendedIcon,
  AddToPlaylistIcon,
  DiscoverIcon,
  CreatePlaylistIcon,
  ShareIcon2,
  HistoryIcon,
  RecentlyPlayedIcon,
  LikedSongsIcon,
  LibraryIcon,
  CollabIcon,
  TopAlbumIcon,
  TopSongsIcon,
} from "assets";

type NavItem = {
  path: string;
  title: string;
  icon: JSX.Element;
  children?: NavItem[];
};

type NavSection = {
  title: string;
  sectionItems: NavItem[];
};

export const getPlayerSidebarNavItems = (
  isLoggedIn: boolean,
  isMobile = false
): NavSection[] => {
  const style =
    isMobile && isLoggedIn
      ? {
          transform: "none",
        }
      : {};

  const navList: NavSection[] = [
    {
      title: "General",
      sectionItems: [
        {
          path: "/",
          title: "Home",
          icon: <HomeIcon width={25} style={style} />,
        },
        {
          path: "/discover",
          title: "Discover",
          icon: <DiscoverIcon width={25} style={style} />,
        },
        {
          path: "/search",
          title: "Search",
          icon: <SearchIcon width={25} style={style} />,
        },
      ],
    },
    {
      title: "Your Music",
      sectionItems: [
        {
          path: "/top-songs",
          title: "Top Songs",
          icon: <TopSongsIcon  width={24} height={24} style={style} />,
        },
        {
          path: "/top-album",
          title: "Top Albums",
          icon: <TopAlbumIcon  width={20} height={20}  />,
        },
        {
          path: "/top-collab",
          title: "Top Colab",
          icon: <CollabIcon color="#929292" width={25}  />,
        },
      ],
    },
  ];

  if (isLoggedIn) {
    navList[1].sectionItems = [
      ...navList[1].sectionItems,
      {
        path: "/library",
        title: "Your Library",
        icon: <LibraryIcon width={25} style={style} />,
      },
      {
        path: "/liked",
        title: "Liked Songs",
        icon: <LikedSongsIcon width={25} style={style} />,
      },
      {
        path: "/recently-played",
        title: "Recently Played",
        icon: <RecentlyPlayedIcon width={25} style={style} />,
      },
      {
        path: "/create-playlist",
        title: "Create Playlist",
        icon: <AddToPlaylistIcon width={25} style={style} />,
        children: [
          {
            path: "/create-new-playlist",
            title: "Create New Playlist",
            icon: <CreatePlaylistIcon width={25} style={style} />,
          },
          {
            path: "/create-collab-playlist",
            title: "Create Collab Playlist",
            icon: <AddToPlaylistIcon width={25} style={style} />,
          },
        ],
      },
      {
        path: "/history",
        title: "History",
        icon: <HistoryIcon width={25} style={style} />,
      },
      {
        path: "/share",
        title: "Share",
        icon: <ShareIcon2 width={25} style={style} />,
      },
    ];
  }

  return navList;
};


// export const playlist = [
//   "In the office... ",
//   "Meeting Prep ",
//   "Made in Naija ",
//   "Daily Commute",
//   "Beach Vibes ",
//   "CEO things ",
//   "Naija is  ",
// ];

export const playlist = [
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=1",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=2",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=3",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
  {
    id: 1,
    img: "https://picsum.photos/500/300?random=5",
    text: "In the office... ",
  },
]

export const nowPlayingRightSideItems = [
  {
    title: "Artist Catalogue",
    icon: <ArtistCatalogueIcon width={18} />,
  },

  {
    title: "Recommended",
    icon: <RecommendedIcon width={18} />,
  },
  {
    title: "Add to Playlist",
    icon: <AddToPlaylistIcon width={18} />,
  },
  {
    title: "Manage Queue",
    icon: <QueueIcon width={18} />,
  },
]

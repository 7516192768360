import { Config } from "config"
import { SourceService, Types, useMainContext } from "context"
import { signUp } from "network/authenticationServices"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { SignUpPayload } from "types"

// Custom hook for login service
export const useRegisterService = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { dispatch } = useMainContext()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"

  return useMutation(
    async (payload: SignUpPayload) => {
      return signUp({
        ...payload,
        sourceService: Config.sourceService as SourceService,
      })
    },
    {
      onSuccess: ({ data }) => {
        if (data) {
          const { user, tokens } = data
          const pathToReturn = `/payment/confirm?type=${plan}&mode=${mode}`

          // Store user and tokens
          localStorage.setItem("udx__user", JSON.stringify(user))
          localStorage.setItem("udx__tokens", JSON.stringify(tokens))

          // Update the global state
          dispatch({ type: Types.CURRENT_USER, payload: user })

          // Navigate to the confirmation page
          navigate(pathToReturn, {
            state: { prevRoute: `/payment/plan?type=${plan}&mode=${mode}` },
          })
        }
      },
      onError: error => {
        // Dispatch the error to the global state
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    }
  )
}

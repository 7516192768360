import { isEmpty } from "lodash"
import { DisplayArtist, Track } from "types/artist"

export function getArtist(currentTrack?: Track): DisplayArtist | undefined {
  if (!isEmpty(currentTrack)) {
    return currentTrack?.track
      ? currentTrack?.track?.metadata?.displayArtists?.find(
          (da: DisplayArtist) => da.isMainArtist
        )
      : currentTrack?.metadata?.displayArtists?.find(da => da.isMainArtist)
  } else return undefined
}

export function getArtistId(artist: DisplayArtist) {
  return artist.artist.id
}

export function getArtistStageName(artist: DisplayArtist) {
  return artist.artist.stageName
}

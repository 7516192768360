import React from "react"
import SignUpImage from "assets/images/payment/payment-sign-up.png"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import { BorderedTextfield, FlexibleDiv, Button } from "components"
import { Form } from "antd"
import {
  validateBasicField,
  validateConfirmPassword,
  validatePassword,
  validateEmail,
} from "utils"
import { Link, useSearchParams } from "react-router-dom"
import { PaymentRegisterWrapper } from "../../paymentRegister.styles"
import { useState } from "react"
import { useEffect } from "react"
import { SignUpPayload } from "types"

const checkHeight = window.innerHeight > 800 ? true : false
const checkSmallScreenHeight = window.innerHeight < 680 ? true : false

export type StepOneProps = {
  getStepOneData: (payload: Partial<SignUpPayload>) => void
}

export const StepOne = ({ getStepOneData }: StepOneProps) => {
  const [signUpForm] = Form.useForm()
  const [params] = useSearchParams()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"

  //I am creating a useState to store all inputs
  //when users navigate away from the page.
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  })

  //Get the data stored in the localStorage
  useEffect(() => {
    const registrationData = localStorage.getItem("registrationData")

    if (registrationData !== null && registrationData !== undefined) {
      setUserData(JSON.parse(registrationData))
    }
  }, [])

  const moveToNextStep = (values: Partial<SignUpPayload>) => {
    //store currently saved data in local storage
    localStorage.setItem("registrationData", JSON.stringify(userData))

    getStepOneData(values)
  }

  return (
    <PaymentRegisterLayout
      checkHeight={checkHeight}
      smallHeight={checkSmallScreenHeight}
      galleryImage={SignUpImage}
      backURL={`/payment/auth?type=${plan}&mode=${mode}`}
    >
      <PaymentRegisterWrapper>
        <Form
          form={signUpForm}
          onFinish={moveToNextStep}
          className="form_content"
        >
          <FlexibleDiv className="signup__form__box">
            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                type="text"
                name="username"
                paddingLeft="5px"
                borderWidth="0.5px"
                placeholder="Username"
                borderColor="var(--dampWhiteLight)"
                font_family={'"Baguede", sans-serif'}
                validator={validateBasicField}
              />
            </FlexibleDiv>

            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                type="email"
                paddingLeft="5px"
                name="emailAddress"
                borderWidth="0.5px"
                placeholder="What's your email"
                borderColor="var(--dampWhiteLight)"
                font_family={'"Baguede", sans-serif'}
                validator={validateEmail}
              />
            </FlexibleDiv>

            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                type="password"
                name="password"
                paddingLeft="5px"
                borderWidth="0.5px"
                placeholder="Enter Password"
                borderColor="var(--dampWhiteLight)"
                font_family={'"Baguede", sans-serif'}
                validator={validatePassword}
              />
            </FlexibleDiv>

            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                type="password"
                paddingLeft="5px"
                borderWidth="0.5px"
                name="confirmPassword"
                placeholder="Re-enter Password"
                borderColor="var(--dampWhiteLight)"
                font_family={'"Baguede", sans-serif'}
                validator={validateConfirmPassword}
              />
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv
            flexDir="column"
            className="btn__box"
            justifyContent="center"
          >
            <Button
              width="180px"
              height="40px"
              font_size="0.8rem"
              type="submit"
              radius="0px"
              color="var(--black)"
              contained
              bold
              ltr
            >
              <span>Confirm Details</span>
            </Button>

            <Link to={`/payment/login?type=${plan}`} className="existing__user">
              Existing user? Login
            </Link>
          </FlexibleDiv>
        </Form>
      </PaymentRegisterWrapper>
    </PaymentRegisterLayout>
  )
}

export default StepOne

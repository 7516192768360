import React, { Ref, forwardRef, memo } from "react"
import { TabContentWrap } from "./tab.styles"

export type TabContentProps = {
  children: React.ReactNode
  className?: string
}

const TabContentRefComponent = forwardRef(
  ({ children, ...props }: TabContentProps, ref: Ref<HTMLDivElement>) => {
    return (
      <TabContentWrap ref={ref} {...props}>
        {children}
      </TabContentWrap>
    )
  }
)

TabContentRefComponent.displayName = "TabContentRefComponent"

export const TabContent = memo(TabContentRefComponent)

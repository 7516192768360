import { Drawer } from "antd"
import { FlexibleDiv } from "components/lib"
import styled from "styled-components"

export const NotificationWidgetWrap = styled(FlexibleDiv)`
  width: max-content;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
  justify-content: space-around;
  flex-wrap: nowrap;

  button {
    flex-grow: 1;
    width: max-content;
    padding-left: 0.5rem;
    outline: 0;
    height: auto;
    border: none;
    display: flex;

    align-items: center;
    justify-content: space-between;
    background: none !important;
    color: var(--highlight);

    span {
      font-size: 1rem;
      opacity: 0.6;
      color: var(--highlight);
      text-transform: capitalize;
      font-family: "Baguede", sans-serif;
    }

    svg {
      font-size: 1.5rem;
    }

    :hover,
    :focus {
      svg {
        color: var(--highlight);
      }
    }
  }

  @media screen and (max-width: 600px) {
    button {
      button {
        width: 50px;
      }
    }
  }
`

export const NotificationDrawer = styled(Drawer)`
  &.notification__drawer {
    height: calc(100vh - var(--headerHeight));
    margin-top: var(--headerHeight);
  }

  &.ant-drawer-content-wrapper {
  }

  &.ant-drawer-content {
    background: var(--primaryBlack);
  }
`

export const NotificationItemWrap = styled(FlexibleDiv)`
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  align-items: flex-start;

  .avatar__icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    & .ant-avatar {
      background: var(--primaryYellow);
    }
  }

  .notification__text {
    font-size: 0.75rem;
    color: var(--primaryWhite);
  }
  .timestamp {
    padding-top: 0.2rem;
    font-size: 0.65rem;
    color: var(--primaryWhite);
    opacity: 0.5;
  }

  .info {
    flex: 1;
    align-items: flex-start;
  }

  .action__wrap {
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-top: 0.65rem;

    button {
      width: 20%;

      span {
        font-size: 0.65rem;
      }

      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    button {
      button {
        width: 50px;
      }
    }
  }
`

import styled from "styled-components"

export interface ArtistPlaylistWrapProps {
  isPlaylist?: boolean
  bgImage?: string
}

export const ArtistPlaylistWrap = styled.div<ArtistPlaylistWrapProps>`
  padding: 0px;
  width: 100%;
  margin-top: 20px;

  .artistPlaylist__card__main {
    background: #000;
    transition: all 0.3s ease;
    height: 450px;
  }

  .main__display__image {
    width: 35%;
    min-width: 200px;
    height: 100%;
    position: relative;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // ::before {
    //   content: "";
    //   width: 0;
    //   height: 100%;
    //   top: 0;
    //   background-color: var(--overlayMain);
    //   transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    //   position: absolute;
    // }
  }

  .is__empty {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .empty__image__wrap {
      width: 100px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .empty__text {
      margin-top: 1.5rem;
      font-family: "Baguede", sans-serif;
    }
  }

  .tracks__container {
    width: 65%;
    height: 100%;
    border: ${({ isPlaylist }) =>
      isPlaylist ? "none" : "0.25px solid var(--borderLight)"};
    border-left: 0;
    transition: all 0.3s ease-in;
    background-image: ${({ bgImage }) => `url(${bgImage})`};
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-top: 20px; */

    .tracks__container-wrapper {
      background: linear-gradient(#00000099, #00000099);
      height: 100%;

      @media screen and (min-width: 970px) {
        margin-left: 52px;
        padding: 0.8rem 0 2.2rem 4rem;
      }

      @media screen and (min-width: 2000px) {
        margin-left: 77px;
      }
    }

    .tracklist__header {
      padding-top: 20px;
      justify-content: space-between;
      width: 95%;
      padding-left: 1.7em;

      /* @media screen and (min-width: 970px) {
        margin-left: 52px;
      } */

      .tracklist__header_title {
        font-size: 15px;
        color: var(--primaryWhite);
        font-family: "Bw ModelicaSS01Bold", sans-serif;
      }

      .tracklist__header__link {
        color: #d9d9d9e5;
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }

  .artistPlaylist__card__main:hover {
    // .main__display__image:before {
    //   width: 100%;
    // }
  }

  .tracks__list {
    height: 100%;
    padding: 1rem;
    /* background: linear-gradient(#00000099, #00000099); */

    /* @media screen and (min-width: 970px) {
      margin-left: 52px;
    } */
  }

  @media screen and (max-width: 800px) {
    width: 100%;

    .main__display__image {
      display: none;
    }

    .tracks__container {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .tracks__container {
      .tracklist__header {
        padding-top: 20px;
        padding-left: 1.2em;
      }
    }
  }
`

import {
  ResponseStreamLog,
  ResponseStreamLogs,
  ResponseWithTracks,
  StreamLogPayload,
} from "types"
import { instance } from "./axios"

export const sendStreamData = async (data: StreamLogPayload) => {
  const response = await instance.post<ResponseStreamLog>(
    `/data/logs/stream`,
    data
  )

  return response.data
}

export const getStreams = async () => {
  const response = await instance.get<ResponseStreamLogs>(`/data/logs/stream`)

  return response.data
}

export const getTopStreamedTracks = async (userId?: string) => {
  const url = `/data/logs/stream/top-tracks${userId ? `/${userId}` : ""}`
  const response = await instance.get<ResponseWithTracks>(url)

  return response.data
}

export const getRecentlyPlayedTracks = async (userId?: string) => {
  const url = `/data/logs/stream/recently-played${userId ? `/${userId}` : ""}`
  const response = await instance.get<ResponseStreamLogs>(url)

  return response.data
}

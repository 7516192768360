import { IconProps } from "types";

export const DiscoverIcon = ({
  color = "#929292",
  width = "22.338",
  height = "17.992",
}: IconProps) => (
  <svg
    width={width || "14"}
    height={height || "14"}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.538 5.49267L9 5L8.50733 7.462C8.45576 7.72005 8.32898 7.95706 8.14297 8.1432C7.95695 8.32933 7.72001 8.45626 7.462 8.508L5 9L5.49267 6.538C5.54436 6.28007 5.67119 6.0432 5.85719 5.85719C6.0432 5.67119 6.28007 5.54436 6.538 5.49267Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

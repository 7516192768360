import styled from "styled-components"
import { FlexibleDiv, FlexibleDivProps } from "components"

export type PricingPlanWrapProps = FlexibleDivProps & {
  bng?: string
  gradient?: string
  flipDir?: boolean
  borderColor?: string
}

export const SubscriptionPlansWrapper = styled(FlexibleDiv)`
  width: 100%;
  margin-top: 3rem;

  .top__section {
    margin-bottom: 3rem;

    .subscription__mode__selector {
      width: 50%;
      height: 60px;
      padding: 0.5rem;
      background-color: var(--primaryBlack);
      border: 1px solid var(--primaryGray);
      transition: all ease-in 0.25s;

      .mode__text__bg {
        height: 100%;
        width: 50%;
        cursor: pointer;

        p {
          text-align: center;
        }
      }

      .active__tab {
        background-color: var(--primaryCyan);
        color: var(--primaryBlack);

        p {
          font-family: var(--mBold);
        }
      }
    }
  }

  .bottom__section {
    padding: 0.5rem;
  }

  @media (min-width: 601px) and (max-width: 912px) {
    padding: 0 5%;

    .top__section {
      height: 25%;

      .subscription__mode__selector {
        max-height: 50px;
        max-width: 350px;
      }
    }

    .bottom__section {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 30px;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0 5%;

    .top__section {
      height: 35%;

      @media (max-width: 376px) {
        height: 32%;
      }

      .subscription__mode__selector {
        width: 100%;
        height: 50px;
        padding: 0.5rem;
      }
    }

    .bottom__section {
      width: 100%;
      padding: 0;
      overflow-x: scroll;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 40px;

      @media (max-width: 376px) {
        height: 60%;
      }

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

export const PricingPlanWrap = styled(FlexibleDiv)<PricingPlanWrapProps>`
  background: var(--primaryBlack);
  padding: 6px;
  height: 400px;
  width: 25%;
  border: 1px solid rgba(111, 111, 111, 0.5);

  .slot {
    height: 100%;
    width: 100%;
    background: ${({ bng, gradient }) =>
      `linear-gradient(202deg, ${bng}, ${gradient})`};
  }

  .price__wrapper {
    margin-bottom: 1.5%;
    position: relative;

    .card__background {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: ${({ bng }) => bng};
      position: relative;

      span {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        font-size: 0.5rem;
        border: 1px solid var(--primaryBlack);
        padding: 0.25rem;
        text-transform: uppercase;
      }

      .most__preferred {
        background-color: var(--primaryBlack);
      }

      .hot__deal {
        color: var(--primaryBlack);
      }
    }

    .text__container {
      width: 100%;
      height: 100%;
      z-index: 5;
      padding: 1.5rem 0;

      .price__text,
      .duration__text {
        color: var(--primaryBlack);
      }

      .duration__text {
        font-family: var(--mBold);
        font-size: 0.85rem;
        padding-bottom: 0.8rem;
      }

      .price__text {
        font-family: var(--mBold);
        font-size: 1.7rem;
        -webkit-text-stroke: var(--w700);
      }
    }
  }

  .subscription__features {
    gap: 15px;
    padding: 0 3%;

    .features__container {
      align-items: start;
      justify-content: center;
      gap: 10px;

      .icon__wrapper {
        height: 100%;
      }

      .features__text {
        font-family: var(--mMedium);
        font-size: 0.9em;
        width: 70%;
        color: var(--primaryBlack);
        margin: 0;

        @media (max-width: 1400px) {
          font-size: 0.8em;
        }

        svg {
          font-size: 15px;
        }
      }
    }

    @media (max-width: 1400px) {
      padding: 0 1%;
    }
  }

  .upgrade__wrapper {
    .upgrade__text {
      font-size: 1.1rem;
      color: var(--white);
      text-decoration: none;
      font-family: var(--mBold);
      transition: transform 1s, color 0.2s;

      @media (max-width: 1400px) {
        font-size: 0.9rem;
      }
    }
  }

  :hover {
    .upgrade__wrapper {
      .upgrade__text {
        color: var(--primaryBlack);
        padding: 0.35rem;
        background: ${({ bng, gradient }) =>
          `linear-gradient(202deg, ${bng}, ${gradient})`};
      }
    }
  }

  @media (min-width: 601px) and (max-width: 912px) {
    width: 60%;
    padding: 10px;

    .slot {
      height: 400px;
    }

    .price__wrapper {
      .text__container {
        .duration__text {
          font-size: 1.5rem;
          font-family: var(--mRegular);

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.9rem;
          }
        }

        .price__text {
          font-size: 2rem;

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 2.4rem;
          }
        }
      }
    }

    .subscription__features {
      gap: 12px;
      .features__container {
        gap: 18px;

        .features__text {
          font-size: 1em;

          @media (min-width: 900px) and (max-width: 912px) {
            font-size: 1.7em;
          }
        }
      }
    }

    .upgrade__wrapper {
      .upgrade__text {
        font-size: 1rem;

        @media (min-width: 900px) and (max-width: 912px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    .slot {
      height: 300px;
    }

    .price__wrapper {
      .text__container {
        .duration__text {
          @media (max-width: 281px) {
            font-size: 1.2em;
          }
        }

        .price__text {
          @media (max-width: 281px) {
            font-size: 1.4em;
          }
        }
      }
    }

    .subscription__features {
      height: auto;
      gap: 15px;
      padding: 0;

      @media (max-width: 281px) {
        gap: 7px;
      }

      .features__container {
        gap: 12px;

        @media (max-width: 281px) {
          align-items: start;
        }

        .features__text {
          font-size: 0.85em;

          @media (max-width: 281px) {
            font-size: 0.6em;
          }
        }

        .icon__wrapper {
          padding-top: 0px;

          @media (max-width: 281px) {
            height: fit-content;
            margin-top: -3.5px;
          }

          svg {
            @media (max-width: 281px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .upgrade__wrapper {
      .upgrade__text {
        @media (max-width: 281px) {
          padding-top: 3px;
          font-size: 1.3rem;
        }
      }
    }
  }
`

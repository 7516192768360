import { FlexibleDiv } from "components/lib/Box/styles"
import styled from "styled-components"

export interface CMContainerProps {
  checkHeight?: boolean
  smallHeight?: boolean
}
export const Container = styled(FlexibleDiv)<CMContainerProps>`
  width: 100%;

  .form_box {
    > p {
      color: var(--uduxYellowPrimary);
      font-family: "Baguede", sans-serif;
      text-align: center;
      font-size: 17px;
      font-weight: 700;
      line-height: 20px;
    }

    @media screen and (max-width: 550px) {
      > p {
        font-size: 13px;
      }
    }
  }

  .btn_box {
    margin-top: 1rem;
  }

  .action_text_box {
    margin-top: 5%;
  }

  .action_text {
    color: var(--uduWhiteSecondary);
    font-size: ${({ checkHeight }) => {
      return checkHeight ? "20px" : "15px"
    }};
    font-weight: 600;
    cursor: pointer;

    :hover {
      transition: all 0.4s;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 600px) {
  }
`

import { Button } from "components"
import { User } from "context"
import { UserListItemWrap } from "../styles"
import { useInviteCollabUserService } from "network/hooks"
import { useState } from "react"

export type UserListItemProps = {
  user: User
  playlistId: string
}
export const UserListItem = ({ user, playlistId }: UserListItemProps) => {
  const { mutate, isLoading } = useInviteCollabUserService()
  const [isInvited, setIsInvited] = useState(false)

  return (
    <UserListItemWrap key={user.id}>
      <div className="image__wrap">
        <img
          src={user.profile.profilePicture.url}
          alt={`${user.username} photo`}
        />
      </div>

      <div className="user__details">
        <p className="username">{user.username}</p>
        <p className="profile__detail">Profile</p>
      </div>

      <Button
        width="20%"
        onClick={() => {
          mutate(
            {
              playlistId: playlistId,
              userId: user.id,
              message: "Join my playlist, let's make memories together",
            },
            {
              onSuccess: () => {
                setIsInvited(true)
              },
            }
          )
        }}
        loading={isLoading}
      >
        {isInvited ? "Invited" : "Invite"}
      </Button>
    </UserListItemWrap>
  )
}

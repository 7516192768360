import { FlexibleDiv } from "components/lib/Box"
import { ASMWrap } from "./afro-skin-modal.styles"
import { Button } from "components/lib/Button"

export type AfroSkinModalProps = {
  open?: boolean
  title?: string
  content?: string
  btnContent?: string
  skinBanner?: string
  handleClose?: () => void
  handleBtnClick?: () => void
}
export const AfroSkinModal = ({
  title,
  content,
  btnContent,
  skinBanner,
  handleClose,
  handleBtnClick,
  open,
}: AfroSkinModalProps) => {
  return (
    <ASMWrap
      title={title}
      visible={open}
      onOk={handleBtnClick}
      onCancel={handleClose}
      skinBanner={skinBanner}
      footer={null}
    >
      <FlexibleDiv className="text__content">{content}</FlexibleDiv>

      <FlexibleDiv className="btn__wrap">
        <Button
          width="45%"
          padding="0.2rem"
          font_size="0.75rem"
          color="var(--black)"
          onClick={handleBtnClick}
          contained
        >
          <span>{btnContent}</span>
        </Button>
      </FlexibleDiv>
    </ASMWrap>
  )
}

import { FlexibleDiv } from "components"
import styled from "styled-components"

export const Content = styled.div`
  flex-grow: 1;
`

export const Buttons = styled.div`
  flex-grow: 0.2;
  justify-content: center;
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-bottom: 3rem;
  }
`

export const PaymentAuthenticationBox = styled(FlexibleDiv)`
  .info__desc {
    margin-bottom: 10%;

    h2 {
      color: var(--primaryYellow);
      padding-bottom: 1rem;
    }

    p {
      text-align: center;
      font-size: small;
    }
  }

  @media screen and (max-width: 600px) {
    .info__desc {
      margin-bottom: 10%;

      h2 {
        font-size: 1.2rem;
        text-align: center;
      }

      p {
        text-align: center;
        font-size: small;
      }
    }
  }
`

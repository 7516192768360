import { FlexibleDiv, Loader } from "components"
import { Types, useMainContext } from "context"
import { pollSubscriptionStatus, verifyPayment } from "network"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { PaymentFailed } from "./PaymentFailed"
import { PaymentSuccess } from "./PaymentSuccess"
import { VerifyPaymentWrapper } from "./verify-payment.styles"
import UduXGrayLogo from "assets/images/Auth/udux_logo_yellow.svg"

export const VerifyPayment = () => {
  const { dispatch } = useMainContext()
  const [params] = useSearchParams()
  const reference = params.get("reference") as string
  const provider = JSON.parse(localStorage.getItem("udx_pay") ?? "")

  useQuery(["user-subscription-status"], () =>
    // FIXME: Api doesn't return user subscription data
    pollSubscriptionStatus()
  )
  const { data: pvData, isLoading } = useQuery(
    ["verify-payment", reference, provider.method],
    () => verifyPayment(provider.method, reference),
    {
      enabled: !!reference && !!provider.method,
      onError: error => {
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    }
  )

  const summary = pvData?.data
  const trxSuccessful = summary?.transactionStatus == "SUCCESS"
  const trxFailed = summary?.transactionStatus == "FAILED"

  return (
    <VerifyPaymentWrapper>
      {isLoading ? (
        <Loader variant="green" />
      ) : (
        <FlexibleDiv className="content__box">
          <div className="logo__wrap">
            <img src={UduXGrayLogo} alt="uduX" />
          </div>
          {trxSuccessful && <PaymentSuccess {...summary} />}
          {trxFailed && <PaymentFailed />}
        </FlexibleDiv>
      )}
    </VerifyPaymentWrapper>
  )
}

import {
  AlbumCard,
  ArtisteCard,
  FlexibleDiv,
  PlaylistCard,
  TrackListItem,
  UserCard,
} from "components"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { useLocation } from "react-router-dom"
import { SearchHistoryWrap } from "../SearchHistory/search-history.styles"

export const SeeAll = () => {
  const { state, search } = useLocation() as any
  const params = new URLSearchParams(search)
  const searchQuery = params.get("q")
  const type = state.type as string
  const list = state.list as Array<any>

  return (
    <PlayerLayout>
      <SearchHistoryWrap>
        <FlexibleDiv className="search__header" justifyContent="flex-start">
          <h2>
            See all "{searchQuery} {type}"
          </h2>
        </FlexibleDiv>

        {/* <FlexibleDiv className="search__filter__wrap">
          <FlexibleDiv className="search__filter__wrap__content">
            <SearchFilter />
          </FlexibleDiv>
        </FlexibleDiv> */}

        <div className="result__main">
          {type === "playlists" &&
            list.map(pl => <PlaylistCard key={pl.id} {...pl} />)}

          {type === "albums" &&
            list.map(abl => <AlbumCard key={abl.id} {...abl} />)}

          {type === "tracks" &&
            list.map((sl, idx) => (
              <TrackListItem key={sl.id} idx={idx} {...sl} />
            ))}

          {type === "artists" &&
            list.map(al => <ArtisteCard key={al.id} {...al} />)}

          {type === "users" && list.map(ul => <UserCard key={ul.id} {...ul} />)}
        </div>
      </SearchHistoryWrap>
    </PlayerLayout>
  )
}

import { Button } from "components"
import { User } from "context"
import { UserInvitationItemWrap } from "../styles"
import { useInviteCollabUserService } from "network/hooks"
import { useState } from "react"
import { CollabRequestRecSen, CollabRequestStatus } from "types"
import dayjs from "dayjs"
import { capitalize } from "lodash"

export type UserInvitationItemProps = {
  // user: User
  expires: number
  id: string
  emailAddress: string
  receiver: CollabRequestRecSen
  status: CollabRequestStatus
  message?: string
}
export const UserInvitationItem = ({
  id,
  emailAddress,
  expires,
  status,
  message,
  receiver,
}: UserInvitationItemProps) => {
  const { mutate, isLoading } = useInviteCollabUserService()
  const [isInvited, setIsInvited] = useState(false)
  const username = receiver?.username || emailAddress

  return (
    <UserInvitationItemWrap key={id}>
      {/* <div className="image__wrap">
        <img
          src={user.profile.profilePicture.url}
          alt={`${user.username} photo`}
        />
      </div> */}

      <div className="user__details">
        <p className="username">{username}</p>
        <p className="expires">
          Expires {dayjs(expires).format("ddd, DD MMM YYYY")}
        </p>
      </div>

      <div className="user__details">
        <p className="status">{capitalize(status)}</p>
      </div>

      <Button
        width="20%"
        onClick={() => {
          // mutate(
          //   {
          //     playlistId: playlistId,
          //     userId: user.id,
          //     message: "Join my playlist, let's make memories together",
          //   },
          //   {
          //     onSuccess: () => {
          //       setIsInvited(true)
          //     },
          //   }
          // )
        }}
        loading={isLoading}
      >
        Delete
      </Button>
    </UserInvitationItemWrap>
  )
}

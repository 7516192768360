import { Collaboration } from "types"
import { UserListItem } from "./UserListItem"
import { UserInvitationItem } from "./UserInvitationItem"

export type InvitesTabDisplayProps = {
  invites?: Collaboration[]
}

export const InvitesTabDisplay = ({ invites }: InvitesTabDisplayProps) => {
  console.log({ invites })

  return (
    <>
      {invites?.map(invite => (
        <UserInvitationItem
          key={invite.id}
          id={invite.id}
          emailAddress={invite.potentialNewUserEmail}
          receiver={invite.receiver}
          expires={invite.expires}
          message={invite.message}
          status={invite.status}
        />
      ))}
    </>
  )
}

import { FlexibleDiv, FlexibleDivProps } from "components/lib"
import styled from "styled-components"

export interface PaymentRegisterLayoutWrapperProps extends FlexibleDivProps {
  dominantColors?: string[]
}

export const FMHWrap = styled(FlexibleDiv)<PaymentRegisterLayoutWrapperProps>`
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(0deg, ${dominantColors[0]} 2.59%, ${dominantColors[1]} 99.97%, ${dominantColors[2]} 99.98%)`
      : `linear-gradient(
      180deg,
      #bb8233 99.99%
      #9f6f2d 99.98%,
      #232b1a 99.97%,
      #39452d 2.59%,
    )`};
  height: 100px;

  .top-layer {
    padding: 0.5rem 1rem;

    .arrow {
      cursor: pointer;
      font-size: 1.5rem;
    }
  }
`

import { FlexibleDiv } from "components"
import { useMainContext } from "context"
import { findIndex, last } from "lodash"
import { memo } from "react"
import { useNavigate } from "react-router-dom"
import { getDisplayArtist, getDominantColors } from "utils"
import { FsemFooterWrap } from "./fsem-footer.styles"

export const FSEMFooter = memo(() => {
  const navigate = useNavigate()
  const {
    state: { player },
  } = useMainContext()

  const { currentTrack, playingList, prevTracks } = player

  const prevPlayed = getPrevPlayed()
  const upNext = getUpNext()

  return (
    <FsemFooterWrap
      justifyContent="space-between"
      dominantColors={getDominantColors(currentTrack)}
    >
      <FlexibleDiv
        flexDir="column"
        alignItems="flex-end"
        className="info__box prev__track__info"
      >
        <small className="track__status">
          {prevTracks.length ? "Just played" : "Now Playing"}
        </small>
        <p className="title__track">{prevPlayed?.track?.metadata?.title}</p>
        <span className="artist">{getDisplayArtist(prevPlayed, navigate)}</span>
      </FlexibleDiv>

      <FlexibleDiv
        flexDir="column"
        alignItems="flex-start"
        className="info__box next__track__info"
      >
        <small className="track__status">Up Next</small>
        <p className="title__track">{upNext?.track?.metadata?.title}</p>
        <span className="artist">{getDisplayArtist(upNext, navigate)}</span>
      </FlexibleDiv>
    </FsemFooterWrap>
  )

  function getPrevPlayed() {
    if (prevTracks.length > 0) {
      return last(prevTracks)
    } else {
      return currentTrack
    }
  }

  function getUpNext() {
    if (playingList?.tracks.length > 0) {
      const currentIdx = findIndex(playingList?.tracks, currentTrack)
      return playingList?.tracks[currentIdx + 1]
    } else {
      return currentTrack
    }
  }
})

FSEMFooter.displayName = "FSEMFooter"

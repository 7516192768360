import { Button, FlexibleDiv } from "components";
import { forwardRef, memo, useState } from "react";
import { EPNWrap } from "./styles";
import { useMutation } from "react-query";
import {
  createCollabPlaylist,
  createSoloPlaylist,
  errorResponseHandler,
  successResponseHandler,
} from "network";
import { Types, useMainContext } from "context";
import { capitalize } from "lodash";
import { HiInformationCircle } from "react-icons/hi2";
import { IoIosArrowRoundBack } from "react-icons/io";

type EnterPlaylistNameRefElProps = {
  type: string;
  bng: string;
  placeholder?: string;

  onComplete?: () => void;
  onClose: () => void;
};
const EnterPlaylistNameRefEl = forwardRef(
  ({ placeholder, onClose, type, bng }: EnterPlaylistNameRefElProps) => {
    const [value, setValue] = useState("");
    const {
      state: { player },
      dispatch,
    } = useMainContext();
    const { mutate: mutateCreateSoloPlaylist, isLoading: cspLoading } =
      useMutation(createSoloPlaylist);
    const { mutate: mutateCreateCollabPlaylist, isLoading: ccpLoading } =
      useMutation(createCollabPlaylist);

    const { currentTrack } = player;

    return (
      <EPNWrap bng={bng}>
        <FlexibleDiv className="title__wrap" justifyContent="space-between">
          <header>
            <p>You are going {capitalize(type)}</p>
            <small className="subtext">
              Every epic soundtrack deserves a Title
            </small>
          </header>
          <button className="go__back__box" onClick={onClose}>
            <IoIosArrowRoundBack color="var(--primaryWhite)" size={30} />{" "}
            <span>Back</span>
          </button>
        </FlexibleDiv>

        <FlexibleDiv className="input__wrap">
          <input
            value={value}
            placeholder={placeholder || `${type} playlist #1`}
            onChange={({ target }) => setValue(target.value)}
          />

          <FlexibleDiv className="btn__wrap">
            <Button onClick={handleMutation} loading={cspLoading || ccpLoading}>
              <span>Create Playlist</span>
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>

        {type === "collab" && (
          <FlexibleDiv
            className="collab__warn__wrap"
            justifyContent="flex-start"
            alignItems="center"
          >
            <p>
              <HiInformationCircle />
              <small>
                You may only add this song to a collab playlist after inviting
                your friends.
              </small>
            </p>
          </FlexibleDiv>
        )}
      </EPNWrap>
    );

    function handleMutation() {
      if (type === "solo") {
        mutateCreateSoloPlaylist(
          {
            title: value,
            tracks: [
              {
                sequence: 0,
                track: currentTrack?.track?.id,
              },
            ],
          },
          {
            onSuccess: (res) => {
              successResponseHandler(res);
              onClose && onClose();
              dispatch({ type: Types.CREATING_PLAYLIST, payload: false });
            },
            onError: (err) => errorResponseHandler(err),
          }
        );
      }

      if (type === "collab") {
        mutateCreateCollabPlaylist(
          {
            title: value,
            private: true,
          },
          {
            onSuccess: (res) => {
              successResponseHandler(res);
              onClose && onClose();
              dispatch({ type: Types.CREATING_PLAYLIST, payload: false });
            },
            onError: (err) => errorResponseHandler(err),
          }
        );
      }
    }
  }
);

EnterPlaylistNameRefEl.displayName = "EnterPlaylistNameRefEl";

export const EnterPlaylistName = memo(EnterPlaylistNameRefEl);

import { FlexibleDiv } from "components"
import styled from "styled-components"

export const ArtistProfileWrap = styled(FlexibleDiv)`
  padding: 2% 3%;
  overflow-x: hidden;

  .section__display__controls {
    .category__filter__content {
      width: max(12rem, 10%);
    }
  }

  @media screen and (max-width: 600px) {
    padding: 3% 0;

    section {
      padding: 5px 0;
    }
  }
`


import { FlexibleDiv } from "components/lib/Box";
import styled from "styled-components";

export interface ActionBoxWrapperProps {
  dominantColors?: Array<string>;
}

export const AlbumCardWrap = styled(FlexibleDiv)<ActionBoxWrapperProps>`
  max-width: 200px;
  min-width: 200px;
  height: 260px;
  cursor: pointer;
  position: relative;
  align-items: flex-end;
  border: 0.5px solid var(--uduxGrayLightAlpha);

  &:hover .card__play__btn {
    opacity: 1;
    transform: scale(1.1);

    svg {
      display: inline-flex;
    }
  }
  

  .card__play__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: flex-end;
    z-index: 20;
    justify-content: flex-end;
    opacity: 0;
    padding-right: 10%;
    padding-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.3s ease, transform 0.3s ease;

    .icon__wrap {
      width: 55px;
      height: 55px;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
      background: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%);
    }

    svg {
      display: none;
    }
  }

  .inner__wrap {
    width: 100%;
    z-index: 9;
    height: 80%;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 15px;
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[1]}, ${dominantColors[0]})`
        : "var(--gradientPrimary)"};
  }

  .picture__box {
    width: 80%;
    height: calc(100% - 20%);
    display: flex;
    border-bottom: 0;
    position: absolute;
    left: 50%;
    top: -10%;
    transform: translateX(-50%);
    align-items: center;
    background-color: black;
    border: 0.5px solid var(--uduxGrayLightAlpha);
  }

  .image__wrap {
    width: 85%;
    height: 90%;
    margin: 0 auto;

    img {
      float: left;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name__box {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem 0.5rem 10%;
    justify-content: space-between;
    border: ${({ dominantColors }) =>
      dominantColors?.length
        ? `0.5px solid linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "0.5px solid var(--gradientPrimary)"};
    background: ${({ dominantColors }) =>
      dominantColors?.length
        ? `linear-gradient(${dominantColors[0]}, ${dominantColors[1]})`
        : "var(--gradientPrimary)"};

    p {
      margin: 0;
      font-size: 13px;
      font-family: "Baguede", sans-serif;
    }

    .title__and__artists {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .name {
      font-size: 15px;
      text-transform: capitalize;
    }

    .artist__names {
      display: flex;
      column-gap: 2px;
      justify-content: flex-start;

      width: 160px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .artist {
      font-size: 0.65rem;
      text-transform: capitalize;
      width: max-content;

      :hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .name__box {
      /* height: 70px; */
    }
  }
`;
import { Form } from "antd"
import { BorderedTextfield, Button, FlexibleDiv } from "components"
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout"
import { SourceService, Types, useMainContext } from "context"
import {
  completeForgotPassword,
  confirmResetPasswordToken,
  errorResponseHandler,
  resetPassword,
  successResponseHandler,
} from "network"
import React from "react"
import { useMutation } from "react-query"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  getErrorResponse,
  validateConfirmPassword,
  validatePassword,
} from "utils"
import { Container } from "./confirmPassword.styles"
import galleryImage from "assets/images/Auth/confirm_new_password.jpg"
import { Config } from "config"

const checkHeight = window.innerHeight > 800 ? true : false

const ConfirmPassword = () => {
  const [form] = Form.useForm()
  const { search } = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(search)
  const { dispatch } = useMainContext()
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [isExpired, setIsExpired] = React.useState(false)
  const { mutate } = useMutation(confirmResetPasswordToken)
  const { mutate: mutateResetPassword, isLoading: rpLoading } = useMutation(
    completeForgotPassword
  )
  const { mutate: mutateResendLink, isLoading: rlLoading } =
    useMutation(resetPassword)

  const token = searchParams.get("token") ?? ""
  const emailAddress = searchParams.get("emailAddress") ?? ""

  const checkToken = React.useCallback(() => {
    mutate(
      {
        token,
        emailAddress,
      },
      {
        onSuccess: () => {
          setIsDisabled(false)
        },
        onError: error => {
          const err = getErrorResponse(error)
          const shouldResend =
            err?.includes("expired") || err?.includes("Invalid")

          if (shouldResend) {
            setIsExpired(shouldResend)
          }

          dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
        },
      }
    )
  }, [dispatch, emailAddress, mutate, token])

  React.useEffect(() => {
    checkToken()
  }, [checkToken])

  const handleFormSubmit = () => {
    mutateResetPassword(
      {
        emailAddress,
        password: form.getFieldValue("password"),
      },
      {
        onSuccess: res => {
          successResponseHandler(res)
          navigate("/login/basic")
        },
        onError: err => {
          form.setFieldsValue({
            password: "",
            confirmPassword: "",
          })
          dispatch({ type: Types.API_RESPONSE_ERROR, payload: err })
        },
      }
    )
  }

  return (
    <AuthLayout
      title="Confirm New Password"
      subText="Let’s get you back to your music"
      galleryImage={galleryImage}
    >
      <Container checkHeight={checkHeight}>
        <Form form={form} className="form_box">
          {!isExpired && (
            <React.Fragment>
              <FlexibleDiv className="textfield__wrap">
                <BorderedTextfield
                  type="password"
                  name="password"
                  paddingLeft="0px"
                  borderBottom="1px solid #707070"
                  borderWidth="0.5px"
                  placeholder="Enter New Password"
                  font_family={'"Bw Modelica", sans-serif'}
                  validator={validatePassword}
                  small
                />
              </FlexibleDiv>

              <FlexibleDiv className="textfield__wrap">
                <BorderedTextfield
                  type="password"
                  paddingLeft="0px"
                  borderWidth="0.5px"
                  borderBottom="1px solid #707070"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  font_family={'"Bw Modelica", sans-serif'}
                  validator={validateConfirmPassword}
                  small
                />
              </FlexibleDiv>
            </React.Fragment>
          )}

          <FlexibleDiv justifyContent="center" className="btn__box">
            {isExpired ? (
              <Button
                onClick={() =>
                  mutateResendLink(
                    {
                      emailAddress,
                      sourceService: Config.sourceService as SourceService,
                    },
                    {
                      onError: err => {
                        errorResponseHandler(err)
                      },
                      onSuccess: res => {
                        dispatch({
                          type: Types.API_RESPONSE_ERROR,
                          payload: "",
                        })
                        successResponseHandler(res)
                      },
                    }
                  )
                }
                loading={rlLoading}
                type="submit"
                font_size="16px"
                  width="180px"
              height="40px"
                color="var(--uduxWhitePrimary)"
                bold
                ltr
              >
                <span>Resend Link</span>
              </Button>
            ) : (
              <Button
                onClick={handleFormSubmit}
                disabled={isDisabled}
                loading={rpLoading}
                color="var(--uduxWhitePrimary)"
                type="submit"
                font_size="16px"
                 width="180px"
              height="40px"
                bold
                ltr
              >
                <span>Reset Password</span>
              </Button>
            )}
          </FlexibleDiv>
        </Form>

        <FlexibleDiv
          flexDir="column"
          className="cta__btn__box"
          justifyContent="center"
        >
          <Link to="/reset-password" className="existing__user">
            Forgot your Password
          </Link>

          <Link to="/signup" className="existing__user">
            Create an Account
          </Link>
        </FlexibleDiv>
      </Container>
    </AuthLayout>
  )
}

export default ConfirmPassword

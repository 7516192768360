import { IconProps } from "types";

export const TopSongsIcon = ({
  color = "#929292",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "13"}
    height={height || "15"}
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99967 11.3822C4.47034 11.3822 5.66634 10.1862 5.66634 8.71552V1.95218L11.6663 4.13352V9.08685C11.263 8.8464 10.8026 8.71819 10.333 8.71552C8.86234 8.71552 7.66634 9.91152 7.66634 11.3822C7.66634 12.8529 8.86234 14.0489 10.333 14.0489C11.8037 14.0489 12.9997 12.8529 12.9997 11.3822V3.66685C12.9996 3.53012 12.9574 3.39672 12.879 3.28474C12.8005 3.17276 12.6895 3.08762 12.561 3.04085L5.22767 0.374185C5.127 0.337545 5.01898 0.325707 4.91276 0.339674C4.80654 0.353641 4.70526 0.393001 4.61748 0.45442C4.5297 0.515839 4.45801 0.597509 4.40849 0.692512C4.35898 0.787514 4.33308 0.893051 4.33301 1.00018V6.42018C3.92962 6.17973 3.46928 6.05153 2.99967 6.04885C1.52901 6.04885 0.333008 7.24485 0.333008 8.71552C0.333008 10.1862 1.52901 11.3822 2.99967 11.3822Z"
      fill={color}
    />
  </svg>
);

// import { FlexibleDiv } from "components/lib/Box";
// import { CPMWrap } from "./create-playlist-modal.styles";
// import { Button } from "components/lib/Button";
// import { CloseOutlined } from "@ant-design/icons";
// import { BorderedTextarea, BorderedTextfield } from "components/lib/TextField";
// import { Form, notification, Select } from "antd";
// import { BorderedSelectField } from "components/lib/Select";
// import { capitalize } from "utils";
// import { NewPlaylistIcon } from "assets/icons/NewPlaylistIcon";
// import { useState } from "react";
// import { errorResponseHandler, getPreSignedUrl } from "network";
// import { UploadCategory } from "types";
// import { useMutation } from "react-query";

// export type CreatePlaylistPayloadProps = {
//   coverArt?: string;
//   description?: string;
//   title: string;
//   type: string;
// };

// export type CreatePlaylistModalProps = {
//   open?: boolean;
//   title?: string;
//   content?: string;
//   btnContent?: string;
//   skinBanner?: string;
//   isLoading?: boolean;
//   handleClose?: () => void;
//   handleBtnClick?: (payload: CreatePlaylistPayloadProps) => void;
// };

// export const CreatePlaylistModal = ({
//   handleBtnClick,
//   handleClose,
//   title = "New Playlist",
//   open,
//   isLoading,
// }: CreatePlaylistModalProps) => {
//   const [tempCoverArt, setTempCoverArt] = useState("");
//   const [playlistData, setPlaylistData] = useState<CreatePlaylistPayloadProps>({
//     title: "",
//     description: "",
//     type: "",
//     coverArt: "",
//   });
//   const { mutate: mutatePreSignUpload } = useMutation(getPreSignedUrl);
//   const updateState = (payload: Partial<CreatePlaylistPayloadProps>) => {
//     setPlaylistData((prev) => ({
//       ...prev,
//       ...payload,
//     }));
//   };

//   return (
//     <CPMWrap
//       open={open}
//       centered
//       className="modal__wrap"
//       footer={null}
//       playlistCover={tempCoverArt ? tempCoverArt : ""}
//       closable={false}
//       onCancel={handleClose} // Clicking outside closes the modal
//     >
//       <FlexibleDiv
//         className="modal__header"
//         alignItems="center"
//         justifyContent="space-between"
//       >
//         <h2>{title}</h2>
//         <CloseOutlined onClick={handleClose} className="close__icon" />
//       </FlexibleDiv>
//       {/* Modal Content with Image and Form */}
//       <FlexibleDiv alignItems="center" className="content">
//         {/* Left Image/Icon */}
//         <div className="playlist__image__wrap">
//           {!tempCoverArt && <NewPlaylistIcon width={"80"} height={"60"} />}
//           <FlexibleDiv
//             justifyContent="flex-start"
//             className="file__uploader__input__wrap"
//           >
//             <input
//               type="file"
//               name="playlistImage"
//               id="playlistImage"
//               onChange={handleImageUpload}
//               accept="image/*"
//               hidden
//             />
//             <label htmlFor="playlistImage" />
//           </FlexibleDiv>
//         </div>

//         {/* Form Fields */}
//         <Form className="form_content">
//           <FlexibleDiv className="signup__form__box">
//             <FlexibleDiv className="textfield__wrap">
//               <BorderedTextfield
//                 small
//                 type="text"
//                 name="title"
//                 placeholder="Title"
//                 paddingLeft="0.5rem"
//                 hasErrorMessage={"Playlist title is required"}
//                 onChange={({ target }) => updateState({ title: target.value })}
//               />
//             </FlexibleDiv>
//             <FlexibleDiv className="textfield__wrap">
//               <BorderedTextarea
//                 small
//                 type="text"
//                 name="description"
//                 placeholder="Description"
//                 paddingLeft="0.5rem"
//                 onChange={({ target }) =>
//                   updateState({ description: target.value })
//                 }
//               />
//             </FlexibleDiv>

//             <FlexibleDiv className="select_box gender__input__wrap">
//               <BorderedSelectField
//                 small
//                 width="120px"
//                 radius="0px"
//                 name="type"
//                 padding="0.5rem 0.5rem 0rem 0.5rem"
//                 placeholder="Type"
//                 onchange={(value) => {
//                   updateState({ type: value });
//                 }}
//               >
//                 {["public", "private"].map((value, idx) => (
//                   <Select.Option
//                     className="gender__dropdown"
//                     style={{ width: "200px", borderRadius: "0px" }}
//                     value={value}
//                     key={idx}
//                   >
//                     {capitalize(value)}
//                   </Select.Option>
//                 ))}
//               </BorderedSelectField>
//             </FlexibleDiv>
//           </FlexibleDiv>

//           <FlexibleDiv className="btn__box" justifyContent="flex-end">
//             <Button
//               width="115px"
//               height="40px"
//               font_size="15px"
//               type="submit"
//               className="cancel__btn"
//               color="var(--primaryWhite)"
//               radius="0px"
//               onClick={handleClose}
//               ltr
//             >
//               Cancel
//             </Button>
//             <Button
//               width="115px"
//               className="create__btn"
//               height="40px"
//               font_size="15px"
//               type="submit"
//               color="var(--black)"
//               contained
//               radius="0px"
//               bold
//               onClick={() => {
//                 if (handleBtnClick) {
//                   handleBtnClick(playlistData);
//                 }
//               }}
//               loading={isLoading}
//               ltr
//             >
//               Create
//             </Button>
//           </FlexibleDiv>
//         </Form>
//       </FlexibleDiv>
//     </CPMWrap>
//   );

//   async function handleImageUpload({
//     target,
//   }: React.ChangeEvent<HTMLInputElement>) {
//     if (target.files) {
//       const file = target.files[0];

//       if (validateFileInput(file)) {
//         const tempCoverArt = URL.createObjectURL(file);
//         setTempCoverArt(tempCoverArt);

//         const payload = {
//           fileName: file.name,
//           category: UploadCategory.PROFILE_PICTURES,
//           shouldNotPrefixFileName: true,
//         };

//         const formData = new FormData();
//         formData.append("file", file);
//         const uploadFile = formData.get("file");

//         mutatePreSignUpload(payload, {
//           onSuccess: async ({ data }) => {
//             console.log({ data });
//             updateState({ coverArt: data?.fileId });
//             await fetch(data.presignedUrl, {
//               method: "PUT",
//               body: uploadFile,
//             });
//           },
//           onError: (error) => {
//             // setFetching("")
//             errorResponseHandler(error);
//           },
//         });
//       }
//     }
//   }

//   function validateFileInput(file: File) {
//     const maxFileLimit = 5000000; // 5mb
//     const imageType = /image.*/;

//     if (!file) {
//       return;
//     }

//     if (!file?.type?.match(imageType)) {
//       notification.error({
//         message: "error",
//         description: "Only Images are allowed. Please upload an image instead.",
//       });
//       return;
//     }
//     if (file?.size > maxFileLimit) {
//       notification.error({
//         message: "error",
//         description: "File is too large, Max file size is 1mb",
//       });
//       return;
//     }

//     return true;
//   }
// };

export type CreatePlaylistPayloadProps = {
  coverArt?: string;
  description?: string;
  title: string;
  type: string;
};

export type CreatePlaylistModalProps = {
  open?: boolean;
  title?: string;
  content?: string;
  btnContent?: string;
  skinBanner?: string;
  isLoading?: boolean;
  handleClose?: () => void;
  handleBtnClick?: (payload: CreatePlaylistPayloadProps) => void;
};

import { useState } from "react";
import { Form, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FlexibleDiv } from "components/lib/Box";
import { BorderedTextarea, BorderedTextfield } from "components/lib/TextField";
import { BorderedSelectField } from "components/lib/Select";
import { NewPlaylistIcon } from "assets/icons/NewPlaylistIcon";
import { capitalize } from "utils";
import { CPMWrap } from "./create-playlist-modal.styles";
import { useImageUpload } from "network/hooks";
import { Button } from "components/lib/Button";

export const CreatePlaylistModal = ({
  handleBtnClick,
  handleClose,
  title = "New Playlist",
  open,
  isLoading,
}: CreatePlaylistModalProps) => {
  const [playlistData, setPlaylistData] = useState<CreatePlaylistPayloadProps>({
    title: "",
    description: "",
    type: "",
    coverArt: "",
  });

  const { tempCoverArt, handleImageUpload, isUploading } = useImageUpload();

  const updateState = (payload: Partial<CreatePlaylistPayloadProps>) => {
    setPlaylistData((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  return (
    <CPMWrap
      open={open}
      centered
      className="modal__wrap"
      footer={null}
      playlistCover={tempCoverArt || ""}
      closable={false}
      onCancel={handleClose}
    >
      <FlexibleDiv
        className="modal__header"
        alignItems="center"
        justifyContent="space-between"
      >
        <h2>{title}</h2>
        <CloseOutlined onClick={handleClose} className="close__icon" />
      </FlexibleDiv>

      <FlexibleDiv alignItems="center" className="content">
        <div className="playlist__image__wrap">
          {!tempCoverArt && <NewPlaylistIcon width={"80"} height={"60"} />}
          <FlexibleDiv
            justifyContent="flex-start"
            className="file__uploader__input__wrap"
          >
            <input
              type="file"
              name="playlistImage"
              id="playlistImage"
              onChange={(e) => handleImageUpload(e, updateState)}
              accept="image/*"
              hidden
            />
            <label htmlFor="playlistImage" />
          </FlexibleDiv>
        </div>

        <Form className="form_content">
          <FlexibleDiv className="signup__form__box">
            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                small
                type="text"
                name="title"
                placeholder="Title"
                paddingLeft="0.5rem"
                hasErrorMessage={"Playlist title is required"}
                onChange={({ target }) => updateState({ title: target.value })}
              />
            </FlexibleDiv>

            <FlexibleDiv className="textfield__wrap">
              <BorderedTextarea
                small
                type="text"
                name="description"
                placeholder="Description"
                paddingLeft="0.5rem"
                onChange={({ target }) =>
                  updateState({ description: target.value })
                }
              />
            </FlexibleDiv>

            <FlexibleDiv className="select_box gender__input__wrap">
              <BorderedSelectField
                small
                width="120px"
                radius="0px"
                name="type"
                padding="0.5rem 0.5rem 0rem 0.5rem"
                placeholder="Type"
                onchange={(value) => {
                  updateState({ type: value });
                }}
              >
                {["public", "private"].map((value, idx) => (
                  <Select.Option
                    className="gender__dropdown"
                    style={{ width: "200px", borderRadius: "0px" }}
                    value={value}
                    key={idx}
                  >
                    {capitalize(value)}
                  </Select.Option>
                ))}
              </BorderedSelectField>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv className="btn__box" justifyContent="flex-end">
            <Button
              width="115px"
              height="40px"
              font_size="15px"
              type="submit"
              className="cancel__btn"
              color="var(--primaryWhite)"
              radius="0px"
              onClick={handleClose}
              ltr
            >
              Cancel
            </Button>

            <Button
              width="115px"
              className="create__btn"
              height="40px"
              font_size="15px"
              type="submit"
              color="var(--black)"
              contained
              radius="0px"
              bold
              onClick={() => {
                if (handleBtnClick) {
                  handleBtnClick(playlistData);
                }
              }}
              loading={isLoading || isUploading}
              ltr
            >
              Create
            </Button>
          </FlexibleDiv>
        </Form>
      </FlexibleDiv>
    </CPMWrap>
  );
};

import React from "react"
import { IconProps } from "types"

export const Logo = ({ fill, width, height, className }: IconProps) => {
  return (
    <svg
      width={width ?? "147"}
      height={height ?? "48"}
      viewBox="0 0 147 48"
      className={className}
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M131.796 24.0293L146.822 0.0595345H136.846C136.133 1.24357 135.304 2.54572 134.411 3.90069C133.521 5.32287 132.689 6.68552 131.798 8.10195C130.906 9.51837 130.076 10.8868 129.244 12.2446C128.412 13.6025 127.581 14.8489 126.868 15.9734L116.948 0H106.852C109.287 3.90645 111.781 7.87437 114.395 11.9566C117.01 16.0387 119.503 20.0057 121.879 23.8526L106.792 48H116.711L126.926 31.9008L137.141 48H147L131.796 24.0293Z" />
      <path d="M61.9514 19.1773C59.5764 17.5687 56.8063 16.6356 53.9389 16.4782C51.0715 16.3209 48.2153 16.9452 45.6774 18.2842C43.1394 19.6232 41.0156 21.6261 39.5344 24.0777C38.0532 26.5292 37.2705 29.3367 37.2705 32.1983C37.2705 35.0599 38.0532 37.8674 39.5344 40.319C41.0156 42.7706 43.1394 44.7735 45.6774 46.1125C48.2153 47.4514 51.0715 48.0758 53.9389 47.9184C56.8063 47.7611 59.5764 46.8279 61.9514 45.2193V47.1726H68.9005V7.33984H61.9514V19.1773ZM53.0422 40.2479C51.4446 40.2479 49.8829 39.7759 48.5545 38.8914C47.2262 38.007 46.1909 36.7499 45.5795 35.2791C44.9681 33.8083 44.8081 32.1899 45.1198 30.6285C45.4315 29.0671 46.2008 27.6329 47.3305 26.5072C48.4601 25.3815 49.8994 24.6149 51.4663 24.3043C53.0332 23.9938 54.6574 24.1532 56.1333 24.7624C57.6093 25.3716 58.8709 26.4033 59.7585 27.7269C60.646 29.0506 61.1198 30.6068 61.1198 32.1988C61.1196 33.2559 60.9106 34.3026 60.5044 35.2792C60.0983 36.2558 59.5031 37.1431 58.7529 37.8905C58.0026 38.6378 57.112 39.2306 56.1318 39.6349C55.1516 40.0393 54.1011 40.2472 53.0403 40.247L53.0422 40.2479Z" />
      <path d="M26.668 17.8745V30.0702C26.5414 32.448 25.5176 34.6902 23.802 36.3476C22.0863 38.005 19.8055 38.955 17.4165 39.0074C15.0276 39.0597 12.707 38.2105 10.9199 36.6298C9.13281 35.0492 8.01118 32.854 7.7798 30.4841H7.72102V17.8745H0V30.4812C0.228403 34.864 2.13649 38.9923 5.33053 42.0141C8.52456 45.0359 12.7608 46.7206 17.1651 46.7206C21.5694 46.7206 25.8056 45.0359 28.9997 42.0141C32.1937 38.9923 34.1018 34.864 34.3302 30.4812V17.8745H26.668Z" />
      <path d="M98.834 18.4663V30.662C98.7075 33.0398 97.684 35.2821 95.9684 36.9396C94.2528 38.5971 91.972 39.5473 89.583 39.5998C87.194 39.6522 84.8734 38.8031 83.0862 37.2226C81.299 35.642 80.1773 33.4467 79.9458 31.0768H79.8861V18.4663H72.166V31.073C72.3944 35.4558 74.3025 39.5841 77.4965 42.6059C80.6906 45.6277 84.9268 47.3124 89.3311 47.3124C93.7354 47.3124 97.9716 45.6277 101.166 42.6059C104.36 39.5841 106.268 35.4558 106.496 31.073V18.4663H98.834Z" />
    </svg>
  )
}

export const DeleteIcon = ({ width, color }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "15"}
      height="19"
      viewBox="0 0 15 19"
      fill="none"
    >
      <path
        d="M5.46582 2.43722V1H9.05887V2.43722"
        stroke={color || "#D9D9D9"}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6522 5.67188V16.8103C12.6522 17.6041 12.0087 18.2475 11.215 18.2475H3.31027C2.51653 18.2475 1.87305 17.6041 1.87305 16.8103V5.67188"
        stroke={color || "#D9D9D9"}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.435547 2.4375H14.0891V3.87472H0.435547V2.4375Z"
        stroke={color || "#D9D9D9"}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.05859 6.83789V16.4493"
        stroke={color || "#D9D9D9"}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.46582 6.83789V16.4493"
        stroke={color || "#D9D9D9"}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const UploadIcon = ({ width, color }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "13"}
      height="15"
      viewBox="0 0 13 15"
      fill="none"
    >
      <path
        d="M1 8.19141V12.1133C1 12.8353 1.54721 13.4206 2.22222 13.4206H10.7778C11.4528 13.4206 12 12.8353 12 12.1133V8.19141"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50087 10.151V1M6.50087 1L3.44531 4.55874M6.50087 1L9.55642 4.55873"
        stroke={color || "#D9D9D9"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Hamburger = ({
  stroke,
  fill,
  width,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width ?? "30"}
      height={height ?? "24"}
      viewBox="0 0 30 24"
      fill={fill ?? "none"}
      stroke={stroke ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.96191 2.96191H26.9619"
        strokeWidth="4.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75977 12H20.7598"
        strokeWidth="4.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.96191 20.9619H26.9619"
        strokeWidth="4.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Login = ({ stroke, fill, width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill ?? "none"}
      stroke={stroke ?? ""}
      width={width ?? "35"}
      height={height ?? "35"}
      viewBox="0 0 35 35"
    >
      <path d="M27.7113 4.375H7.2946C5.68605 4.375 4.37793 5.68313 4.37793 7.29167V13.125H7.2946V7.29167H27.7113V27.7083H7.2946V21.875H4.37793V27.7083C4.37793 29.3169 5.68605 30.625 7.2946 30.625H27.7113C29.3198 30.625 30.6279 29.3169 30.6279 27.7083V7.29167C30.6279 5.68313 29.3183 4.375 27.7113 4.375Z" />
      <path d="M16.0417 23.3333L23.3333 17.5L16.0417 11.6666V16.0431H4.375V18.9598H16.0417V23.3333Z" />
    </svg>
  )
}

export const Cancel = ({ fill, width, height, className }: IconProps) => {
  return (
    <svg
      className={className ?? "x"}
      width={width ?? "22"}
      height={height ?? "22"}
      viewBox="0 0 22 22"
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2502 0L10.999 8.25L2.74976 0L0 2.75L8.24927 11L0 19.25L2.74976 22L10.999 13.75L19.2502 22L22 19.25L13.7507 11L22 2.75L19.2502 0Z"
        fill="#ED1651"
      />
    </svg>
  )
}

export const Check = ({ fill, width, height, className }: IconProps) => {
  return (
    <svg
      className={className ?? "check"}
      width={height ?? "23"}
      height={width ?? "17"}
      viewBox="0 0 23 17"
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.98438 8.85938L8.32812 15.2031L21.0156 1.60938"
        stroke="#FBBA12"
        strokeWidth="2.8125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Back = ({
  width,
  stroke,
  height,
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      className={className ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "41"}
      height={height ?? "41"}
      viewBox="0 0 41 41"
      stroke={stroke ?? "#FDC474"}
      fill="none"
      {...props}
    >
      <path
        d="M1.10449 20.4989C1.10449 24.3348 2.24196 28.0845 4.37304 31.2739C6.50413 34.4633 9.53312 36.9491 13.077 38.417C16.6209 39.885 20.5204 40.269 24.2826 39.5207C28.0447 38.7723 31.5005 36.9252 34.2128 34.2128C36.9252 31.5005 38.7723 28.0447 39.5207 24.2826C40.269 20.5204 39.885 16.6209 38.417 13.077C36.9491 9.53312 34.4633 6.50413 31.2739 4.37304C28.0845 2.24196 24.3348 1.10449 20.4989 1.10449C15.3552 1.10449 10.4221 3.14783 6.78499 6.78499C3.14783 10.4221 1.10449 15.3552 1.10449 20.4989V20.4989Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.499 28.2568L12.7412 20.499L20.499 12.7412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2568 20.499H12.7412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

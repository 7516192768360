import GalleryBng from "assets/images/payment/forgot_password.png"
import { FlexibleDiv } from "components"
import { PaymentRegisterLayout } from "components/layouts/PaymentsLayout/PaymentRegisterLayout/paymentRegisterLayout"
import { Link, useSearchParams } from "react-router-dom"
import { PaymentCheckMailWrapper } from "./payment-check-mail.styles"

export const PaymentCheckMail = () => {
  const [params] = useSearchParams()
  const plan = params.get("type") || "weekly"
  const mode = params.get("mode") || "udux"

  return (
    <PaymentRegisterLayout
      galleryImage={GalleryBng}
      backURL={`/payment/auth?type=${plan}&mode=${mode}`}
    >
      <PaymentCheckMailWrapper>
        <FlexibleDiv flexDir="column" className="info__description">
          <h2>Password Reset</h2>
          <p>We just sent over password reset instructions... See you soon</p>
        </FlexibleDiv>

        <FlexibleDiv
          flexDir="column"
          className="btn__box"
          justifyContent="center"
        >
          <Link to="/payment/login" className="existing__user">
            Login
          </Link>
        </FlexibleDiv>
      </PaymentCheckMailWrapper>
    </PaymentRegisterLayout>
  )
}

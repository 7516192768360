import { FlexibleDiv, TabContent, TabHeader } from "components";
import { Types, useMainContext } from "context";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getArtist } from "utils";
import { AddToPlaylist } from "../AddToPlaylist";
import { ArtistCatalogue } from "../ArtistCatalogue";
import { FSEMPlayer } from "../FSEMPlayer";
import { ManageQueue } from "../ManageQueue";
import { Recommended } from "../Recommended";
// import { Preloader } from "./Preloader"
import { MobileMusicPlayerWrap } from "./mobile-player.styles";
import { MoreModal } from "./MoreModal";
import {
  AddToPlaylistIcon,
  AddToQueueIcon,
  GotoAlbumIcon,
  SimilarVibesIcon,
} from "assets";
import { DownOutlined } from "@ant-design/icons";
import { NowPlayingMiniPlayer } from "../MiniPlayer/NowPlayingMiniPlayer";

export type MobilePlayerTabRoutes = typeof mobilePlayerTabRoutes;
const mobilePlayerTabRoutes = [
  {
    icon: <GotoAlbumIcon />,
    title: "Artist Catalogue",
    hash: "#artist-catalogue",
  },
  {
    icon: <SimilarVibesIcon />,
    title: "Recommended",
    hash: "#recommended",
  },
  {
    icon: <AddToPlaylistIcon />,
    title: "Add To Playlist",
    hash: "#add-to-playlist",
  },
  {
    icon: <AddToQueueIcon />,
    title: "Manage Queue",
    hash: "#manage-queue",
  },
];

export const MobilePlayer = () => {
  const tabContentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [nowPlaying, SetNowPlaying] = useState(true);

  const {
    state: { player },
    dispatch,
  } = useMainContext();

  const { currentTrack } = player;

  const artist = getArtist(currentTrack);

  // Function to toggle the "nowPlaying" state
  const toggleNowPlaying = () => {
    SetNowPlaying((prev) => !prev);
    // Request fullscreen
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };

  // Ensure nowPlaying is turned off when interacting with TabHeader
  const handleTabChange = (tab: string) => {
    setActiveTab(tab); // Update the active tab
    SetNowPlaying(false); // Immediately turn off nowPlaying when a tab is clicked
  };

  useEffect(() => {
    if (isEmpty(currentTrack)) {
      dispatch({ type: Types.IS_PLAYING, payload: false });
      dispatch({ type: Types.IS_PLAYER_ACTIVE, payload: false });
      navigate("/");
    }
  }, [currentTrack, navigate, dispatch]);

  return (
    <MobileMusicPlayerWrap flexWrap="nowrap">
      {nowPlaying ? (
        <FlexibleDiv className="top__wrap">
          <div className="top__info">
            <div className="toggle__now__playing__wrap">
              <DownOutlined
                onClick={toggleNowPlaying}
                className="toggle__icon"
              />
            </div>
            <h3>Now Playing</h3>
            <p>From Liked Songs</p>
          </div>
          <FSEMPlayer />
          {nowPlaying && (
            <TabHeader
              activeTab={activeTab}
              background="#000"
              tabs={mobilePlayerTabRoutes}
              setActiveTab={handleTabChange}
              noGap
            />
          )}
        </FlexibleDiv>
      ) : (
        <FlexibleDiv flexDir="column" className="bottom__wrap">
          {!nowPlaying && (
            <NowPlayingMiniPlayer handleMaximise={() => toggleNowPlaying()} />
          )}
          <div className="bottom__content__wrap">
            <TabHeader
              activeTab={activeTab}
              tabs={mobilePlayerTabRoutes}
              background="#0f0f0f"
              setActiveTab={handleTabChange} // Handle tab change and toggle nowPlaying
              noGap
            />

            <TabContent ref={tabContentRef}>
              {activeTab === "#artist-catalogue" && artist && (
                <ArtistCatalogue artist={artist} />
              )}
              {activeTab === "#recommended" && artist && (
                <Recommended artist={artist} />
              )}
              {activeTab === "#add-to-playlist" && <AddToPlaylist />}
              {activeTab === "#manage-queue" && <ManageQueue />}
            </TabContent>
          </div>
        </FlexibleDiv>
      )}
      {showMoreMenu && (
        <MoreModal
          open={showMoreMenu}
          handleClose={() => setShowMoreMenu(false)}
        />
      )}
    </MobileMusicPlayerWrap>
  );
};

import { notification } from "antd"

export function successResponseHandler(response: any) {
  if (response?.message) {
    return notification.success({
      message: "Success",
      description: response.message,
    })
  }

  if (response?.data) {
    return notification.success({
      message: response.data.message,
      description: response.data.message,
    })
  }
}

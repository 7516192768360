import { Rule } from "antd/lib/form"

export type ValidateInputProps = {
  value: string
  setStatus: (args: SetStatus) => void
  rules?:
    | Rule
    | Rule[]
    | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  nullable?: boolean
}
export type SetStatus = {
  err?: string | boolean
  success?: string | boolean
}

export const validateEmail = ({
  value,
  setStatus,
}: ValidateInputProps): Promise<void> => {
  return new Promise(resolve => {
    if (!value) {
      setStatus({ err: "Email value must not be empty" })
      return
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      setStatus({
        err: "Email address entered is Invalid, Kindly re-check entry",
      })
      return
    } else {
      setStatus({ success: "success" })
      localStorage.setItem("emailTest", value)
      resolve()
    }
  })
}

/* 
Usernames can only have: 
- Lowercase Letters (a-z) 
- Numbers (0-9)
- Dots (.)
- Underscores (_)
*/
export const validateUsername = ({
  value,
  setStatus,
}: ValidateInputProps): Promise<void> => {
  // eslint-disable-next-line no-useless-escape
  const __regx = new RegExp(/^[a-zA-Z0-9_\.]+$/)
  return new Promise(resolve => {
    if (!value) {
      setStatus({ err: "Username value must not be empty" })
      return
    } else if (!__regx.test(value)) {
      setStatus({
        err: "Username entered is invalid, only special characters allowed are '_' & '.'",
      })
      return
    } else {
      setStatus({ success: "success" })
      localStorage.setItem("emailTest", value)
      resolve()
    }
  })
}

export const validatePassword = ({
  value,
  setStatus,
}: ValidateInputProps): Promise<void> => {
  return new Promise(resolve => {
    if (!value) {
      setStatus({
        err: "Password cannot be empty.",
      })

      return
    } else if (value.length < 6) {
      setStatus({
        err: "Password must not be less than 6 characters.",
      })

      return
    } else if (value.length > 16) {
      setStatus({
        err: "Password must not be more than16 characters.",
      })

      return
    } else {
      setStatus({ success: "success" })
      localStorage.setItem("passwordTest", value)

      resolve()
    }
  })
}

export const validateConfirmPassword = ({
  value,
  setStatus,
  nullable,
}: ValidateInputProps): Promise<void> => {
  return new Promise(resolve => {
    if (!nullable && !value) {
      setStatus({
        err: "The Passwords entered do not match. Kindly re-check this entry",
      })
      return
    } else if (value !== localStorage.getItem("passwordTest")) {
      setStatus({
        err: "The Passwords entered do not match. Kindly re-check this entry",
      })

      return
    } else if (nullable && !value) {
      resolve()
    } else {
      setStatus({ success: "success" })
      resolve()
    }
  })
}

export const validateBasicField = ({
  value,
  setStatus,
  nullable,
}: ValidateInputProps): Promise<void> => {
  return new Promise(resolve => {
    if (!nullable && !value) {
      setStatus({
        err: "This input field is empty. Kindly re-check this entry",
      })
      return
    } else if (nullable && !value) {
      resolve()
    } else {
      setStatus({ success: "success" })
      resolve()
    }
  })
}

export const validateCountryCode = ({
  value,
  setStatus,
  nullable,
}: ValidateInputProps): Promise<void> => {
  return new Promise(resolve => {
    if (!nullable && !value) {
      setStatus({
        err: "Select country",
      })
      return
    } else if (nullable && !value) {
      resolve()
    } else {
      setStatus({ success: "success" })
      resolve()
    }
  })
}

export const validatePhoneNumber = ({
  value,
  setStatus,
  nullable,
}: ValidateInputProps): Promise<void> => {
  const valueLen = value.replace(/\s/g, "")

  return new Promise(resolve => {
    if (!nullable && !value) {
      setStatus({
        err: "This input field is empty. Kindly re-check this entry",
      })
      return
    } else if (!value.startsWith("+234") && valueLen.length < 10) {
      setStatus({
        err: "Please enter a valid phone number.",
      })
      return
    } else if (value.startsWith("+234") && value.length < 14) {
      setStatus({
        err: "Please enter a valid Nigerian phone number.",
      })
      return
    } else if (nullable && !value) {
      resolve()
    } else {
      setStatus({ success: "success" })
      resolve()
    }
  })
}

export const validatePhoneNumberWithoutCountryCode = ({
  value,
  setStatus,
  nullable,
}: ValidateInputProps): Promise<void> => {
  return new Promise(resolve => {
    if (!nullable && !value) {
      setStatus({
        err: "This input field is empty. Kindly re-check this entry",
      })
      // return;
    } else if (value.length > 14) {
      setStatus({
        err: "Please enter a valid phone number.",
      })
      // return;
    } else if (!Number(value)) {
      setStatus({
        err: "Only numbers are allowed.",
      })
    } else if (nullable && !value) {
      resolve()
    } else {
      setStatus({ success: "success" })
      resolve()
    }
  })
}

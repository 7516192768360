import { CollabPlaylistTabHeader, TabContent } from "components"
import { useEffect, useState } from "react"
import { BsGraphUpArrow } from "react-icons/bs"
import { IoMusicalNotes } from "react-icons/io5"
import { MdLeaderboard } from "react-icons/md"
import { PiMusicNotesPlusBold } from "react-icons/pi"
import { CollabPlaylistOrganizerWrap } from "./collab-playlist-organizer.styles"
import { CollabSongsTabDisplay, CollabStatsTabDisplay } from "./components"
import { CollabLeaderboardTabDisplay } from "./components/LeaderboardTab/CollabLeaderboardTabDisplay"
import { CollabTabDisplay } from "./components/CollabTab/CollabTabDisplay"

export type CollabPlaylistTabRoutes = typeof collabPlaylistTabRoutes
const collabPlaylistTabRoutes = [
  {
    icon: <IoMusicalNotes />,
    title: "Songs",
    hash: "#songs",
  },
  {
    icon: <BsGraphUpArrow />,
    title: "Stats",
    hash: "#stats",
  },
  {
    icon: <MdLeaderboard />,
    title: "Leaderboard",
    hash: "#leaderboard",
  },
  {
    icon: <PiMusicNotesPlusBold />,
    title: "Collaborators",
    hash: "#collaborators",
  },
]

export const CollabPlaylistOrganizer = () => {
  const [activeTab, setActiveTab] = useState("#songs")

  useEffect(() => {
    const currentUrl = window.location.href.split("#")[0] // get the current URL without any hash
    const newUrl = `${currentUrl}${activeTab}` // append the activeTab
    window.history.replaceState(null, "", newUrl)
  }, [activeTab])

  return (
    <CollabPlaylistOrganizerWrap>
      <CollabPlaylistTabHeader
        width="100%"
        activeTab={activeTab}
        tabs={collabPlaylistTabRoutes}
        padding="0.2rem"
        setActiveTab={tab => setActiveTab(tab)}
      />

      <TabContent>
        {activeTab === "#songs" && <CollabSongsTabDisplay />}
        {activeTab === "#stats" && <CollabStatsTabDisplay />}
        {activeTab === "#leaderboard" && <CollabLeaderboardTabDisplay />}
        {activeTab === "#collaborators" && <CollabTabDisplay />}
      </TabContent>
    </CollabPlaylistOrganizerWrap>
  )
}

import { Skeleton } from "antd";
import { FlexibleDiv, LotteryCampaignCard } from "components";

export type CampaignListProps = {
  loading: boolean;
  campaigns: { src: string }[]; // Ensure each campaign item has a 'src' property
  size?: "large" | "small";
};

export const CampaignList = ({
  loading,
  campaigns,
  size = "small",
}: CampaignListProps) => {

  return loading ? (
    <FlexibleDiv minHeight="300px">
      <Skeleton active={loading} />
    </FlexibleDiv>
  ) : (
    <>
      {campaigns !== undefined &&
        campaigns.map((item, idx) => {
          return (
            <FlexibleDiv
              className="lottery__campaign__card"
              width="max-content"
              key={idx}
              margin={idx !== 0 ? "0 0 0 30px" : "0"}
            >
              <LotteryCampaignCard banner={{ src: item.src }} size={size} />
            </FlexibleDiv>
          );
        })}
    </>
  );
};

import { FlexibleDiv } from "components"
import styled from "styled-components"

export type LeaderboardEngagementCardWrapProps = {
  bg?: string
  hoverColor?: string
}
export const LeaderboardEngagementCardWrap = styled(
  FlexibleDiv
)<LeaderboardEngagementCardWrapProps>`
  background-color: ${({ bg }) => bg || "var(--secondaryBlack)"};
  width: 100%;
  padding: 1rem 2rem;

  .header {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 1rem;

    .title__desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .total {
        font-size: 3rem;
      }
      .subtitle {
        font-size: 0.85rem;
      }
    }

    .user__avatar {
      display: flex;
      flex-direction: column;
      align-items: center;

      .winner {
        font-size: 0.85rem;
        font-family: var(--mLight);
      }
      .winner__avatar {
        width: 60px;
        height: 60px;
      }
    }
  }

  .list__item {
    padding: 1rem 0.5rem;
    justify-content: space-between;
    flex-wrap: nowrap;

    &:not(:last-child) {
      border-bottom: 1px solid var(--borderLighter);
    }

    &:hover {
      transition: all 0.5s ease-in-out;
      background-color: ${({ hoverColor }) =>
        hoverColor || "var(--secondaryGray)"};
    }

    .user__data,
    .info__stats {
      display: flex;
      flex: 1;
    }

    .user__data {
      align-items: center;

      .number {
        font-size: 0.75rem;
        padding-right: 0.5rem;
      }

      .username {
        font-family: var(--mBold);
        padding-left: 0.5rem;
      }
    }

    .info__stats {
      align-items: center;
      justify-content: space-around;

      .info {
        font-size: 0.85rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem;

    .header {
      .title__desc {
        .total {
          font-size: 2.5rem;
        }
        .subtitle {
          font-size: 0.65rem;
        }
      }

      .user__avatar {
        .winner {
          font-size: 0.65rem;
        }
        .winner__avatar {
          width: 40px;
          height: 40px;
        }
      }
    }

    .list__item {
      padding: 0.5rem 0.2rem;

      .user__data {
        .number {
          font-size: 0.65rem;
          padding-right: 0.5rem;
        }

        .username {
          font-size: 0.85rem;
          font-family: var(--mBold);
        }
      }

      .info__stats {
        align-items: center;
        justify-content: space-around;

        .info {
          font-size: 0.65rem;
        }
      }
    }
  }
`

import { DominantColors } from "context";
import styled from "styled-components";

interface FsPWrapProps {
  dominantColors?: DominantColors;
  isMobile?: boolean;
  bgCover?: string;
}
export const FsPWrap = styled.div<FsPWrapProps>`
  background-color: #000; 
  background-image: url(${({ bgCover }) => bgCover || ''});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100dvh;
  min-height: 100dvh;
  position: fixed;
  top: 0;
  left: 0; 
  z-index: -1;


  .fsem__wrap {
    position: absolute;
    left: 0;
    bottom: 20px;
    background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, ${dominantColors[0]} 0%, ${dominantColors[1]} 100%)`
      : ""};
    width: 100%;
    min-height: ${({ isMobile }) => (isMobile ? "fit-content" : "90px")};
    padding: ${({ isMobile }) => (isMobile ? "0px 10px" : "2px 40px")};
    flex-wrap: nowrap;

    .track__image__wrap {
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;

      .name__and__cover {
        display: flex;
        align-items: center;
        column-gap: 5px;
        min-height: ${({ isMobile }) => (isMobile ? "60px" : "65px")};

        .cover__art {
          width: ${({ isMobile }) => (isMobile ? "50px" : "60px")};
          min-height: ${({ isMobile }) => (isMobile ? "100%" : "100%")};
          border-radius: 4px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        .title__and__album__artist {
          display: flex;
          padding: 5px;
          flex-direction: column;
          justify-content: flex-start;
          min-height: ${({ isMobile }) => (isMobile ? "100%" : "40px")};
          align-items: flex-start;
          gap: 5px;
          row-gap: ${({ isMobile }) => (isMobile ? "2px" : "5px")};

          .title {
            font-size: ${({ isMobile }) => (isMobile ? "0.6rem" : "0.7rem")};
            opacity: 0.7;
            font-family: "Bw Modelica", sans-serif;
            letter-spacing: 4%;
          }

          .album {
            font-size: ${({ isMobile }) => (isMobile ? "0.4" : "0.8rem")};
            font-weight: ${({ isMobile }) => (isMobile ? "400" : "700")};
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
          }

          .artist {
            font-size: ${({ isMobile }) => (isMobile ? "0.6rem" : "0.6")};
            letter-spacing: 4%;
            font-family: "Bw Modelica", sans-serif;
            opacity: 0.6;
          }
        }
      }

      .caret {
        cursor: pointer;
        margin-left: 30px;
        font-size: 40px;
      }
    }

    .track__controls__wrap {
      gap: 5px;
      flex: 1;

      .controls__main {
        column-gap: ${({ isMobile }) => (isMobile ? "10px" : "20px")};
        justify-content: ${({ isMobile }) =>
          isMobile ? "flex-end" : "center"};
        .control__icons {
          cursor: pointer;
        }

        .repeat__icon,
        .shuffle__icon {
          display: ${({ isMobile }) => (isMobile ? "none" : "flex")};
        }
      }

      .song__progress__bar {
        position: relative;
        height: 6px;
        display: ${({ isMobile }) => (isMobile ? "none" : "flex")};

        .track__inner {
          height: 100%;
          position: absolute;
          background-color: var(--yellowPrimary);
        }
      }
    }

    .visual__controls {
      align-items: center;
      justify-content: flex-end;
      margin-right: 40px;
      flex: 1;
      flex-wrap: nowrap;
      column-gap: 20px;
      display: ${({ isMobile }) => (isMobile ? "none" : "flex")};

      .v__icon__wrap {
        cursor: pointer;


        .minimise{
            font-size: 30px;
            color: var(--primaryWhite);
        }
      }
    }
  }
`;

import { FlexibleDiv } from "components";
import styled from "styled-components";

export const CollabControlsWrap = styled(FlexibleDiv)`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: max-content;

  .icon__wrap {
    display: flex;
    color: var(--primaryWhite);
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;

    svg {
      font-size: 1.65rem;

      path {
        transition: all 0.5s ease;
      }
    }

    &:hover {
      color: var(--primaryYellow);
    }
  }
`;

export const StandardControlsWrap = styled(FlexibleDiv)`
  height: 100%;
  column-gap: 20px;
  align-items: center;
  justify-content: flex-start;
  margin-left: -10px;
  @media screen and (max-width: 1024px) {
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-left: 0;
    column-gap: 10px;
  }

  .hover__fill {
    :hover {
      svg {
        path {
          fill: var(--uduxYellowPrimary);
        }
      }
    }
  }
  .hover__stroke {
    :hover {
      svg {
        path {
          stroke: var(--uduxYellowPrimary);
        }
      }
    }
  }

  .icon__wrap {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      height: 25px;
      width: 25px;
      align-items: flex-end;
      justify-content: flex-end;
    }
    svg {
      path {
        transition: all 0.5s ease;
      }
    }
  }
`;

import { Album } from "types/artist"
import { Playlist, PlaylistType } from "types/playlist"

export const isAlbum = (alb?: Album | Playlist): alb is Album => {
  return alb?.type !== typeof PlaylistType
}

export const isPlaylist = (pl?: Album | Playlist): pl is Playlist => {
  return pl?.type === typeof PlaylistType
}

import { Space, SpaceProps } from "antd"
import styled from "styled-components"

export const VolumeControllerStyles = styled(Space)<SpaceProps>`
  background-color: transparent;
  flex-direction: column;


  .volume__bar {
    height: 6rem;
    width: 2px;
    margin: 0 auto;
    position: relative;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
  }

  .ant-space-item {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .volume__divider {
    margin: 0.5rem 0 0;
    background-color: rgba(74, 74, 74, 0.5);
  }

`

export const VolumeControlWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin-left: 65px;
  margin-bottom: 5px;


  .icons__wrap {
    height: 100%;
    cursor: pointer;
    margin-left: -100px;
    margin-bottom: -8px;
  }
`;
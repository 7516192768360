import { FooterView, FooterContent, Section } from "./footer.styles"
import { Link } from "react-router-dom"
import { Logo } from "assets/images/general/SVGExports"
import {
  FacebookSVG,
  InstagramSVG,
  YoutubeSVG,
  GooglePlaySVG,
} from "assets/images/payment/SVGExports"
import { FlexibleDiv } from "components"

export const Footer = () => {
  return (
    <FooterView>
      <FooterContent>
        <FlexibleDiv className="sections">
          <Section className="logo-container">
            <Logo className="logo" />
            <p className="description">
              It’s music the way you love it, whenever you want it!
            </p>
            <div className="socials">
              <FacebookSVG width="3rem" height="3rem" stroke="#000" />
              <InstagramSVG width="3rem" height="3rem" stroke="#000" />
              <YoutubeSVG width="3rem" height="3rem" stroke="#000" />
              <GooglePlaySVG width="3rem" height="3rem" stroke="#000" />
            </div>
          </Section>
          <Section className="products">
            <span className="title">Product</span>
            <Link to="#">Overview</Link>
            <Link to="#">Pricing</Link>
            <Link to="#">Customer Stories</Link>
          </Section>
          <Section className="resources">
            <span className="title">Resources</span>
            <Link to="#">Blog</Link>
            <Link to="#">Guides & Tour</Link>
            <Link to="#">Whats's new</Link>
          </Section>
          <Section className="company">
            <span className="title">Company</span>
            <Link to="#">About Us</Link>
            <Link to="#">Contact Support</Link>
          </Section>
        </FlexibleDiv>
        <hr />
        <p className="copyright">
          Copyright © 2022 | uduX. All rights reserved.
        </p>
      </FooterContent>
    </FooterView>
  )
}

import { IconProps } from "types";

export const DownloadIcon = ({
  color = "#FFFFFF",
  width,
  height,
}: IconProps) => (
  <svg
    width={width || "32"}
    height={height || "27"}
    viewBox="0 0 32 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fill__player__svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.969518 17.2578C1.22665 17.2578 1.47325 17.36 1.65507 17.5418C1.83689 17.7236 1.93904 17.9702 1.93904 18.2273V23.0749C1.93904 23.5892 2.14333 24.0824 2.50697 24.446C2.87061 24.8097 3.36381 25.014 3.87807 25.014H27.1465C27.6608 25.014 28.154 24.8097 28.5176 24.446C28.8812 24.0824 29.0855 23.5892 29.0855 23.0749V18.2273C29.0855 17.9702 29.1877 17.7236 29.3695 17.5418C29.5513 17.36 29.7979 17.2578 30.0551 17.2578C30.3122 17.2578 30.5588 17.36 30.7406 17.5418C30.9224 17.7236 31.0246 17.9702 31.0246 18.2273V23.0749C31.0246 24.1034 30.616 25.0899 29.8887 25.8171C29.1614 26.5444 28.175 26.953 27.1465 26.953H3.87807C2.84954 26.953 1.86314 26.5444 1.13586 25.8171C0.408581 25.0899 0 24.1034 0 23.0749V18.2273C0 17.9702 0.102145 17.7236 0.283965 17.5418C0.465785 17.36 0.712386 17.2578 0.969518 17.2578Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.827 21.0463C14.9171 21.1366 15.0241 21.2082 15.1419 21.2571C15.2596 21.306 15.3859 21.3311 15.5134 21.3311C15.641 21.3311 15.7672 21.306 15.885 21.2571C16.0028 21.2082 16.1098 21.1366 16.1999 21.0463L22.017 15.2292C22.199 15.0471 22.3013 14.8002 22.3013 14.5428C22.3013 14.2853 22.199 14.0384 22.017 13.8563C21.8349 13.6743 21.588 13.572 21.3305 13.572C21.0731 13.572 20.8262 13.6743 20.6441 13.8563L16.483 18.0195V0.969518C16.483 0.712386 16.3808 0.465785 16.199 0.283965C16.0172 0.102145 15.7706 0 15.5134 0C15.2563 0 15.0097 0.102145 14.8279 0.283965C14.6461 0.465785 14.5439 0.712386 14.5439 0.969518V18.0195L10.3827 13.8563C10.2007 13.6743 9.95379 13.572 9.69633 13.572C9.43887 13.572 9.19196 13.6743 9.00991 13.8563C8.82786 14.0384 8.72559 14.2853 8.72559 14.5428C8.72559 14.8002 8.82786 15.0471 9.00991 15.2292L14.827 21.0463Z"
      fill={color}
    />
  </svg>
);

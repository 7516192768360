import { Modal } from "antd"
import styled from "styled-components"
import { FlexibleDiv, FlexibleDivProps } from "../Box"
import { APIResponseTypes } from "types"

export interface ARBWrapProps extends FlexibleDivProps {
  type?: APIResponseTypes
}

export const ARBMain = styled(FlexibleDiv)<FlexibleDivProps>`
  /* position: sticky; */
  /* top: 0; */
  z-index: 1000;
`

export const ARBWrap = styled(FlexibleDiv)<ARBWrapProps>`
  background-color: ${({ type }) =>
    type === "SUCCESS"
      ? "var(--successMain)"
      : type === "ERROR"
      ? "var(--errorMain)"
      : type === "INFO"
      ? "var(--primaryInfo)"
      : "var(--grayPrimary)"};
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0.85rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  svg:first-child {
    font-size: 1.5rem;
    margin-right: 10%;
  }

  svg:last-child {
    font-size: 1.5rem;
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  .message {
    flex: 2;
    font-family: "Baguede", sans-serif;
  }

  .see__more {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem 1rem;

    svg {
      margin-right: 5%;
    }

    .message {
      font-size: 0.65rem;
      width: 80%;
    }

    .see__more {
      font-size: 0.75rem;
    }
  }
`

export const AREModalWrap = styled(Modal)`
  .ant-modal-content {
    color: var(--dampWhiteLight);
    background-color: var(--uduxGraySecondary);

    .ant-modal-close-x {
      color: var(--dampWhiteLight);
    }
  }

  .ant-modal-header {
    color: var(--primaryWhite);
    background-color: var(--uduxGraySecondary);
    border-bottom: 0.5px solid var(--primaryGray);
  }

  .ant-modal-title {
    color: var(--primaryWhite);
  }

  .ant-modal-footer {
    border-top: 0.5px solid var(--primaryGray);

    .ant-btn-primary {
      width: 20%;
      border-color: var(--uduxYellowPrimary);
      background-color: var(--uduxYellowPrimary);
    }
    .ant-btn-default {
      width: 20%;
      border-color: var(--errorPrimary);
      background-color: var(--errorPrimary);

      span {
        color: white;
      }
    }
  }
`

import { MoreIcon } from "assets"
import { FlexibleDiv, FlexibleDivProps } from "../Box"
import { LoginTabHeaderWrap } from "./tab.styles"
import { MdClose } from "react-icons/md"
import { LoginTabRoutes } from "pages/Auth/Login/login"

export type TabHeaderProps = {
  tabs: LoginTabRoutes
  activeTab?: string
  showCloser?: boolean
  pad_link?: string
  noGap?: boolean

  handleClose?: () => void
  handleOpenMore?: () => void
  setActiveTab: (args: string) => void
} & FlexibleDivProps
export const LoginTabHeader = ({
  tabs,
  activeTab,
  setActiveTab,
  handleClose,
  showCloser,
  handleOpenMore,
  ...props
}: TabHeaderProps) => {
  return (
    <LoginTabHeaderWrap  {...props}>
      <FlexibleDiv
        className="navigation__routes__web"
        flexWrap="nowrap"
      >
        {tabs.map((route, index) => (
          <span
            key={index}
            className={`tab__links ${
              activeTab === route.hash ? "active__tab" : ""
            }`}
            onClick={() => setActiveTab(route.hash)}
          >
            <p>{route.title}</p>
          </span>
        ))}
      </FlexibleDiv>

      {showCloser && (
        <FlexibleDiv className="more__and__close">
          <MoreIcon onClick={handleOpenMore} />

          <MdClose onClick={handleClose} className="close-icon" />
        </FlexibleDiv>
      )}
    </LoginTabHeaderWrap>
  )
}

import { Button, FlexibleDiv } from "components"
import { PaymentFailedWrapper } from "./verify-payment.styles"
import { useNavigate } from "react-router-dom"

export const PaymentFailed = () => {
  const navigate = useNavigate()

  return (
    <PaymentFailedWrapper width="50%" flexDir="column">
      <FlexibleDiv height="100%" width="100%" className="payment__container">
        <FlexibleDiv className="top__container" height="25%">
          <h2 className="congratulations__text">We have some bad news</h2>
          <p className="payment__text">
            Unfortunately your subscription to uduX failed
          </p>
        </FlexibleDiv>

        <FlexibleDiv
          className="bottom__container"
          flexDir="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <p>
            This could happen for a number of reasons beyond our control. We
            suggest that you try another payment method to get the benefits of a
            premium subscription.
          </p>
          <Button
            color="var(--primaryBlack)"
            contained
            onClick={() => navigate("/payment")}
          >
            <span>Retry Payment</span>
          </Button>
        </FlexibleDiv>
      </FlexibleDiv>
    </PaymentFailedWrapper>
  )
}

import { ActionProps } from "context/reducer"
import { Types } from "context/types"
import { AlbumState } from "./album.types"

export const albumReducer = (
  state: AlbumState,
  { type, payload }: ActionProps
) => {
  switch (type) {
    case Types.SET_CURRENT_ALBUM:
      return {
        ...state,
        ...payload,
      }

    case Types.UPDATE_CURRENT_ALBUM:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

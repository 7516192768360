import React, { useState } from "react";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import { StatusState } from "types";
import { validatePhoneNumber } from "utils";
import { FlexibleDiv } from "../Box";
import {
  PhoneInputInnerWrap,
  PhoneInputWrap,
  PhoneInputWrapProps,
} from "./phoneNumberField.styles";

export type PhoneInputProps = {
  name?: string;
  label?: string;
  value?: string;
  bordered?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  hasSuccess?: boolean;
  nullable?: boolean;
  borderBottom?:string;
  border?:string;
  onChange: (value: string) => void;
  onSetCountryCode: (value: string) => void;
} & PhoneInputWrapProps;

export const PhoneInput = ({
  name,
  label,
  value,
  onChange,
  disabled,
  onSetCountryCode,
  ...props
}: PhoneInputProps) => {
  const [status, setStatus] = useState<StatusState>();

  props.hasError = Boolean(status?.err);
  props.hasSuccess = Boolean(status?.success);

  return (
    <PhoneInputWrap {...props}>
      <FlexibleDiv className="label">
        <label htmlFor="countryCode">{label ?? "Phone Number"}</label>
      </FlexibleDiv>

      <PhoneInputInnerWrap disabled={disabled} {...props}>
        <ReactPhoneInput
          enableSearch
          value={value || "+234"} // Set default value to Nigeria's country code
          disabled={disabled}
          isValid={checkValid}
          inputProps={{
            name: name ?? "phoneNumber",
          }}
          areaCodes={"234" as unknown as object} // Bad typing from the react-phone-input-2 library
          country="ng" // Preselect Nigeria as the default country
          preferredCountries={["ng"]}
          searchClass="search-input"
          dropdownClass="dropdown-select"
          onChange={(phone, data: CountryData) => {
            onChange(phone);
            onSetCountryCode(`+${data.dialCode}`);
            validatePhoneNumber({ value: phone, setStatus });
          }}
          onBlur={({ target }) => {
            validatePhoneNumber({ value: target.value, setStatus });
          }}
        />
      </PhoneInputInnerWrap>
      <p className="input__error__text">{status?.err}</p>
    </PhoneInputWrap>
  );

  function checkValid(value: string, country: any) {
    if (value.match(/12345/)) {
      return "Invalid value: " + value + ", " + country.name;
    } else if (value.match(/1234/)) {
      return false;
    } else {
      return true;
    }
  }
};

import { FlexibleDiv } from "components/lib/Box";
import { CheckBox } from "components/lib/CheckBox";
import { ForwardedRef, forwardRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import { truncate } from "utils";
import { AddPlaylistCardWrap } from "./add-playlist-card";
import { Picture, StringNumberUnion } from "types";
import { Editor, PlaylistType } from "types/playlist";
import { CheckboxChangeEventTarget } from "antd/lib/checkbox/Checkbox";
import { RadioChangeEvent } from "antd";
import { Track } from "types/artist";
import { CollabIcon, SoloIcon } from "assets";

export const playlistTypes = {
  SOLO: "Solo",
  MANAGED: "Managed",
  COLLABORATIVE: "Collab",
  SINGLE: "Single",
  ALBUM: "Album",
};

export type AddPlaylistCardProps = {
  id: any;
  coverArt: Picture;
  title: string;
  editors: Editor[];
  tracks: Array<Track>;
  isMobile?: boolean;
  type: PlaylistType;
  onCheck: (target: CheckboxChangeEventTarget, id: StringNumberUnion) => void;
};

const AddPlaylistCardRefEl = forwardRef(
  (
    { onCheck, ...props }: AddPlaylistCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const navigate = useNavigate();
    const { id, coverArt, title, editors, tracks, type } = props;
    const __type = playlistTypes[type];

    return (
      <AddPlaylistCardWrap ref={ref}>
        <FlexibleDiv className="selectable--wrap wrap--layer">
          <FlexibleDiv className="checkbox">
            <CheckBox
              onChange={({ target }: RadioChangeEvent) => onCheck(target, id)}
            />
          </FlexibleDiv>

            <div className="image--wrap">
              <img src={coverArt?.url} alt={title} />
            </div>

          <FlexibleDiv className="info--wrap">
            <p className="title">{truncate(title)}</p>
            <small className="author">
              {editors?.map(({ id, username }: any, idx: string | number) => (
                <span
                  className="artist"
                  onClick={() => navigate(`/users/${id}`)}
                  key={idx}
                >
                  {username}
                </span>
              ))}
            </small>
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv className="type--wrap wrap--layer">
          <p className="type--icon">
            {__type === "Collab" ? <CollabIcon /> : <SoloIcon />}
          </p>
        </FlexibleDiv>

        <FlexibleDiv className="statistics--wrap wrap--layer">
          <FlexibleDiv>
            <p className="tracks">
              {tracks.length} {tracks.length === 1 ? "song" : "songs"}
            </p>
          </FlexibleDiv>

          <FlexibleDiv>
            <p className="type">100 Followers</p>
          </FlexibleDiv>
        </FlexibleDiv>
      </AddPlaylistCardWrap>
    );
  }
);

AddPlaylistCardRefEl.displayName = "AddPlaylistCardRefEl";

export const AddPlaylistCard = memo(AddPlaylistCardRefEl);

// import { AppGuideTour } from "components"
import { useMainContext } from "context"
import { useEffect, useRef } from "react"
import { HomeActivityPane } from "./components"

export const VisitorsHomePage = () => {
  const el = useRef<HTMLDivElement>()
  const {
    state: { app },
  } = useMainContext()

  const { scrollToBottom } = app

  useEffect(() => {
    if (scrollToBottom && el.current) {
      el.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    }
  }, [scrollToBottom])

  return (
    <>
      {/* {startTour && <AppGuideTour setStartTour={setStartTour} />} */}
      <HomeActivityPane />
    </>
  )
}

import { MoreIcon } from "assets"
import { CollabPlaylistTabRoutes } from "pages/Playlist/components/CollabPlaylistOrganizer"
import { MdClose } from "react-icons/md"
import { FlexibleDiv, FlexibleDivProps } from "../Box"
import { CollabPlaylistTHWrap } from "./tab.styles"

export type CollabPlaylistTabHeaderProps = {
  tabs: CollabPlaylistTabRoutes
  activeTab?: string
  showCloser?: boolean
  pad_link?: string
  noGap?: boolean
  icon?: React.ReactElement

  handleClose?: () => void
  handleOpenMore?: () => void
  setActiveTab: (args: string) => void
} & FlexibleDivProps

export const CollabPlaylistTabHeader = ({
  tabs,
  activeTab,
  setActiveTab,
  handleClose,
  showCloser,
  handleOpenMore,
  ...props
}: CollabPlaylistTabHeaderProps) => {
  return (
    <CollabPlaylistTHWrap {...props}>
      <FlexibleDiv className="navigation__routes__web" flexWrap="nowrap">
        {tabs.map((route, index) => (
          <span
            key={index}
            className={`tab__links ${
              activeTab === route.hash ? "active__tab" : ""
            }`}
            onClick={() => setActiveTab(route.hash)}
          >
            <p>
              <span className="icon__wrap">{route.icon}</span>
              <span>{route.title}</span>
            </p>
          </span>
        ))}
      </FlexibleDiv>

      {showCloser && (
        <FlexibleDiv className="more__and__close">
          <MoreIcon onClick={handleOpenMore} />

          <MdClose onClick={handleClose} className="close-icon" />
        </FlexibleDiv>
      )}
    </CollabPlaylistTHWrap>
  )
}

import { PlayerState, Types, useMainContext } from "context"
import {
  getPlaylistFeatArtist,
  getPlaylistsByQuery,
  sendStreamData,
} from "network"
import React, { useCallback } from "react"
import ReactHlsPlayer from "react-hls-player/dist"
import { StreamLogPayload, StreamLogSource } from "types"
import { addToQueue, getCurrentTrackID, pauseAudio, playNextTrack } from "utils"
import { getUserAgent } from "utils/function-helpers/userAgent"

export const AudioPlayer = () => {
  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext()

  const {
    repeat,
    isPlaying,
    isPaused,
    playingList,
    queuedTracks,
    currentTrack,
    isPlayerMuted,
  }: PlayerState = player

  const currentTrackTime: number | undefined = playerRef?.current?.currentTime

  const whilePlaying = () => {
    dispatch({
      type: Types.CURRENT_PLAYING_TIME,
      payload: playerRef?.current?.currentTime,
    })

    if (
      !app.isLoggedIn &&
      !window.location.pathname.includes("widget") &&
      currentTrackTime && // Ensure currentTrackTime is defined
      currentTrackTime > 25
    ) {
      dispatch({ type: Types.AUTH_STREAMING_WARNING, payload: true })
      pauseAudio(playerRef, dispatch)
    }
  }

  const fetchMoreTracks = useCallback(
    async (track: any, module: string) => {
      let tracksToAdd: any[] = []

      if (module === "album" || module === "track") {
        let artist: any | undefined = undefined
        track.forEach((t: any) => {
          artist = t?.metadata?.displayArtists.find(
            (da: any) => da?.isMainArtist
          )?.artist
        })

        try {
          const res = await getPlaylistFeatArtist(
            artist?.id
            // "sortByPopularity=asc"
          )

          tracksToAdd = res.length
            ? res.flatMap((r: any) => r.tracks).slice(0, 10)
            : []
        } catch (error) {
          console.log(error)
        }
      }
      if (module === "playlist") {
        try {
          const res = await getPlaylistsByQuery("managedBy=ADMIN")

          tracksToAdd = res.length
            ? res.flatMap((r: any) => r.tracks).slice(0, 10)
            : []
        } catch (error) {
          console.log(error)
        }
      }

      addToQueue(dispatch, tracksToAdd)
    },
    [dispatch]
  )

  return (
    <div style={{ display: "none" }}>
      {currentTrack?.file?.url && (
        // ||
        // currentTrack?.track?.file?.url
        <ReactHlsPlayer
          loop={repeat}
          controls={false}
          // volume={volume ** 2}
          playerRef={playerRef}
          muted={isPlayerMuted}
          onPlay={handleOnPlay}
          onEnded={handleOnEnded}
          onTimeUpdate={whilePlaying}
          autoPlay={isPaused || isPlaying}
          src={
            currentTrack?.file?.url
            // || currentTrack?.track?.file?.url
          }
        />
      )}
    </div>
  )

  async function handleOnPlay() {
    if (isPlaying && queuedTracks.length < 3) {
      const module: string = playingList?.type?.toLowerCase() ?? "track"
      fetchMoreTracks(queuedTracks, module)
    }

    const payload: StreamLogPayload = {
      source: StreamLogSource.WEB,
      userAgent: getUserAgent(),
      track: getCurrentTrackID(currentTrack),
    }

    try {
      await sendStreamData(payload)
    } catch (error) {
      console.error(error)
    }
  }

  function handleOnEnded() {
    playNextTrack(dispatch, {
      track: currentTrack,
      checkAutoEnd: true,
    })
  }
}

import classNames from "classnames"
import React, { memo } from "react"
import { SearchFilterWrap } from "./search-filter.styles"
import { FilterProps } from "./data-helper"

export type SearchCategoryFilterProps = {
  filterBy: FilterProps[]
  setActiveLabel: (param: string) => void
  activeLabel: string
}

export const SearchCategoryFilter = memo(
  ({
    filterBy = [],
    setActiveLabel,
    activeLabel = "",
  }: SearchCategoryFilterProps) => {
    const [active, setActive] = React.useState(activeLabel)

    return (
      <SearchFilterWrap mini>
        <ul className="filter__list">
          {filterBy.map((fl, idx) => (
            <li
              className={classNames("filter__list__item", {
                active__item: active === fl.label,
              })}
              onClick={() => {
                setActive(fl.label)
                setActiveLabel && setActiveLabel(fl.label)
              }}
              key={`${idx}__${fl.label}`}
            >
              {fl.name}
            </li>
          ))}
        </ul>
      </SearchFilterWrap>
    )
  }
)

SearchCategoryFilter.displayName = "SearchCategoryFilter"

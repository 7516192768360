import { useQuery, useQueryClient } from "react-query"
import { getAllAlbums } from "network"
import { Album } from "types/artist";


// General hook for fetching playlists with dynamic parameters
// General hook for fetching albums with dynamic parameters
export const useFetchPublicAlbum = (queryKey: string[]) => {
  const queryClient = useQueryClient();

  return useQuery<Album[]>(
    queryKey, // Cache key
    () => getAllAlbums(), // Query with dynamic filters
    {
      initialData: () => {
        // Check if data is cached already for albums
        const cachedData = queryClient.getQueryData<Album[]>(queryKey);
        return cachedData;
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  );
};

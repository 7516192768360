import listen from "assets/images/general/listen.png";
import { FlexibleDiv } from "../Box";
import { BannerWrap } from "./banner-styles";
import { Logo } from "assets/images/general/SVGExports";
const Banner = () => {
  return (
    <BannerWrap>
        <FlexibleDiv justifyContent="flex-start" alignItems="flex-start" flexDir="column" className="banner__content">
          <div className="udux__logo__wrap">
            <Logo className="logo" />
          </div>
          <div className="content">
            <h2 className="banner__title">
              The <span className="highlight1">No.01</span> Music platform
              <span className="highlight1"> empowering African artists</span>
            </h2>
            <p className="banner__description">
              uduX Music is an artist-first music platform that helps musicians
              <span className="highlight2"> reach</span> and
              <span className="highlight2"> engage fans across the world.</span>
            </p>
          </div>
        </FlexibleDiv>
        <FlexibleDiv className="listen__wrap" margin="15px 0 0 0" justifyContent="flex-end">
          <div className="list__image__wrap">
            <img className="listen" src={listen} alt="icon" />
          </div>
        </FlexibleDiv>
    </BannerWrap>
  );
};

export default Banner;

import { Section, SectionProps } from "components"
import styled from "styled-components"

interface FSEMWrapProps extends SectionProps {
  isMobile?: boolean
  noSmPad?: boolean
}
export const FSEMWrap = styled(Section)<FSEMWrapProps>`
  overflow-y: auto;

  .display__row {
    display: flex;
    flex-wrap: nowrap;
    margin: ${({ isMobile }) => (isMobile ? "1.5rem 0" : "1.5rem auto 1rem")};
    overflow: hidden;
    column-gap: 20px;
  }

  .section__display__controls {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .title {
      margin: 0;
      color: var(--textPrimary);
      font-family: var(--mBold);
      text-transform: capitalize;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: 0;
    height: 100%;

    .display__row {
      margin: 2rem auto;
      column-gap: 10px;
    }

    .section__display__controls {
      margin-bottom: 1rem;
      align-items: flex-start;

      .title {
        white-space: nowrap;
        font-size: ${({ isMobile }) => (isMobile ? "0.85" : "1rem")};
        max-width: ${({ isMobile }) => (isMobile ? "initial" : "280px")};
      }

      .see__all {
        padding-top: 0.35rem;
        font-size: 0.65rem;
      }
    }
  }

  @media screen and (max-height: 670px) {
    padding-bottom: 20px;
    .display__row {
      column-gap: 10px;
    }
  }
`

interface ExplorerBoxProps extends SectionProps {
  isMobile?: boolean
}
export const ExplorerBox = styled(Section)<ExplorerBoxProps>`
  column-gap: 5%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 600px) {
    /* flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")}; */
    row-gap: 4rem;
  }
`
